import config from "utils/config";

export const msalConfig = {
  auth: {
    clientId: config.SSO_GETCODE_PARAMS.client_id,
    authority: `${config.SSO_BASE_URL}/${config.TENANT}`,
    redirectUri: config.SSO_GETCODE_PARAMS.redirect_uri,
  },
  cache: {
    cacheLocation: "sessionStorage",
  },
};
