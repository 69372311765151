import React, { useCallback } from "react";
import { entityType } from "utils/commons";
import BusinessChallenge from "./BusinessChallenge";
import Event from "./Event";
import Offering from "./Offering";
import Qual from "./Qual";

import "./style.scss";

const ContentCard = ({
  documentType,
  code,
  userEditScope,
  isContributor,
  isDownloader,
  canLike,
  isDraft,
  callback,
  unitsColor,
  categories,
  showFileWithErrors = false,
  ...data
}) => {
  const handleAction = ({ action, versionType }) => {
    if (callback) {
      callback({ action, code, documentType, versionType });
    }
  };

  const renderCard = useCallback(
    documentType => {
      switch (documentType) {
        case entityType.QUALIFICATION:
          return (
            <Qual
              code={code}
              isContributor={isContributor}
              userEditScope={userEditScope}
              isDownloader={isDownloader}
              canLike={canLike}
              isDraft={isDraft}
              callback={handleAction}
              myLike={data.myLike}
              totalLikes={data.totalLikes}
              cardData={{ code, ...data }}
              unitsColor={unitsColor}
              categories={categories}
              showFileWithErrors={showFileWithErrors}
            />
          );
        case entityType.EVENT:
          return <Event data={{ code, ...data }} />;
        case entityType.OFFERING_TOPIC:
          return (
            <Offering
              code={code}
              isContributor={isContributor}
              isDownloader={isDownloader}
              canLike={canLike}
              userEditScope={userEditScope}
              callback={handleAction}
              myLike={data.myLike}
              totalLikes={data.totalLikes}
              cardData={{ code, ...data }}
              unitsColor={unitsColor}
              categories={categories}
              showFileWithErrors={showFileWithErrors}
            />
          );
        case entityType.BUSINESS_CHALLENGE:
          return (
            <BusinessChallenge
              code={code}
              isContributor={isContributor}
              isDownloader={isDownloader}
              userEditScope={userEditScope}
              canLike={canLike}
              callback={handleAction}
              myLike={data.myLike}
              totalLikes={data.totalLikes}
              cardData={{ code, ...data }}
            />
          );
        default:
          return <div className="no-card" />;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [code, unitsColor]
  );

  return renderCard(documentType);
};

export default ContentCard;
