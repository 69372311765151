import React from "react";
import "./style.scss"; // Assuming you will create a similar SCSS file for styling

const newsList = [
  {
    id: 1,
    title: "New Generative AI Chatbot",
    date: "July 20, 2024",
    summary:
      "We are thrilled to announce the release of our latest innovation, the Generative AI Chatbot. This cutting-edge technology is designed to revolutionize the way you interact with Myracle, offering a more intuitive and responsive experience. You should find the app already pre-installed in your MS Teams (in the left bar). Access it from {teams_app}. For detailed information on usage, please refer to our {guide}.",
    link: [
      {
        url: "https://teams.microsoft.com/l/entity/1b343419-5456-4eaa-aa46-79dc56fff1d8",
        text: "this link",
        id: "teams_app",
      },
      {
        url: "/chatbot_quickguide.pdf",
        text: "guide",
        id: "guide",
      },
    ],
  },
];

const NewsList = () => {
  const generateSummaryWithLinks = (summary, links) => {
    let initialSummaryIndex = 0;
    const elements = [];

    links.forEach((link, index) => {
      const linkPlaceholder = `{${link.id}}`;
      const linkIndex = summary.indexOf(linkPlaceholder);

      if (linkIndex !== -1) {
        const textBefore = summary.slice(initialSummaryIndex, linkIndex);
        initialSummaryIndex = linkIndex + linkPlaceholder.length;

        elements.push(textBefore);
        elements.push(
          <a
            key={`link-${index}`}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer">
            {link.text}
          </a>
        );
      }
    });

    // Add the remaining text after the last link
    elements.push(summary.slice(initialSummaryIndex));

    return elements;
  };

  return (
    <div className="news-list-container">
      <div className="news-list-title">LATEST NEWS</div>
      <ul className="news-items">
        {newsList.map(news => (
          <li key={news.id} className="news-item">
            <div className="news-date">{news.date}</div>
            <div className="news-title">{news.title}</div>
            <div className="news-summary">
              {news.summary && news.link
                ? generateSummaryWithLinks(news.summary, news.link)
                : news.summary}{" "}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NewsList;
