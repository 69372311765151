import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { getPersonas } from "services/clientPersona";
import { getRelated } from "services/clientPersona";
import BackSave from "components/BackSave";
import RelatedCardList from "components/RelatedCardList";
import { ReactComponent as PersonasImage } from "images/Personas icon.svg";
import useRelatedData from "../../hooks/useRelatedData";
import { getBusinessChallenge } from "../../services/businessChallenge";
import { getOfferingTopic } from "../../services/offeringTopic";
import { getQualification } from "../../services/quals";
import { entityType } from "../../utils/commons";

import "./style.scss";

const ClientPersonasDetail = props => {
  const [persona, setPersona] = useState();
  const [related, setRelated] = useState();
  const { personaId } = useParams();

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (!persona && isMounted) {
        const res1 = await getPersonas(personaId);
        setPersona(res1.data);
        const res2 = await getRelated(personaId);
        setRelated(res2.data);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { businessChallengeCodes, qualificationCodes, offeringTopicCodes } =
    related || {};

  const { data: qualCardData, loadMore: loadMoreQuals } = useRelatedData(
    qualificationCodes,
    getQualification,
    "ClientPersonasDetail error: "
  );
  const { data: offeringCardData, loadMore: loadMoreOffering } = useRelatedData(
    offeringTopicCodes,
    getOfferingTopic,
    "ClientPersonasDetail error: "
  );

  const { data: bcCardData, loadMore: loadMoreBc } = useRelatedData(
    businessChallengeCodes,
    getBusinessChallenge,
    "ClientPersonasDetail error: "
  );

  return (
    <div className="container detail">
      <BackSave />
      {!persona || !related ? (
        <Skeleton width={"100%"} height={"45vw"} />
      ) : (
        <>
          <div className="persona-header">
            <div className="logo-img">
              {persona?.image ? (
                <img
                  src={`data:image/png;base64,${persona?.image}`}
                  alt={persona?.name}
                />
              ) : (
                <PersonasImage />
              )}
            </div>
            <div className="title-section">
              <div className="title">{persona?.name}</div>
              <div className="short">{persona?.shortDescription}</div>
            </div>
          </div>
          <div className="persona-wrapper">
            <div className="desc">{persona?.longDescription}</div>
          </div>
          {businessChallengeCodes &&
          businessChallengeCodes?.length &&
          bcCardData?.length ? (
            <RelatedCardList
              elemList={businessChallengeCodes}
              documentType={entityType.BUSINESS_CHALLENGE}
              data={bcCardData}
              onGoRight={loadMoreBc}
            />
          ) : null}

          {offeringTopicCodes &&
          offeringTopicCodes.length > 0 &&
          offeringCardData?.length ? (
            <RelatedCardList
              elemList={offeringTopicCodes}
              documentType={entityType.OFFERING_TOPIC}
              data={offeringCardData}
              onGoRight={loadMoreOffering}
            />
          ) : null}

          {qualificationCodes &&
          qualificationCodes?.length > 0 &&
          qualCardData?.length ? (
            <RelatedCardList
              elemList={qualificationCodes}
              documentType={entityType.QUALIFICATION}
              data={qualCardData}
              onGoRight={loadMoreQuals}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

ClientPersonasDetail.propTypes = {};

export default ClientPersonasDetail;
