import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table-6";
import moment from "moment/moment";
import routes from "routes";

import "react-table-6/react-table.css";

import {errorsSeverities, entityType} from "utils/commons";
import PageSize from "components/SelectedFilters/PageSize";

import ShowFileErrors from "../ActionModal/ShowFileErrors";
import "./style.scss";


const TableErrors = ({
  loading = false,
  setCurrentPage,
  currentPage,
  totalPages,
  pageSize,
  setPageSize,
  data,
  documentType,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selected, setSelected] = useState();
  
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const columns = [
    {
      id: "code",
      Header: "Document code",
      accessor: "code",
      minWidth: 70,
      Cell: props => (
        <Link
          to={{
            pathname: `${documentType === entityType.OFFERING_TOPIC ?  routes.OFFERING : routes.QUALS}/${props.value}`,
            state: {
              code: props.value,
            },
          }}
          className="table-text-color">
          {`${props.value}`}
        </Link>
      ),
    },
    {
      id: "title",
      Header: "Title",
      accessor: "title",
      Cell: props => <span className="table-text-color">{props.value}</span>,
    },
    {
      id: "lastModifiedDate",
      Header: "Last modification",
      accessor: "lastModifiedDate",
      Cell: props => <span className="table-text-color">{props.value ? moment(props.value).format("DD MMM YYYY") : ''}</span>,
    },
    {
      id: "referenceContacts",
      Header: "Technical contact",
      accessor: "referenceContacts",
      Cell: props => <span className="table-text-color">{
        props.value && props.value.length > 0
          ? props.value.map((el, index) => `${index > 0 ? ', ' : ''}${el.email}`)
          : ''
      }</span>,
    },
    {
      id: "severity",
      Header: "Severity",
      accessor: "severity",
      minWidth: 150,
      Cell: props => (
        <div className="severity-container">
          {Object.keys(props.value)?.map(el => (
            <div
              key={el}
              className={`status-container ${el === errorsSeverities.HIGH ? 'error-HIGH' : el === errorsSeverities.MEDIUM ? 'error-MEDIUM' : 'error-LOW'}`}>
              {`${el} (${props.value[el]})`}
            </div>
          ))}
        </div>
      ),
    },
    {
      id: "actions",
      Header: "Actions",
      accessor: "code",
      minWidth: 70,
      Cell: (props) => <span className="table-link" onClick={() => {
        setSelected(props.value);
        setIsOpenModal(true);
      }}>Show errors</span>,
    },
  ];

  return (
    <div className="table-container">
      <div className="pre-header-data">
        <div className="massive-action-container" />
        <PageSize pageSize={pageSize} setPageSize={setPageSize} isErrors={true} />
      </div>
      <ReactTable
        data={data}
        columns={columns}
        minRows={1}
        showPageSizeOptions={false}
        showPageJump={false}
        pageSize={pageSize}
        collapseOnPageChange={false}
        PadRowComponent={() => null}
        noDataText="No user found"
        loading={isLoading}
        sortable={false}
        headerClassName="table-header"
        PaginationComponent={props => {
          return (
            <div className="pagination">
              <button
                disabled={currentPage === 0}
                className="change-page"
                onClick={() => {
                  props.onPageChange(currentPage - 1);
                  setCurrentPage(currentPage - 1);
                }}>
                <div className="arrow left" />
              </button>
              <span className="page-number">{`${
                totalPages === 0 ? currentPage : currentPage + 1
              } / ${totalPages}`}</span>
              <button
                disabled={currentPage + 1 === totalPages || totalPages === 0}
                className="change-page"
                onClick={() => {
                  props.onPageChange(currentPage + 1);
                  setCurrentPage(currentPage + 1);
                }}>
                <div className="arrow right" />
              </button>
            </div>
          );
        }}
      />
      <ShowFileErrors
        errors={Object.values(data)?.filter(el => String(selected) === String(el.code))?.[0]}
        isOpenProp={isOpenModal}
        setLoading={setIsLoading}
        onClose={() => {
          setSelected(undefined);
          setIsOpenModal(false);
        }}
      />
    </div>
  );
};
export default TableErrors;
