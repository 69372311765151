import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { entityType } from "../utils/commons";
import { CLEAR_BUSINESS_CHALLENGE } from "./businessChallenge";
import { CLEAR_OFFERING } from "./offerings";
import { CLEAR_QUAL } from "./quals";

export const CLEAN_STORE = createAsyncThunk(
  "@@MODALS/CLEAN_STORE",
  async ({ code, documentType }, thunkAPI) => {
    switch (documentType) {
      case entityType.QUALIFICATION:
        thunkAPI.dispatch(CLEAR_QUAL(code));
        break;
      case entityType.OFFERING_TOPIC:
        thunkAPI.dispatch(CLEAR_OFFERING(code));
        break;
      case entityType.BUSINESS_CHALLENGE:
        thunkAPI.dispatch(CLEAR_BUSINESS_CHALLENGE(code));
        break;

      default:
        break;
    }
  }
);

const modal = createSlice({
  name: "@@MODAL",
  initialState: {
    isOpen: false,
    modalId: null,
    modalProps: {
      code: null,
      selectedCoe: null,
      type: null,
      versionType: null,
      industryName: null,
    },
    shouldReloadOfferings: false,
  },
  reducers: {
    TRIGGER_MODAL: (state, action) => {
      state.isOpen = action.payload.isOpen;
      state.modalProps = action.payload.modalProps;
      state.shouldReloadOfferings = action.payload.shouldReloadOfferings;
    },
  },
});

export const { TRIGGER_MODAL } = modal.actions;

export default modal.reducer;
