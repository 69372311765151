import { createSlice } from "@reduxjs/toolkit";

const filters = createSlice({
  name: "@@OFFERINGSFILTERS",
  initialState: {},
  reducers: {
    SET_FILTERS: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    RESET_FILTERS: () => ({}),
  }
});

export const { SET_FILTERS } = filters.actions;
export const { RESET_FILTERS } = filters.actions;

export default filters.reducer;
