import React from "react";
import DatePicker from "react-datepicker";

import MidArrow from "images/arrow.svg";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";


const CustomDatePicker = props => {
  return (
    <div className="custom-datepicker-wrapper">
      <DatePicker {...props} locale={'en'} dateFormat={'dd/MM/yyyy'}/>;
      <div className="custom-datepicker-arrow">
        <img src={MidArrow} alt="arrow-control" />
      </div>
    </div>
  );
};

export default CustomDatePicker;
