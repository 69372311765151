import React from "react";
import Modal from "react-modal";
import { ReactComponent as ClearSvg } from "images/cancel.svg";

import "./style.scss";
import FileErrorsBox from "../FileErrorsBox";

const ShowFileErrors = ({
  isOpenProp,
  onClose,
  errors,
}) => {

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpenProp}
      className="custom-modal action-modal"
      ariaHideApp={false}>
      <div className="action-modal-content">
        <div className="action-modal-header">
          <div className="action-modal-title">
            Document Issues
          </div>
          <ClearSvg onClick={handleClose} />
        </div>
        <div className="errors-management-box">
          <FileErrorsBox errors={errors} />
        </div>
      </div>
    </Modal>
  );
};

export default ShowFileErrors;
