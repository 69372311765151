import { apis, serviceInterface } from "./config";

export const getLikes = code => {
  return serviceInterface.get(`${apis.LIKE}?code=${code}`);
};

export const addLike = params => {
  return serviceInterface.post(`${apis.LIKE}`, { ...params });
};

export const removeLike = (code, contentType) => {
  return serviceInterface.delete(
    `${apis.LIKE}?code=${code}&contentType=${contentType}`
  );
};

export const resetContentLikes = (code, contentType) => {
  return serviceInterface.delete(
    `${apis.LIKE}/${code}?contentType=${contentType}`
  );
};
