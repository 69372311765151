import HamburgerMenu from "react-hamburger-menu";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import routes from "routes";

import "./style.scss";


const IconBurger = ({ toggleVisibility, isOpen = true, color }) => {
  const location = useLocation();
  const iconColor = color
    ? color
    : location.pathname === routes.HOME
    ? "#F4F2F2"
    : "#3c3c3c";

  const handleClick = e => {
    e.stopPropagation();
    toggleVisibility();
  };
  return (
    <div className="burger-container" onClick={e => handleClick(e)}>
      <HamburgerMenu
        menuClicked={() => {}}
        isOpen={isOpen}
        width={18}
        height={15}
        strokeWidth={1}
        rotate={0}
        color={iconColor}
        borderRadius={0}
        animationDuration={0.5}
      />
    </div>
  );
};

IconBurger.propTypes = {
  toggleVisibility: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  color: PropTypes.string,
};

export default IconBurger;
