import React, { useState } from "react";
import { connect } from "react-redux";
import ReactTable from 'react-table-6';
import { useTracking } from "react-tracking";

import { DELETE_GROUP } from "redux/groups";
import {deleteGroup} from "services/backoffice";
import { auditActions, auditTypes } from "utils/commons";

import 'react-table-6/react-table.css';

import Edit from "images/edit.svg";
import Trash from "images/trash.svg";
import ConfirmActionModal from "../ActionModal/ConfirmActionModal";
import "./style.scss";
import GroupDetail from "../GroupDetail";


const TableGroup = ({
  deleteGroupFromRedux,
  roles,
  loading = false,
  data}) => {

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deletingGroup, setDeletingGroup] = useState(null);
  const [groupToEdit, setGroupToEdit] = useState(null);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const { trackEvent } = useTracking();

  const handleDelete = (group) => {
    trackEvent({
      type: auditTypes.ACTION,
      action: auditActions.DELETE_GROUP,
    });
    deleteGroup(group.id)
      .then((response) => {
        deleteGroupFromRedux(group);
        setIsOpenModal(false);
        setDeletingGroup(null);
      })
      .catch(e => {
        console.log(e);
      })
  }

  /*const getRoles = (ids) => {
    const rolesNames = Object.values(roles).map((role) => {
      if (ids.includes(role.id)) {
        return role.name;
      }
      return null;
    }).filter(el => el != null);
    if (rolesNames && rolesNames.length > 0) {
      return rolesNames.join(', ');
    } else {
      return '';
    }
  }*/

  const columns = [{
    id: 'name',
    Header: 'Name',
    accessor: 'name',
    Cell: props => <span className="table-text-color align-start">{`${props.value}`}</span>
  }, {
    id: 'userCount',
    Header: 'Members',
    accessor: 'userCount',
    Cell: props => <span className="table-text-color align-start">{`${props.value}`}</span>
  },
    /*
    {
    id: 'roles',
    Header: 'Roles',
    accessor: 'rolesIds',
    Cell: props => {
      const roles = getRoles(props.value);
      return <span className="table-text-color align-start">{roles}</span>;
    }
  },

     */
    {
    id: 'actions',
    Header: 'Actions',
    accessor: d => d,
    Cell: props => <div className="actions-container">
      <div onClick={() => {
        setIsOpenEditModal(true);
        setGroupToEdit(props.value);
      }}>
        <img className="active-trees-image" src={Edit} alt="edit"/>
      </div>
      {props.value.userCount === 0 ? <div onClick={() => {
        setIsOpenModal(true);
        setDeletingGroup(props.value);
        setIsOpenModal(true);
      }}>
        <img className="active-trees-image" src={Trash} alt="trash" />
      </div> : <div className="empty" />}
    </div>,
    minWidth: 40,
  }]

  return (
  <div className="table-container">
    <ReactTable
      data={data}
      columns={columns}
      minRows={1}
      showPageSizeOptions={false}
      showPageJump={false}
      defaultPageSize={10}
      collapseOnPageChange={false}
      PadRowComponent={() => null}
      noDataText="No groups found"
      loading={loading}
      headerClassName="table-header"
      PaginationComponent={props => {
        return (
          <div className="pagination">
            <button
              disabled={props.page === 0}
              className="change-page"
              onClick={() => {
                props.onPageChange(props.page - 1);
              }}>
              <div className="arrow left"/>
            </button>
            <span className="page-number">{`${props.pages === 0 ? props.page : props.page + 1} / ${props.pages}`}</span>
            <button
              disabled={props.page + 1 === props.pages || props.pages === 0}
              className="change-page"
              onClick={() => {
                props.onPageChange(props.page + 1);
              }}>
              <div className="arrow right"/>
            </button>
          </div>);
      }}
    />
    <ConfirmActionModal
      onConfirm={() => handleDelete(deletingGroup)}
      isOpenProp={isOpenModal}
      text="Are you sure you want to delete this group?"
      onClose={() => {
        setIsOpenModal(false);
        setDeletingGroup(null);
      }} />
    <GroupDetail group={groupToEdit} isOpenProp={isOpenEditModal} onClose={() => setIsOpenEditModal(false)}/>
  </div>)
}

export default connect(
  ({ roles }) => ({
    roles,
  }),
  dispatch => ({
    deleteGroupFromRedux: (payload) => dispatch(DELETE_GROUP(payload)),
  })
)(TableGroup);
