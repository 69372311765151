import React from "react";

import PropTypes from "prop-types";

import "./style.scss";


const HeroTitle = ({ type, title, subtitle, blueVariant, grayVariant, content }) => {
  return (
    <div
      className={`title-container ${blueVariant ? "blue-image" : ""} ${
        grayVariant ? "gray-image" : ""
      }`}>
      {type && <div className="type">{type}</div>}
      <div className={"title"}>{title}</div>
      <div className="subtitle">{subtitle}</div>
      {content}
    </div>
  );
};

HeroTitle.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  blueVariant: PropTypes.bool,
  grayVariant: PropTypes.bool,
  twoColumnsVariant: PropTypes.bool,
};

export default HeroTitle;
