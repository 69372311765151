import { apis, serviceInterface } from "./config";

export const getBookmarks = () => {
  return serviceInterface.get(`${apis.BOOKMARK}`);
};

export const removeAllBookmarks = userId => {
  return serviceInterface.delete(`${apis.BOOKMARK}`);
};

export const addBookmark = ({ documentType, documentId }) => {
  return serviceInterface.put(`${apis.BOOKMARK}/${documentType}/${documentId}`);
};

export const removeBookmark = ({ documentType, documentId }) => {
  return serviceInterface.delete(
    `${apis.BOOKMARK}/${documentType}/${documentId}`
  );
};

export const downloadBookmarks = () => {
  return serviceInterface.get(`${apis.BOOKMARK}/download`);
};
