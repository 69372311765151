import React, { useCallback, useState } from "react";
import ReactTooltip from "react-tooltip";
import DeleteIcon from "images/treeView/delete.svg";
import EditIcon from "images/treeView/edit.svg";
import MoveDown from "images/treeView/move_down.svg";
import MoveDownDisabled from "images/treeView/move_down_disabled.svg";
import MoveUp from "images/treeView/move_up.svg";
import MoveUpDisabled from "images/treeView/move_up_disabled.svg";
import { useTreeContext } from "../../contexts/Tree";
import {
  deleteTreeLevel,
  getTreeLevels,
  updateTreeLevels,
} from "../../services/treeView";
import {
  auditActions,
  entityType,
  treeLevelsLabels,
} from "../../utils/commons";
import { transformList } from "../../utils/utils";
import AddNewLevelModal from "../ActionModal/AddNewLevelModal";
import ConfirmActionModal from "../ActionModal/ConfirmActionModal";
import RenameLevelModal from "../ActionModal/RenameLevelModal";

import "./style.scss";

const Level = ({
  label,
  counter,
  children,
  isActive,
  id,
  expanded,
  level,
  readOnly,
  type,
  isManagePage,
  sortNumber,
  idx,
  lastElem,
  currentLevels,
  selectedCoeId,
  el,
}) => {
  const {
    selectedId: selectedTree,
    setSelectedId: setSelectedTree,
    setTreeList,
    isDidMount,
    setIsDidMount,
  } = useTreeContext();

  const [open, setOpen] = useState(expanded);
  const [action, setAction] = useState("");
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedLevelLabel, setSelectedLabel] = useState("");

  const handleClick = useCallback(
    e => {
      e.stopPropagation();
      setSelectedTree(id);
      !isDidMount && setIsDidMount && setIsDidMount(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  const changePosition = async (el1, el2) => {
    const idEl1 = el1.id;
    const idEl2 = el2.id;

    delete el1.id;
    delete el2.id;

    await updateTreeLevels(selectedCoeId, idEl1, {
      ...el1,
      sortNumber: el2.sortNumber,
    });
    const res2 = await updateTreeLevels(selectedCoeId, idEl2, {
      ...el2,
      sortNumber: el1.sortNumber,
    });

    setTreeList(transformList([res2?.data]));
  };

  const moveUp = async (currentLevels, sortNumber) => {
    const el1 = currentLevels?.find(el => el.sortNumber === sortNumber);
    const el2 = currentLevels[currentLevels.indexOf(el1) - 1];

    await changePosition(el1, el2);
  };

  const moveDown = async (currentLevels, sortNumber) => {
    const el1 = currentLevels?.find(el => el.sortNumber === sortNumber);
    const el2 = currentLevels[currentLevels.indexOf(el1) + 1];

    await changePosition(el1, el2);
  };

  return (
    <>
      <li
        className={`level-children-${level}`}
        data-id={id}
        key={id}
        onClick={handleClick}>
        <div
          className={
            isActive && !readOnly && !isManagePage ? "level active" : "level"
          }>
          {level && type === entityType.OFFERING_TREE_VIEW ? (
            <div className="icon-container">
              <div>
                <img
                  src={EditIcon}
                  alt="EditIcon"
                  onClick={() => {
                    setAction("edit");
                    setIsEditModalOpen(true);
                    setSelectedLabel(label);
                  }}
                />
              </div>
              <div className={children.length ? "disabled" : ""}>
                <img
                  src={DeleteIcon}
                  alt="DeleteIcon"
                  onClick={() => {
                    if (!children.length) {
                      setAction(auditActions.DELETE);
                      setIsOpenConfirmModal(true);
                    } else {
                      console.log("delete disabled");
                    }
                  }}
                />
              </div>
              <div>
                {Number(idx) === 0 ? (
                  <img src={MoveUpDisabled} alt="MoveUpDisabled" />
                ) : (
                  <img
                    src={MoveUp}
                    alt="MoveUp"
                    onClick={() => moveUp(currentLevels, sortNumber)}
                  />
                )}
              </div>
              <div>
                {Number(idx) === lastElem ? (
                  <img src={MoveDownDisabled} alt="MoveDownDisabled" />
                ) : (
                  <img
                    src={MoveDown}
                    alt="MoveDown"
                    onClick={() => moveDown(currentLevels, sortNumber)}
                  />
                )}
              </div>
            </div>
          ) : null}
          {level <= 2 ? (
            isManagePage ? (
              <div
                className="level-marker"
                onClick={() => {
                  setAction("add");
                  setIsAddModalOpen(true);
                  setSelectedLabel(label);
                }}>
                +
              </div>
            ) : children?.length ? (
              <div
                className="level-marker"
                onClick={() => (!readOnly ? setOpen(!open) : null)}>
                {open ? "-" : "+"}
              </div>
            ) : null
          ) : null}
          <div className="label-container">
            <span data-for={`label${id}`} data-tip={label} className="label">
              {label}
            </span>
          </div>
          {label?.length > 45 ? (
            <ReactTooltip
              id={`label${id}`}
              place="top"
              multiline
              border
              textColor="black"
              arrowColor="lightGray"
              backgroundColor="lightGray">
              {label}
            </ReactTooltip>
          ) : null}
          <div className="filter-number">{counter}</div>
        </div>
      </li>
      {open && children?.length ? (
        <>
          {children?.map((el, idx) => {
            return (
              <Level
                id={el.id}
                key={`${el.label} + ${idx}`}
                label={el.label}
                counter={el.counter}
                children={el.children}
                expanded={el.expanded}
                isActive={selectedTree === el.id}
                level={el.level}
                readOnly={readOnly}
                isManagePage={isManagePage}
                type={type}
                sortNumber={el.sortNumber}
                idx={idx}
                lastElem={children.length - 1}
                currentLevels={children}
                selectedCoeId={selectedCoeId}
                el={el}
              />
            );
          })}
        </>
      ) : null}
      <ConfirmActionModal
        onClose={() => setIsOpenConfirmModal(false)}
        onConfirm={async () => {
          if (action === auditActions.DELETE) {
            const res = await deleteTreeLevel(selectedCoeId, id);
            if (!res || res?.data === null) {
              console.log(
                `Error deleting content: ${res?.message?.toString()}`
              );
              setIsOpenConfirmModal(false);
            } else {
              const res = await getTreeLevels(selectedCoeId);
              setTreeList(transformList([res?.data]));
              setIsOpenConfirmModal(false);
              setSelectedTree(treeLevelsLabels.ALL_OFFERINGS);
            }
          }
          setIsOpenConfirmModal(false);
        }}
        text={
          action === auditActions.DELETE
            ? "Are you sure to delete the level?\n" +
              "\n" +
              'Offerings associated with this level will be moved to "uncategorized”.'
            : ""
        }
        isOpenProp={isOpenConfirmModal}
      />
      <RenameLevelModal
        onClose={() => setIsEditModalOpen(false)}
        contentCode={id}
        selectedCoeId={selectedCoeId}
        isOpenProp={isEditModalOpen}
        el={el}
        selectedLevelLabel={selectedLevelLabel}
      />
      <AddNewLevelModal
        onClose={() => setIsAddModalOpen(false)}
        contentCode={id}
        parentId={id}
        selectedCoeId={selectedCoeId}
        isOpenProp={isAddModalOpen}
        el={el}
        selectedLevelLabel={selectedLevelLabel}
      />
    </>
  );
};

export default Level;
