import routes from "routes";

const startYear = 2003;
const lastYear = new Date().getFullYear();

export const yearFilters = Array.from(
  { length: lastYear - startYear + 1 },
  (el, idx) => startYear + idx
);

export const filtersMyracle = {
  QUALS: [
    "organizational_unit",
    "industry",
    "personas",
    "epics",
    "year",
    "country",
    "project_type",
    "elapsed",
    "fte",
    "technologies",
    "business_challenges",
    "project_types",
    "customer",
  ],
  OFFERINGS: [
    "organizational_unit",
    "trendy_topic",
    //"technologies",
    "business_challenges",
    "industry",
  ],
  BUSINESS_CHALLENGES: ["personas"],
  CONTRIBUTOR_QUALS: ["industry"],
  CONTRIBUTOR_OFFERINGS: [],
  ADMIN_USER: [
    "group",
    "organizational_unit",
    "create_date_from",
    "create_date_to",
  ],
  ADMIN_ERRORS_OFFERING_TOPIC: [
    "error_severity",
    "organizational_unit",
    "industry",
    "technical_contact"
  ],
  ADMIN_ERRORS_QUALIFICATION: [
    "error_severity",
    "organizational_unit",
    "industry",
    "customer",
    "technical_contact"
  ],
  ADMIN_GROUP: ["roles"],
};

export const elapsed = [
  { id: "1", value: "10-30", label: "10 - 30 days" },
  { id: "2", value: "30-60", label: "1 mos - 2 mos" },
  { id: "3", value: "60-180", label: "2 mos - 6 mos" },
  { id: "4", value: "180-360", label: "6 mos - 1 yr" },
  { id: "5", value: "360-720", label: "1 yr - 2 yr" },
  { id: "6", value: "720", label: "2+ yr" },
];

export const fte = [
  { id: "1", value: "1-5" },
  { id: "2", value: "5-10" },
  { id: "3", value: "10-20" },
  { id: "4", value: "20-50" },
  { id: "5", value: "50", label: "50+" },
];

export const projectTypes = [
  { id: "STRATEGY", value: "STRATEGY" },
  { id: "DESIGN", value: "DESIGN" },
  { id: "TRANSFORMATION", value: "TRANSFORMATION" },
  { id: "BUILD", value: "BUILD" },
  { id: "OPERATE & SERVICE", value: "OPERATE & SERVICE" },
];

export const entityType = {
  QUALIFICATION: "QUALIFICATION",
  OFFERING_TOPIC: "OFFERING_TOPIC",
  BUSINESS_CHALLENGE: "BUSINESS_CHALLENGE",
  EVENT: "EVENT",
  BIP_UNITS: "BIP_UNITS",
  INDUSTRIES: "INDUSTRIES",
  SUBINDUSTRY: "SUBINDUSTRY",
  DRAFT: "DRAFT",
  PUBLISHED: "PUBLISHED",
  TREE_LEVEL_VIEW: "TREE_LEVEL_VIEW",
  OFFERING_TREE_VIEW: "OFFERING_TREE_VIEW",
};

export const errorsSeverities = {
  HIGH: 'ERROR',
  MEDIUM: 'WARNING',
  LOW: 'INFO',
}

export const errorsSeverity = [
  { id: errorsSeverities.HIGH, value: errorsSeverities.HIGH },
  { id: errorsSeverities.MEDIUM, value: errorsSeverities.MEDIUM },
  { id: errorsSeverities.LOW, value: errorsSeverities.LOW },
];

export const documentType = [
  {id: 'OFFERING_TOPIC', value: 'OFFERING_TOPIC'},
  {id: 'QUALIFICATION', value: 'QUALIFICATION'},
];

export const entityLabels = {
  [entityType.QUALIFICATION]: {
    full: "Qualification",
    singolar: "Qual",
    plural: "Quals",
  },
  [entityType.OFFERING_TOPIC]: {
    full: "Offering Topic",
    singolar: "Offering",
    plural: "Offerings",
  },
  [entityType.BUSINESS_CHALLENGE]: {
    full: "Business Challenge",
    singolar: "Business Challenge",
    plural: "Business Challenges",
  },
};

export const entityAdmin = {
  USERS: "users management",
  GROUPS: "groups management",
  CAPABILITIES: "capabilities",
};

export const metaData = {
  [routes.ABOUT]: { title: "About" },
  [routes.BUSINESS_CHALLENGE]: { title: "Business Challenges" },
  [routes.BIP_UNITS]: { title: "Organizational Units" },
  [routes.CLIENT_PERSONAS]: { title: "Client Personas" },
  [routes.EVENTS]: { title: "Events" },
  [routes.COLLABORATION_RULES]: { title: "Collaboration Rules" },
  [routes.OFFERING]: { title: "Offering topics" },
  [routes.HOME]: { title: "Homepage" },
  [routes.QUALS]: { title: "Qualifications" },
  [routes.SERP]: { title: "Search" },
};

export const auditTypes = {
  DOWNLOAD: "USER DOWNLOAD",
  ACTION: "USER ACTION",
  CONTENT: "USER CONTENT",
  ROUTE: "USER ROUTE",
  ADMIN: "ADMIN ACTION",
};

export const auditActions = {
  EDIT_AND_PUBLISH: "publish resource",
  EDIT_SAVE_DRAFT: "save qual in draft",
  OPEN_DETAIL: "see content",
  DELETE: "delete",
  DOWNLOAD: "download",
  NEW_SAVE_DRAFT: "save new qual in draft",
  LOAD_MORE: "load more",
  COPY_LINK: "copy link",
  NAVIGATE: "navigate",

  //bookmarks
  ADD_BOOKMARK: "add bookmark",
  DELETE_BOOKMARK: "delete bookmark",
  DOWNLOAD_SET: "download bookmarks",

  //ask content
  ASK_CONTENT: "ask for a new content",

  //comments
  ADD_COMMENT: "add comment",
  UPDATE_COMMENT: "update comment",
  DELETE_COMMENT: "delete comment",

  //likes
  ADD_LIKE: "add like",
  REMOVE_LIKE: "remove like",
  RESET_LIKES: "reset likes",

  //users management
  MODIFY_USER: "modify user detail",
  ADD_USER: "add new user",
  ENABLE_USER: "enable user",
  DISABLE_USER: "disable user",
  DELETE_USER: "delete user",

  //groups management
  DELETE_GROUP: "delete group",
  MODIFY_GROUP: "modify group detail",
  ADD_GROUP: "add new group",
};

export const unitTypes = {
  COE: "COE",
  MFU: "MFU",
  VALUE_LINE: "VALUE_LINE",
  SUBINDUSTRY: "SUBINDUSTRY",
};

export const contentCardLabels = {
  TRENDING: "TRENDING",
  CODE: "CODE",
  LAST_EDIT: "LAST EDIT",
  CATEGORY: "CATEGORY",
  TRENDY: "TRENDY",
  CONTRIBUTOR_CODE: "Code: ",
  CONTRIBUTOR_LAST_EDIT: "Last edit: ",
  OWNER_UNIT: "Owner Unit: ",
  INDUSTRIES: "Industries: ",
  CONTRIBUTOR_UNITS: "Contributor Units: ",
  INSTITUTIONAL: "INSTITUTIONAL",
};

export const reactToolTip = {
  DOWNLOAD_PPT: "Download ppt",
  DOWNLOAD_PPT_WITHOUT_OU: "Download ppt with hidden OU name",
};

export const inputLength = {
  ACHIEVEMENT_TITLE: 28,
  ACHIEVEMENT_DESCRIPTION: 54,
  PHONE: 6,
};

export const treeLevelsLabels = {
  ALL_OFFERINGS: "all-offerings",
  UNCATEGORIZED: "uncategorized",
};

export const treeLevelTitles = {
  ALL_OFFERINGS: "ALL OFFERINGS",
  UNCATEGORIZED: "UNCATEGORIZED",
};

export const treeLevelDescriptions = {
  CONTRIBUTOR_DESCRIPTION:
    "In this page, you can drag & drop Offerings from the right side into their categorisation tree levels (left side).",
  CONTRIBUTOR_DESCRIPTION_LEVEL_1:
    " - LEVEL 1 (Organizational Unit): please put here your Institutional Decks only.",
  CONTRIBUTOR_DESCRIPTION_LEVEL_2: " - LEVEL 2: Lines of Offering",
  CONTRIBUTOR_DESCRIPTION_LEVEL_3:
    " - LEVEL 3: Vertical Decks below each Line of Offering",
  MANAGE_VIEW_DESCRIPTION:
    "In this page you can create, delete, move levels starting from the institutional level.",
  MODAL_DESCRIPTION:
    "In this page it is not possible to associate Offering to hierarchy",
};
