import React, { useEffect, useState } from "react";

import "./style.scss";
import iconBurger from "../IconBurger";

// content: Array<{title: string; children: html}>
const Tabs = ({ content }) => {
  //get url query params
  const queryParams = new URLSearchParams(window.location.search);
  const documentType = queryParams.get("documentType");

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    switch (documentType) {
      case "QUALIFICATION":
        setSelectedTab(0);
        break;
      case "OFFERING_TOPIC":
        setSelectedTab(1);
        break;
      default:
        setSelectedTab(0);
        break;
    }
  }, [documentType]);

  return (
    <div className="custom-tabs">
      <div className="tabs-container">
        {content.map((tab, index) => (
          <div
            key={`tab-button-${index}`}
            className={`tab ${selectedTab === index ? "selected" : ""}`}
            onClick={() => setSelectedTab(index)}>
            {tab.title}
          </div>
        ))}
      </div>
      <div className="tabs-content">
        {content.map((tab, index) => (
          <div
            key={`tab-content-${index}`}
            id={`tab-${index}`}
            className={`tab-content ${
              selectedTab === index ? "visible-tab" : ""
            }`}>
            {tab.children}
          </div>
        ))}
      </div>
    </div>
  );
};
export default Tabs;
