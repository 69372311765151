import Accordion from "components/Accordion";
import HeroTitle from "components/HeroTitle";

import BrowseIcon from "images/Browse.svg";
import CollaborateIcon from "images/Collaborate.svg";
import GetSupportIcon from "images/get_support.svg";
import "./style.scss";


const CollaborationRules = () => {
  return (
    <div className="container coll-rules-container">
      <HeroTitle
        type="Collaboration Rules"
        title="How to use Myracle"
        subtitle="In this section you may find the general collaboration rules regulating content browsing, request for support, collaboration, sales lifecycle up to the final contract signature and project deployment."
        blueVariant
      />
      <div className="features-container">
        <div>
          <div className="title">
            <img src={BrowseIcon} alt=""/>Browse
          </div>
          Choose your preferred browsing method (by COE, by Customer Persona, by
          Burning Challence), or use the search facility via keywords or
          cognitive search, apply additional filters on most relevant metadata,
          and look for desired content.
        </div>
        <div>
          <div className="title">
            <img src={GetSupportIcon} alt=""/>Get support
          </div>
          When you have identified interesting content, download it and/or
          contact COE reference person for details or to start COE engagement
        </div>
        <div>
          <div className="title">
            <img src={CollaborateIcon} alt=""/>Collaborate
          </div>
          Proceed offline (in the future through new CRM system) to engage the
          COE, collaborate in meeting the Client and preparing a standard or
          tailored offering.
        </div>
      </div>
      <HeroTitle
        title="Workflows and rules"
        subtitle={`After Users find content which is potentially useful to fulfill a specific Client need, they may engage the relevant COE reference person (clearly written in each offering/qual page) and ask for support or require more information / insight or start a collaboration . `}
      />
      <div className="accordions-container">
        <Accordion
          title="Pre-sale phase"
          content={
            <p>
              Myracle MFU users browse the platform, identify potentially
              relevant documents (offering/quals), download them and
              preliminarily check Clients/Prospects interest. In case of
              Client/Prospect interest or Need of clarification MFU can contact
              COE (one or more) to arrange a meeting/call to discuss opportunity
              or require more information. COE will answer within 48 hours (or
              less in case of a proven urgency). Meeting/call clarifies
              Client/Prospect, Scope/Perimeter, Need, COE offering fit, presale
              costs sharing policy. First meeting cost is in charge of MFU; from
              that meeting on, unless different written agreement is takend, the
              rest of the presale costs are shared between MFU and COE. At each
              stage of the lead workflow, COE can "deny" collaboration" to the
              MFU motivating the denial with solid arguments (e.g. offering not
              aligned to need or technical unfeasibility/misalignment or
              economic unfeasibility). MFU can perform unilateral escalation
              (unless in case of total tecnical unfeasibility) and ask anyway
              COE support, assuming 100% of presale costs. All opportunity
              phases need to be tracked via email (or via CRM system, when
              ready). At each stage of the presale involvment of another COE (in
              addition) or reassignment to other COE can be evaluated,
              especially for "cross COE" offerings
            </p>
          }
        />
        <Accordion
          title="Sale phase"
          content={
            <p>
              After confirmation of need/perimeter and COE involvment, COE and
              MFU work together on support material preparation (based on COE
              detailed offering decks and MFU Client knowledge) and meet the
              Client/Prospect. Client meeting is typically performed within 1
              week from first MFU/COE introductory meeting/call. After Client
              meeting, COE/MFU agree together whether to pursue presale and
              prepare an offer or to stop the opportunity. In case of lack of
              agreement, escalation can be performed, and cost sharing policy
              can be reviewed. Timeline depends on the specific lead. During the
              sale phase hypothesis on the project staffing is made considering
              a proper mix of MFU and COE(s) professionals depending on the
              specific situation / country / project type.
            </p>
          }
        />
        <Accordion
          title="Delivery phase"
          content={
            <p>
              In case Client decides to accept the offer and proceed to a
              project stage, COE and MFU will agree on the proper staffing and
              delivery strategy: COE will support either with direct involvment
              with professionals from remote or physically at Client's premises,
              or indirectly contributing to the delivery made by the MFU's
              resources, performing quality assurance or support. Again, this
              depends on the specific case, country, Client requirements and
              project type.
            </p>
          }
        />
        <Accordion
          title="Evaluation phase"
          content={
            <p>
              After the project's end, the MFU and COE(s) will share their
              evaluation of the project (expectations, outcome, main takeaways,
              COE and MFU performance), so to allow a constant improvement in
              the collaboration between MFUs and COE(s).
            </p>
          }
        />
      </div>
    </div>
  );
};

export default CollaborationRules;
