import React from "react";
import Modal from "react-modal";

import { ReactComponent as ClearSvg } from "images/cancel.svg";
import { ReactComponent as Check } from "images/check.svg";

import "./style.scss";

const SuccessMessageModal = ({ isOpenProp, closeModal }) => {
  const afterOpenModal = () => {
    setTimeout(() => closeModal(), 2500);
  };

  return (
    <Modal
      onAfterOpen={afterOpenModal}
      ariaHideApp={false}
      isOpen={isOpenProp}
      className="custom-modal success-message-modal">
      <div className="close-icon-container">
        <ClearSvg onClick={closeModal} />
      </div>
      <div className="success-modal-content">
        <Check />
        <div className="description">
          Your request has been successfully submitted
        </div>
      </div>
    </Modal>
  );
};

export default SuccessMessageModal;
