import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import ChevronRight from "images/chevron_right.png";
import { entityType } from "../../utils/commons";

import "./style.scss";

const GoBackButton = ({ type, setVersionType }) => {
  const history = useHistory();

  const handleClick = useCallback(() => {
    type ? setVersionType(entityType.OFFERING_TOPIC) : history.goBack();
  }, [history, setVersionType, type]);

  return (
    <div onClick={handleClick} className="back-link">
      <img src={ChevronRight} alt="back-icon" />
      <span>BACK</span>
    </div>
  );
};

export default React.memo(GoBackButton);
