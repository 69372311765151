import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { GET_GROUPS } from "redux/groups";
import { GET_ROLES } from "redux/roles";
import { filtersMyracle } from "utils/commons";
import Filters from "components/Filters";
import GroupDetail from "components/GroupDetail";
import SelectedFilters from "components/SelectedFilters";
import TableGroup from "components/Tables/TableGroup";

import SearchIcon from "images/search.svg";
import "./style.scss";

const GroupsManagement = ({ populateGroups, populateRoles, groups, roles }) => {
  const [loading, setLoading] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [search, setSearch] = useState("");
  const [currentGroups, setCurrentGroups] = useState(groups);
  const [isOpenGroupModal, setIsOpenGroupModal] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    order_by: "ALPHABETIC",
  });

  const viewableFilters = filtersMyracle.ADMIN_GROUP;

  useEffect(() => {
    if (viewableFilters) setShowFilters(viewableFilters.length > 0);
  }, [viewableFilters]);

  const handleResetFilters = () => {
    setSelectedFilters({
      order_by: "ALPHABETIC",
    });
  };

  const FilterByName = searchString => {
    return groups.filter(group =>
      group.name.toLowerCase().includes(searchString.toLowerCase())
    );
  };

  const FilterByRoles = (groups, rolesNames) => {
    return groups.filter(group =>
      group.rolesIds.find(roleId =>
        rolesNames.find(
          selectedRoles =>
            selectedRoles ===
            Object.values(roles).find(role => role.id === roleId).name
        )
      )
    );
  };

  const orderBy = (groups, orderBy) => {
    let orderedGroups = Object.values(groups);
    orderedGroups = orderedGroups.slice().sort((a, b) => {
      if (orderBy === "ALPHABETIC") {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      } else if (orderBy === "CREATION DATE") {
        if (a.createdDate < b.createdDate) {
          return -1;
        }
        if (a.createdDate > b.createdDate) {
          return 1;
        }
        return 0;
      }
    });
    return orderedGroups;
  };

  useEffect(() => {
    if (groups.length < 1) {
      populateGroups();
    }
    if (roles.length < 1) {
      populateRoles();
    }
    let filteredByName;
    if (search) {
      filteredByName = FilterByName(search);
    } else {
      filteredByName = groups;
    }
    let filteredByRoles = filteredByName;
    if (selectedFilters.roles && selectedFilters.roles.length > 0) {
      filteredByRoles = FilterByRoles(filteredByName, selectedFilters.roles);
    }
    const orderedGroups = orderBy(filteredByRoles, selectedFilters.order_by);
    setCurrentGroups(orderedGroups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, groups, selectedFilters, roles]);

  const handleChangeFilter = (key, value) => {
    setSelectedFilters({
      ...selectedFilters,
      [key]: value,
    });
  };

  return (
    <div className="container contributor groups-management">
      <div className="contributor-header ">
        <div className="contributor-banner">
          <span>ADMIN MODE</span>
        </div>
        <div className="title">Groups Management</div>
        <div className="action">
          <div className={`add-item`} onClick={() => setIsOpenGroupModal(true)}>
            ADD NEW GROUP
          </div>
        </div>
        <div className="search">
          <div className="search-bar-container">
            <div className="search-icon">
              <img src={SearchIcon} alt="lens" />
            </div>
            <input
              type="text"
              className="search-bar"
              value={search || ""}
              onChange={e => {
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="results-container no-margin">
        {showFilters && (
          <div
            className={`side-filter-bar ${openFilters ? "open-filter" : ""}`}>
            <Filters
              handleChangeFilter={handleChangeFilter}
              viewableFilters={viewableFilters}
              selectedFilters={selectedFilters}
              closeFilters={() => setOpenFilters(false)}
              numberItems={currentGroups?.length}
              handleResetFilters={handleResetFilters}
            />
          </div>
        )}
        <div className="results">
          {showFilters && (
            <SelectedFilters
              selectedFilters={selectedFilters}
              handleChangeFilter={handleChangeFilter}
              elementsCount={currentGroups?.length || 0}
              openFilters={() => setOpenFilters(!openFilters)}
              userOrder={true}
            />
          )}
          />
          <TableGroup data={currentGroups} loading={loading} />
          <GroupDetail
            isOpenProp={isOpenGroupModal}
            onClose={() => setIsOpenGroupModal(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(
  ({ groups, roles }) => ({ groups, roles }),
  dispatch => ({
    populateGroups: () => dispatch(GET_GROUPS()),
    populateRoles: () => dispatch(GET_ROLES()),
  })
)(GroupsManagement);
