import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTracking } from "react-tracking";
import routes from "routes";
import moment from "moment";
import {
  auditActions,
  auditTypes,
  contentCardLabels,
  entityType,
} from "utils/commons";
import DeleteIcon from "images/delete.png";
import EditIcon from "images/edit.svg";
import FlameSvg from "images/flame.svg";
import Likes from "../Likes";

const BusinessChallenge = ({
  code,
  personas,
  userEditScope,
  isContributor,
  canLike,
  callback,
  cardData,
  myLike,
  totalLikes,
}) => {
  const { trackEvent } = useTracking({
    code: code,
    resourceType: entityType.BUSINESS_CHALLENGE,
  });

  const { personasIds, description, isTrendy, lastModifiedDate } =
    cardData || {};

  const personasList = () => {
    return personasIds
      ? personasIds
          .map(ouOff => personas?.find(ou => ou.id === ouOff)?.name)
          .join(",")
          .substr(0, 50)
          .split(",")
      : [];
  };

  const handleAction = (event, action) => {
    event.stopPropagation();
    event.preventDefault();
    if (callback) {
      callback({ action, code, versionType: entityType.BUSINESS_CHALLENGE });
    }
  };

  const isAdmin = userEditScope && userEditScope?.orgIds === "all";

  return (
    <Link
      to={{
        pathname: `${routes.BUSINESS_CHALLENGE}/${code}`,
        state: {
          code,
          title: description,
          isEditable: isAdmin,
          userEditScope,
        },
      }}
      onClick={() =>
        trackEvent({
          type: auditTypes.CONTENT,
          action: auditActions.OPEN_DETAIL,
          resourceType: entityType.BUSINESS_CHALLENGE,
        })
      }
      className="result-card offering-card">
      <div className="status">
        {isTrendy && (
          <>
            <img src={FlameSvg} alt="" className="status-icon" />
            <div className="status-label">{contentCardLabels.TRENDY}</div>
          </>
        )}
        {canLike && !isContributor && (
          <div className="card-likes-container">
            <Likes
              contentType={entityType.BUSINESS_CHALLENGE}
              code={code}
              myLikeData={myLike}
              totalLikes={totalLikes}
            />
          </div>
        )}
      </div>
      {description && (
        <div className="name">{`${description?.substr(0, 100)}${
          description?.length > 100 ? "..." : ""
        }`}</div>
      )}
      <div className="body">
        <div className="manager">
          {personasList().map((p, i) => (
            <span key={i} className="persona">
              {p}
            </span>
          ))}
        </div>
      </div>
      {(isContributor || isAdmin) && (
        <div className="qual-footer draft-footer">
          <div className="info">
            <div className="label">{contentCardLabels.CODE}</div>
            <div className="value">{code}</div>
          </div>
          <div className="info">
            <div className="label">{contentCardLabels.LAST_EDIT}</div>
            <div className="value">{`${moment(lastModifiedDate).format(
              "DD/MM/YYYY"
            )}`}</div>
          </div>
          <div className="info icons">
            <img
              src={EditIcon}
              alt="Edit Qual Draft"
              onClick={callback ? e => handleAction(e, "edit") : null}
            />
            <img
              src={DeleteIcon}
              alt="Delete Qual Draft"
              onClick={callback ? e => handleAction(e, "delete") : null}
            />
          </div>
        </div>
      )}
    </Link>
  );
};

export default connect(({ personas, organizationalUnits }) => ({
  personas,
  organizationalUnits,
}))(BusinessChallenge);
