import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getDictionary, getTechnicalReferenceContacts} from "services/dictionary";

export const GET_DICTIONARY = createAsyncThunk(
  "@@DICTIONARIES/GET_DICTIONARY",
  async (params, thunkAPI) => {
    const state = thunkAPI.getState();
    const { type } = params;
    if (!state?.dictionaries[type]) {
      if (type === 'TECHNICAL_CONTACT') {
        const response = await getTechnicalReferenceContacts();
        return {...state?.dictionaries, [type]: response?.data};
      } else {
        const response = await getDictionary({...params, no_graphic: true});
        if (response?.data)
          return {...state?.dictionaries, [type]: response?.data};
      }
    }
    return { [type]: state?.dictionaries[type] };
  }
);

const dictionaries = createSlice({
  name: "@@DICTIONARIES",
  initialState: {},
  reducers: {},
  extraReducers: {
    [GET_DICTIONARY.fulfilled]: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export default dictionaries.reducer;
