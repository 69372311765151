import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ReactComponent as Logo } from "images/Logo Myracle Rosso flat.svg";

import "./styles.scss";

const LoadApp = () => {
  return (
    <div className="load-app">
      <div className="wrapper">
        <Logo />
        <SkeletonTheme highlightColor="#e40046">
          <Skeleton width={300} height={10} />
        </SkeletonTheme>
      </div>
    </div>
  );
};

export default LoadApp;
