import ReactTooltip from "react-tooltip";
import "./style.scss";

const ColorBar = props => {
    const { id, legend, category } = props;

    const tooltipStyle = {
        backgroundColor: 'white',
        textColor: 'black',
        border: true,
        borderColor: '#ccc',
        className: 'color-bar-tooltip',
        effect: 'float'
    }
    return (
        <div className="color-bar-container">
            {legend && Object.entries(legend).map(([key, item]) => {
                if(category.includes(key))
                    return (
                        <div key={`${id}-${key}`}  style={{width: '100%'}}>
                            <div className="color-bar" style={{backgroundColor: `#${item.color}`}} data-tip={item.name}>
                            </div>
                            <ReactTooltip {...tooltipStyle}/>
                        </div>
                    )
            })
            }
        </div>
    )
}

export default ColorBar;