import { useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useTracking } from "react-tracking";

import { submitRequest } from "services/contentRequest";
import { auditActions, auditTypes } from "utils/commons";
import { checkField, getGroupIdsByGroupsNames } from "utils/utils";
import CustomSelect from "components/CustomSelect";
import SuccessMessageModal from "components/SuccessMessageModal";
import "./style.scss";

const AskContent = ({ profile, organizationalUnits }) => {
  const [selectedBipUnit, setSelectedBipUnit] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { trackEvent } = useTracking();

  const {
    handleSubmit,
    register,
    setError,
    errors,
    getValues,
    watch,
    setValue,
  } = useForm();

  const submit = data => {
    const allData = {
      ...data,
      organizationalUnitId: `${getGroupIdsByGroupsNames(
        organizationalUnits,
        selectedBipUnit
      )}`,
    };
    trackEvent({
      type: auditTypes.ACTION,
      action: auditActions.ASK_CONTENT,
      organizationalUnitId: `${getGroupIdsByGroupsNames(
        organizationalUnits,
        selectedBipUnit
      )}`,
    });
    submitRequest(allData)
      .then(() => {
        setIsSubmitted(true);
      })
      .catch(e => {
        console.log("Warning: Error submitting request", e);
      });
  };

  const clearAll = () => {
    setValue("description", "");
    setSelectedBipUnit(null);
  };

  return (
    <div className="container content-request">
      <div className="modal">
        <section className="modal-main">
          <div className="bo-container">
            <div className="legend">
              <div>
                <label>
                  <span>*</span>mandatory for saving
                </label>
              </div>
            </div>
            <form className="bo-form-container">
              <div className="bo-form-section-title">
                WRITE A REQUEST FOR A NEW CONTENT
              </div>
              <div className="bo-form-section">
                <div className="bo-form-input-group">
                  <p className="">
                    Attention: make sure the content you are requesting is not
                    already available on MYracle
                  </p>
                  <div className="bo-form-input-container">
                    <label>
                      first name<span>*</span>
                    </label>
                    <input
                      name="firstName"
                      type="text"
                      value={profile?.name}
                      readOnly
                      className="disabled"
                    />
                  </div>
                  <div className="bo-form-input-container">
                    <label>
                      last name<span>*</span>
                    </label>
                    <input
                      name="lastName"
                      type="text"
                      value={profile?.surname}
                      readOnly
                      className="disabled"
                    />
                  </div>
                  <div className="bo-form-input-container">
                    <label>
                      email address<span>*</span>
                    </label>
                    <input
                      name="emailAddress"
                      type="email"
                      value={profile?.email}
                      readOnly
                      placeholder="Write email address here..."
                      className="disabled"
                    />
                  </div>
                </div>
                <div className="bo-form-input-container">
                  <label>
                    bip unit<span>*</span>
                  </label>
                  <CustomSelect
                    name="bipUnit"
                    label={
                      selectedBipUnit ? selectedBipUnit : "Select a Bip Unit"
                    }
                    value={selectedBipUnit}
                    items={organizationalUnits?.map(o => ({
                      id: o.id,
                      value: o.name,
                    }))}
                    onChange={val => setSelectedBipUnit(val)}
                    closeOnSelect
                    isAutocomplete
                  />
                  {errors.bipUnit && <p>This field is required</p>}
                </div>
                <div className="bo-form-input-container">
                  <label className="bo-large-label">
                    Request body
                    <span className="bo-input-counter">
                      {getValues("description")?.length || 0}/1024
                    </span>
                  </label>
                  <textarea
                    name="description"
                    placeholder="Write content request description here..."
                    rows={10}
                    maxLength={1024}
                    //value={description}
                    onChange={event =>
                      checkField("description", event, 1024, setError, false)
                    }
                    onFocus={event =>
                      checkField("description", event, 1024, setError, false)
                    }
                    ref={register({ maxLength: 1024 })}
                  />
                  {errors?.description && errors.description?.message && (
                    <p>{errors.description.message}</p>
                  )}
                </div>
              </div>
            </form>
            <div className="bo-submit-container">
              <button
                type="submit"
                className="bo-button"
                disabled={!selectedBipUnit}
                onClick={() => handleSubmit(submit)()}>
                Send Request
              </button>
            </div>
          </div>
        </section>
      </div>
      <SuccessMessageModal
        isOpenProp={isSubmitted}
        closeModal={() => {
          setIsSubmitted(false);
          clearAll();
        }}
      />
    </div>
  );
};

export default connect(
  ({ profile, organizationalUnits }) => ({ profile, organizationalUnits }),
  () => ({})
)(AskContent);
