import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getGroups} from "../services/backoffice";

export const GET_GROUPS = createAsyncThunk(
  "@@GROUPS/GET_GROUPS",
  async (...args) => {
    const state = args[1].getState();
    const response = await getGroups();
    if (response?.data) return [...response?.data];
    return state.groups;
  }
);


const groups = createSlice({
  name: "@@GROUPS",
  initialState: [],
  reducers: {
    DELETE_GROUP: (state, action) => {
      if (action.payload) {
        return state.filter(group => group.id !== action.payload.id);
      } else {
        return state;
      }
    },
    MODIFY_GROUP: (state, action) => {
      if (action.payload) {
        return state.map(group => {
          if (group.id === action.payload.id) {
            return action.payload
          } else {
            return group;
          }
        })
      } else {
        return state;
      }
    },
  },
  extraReducers: {
    [GET_GROUPS.fulfilled]: (state, action) => {
      //return action.payload;
      const { payload: groups } = action;
      const reducerData = {};
      Object.values(groups).forEach((el, index) => {
        reducerData[el.id] = { ...el, orderIndex: index };
      });
      return groups
        ? Object.values({
          ...reducerData,
        })
        : { ...state };
    },
  },
});

export const { MODIFY_GROUP, DELETE_GROUP } = groups.actions;

export default groups.reducer;


