import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { ReactComponent as ClearSvg } from "images/cancel.svg";
import { useTreeContext } from "../../contexts/Tree";
import { createTreeLevel, getTreeLevels } from "../../services/treeView";
import { transformList } from "../../utils/utils";

import "./style.scss";

const AddNewLevelModal = ({
  selectedCoeId,
  isOpenProp,
  onClose,
  selectedLevelLabel,
  parentId,
}) => {
  const [title, setTitle] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const { setTreeList } = useTreeContext();

  const handleClose = () => {
    setTitle(selectedLevelLabel);
    setErrorMsg("");
    onClose();
  };

  const onConfirm = async () => {
    const res = await createTreeLevel(selectedCoeId, {
      parentId,
      label: title,
    });

    try {
      if (res.data) {
        const res1 = await getTreeLevels(selectedCoeId);
        setTreeList(transformList([res1?.data]));
        onClose();
      } else {
        setErrorMsg(res?.message);
      }
    } catch (error) {
      console.error("AddNewLevelModal error: ", error);
    }
  };

  useEffect(() => {
    setTitle(selectedLevelLabel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLevelLabel]);

  return (
    <Modal
      isOpen={isOpenProp}
      className="custom-modal action-modal"
      ariaHideApp={false}>
      <div className="action-modal-content">
        <div className="action-modal-header">
          <div className="action-modal-title">
            {errorMsg ? "" : "ADD NEW LEVEL"}
          </div>
          <div className="action-modal-error-level">{errorMsg}</div>
          <ClearSvg onClick={handleClose} />
        </div>
        <div className="action-modal-form">
          <div className="modal-input-container">
            <label className="modal-label-container">
              <span className="modal-label">
                ADD NEW LEVEL<span className="mandatory">*</span>
              </span>
            </label>
            <input
              type="text"
              onChange={event => {
                setTitle(event.target.value.slice(0, 50));
                setErrorMsg("");
              }}
              max={50}
              maxLength={50}
              value={title}
              placeholder="Write the name of the level…"
            />
          </div>
        </div>
      </div>
      <div className={"footer-button-container"}>
        <button className={"footer-button button-delete"} onClick={handleClose}>
          Cancel
        </button>
        <button
          className={"footer-button button-confirm"}
          disabled={!Boolean(title)}
          onClick={onConfirm}>
          CONFIRM
        </button>
      </div>
    </Modal>
  );
};

export default AddNewLevelModal;
