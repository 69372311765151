import { createSelector, createSlice } from "@reduxjs/toolkit";

const createIndustryTree = (ind, subind) => {
  let industryCopy = ind;
  let myTree = {};
  industryCopy?.length > 0 &&
    industryCopy.forEach(item => {
      myTree[item.id] = [];
    });
  subind?.forEach(item => {
    myTree[item?.industryId].push(item);
  });
  return myTree;
};

const industryTree = createSlice({
  name: "@@INDUSTRY_TREE",
  initialState: {},
  reducers: {
    SET_INDUSTRY_TREE: (state, action) => {
      const industries = action.payload?.industries;
      const subindustries = action.payload?.subindustries;
      return { ...createIndustryTree(industries, subindustries) };
    },
  },
  extraReducers: {},
});
export const industryTreeSelector = state => state.industryTree;
export const getIndustryById = createSelector(
  [industryTreeSelector, (state, id) => id],
  (industries, id) => {
    if (!Object.keys(industries)?.length || !id) return null;
    return Object.values(industries)
      ?.flatMap(val => val)
      .find(industry => industry.id === id)?.industryId;
  }
);
export const { SET_INDUSTRY_TREE } = industryTree.actions;
export default industryTree.reducer;
