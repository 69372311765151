import React from "react";
import ArrowUpSVG from "../../images/arrow-up.svg";

const ArrowUp = ({ onClick }) => {
  return (
    <button onClick={onClick}>
      <img className="arrow-up" src={ArrowUpSVG} alt="arrow-up" />
    </button>
  );
};

export default React.memo(ArrowUp);
