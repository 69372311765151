import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import routes from "routes";
import IconBookmarks from "components/IconBookmarks";
import IconBurger from "components/IconBurger";
import Menu from "components/Menu";
import ProfileCard from "components/ProfileCard";
import SearchBar from "components/SearchBar";

import BipLogo from "images/bip.png";
import Logo from "images/Logo Myracle Rosso flat.svg";
import { ReactComponent as SearchIcon } from "images/search.svg";
import "./style.scss";

const variantPages = [
  routes.BIP_UNITS,
  routes.INDUSTRIES,
  routes.CLIENT_PERSONAS,
  routes.EVENTS,
  routes.ABOUT,
  routes.COLLABORATION_RULES,
  routes.HOME,
  routes.HOWTO,
];

const Header = () => {
  const [menuVisibility, setMenuVisibility] = useState(false);
  const location = useLocation();
  const history = useHistory();

  return (
    <header
      className={`header-container ${
        /*location.pathname !== routes.HOME*/ true ? "not-homepage" : ""
      } ${variantPages.includes(location.pathname) ? "reverse-variant" : ""}`}>
      <div className="burger">
        <IconBurger
          isOpen={menuVisibility}
          toggleVisibility={() => setMenuVisibility(true)}
        />
      </div>
      {location.pathname !== routes.HOME && (
        <div className="logo">
          <img
            src={Logo}
            alt="Myracle"
            onClick={() => history.push(routes.HOME)}
          />
        </div>
      )}
      {location.pathname === routes.HOME && (
        <div className="logo logo-bip">
          <img
            src={BipLogo}
            alt="Bip"
            onClick={() => history.push(routes.HOME)}
          />
        </div>
      )}
      <div className={`menu ${menuVisibility ? "visible" : ""}`}>
        <Menu
          isVisible={menuVisibility}
          toggleVisibility={() => setMenuVisibility(false)}
        />
      </div>
      <div className="bookmarks">
        {location.pathname !== routes.HOME &&
          location.pathname !== routes.SERP && (
            <div
              onClick={() => history.push(routes.SERP)}
              className="search-len">
              <SearchIcon />
            </div>
          )}
        <IconBookmarks />
      </div>
      {location.pathname !== routes.HOME && (
        <div
          className={`header-search ${
            location.pathname === routes.SERP ? "is-search-page" : ""
          }`}>
          <SearchBar />
        </div>
      )}
      <div className="profile">
        <ProfileCard />
      </div>
    </header>
  );
};

export default Header;
