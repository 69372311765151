import { createSlice } from "@reduxjs/toolkit";

export function getUnitColorMap(organizationalUnits){
    const units=organizationalUnits.reduce((map, element) => {
      if (!map[element.type]) {
        map[element.type] = { name: element.type, color: element.color };
      }
      if(!map['MFU_ESTERE'] && element.type === 'MFU' && element.isForeign){
        map['MFU_ESTERE'] = { name: 'MFU estere', color: element.color };
      }
      return map;
    }, {});
    return units
  }

const unitsColor = createSlice({
  name: "mySlice",
  initialState: {},
  reducers: {
    setUnitColorMap: (state, action) => {
        return action.payload;
    }
  },
});

export const { setUnitColorMap } = unitsColor.actions;

export default unitsColor.reducer;