import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import routes from "routes";
import crossImg from "images/cancel.svg";
import sticker from "images/sticker.svg";

import "./style.scss";


const StickerFixed = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  /**
   * On 'heightToHideFrom' scrollHeight close sticker element
   * */
  const listenToScroll = () => {
    const heightToHideFrom = 150;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHideFrom) {
      handleClose();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => { handleToggle(); }, 700);
    return () => clearTimeout(timer);
  },[]);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  },[]);

  return (
    <div className={`sticker-fixed ${isOpen && "sticker-fixed--open"}`}>
      <img
        className="sticker-fixed__img"
        src={sticker}
        alt={sticker}
        onClick={handleToggle} />
      <p className="sticker-fixed__text">Click&nbsp;<a
        href="#"
        className="sticker-fixed__link"
        onClick={() => history.push(routes.HOWTO)}>here</a>&nbsp;if you need help to use Myracle!
        <img
          className="sticker-fixed__cross"
          src={crossImg}
          height="18"
          width="18"
          onClick={handleClose}
          alt="close sticker" />
      </p>
    </div>
  );
};

export default StickerFixed;
