import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTracking } from "react-tracking";
import useQuery from "hooks/useQuery";
import { SET_SEARCH } from "redux/search";
import ConfirmActionModal from "../../components/ActionModal/ConfirmActionModal";
import { CLEAN_STORE, TRIGGER_MODAL } from "../../redux/modal";
import { deleteBusinessChallenge } from "../../services/businessChallenge";
import { deleteOffering } from "../../services/offeringTopic";
import { publish, unPublish } from "../../services/quals";
import { auditActions, auditTypes, entityType } from "../../utils/commons";
import BusinessChallenges from "./BusinessChallenges";
import Offerings from "./Offerings";
import Quals from "./Quals";

import "./style.scss";

const Serp = props => {
  const { searchSerp, setSearch, updateModalProps, cleanStore } = props;
  const { trackEvent } = useTracking({
    resourceType: entityType.OFFERING_TOPIC,
  });
  const [offerings, setOfferings] = useState(null);
  const [quals, setQuals] = useState(null);
  const [businessChallenges, setBusinessChallenges] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [codeToBeUnpublish, setCodeToBeUnpublish] = useState("");
  const [codeToBeDelete, setCodeToBeDelete] = useState("");
  const [action, setAction] = useState("");
  const [versionType, setVersionType] = useState("");

  const query = useQuery();

  const handleChangeSearch = (key, value) => {
    if (Object.keys(value).find(e => e === "q") && !value.q) {
      const prevSearch = {
        ...searchSerp,
        quals: {
          ...searchSerp?.quals,
          q: "",
        },
        offerings: {
          ...searchSerp?.offerings,
          q: "",
        },
        businessChallenges: {
          ...searchSerp?.businessChallenges,
          q: "",
        },
      };
      setSearch({
        ...prevSearch,
      });
    } else {
      setSearch({
        ...searchSerp,
        [key]: {
          ...(searchSerp ? searchSerp[key] : {}),
          ...value,
        },
      });
    }
  };

  const handleChangeTab = tab => {
    setSearch({
      ...(searchSerp || {}),
      selectedTab: tab,
    });
  };

  const handleUpdateCount = (key, value) => {
    setSearch({
      ...searchSerp,
      count: {
        ...searchSerp?.count,
        [key]: value,
      },
    });
  };

  const handleResetQ = notThis => {
    const newSearch = {};
    Object.keys(searchSerp)
      .filter(k => k !== notThis)
      .forEach(k => {
        newSearch[k] =
          typeof searchSerp[k] === "object"
            ? { ...searchSerp[k], q: "" }
            : searchSerp[k];
      });
    setSearch(newSearch);
  };

  useEffect(() => {
    if (query && query.get("q") && !searchSerp.quals) {
      setSearch({
        ...searchSerp,
        quals: {
          ...searchSerp?.quals,
          q: query.get("q"),
        },
        offerings: {
          ...searchSerp?.offerings,
          q: query.get("q"),
        },
        businessChallenges: {
          ...searchSerp?.businessChallenges,
          q: query.get("q"),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const qualCallback = params => {
    const { action, code, versionType } = params;
    setVersionType(versionType);
    switch (action) {
      case "edit":
        setAction("edit");
        updateModalProps({
          isOpen: true,
          modalProps: {
            code,
            type: entityType.QUALIFICATION,
            versionType: "published",
          },
        });
        break;
      case "publish":
        setAction("publish");
        publish(code).then(res => {
          trackEvent({
            action: auditActions.EDIT_AND_PUBLISH,
            code,
            type: auditTypes.ACTION,
          });
        });
        break;
      case "unpublish":
        setAction("unpublish");
        setIsModalOpen(true);
        setCodeToBeUnpublish(code);
        break;
      default:
        break;
    }
  };
  const offeringCallback = params => {
    const { action, code, versionType } = params;
    setVersionType(versionType);
    switch (action) {
      case "edit":
        updateModalProps({
          isOpen: true,
          modalProps: {
            code,
            type: entityType.OFFERING_TOPIC,
          },
        });
        break;
      case "delete":
        setAction("delete");
        setIsModalOpen(true);
        setCodeToBeDelete(code);
        break;
      default:
        break;
    }
  };
  const businessChallengeCallback = params => {
    const { action, code, versionType } = params;
    setVersionType(versionType);
    switch (action) {
      case "edit":
        trackEvent({
          action: auditActions.EDIT_AND_PUBLISH,
          code,
          type: auditTypes.ACTION,
        });
        updateModalProps({
          isOpen: true,
          modalProps: {
            code,
            type: entityType.BUSINESS_CHALLENGE,
          },
        });
        break;
      case "delete":
        setAction("delete");
        setIsModalOpen(true);
        setCodeToBeDelete(code);
        break;
      default:
        break;
    }
  };

  return (
    <div className="container serp">
      <div className="tabs-container">
        <div
          className={`tab ${
            searchSerp?.selectedTab === "offerings" ? "selected" : ""
          }`}
          onClick={() => handleChangeTab("offerings")}>{`OFFERINGS (${
          searchSerp?.count?.offerings || 0
        })`}</div>
        <div
          className={`tab ${
            searchSerp?.selectedTab === "quals" ? "selected" : ""
          }`}
          onClick={() => handleChangeTab("quals")}>{`QUALS (${
          searchSerp?.count?.quals || 0
        })`}</div>
        <span
          className={`tab ${
            searchSerp?.selectedTab === "businessChallenges" ? "selected" : ""
          }`}
          onClick={() => handleChangeTab("businessChallenges")}>
          {`BUSINESS CHALLENGES (${
            searchSerp?.count?.businessChallenges || 0
          })`}
        </span>
      </div>
      <div
        className={`tab-content ${
          searchSerp?.selectedTab === "quals" ? "visible-tab" : ""
        }`}>
        <Quals
          searchPreset={searchSerp?.quals}
          setSearch={handleChangeSearch}
          updateCount={handleUpdateCount}
          handleResetQ={handleResetQ}
          callback={qualCallback}
        />
      </div>
      <div
        className={`tab-content ${
          searchSerp?.selectedTab === "offerings" ? "visible-tab" : ""
        }`}>
        <Offerings
          searchPreset={searchSerp?.offerings}
          setSearch={handleChangeSearch}
          updateCount={handleUpdateCount}
          handleResetQ={handleResetQ}
          callback={offeringCallback}
        />
      </div>
      <div
        className={`tab-content ${
          searchSerp?.selectedTab === "businessChallenges" ? "visible-tab" : ""
        }`}>
        <BusinessChallenges
          searchPreset={searchSerp?.businessChallenges}
          setSearch={handleChangeSearch}
          updateCount={handleUpdateCount}
          handleResetQ={handleResetQ}
          callback={businessChallengeCallback}
        />
      </div>
      <ConfirmActionModal
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => {
          if (action === "delete") {
            if (versionType === "BUSINESS_CHALLENGE") {
              deleteBusinessChallenge(codeToBeDelete).then(res => {
                trackEvent({
                  action: auditActions.DELETE,
                  code: codeToBeDelete,
                  type: auditTypes.ACTION,
                  resourceType: entityType.BUSINESS_CHALLENGE,
                });
                setBusinessChallenges(
                  businessChallenges.filter(
                    item => item.code !== codeToBeDelete
                  )
                );
                cleanStore({
                  code: codeToBeDelete,
                  documentType: entityType.BUSINESS_CHALLENGE,
                });
              });
            }
            if (versionType === "OFFERING_TOPIC") {
              deleteOffering(codeToBeDelete)
                .then(res => {
                  if (!res || res?.data === null) {
                    console.log(
                      "Error deleting content" + " " + res?.message?.toString()
                    );
                    setIsModalOpen(false);
                  } else {
                    trackEvent({
                      action: auditActions.DELETE,
                      code: codeToBeDelete,
                      type: auditTypes.ACTION,
                    });
                    setIsModalOpen(false);
                    setOfferings(
                      offerings.filter(item => item.code !== codeToBeDelete)
                    );
                    cleanStore({
                      code: codeToBeDelete,
                      documentType: entityType.OFFERING_TOPIC,
                    });
                  }
                })
                .catch(e => {
                  console.log(e);
                  console.log("Error deleting content");
                });
            }
          } else {
            if (action === "unpublish") {
              unPublish(codeToBeUnpublish)
                .then(res => {
                  if (!res || res.data === null) {
                    console.log(
                      "Error hiding content" + " " + res?.message?.toString()
                    );
                    setIsModalOpen(false);
                  } else {
                    trackEvent({
                      action: auditActions.DELETE,
                      code: codeToBeUnpublish,
                      type: auditTypes.ACTION,
                    });
                    setIsModalOpen(false);
                    setQuals(
                      quals.filter(item => item.code !== codeToBeUnpublish)
                    );
                    cleanStore({
                      code: codeToBeUnpublish,
                      documentType: entityType.QUALIFICATION,
                    });
                  }
                })
                .catch(e => {
                  console.log(e);
                  console.log("Error hiding content");
                });
            }
          }
          setIsModalOpen(false);
        }}
        text={
          action === "delete"
            ? "Are you sure you want to delete this content?"
            : "Are you sure you want to hide this content? The corresponding draft will be overwritten"
        }
        isOpenProp={isModalOpen}
      />
    </div>
  );
};

export default connect(
  ({ search: { serp: searchSerp } }) => ({ searchSerp }),
  dispatch => ({
    setSearch: payload => dispatch(SET_SEARCH({ serp: payload })),
    updateModalProps: payload => dispatch(TRIGGER_MODAL(payload)),
    cleanStore: payload => dispatch(CLEAN_STORE(payload)),
  })
)(Serp);
