import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOrganizationalUnit } from "services/organizationalUnit";
import { getUnitColorMap, setUnitColorMap } from "./unitsColor";

export const GET_ORGANIZATIONAL_UNITS = createAsyncThunk(
  "@@ORGANIZATIONAL_UNITS/GET_ORGANIZATIONAL_UNITS",
  async (...args) => {
    const state = args[1].getState();
    const dispatch = args[1].dispatch
    if (state.organizationalUnits.length === 0) {
      const response = await getOrganizationalUnit();
      if (response?.data){
        dispatch(setUnitColorMap(getUnitColorMap(response?.data)))
        return [...response?.data];
      } 
    }
    dispatch(setUnitColorMap(getUnitColorMap(state.organizationalUnits)))
    return state.organizationalUnits;
  }
);

const organizationalUnits = createSlice({
  name: "@@ORGANIZATIONAL_UNITS",
  initialState: [],
  reducers: {},
  extraReducers: {
    [GET_ORGANIZATIONAL_UNITS.fulfilled]: (state, action) => {
      return action.payload;
    },
  },
});

export default organizationalUnits.reducer;
