import React from "react";
import { entityType } from "utils/commons";
import Tabs from "components/Tabs";
import TabContent from "./tabContent";
import "./style.scss";

const ErrorsManagement = () => {
  const tabs = [
    {
      title: entityType.QUALIFICATION,
      children: <TabContent documentType={entityType.QUALIFICATION} />,
    },
    {
      title: entityType.OFFERING_TOPIC.replace("_", " "),
      children: <TabContent documentType={entityType.OFFERING_TOPIC} />,
    },
  ];

  return (
    <div className="container contributor admin">
      <div className="contributor-header">
        <div className="contributor-banner">
          <span>CONTRIBUTOR MODE</span>
        </div>
        <div className="title">Quality Management</div>
      </div>
      <Tabs content={tabs} />
    </div>
  );
};

export default ErrorsManagement;
