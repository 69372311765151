import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllOfferingTopics } from "services/offeringTopic";

export const GET_ALL_OFFERINGS_TOPICS = createAsyncThunk(
  "@@FILTERS/GET_ALL_OFFERINGS_TOPICS",
  async (params, thunkAPI) => {
    const state = thunkAPI.getState();

    if (!state.filters.offerings) {
      const response = await getAllOfferingTopics();
      if (response?.data)
        return response?.data.map(ot => ({
          code: ot.code,
          description: ot.description,
          organizationalUnitId: ot.organizationalUnitId,
          organizationalUnitType: ot.organizationalUnitType,
        }));
    }
    return state.offerings;
  }
);

const filters = createSlice({
  name: "@@FILTERS",
  initialState: {},
  reducers: {},
  extraReducers: {
    [GET_ALL_OFFERINGS_TOPICS.fulfilled]: (state, action) => {
      return { ...state, offerings: action.payload };
    },
  },
});

export default filters.reducer;
