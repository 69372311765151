import React from "react";
import Drop from "../Drop";

import "./style.scss";

const TreeViewManageLevel = ({ selectedCoe, total, dataList, type }) => {
  return (
    <Drop
      selectedCoe={selectedCoe}
      total={total}
      dataList={dataList}
      isManagePage={true}
      type={type}
    />
  );
};
export default TreeViewManageLevel;
