import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import "./style.scss";

const RichTextEditor = props => {
  const editorConfiguration = {
    toolbar: [
      "bold",
      "italic",
      "underline",
      "|",
      "fontSize",
      "|",
      "numberedList",
      "bulletedList",
      "|",
      "undo",
      "redo",
    ],
    placeholder: props.placeholder,
    fontSize: {
      options: [10, 12, 14, 16, 18, 20],
    },
  };

  return (
    <div className="App CKEditor">
      <CKEditor
        editor={Editor}
        config={editorConfiguration}
        data={props.data}
        onReady={editor => {
          // You can store the "editor" and use when it is needed.
          //console.log( 'Editor is ready to use!', editor );
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          //console.log( { event, editor, data } );
          props.callback(data);
          //props.checkField();
        }}
        onBlur={(event, editor) => {
          //console.log( 'Blur.', editor );
        }}
        onFocus={(event, editor) => {
          //console.log( 'Focus.', editor );
        }}
      />
    </div>
  );
};

export default RichTextEditor;
