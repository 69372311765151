import React from "react";
import Link from "components/Link";
import { useTypography } from "components/Typography";
import "./style.scss";

const HowToDownload = ({title, subtitle, linkList}) => {
  const { HeadingTwo, Regular } = useTypography();
  return (
    <div className="links-section">
      <HeadingTwo>{title}</HeadingTwo>
      <Regular>
        {subtitle}
      </Regular>
      <div className="links-demo">
        {linkList.map(({ label, href, key }) => (
          <Link label={label} href={href} key={key}/>
        ))}
      </div>
    </div>
  );
};

export default HowToDownload;
