import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getOfferingTopic } from "services/offeringTopic";

export const GET_OFFERING = createAsyncThunk(
  "@@OFFERINGS/GET_OFFERING",
  async (code, thunkAPI) => {
    const state = thunkAPI.getState();
    if (!state.offerings[code]) {
      const response = await getOfferingTopic(code);
      if (response?.data) {
        const organizationalUnit = state.organizationalUnits.find(
          x => x.id === response.data.organizationalUnitId
        );
        return { ...response?.data, organizationalUnit };
      }
    }
    return state.offerings[code];
  }
);

const offerings = createSlice({
  name: "@@OFFERINGS",
  initialState: {},
  reducers: {
    SET_OFFERING: (state, action) => {
      if (!state[action.payload.code]) {
        return {
          ...state,
          [action.payload.id]: action.payload,
        };
      } else {
        return state;
      }
    },
    SET_OFFERINGS: (state, action) => {
      const addthisState = {};
      const { payload } = action;
      payload.forEach(item => {
        addthisState[item.id] = item;
      });
      return { ...state, ...addthisState };
    },
    CLEAR_OFFERING: (state, action) => {
      const newState = { ...state };
      delete newState[action.payload];
      return { ...newState };
    },
  },
  extraReducers: {
    [GET_OFFERING.fulfilled]: (state, action) => {
      const { payload } = action;
      return payload
        ? {
            ...state,
            [payload.code]: payload,
          }
        : { ...state };
    },
  },
});

export const { SET_OFFERING, SET_OFFERINGS, CLEAR_OFFERING } =
  offerings.actions;

export default offerings.reducer;
