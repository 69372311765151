import React from 'react';
import { ReactComponent as CloseIcon } from "images/cancel.svg";
import { ReactComponent as Icon } from './icon.svg';
import "./style.scss";

const LegendButton = props => {
    const [modalIsOpen,setModalIsOpen] = React.useState(false);

    function openModal(bool) {
        setModalIsOpen(bool);
    }

    const { legend } = props;

    return (
        <div>
            {!modalIsOpen && (
                <button className="circle-button" onClick={()=>openModal(true)}>
                    <Icon />
                </button>
            )}
            {modalIsOpen && (
                <div className="card-container">
                  <div className="card-header">
                    <button className="close-button" onClick={()=>openModal(false)}>
                      <CloseIcon className='close-legend-button'/>
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="flex-container">
                      {Object.entries(legend).map(([key, item]) => {
                        return(
                          <div className="flex-row" key={key}>
                            <div className="color-box" style={{backgroundColor: `#${item.color}`}}></div>
                            <span style={{ color: 'black'}}>{item.name}</span>
                          </div>)
                      })}
                    </div>
                  </div>
              </div>
            )}
        </div>
    )
}

export default LegendButton;