import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useTracking } from "react-tracking";
import { GET_INDUSTRIES } from "redux/industries";
import { GET_SUBINDUSTRIES } from "redux/subindustries";
import { MODIFY_USER } from "redux/users";
import { putUser, postUser } from "services/user";
import { auditActions, auditTypes, unitTypes } from "utils/commons";
import {
  getGroupIdsByGroupsNames,
  getSubIndustry,
  objectToArray,
} from "utils/utils";
import CustomOUSelect from "components/CustomOUSelect";
import CustomSelect from "components/CustomSelect";
import SuccessMessageModal from "components/SuccessMessageModal";
import CloseIcon from "images/cancel.svg";
import Avatar from "../Avatar";

import "./style.scss";

const UserDetail = ({
  user,
  isOpenProp,
  onClose,
  organizationalUnits,
  groups,
  modifyUser,
  subindustries,
  industries,
  getIndustries,
  getSubindustries,
}) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isEnabled, setIsEnabled] = useState(false);
  const [phone, setPhone] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedMFUCoeArray, setSelectedMFUCoeArray] = useState([]);
  const [selectedIndustryArray, setSelectedIndustryArray] = useState([]);
  const [selectedCoe, setSelectedCoe] = useState([]);

  const ouTree = { COE: [], MFU: [], VALUE_LINE: [], SUBINDUSTRY: [] };
  const [ouCoeArray, setOuCoeArray] = useState([]);
  const [ouMFUCoeArray, setOuMFUCoeArray] = useState([]);
  const [ouIndustryArray, setIndustryArray] = useState([]);

  const subIndustryTree = bipSubIndustries => {
    let obj = {};
    industries.map(item => (obj[item.id] = []));
    !!bipSubIndustries.length &&
      bipSubIndustries.map(bipSub => {
        const industryId = getSubIndustry(
          subindustries,
          bipSub.subIndustryId
        )?.industryId;
        obj[industryId].push(bipSub);
      });
    return obj;
  };

  useEffect(() => {
    organizationalUnits.length &&
      organizationalUnits &&
      organizationalUnits.map(ou => ouTree[ou.type.toUpperCase()].push(ou));

    // display organizational units by group
    setOuCoeArray(objectToArray({ COE: ouTree.COE, MFU: ouTree.MFU }));

    // display organizational units in flat list
    //setOuCoeArray([...ouTree.COE, ...ouTree.MFU]);

    setOuMFUCoeArray(ouTree.VALUE_LINE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!subindustries.length) {
      getSubindustries();
    } else if (!industries.length) {
      getIndustries();
    } else {
      setIndustryArray(objectToArray(subIndustryTree(ouTree.SUBINDUSTRY)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industries, subindustries]);

  const { trackEvent } = useTracking();

  useEffect(() => {
    if (user) {
      const {
        groupsIds,
        organizationalUnitIds,
        isEnabled,
        name,
        surname,
        email,
      } = user;
      const filteredGroups = groups.filter(group =>
        groupsIds.includes(group.id)
      );
      setSelectedCoe(
        organizationalUnits
          .filter(
            org =>
              organizationalUnitIds.includes(org.id) &&
              org.type !== unitTypes.VALUE_LINE &&
              org.type !== unitTypes.SUBINDUSTRY
          )
          .map(group => group.name)
      );
      setSelectedMFUCoeArray(
        organizationalUnits
          .filter(
            org =>
              organizationalUnitIds.includes(org.id) &&
              org.type === unitTypes.VALUE_LINE
          )
          .map(group => group.name)
      );
      setSelectedIndustryArray(
        organizationalUnits
          .filter(
            org =>
              organizationalUnitIds.includes(org.id) &&
              org.type === unitTypes.SUBINDUSTRY
          )
          .map(group => group.name)
      );
      setIsEnabled(isEnabled);
      setName(name);
      setSurname(surname);
      setEmail(email);
      setPhone(phone.length > 6 ? phone : "");
      setSelectedGroups(filteredGroups.map(group => group.name));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, groups, isOpenProp]);

  const handleRemoveGroup = groupToRemove => {
    setSelectedGroups(selectedGroups.filter(group => group !== groupToRemove));
  };

  const clearAll = () => {
    setName("");
    setSurname("");
    setEmail("");
    setSelectedCoe([]);
    setSelectedMFUCoeArray([]);
    setSelectedIndustryArray([]);
    setIsEnabled(null);
    setSelectedGroups([]);
    setPhone("");
  };

  const handleClose = () => {
    clearAll();
    onClose();
  };

  // todo: use for check
  //console.log("resulting ids", getGroupIdsByGroupsNames(organizationalUnits, [selectedCoe, ...selectedMFUCoeArray, ...selectedIndustryArray]));

  const { handleSubmit, register, getValues } = useForm();

  const submit = data => {
    const preparedData = {
      ...user,
      ...data,
      name: name,
      surname: surname,
      email: email,
      organizationalUnitId: `${getGroupIdsByGroupsNames(
        organizationalUnits,
        selectedCoe
      )}`, //as string
      organizationalUnitIds: getGroupIdsByGroupsNames(organizationalUnits, [
        ...selectedCoe,
        ...selectedMFUCoeArray,
        ...selectedIndustryArray,
      ]), //as array
      groupsIds: getGroupIdsByGroupsNames(groups, selectedGroups), //as array
      isEnabled: isEnabled,
    };
    if (user) {
      // modify user
      trackEvent({
        type: auditTypes.ADMIN,
        action: auditActions.MODIFY_USER,
        user: user.id,
      });
      putUser(preparedData)
        .then(response => {
          //http request
          if (response.data) {
            modifyUser({
              ...response.data,
              orderIndex: preparedData.orderIndex,
            }); //request to update redux
          } else {
            console.log(
              "Warning: Error updating content" +
                " " +
                response?.message.toString()
            );
          }
        })
        .catch(e => {
          console.log(e);
        })
        .then(
          () => {
            setIsSubmitted(true); //open message
            setTimeout(() => handleClose(), 2500);
          } //await for the modal to close
        );
    } else {
      // add new user
      trackEvent({
        type: auditTypes.ADMIN,
        action: auditActions.ADD_USER,
      });
      postUser(preparedData)
        .then(res => {
          if (res && res?.data) {
            setIsSubmitted(true); //open message
            setTimeout(() => handleClose(), 2500);
          } else {
            console.log(
              `Warning: Error uploading content ${res?.message?.toString()}`
            );
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  };

  return (
    isOpenProp && (
      <div className="container user-detail">
        <div className="modal">
          <section className="modal-main">
            <div className="bo-header">
              <h1 className="bo-title">
                {user ? "Editing of" : "Add new user"}
              </h1>
              {user && (
                <h1 className="bo-title-value">{`${user.name} ${user.surname}`}</h1>
              )}
              <div className="bo-header-close" onClick={handleClose}>
                <img src={CloseIcon} alt="" />
              </div>
            </div>
            <div className="bo-container">
              <form className="bo-form-container">
                <div className="bo-form-section-title">1. USER DATA</div>
                <div className="bo-form-section">
                  <div className="bo-form-input-group">
                    {user && (
                      <div className="avatar">
                        <Avatar profile={user} />
                      </div>
                    )}
                    <div className="bo-form-input-container ">
                      <label>
                        ACTIVE TREES SYNC<span>*</span>
                      </label>
                      <input
                        name="sync"
                        type="boolean"
                        value={user?.isSynced ? "YES" : "NO"}
                        readOnly
                        className={`sync sync-${
                          user ? user.isSynced : "false"
                        }`}
                      />
                    </div>
                    <div className="bo-form-input-container">
                      <label>
                        first name<span>*</span>
                      </label>
                      <input
                        name="firstName"
                        type="text"
                        value={name}
                        readOnly={user ? user.isSynced : false}
                        className={user?.isSynced ? "disabled" : "enabled"}
                        onChange={event => {
                          event.persist();
                          setName(event?.target.value);
                        }}
                        ref={register}
                      />
                    </div>
                    <div className="bo-form-input-container">
                      <label>
                        last name<span>*</span>
                      </label>
                      <input
                        name="lastName"
                        type="text"
                        value={surname}
                        readOnly={user ? user.isSynced : false}
                        onChange={event => {
                          event.persist();
                          setSurname(event?.target.value);
                        }}
                        className={user?.isSynced ? "disabled" : "enabled"}
                        ref={register}
                      />
                    </div>
                    <div className="bo-form-input-container">
                      <label>
                        email address<span>*</span>
                      </label>
                      <input
                        name="emailAddress"
                        type="email"
                        value={email}
                        readOnly={user ? user.isSynced : false}
                        onChange={event => {
                          event.persist();
                          setEmail(event?.target.value);
                        }}
                        placeholder="Write email address here..."
                        className={user?.isSynced ? "disabled" : "enabled"}
                        ref={register}
                      />
                    </div>
                    <div className="bo-form-input-container">
                      <label>phone number</label>
                      <input
                        name="phone"
                        type="text"
                        value={phone}
                        className="enabled"
                        onChange={event => {
                          event.persist();
                          setPhone(event.target.value);
                        }}
                        placeholder="Write phone number here..."
                        ref={register}
                      />
                    </div>
                    <div className="bo-form-input-container">
                      <label>coe or mfu of competence</label>
                      <CustomOUSelect
                        label={"Select one or more organizational units"}
                        value={[...selectedCoe] || []}
                        items={
                          ouCoeArray &&
                          ouCoeArray.map(v => {
                            if (v.id) {
                              return {
                                type: "option",
                                id: v.id,
                                value: v.name,
                                code: v.code,
                              };
                            }
                            return {
                              type: "title",
                              id: v,
                              value: v,
                              code: "",
                            };
                          })
                        }
                        onChange={item => setSelectedCoe([...item])}
                        isMultiple
                      />
                      <input
                        type="hidden"
                        name="secondaryOrganizationalUnitsIds"
                        value={selectedCoe || []}
                        ref={register}
                      />
                      {selectedCoe && (
                        <div className="bo-form-chip-list">
                          {selectedCoe.map((v, i) => (
                            <div key={i} className="bo-form-chip">
                              {v}
                              <img
                                className="chip-icon"
                                src={CloseIcon}
                                alt=""
                                onClick={() =>
                                  setSelectedCoe([
                                    ...selectedCoe.filter(item => item !== v),
                                  ])
                                }
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="bo-form-input-container">
                      <label>MFU COE of competence</label>
                      <CustomOUSelect
                        label={"Select one or more organizational units"}
                        value={[...selectedMFUCoeArray] || []}
                        items={
                          ouMFUCoeArray &&
                          ouMFUCoeArray.map(v => {
                            if (v.id) {
                              return {
                                type: "option",
                                id: v.id,
                                value: v.name,
                                code: v.code,
                              };
                            }
                            return {
                              type: "title",
                              id: v,
                              value: v,
                              code: "",
                            };
                          })
                        }
                        onChange={arraySel =>
                          setSelectedMFUCoeArray([...arraySel])
                        }
                        isMultiple
                      />
                      <input
                        type="hidden"
                        name="secondaryOrganizationalUnitsIds"
                        value={selectedMFUCoeArray || []}
                        ref={register}
                      />
                      {selectedMFUCoeArray && (
                        <div className="bo-form-chip-list">
                          {selectedMFUCoeArray.map((v, i) => (
                            <div key={i} className="bo-form-chip">
                              {v}
                              <img
                                className="chip-icon"
                                src={CloseIcon}
                                alt=""
                                onClick={() =>
                                  setSelectedMFUCoeArray([
                                    ...selectedMFUCoeArray.filter(
                                      item => item !== v
                                    ),
                                  ])
                                }
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {/*// todo: industry of competence multiplo alb per industry*/}
                    <div className="bo-form-input-container">
                      <label>industry of competence</label>
                      <CustomOUSelect
                        label={"Select one or more organizational units"}
                        value={[...selectedIndustryArray] || []}
                        items={
                          ouIndustryArray &&
                          ouIndustryArray.map(v => {
                            if (v.id) {
                              return {
                                type: "option",
                                id: v.id,
                                value: v.name,
                                code: v.code,
                              };
                            }
                            return {
                              type: "title",
                              id: v,
                              value: industries.find(ind => ind.id === v).name,
                              code: "",
                            };
                          })
                        }
                        onChange={arraySel =>
                          setSelectedIndustryArray([...arraySel])
                        }
                        isMultiple
                      />
                      <input
                        type="hidden"
                        name="secondaryOrganizationalUnitsIds"
                        value={selectedIndustryArray || []}
                        ref={register}
                      />
                      {selectedIndustryArray && (
                        <div className="bo-form-chip-list">
                          {selectedIndustryArray.map((v, i) => (
                            <div key={i} className="bo-form-chip">
                              {v}
                              <img
                                className="chip-icon"
                                src={CloseIcon}
                                alt=""
                                onClick={() =>
                                  setSelectedIndustryArray([
                                    ...selectedIndustryArray.filter(
                                      item => item !== v
                                    ),
                                  ])
                                }
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="bo-form-input-container">
                      <label>user status</label>
                      <div>
                        <label className="switch">
                          <input
                            type="checkbox"
                            value={isEnabled ? "TRUE" : "FALSE"}
                            onChange={() => setIsEnabled(!isEnabled)}
                            checked={isEnabled}
                          />
                          <span className="slider round" />
                        </label>
                        <span className="switch-label">
                          {isEnabled ? "ACTIVE" : "DISACTIVE"}
                        </span>
                      </div>
                    </div>
                    <div className="bo-form-input-container">
                      <label>group</label>
                      <CustomSelect
                        value={selectedGroups}
                        onChange={items => setSelectedGroups(items)}
                        items={groups.map(({ id, name }) => ({
                          id,
                          value: name,
                        }))}
                        isMultiple
                        label={"Select groups"}
                      />
                      <div className="bo-form-chip-list">
                        {selectedGroups &&
                          selectedGroups.map((group, index) => {
                            return (
                              <div className="bo-form-chip" key={`${index}`}>
                                {group}
                                <img
                                  className="chip-icon"
                                  src={CloseIcon}
                                  alt=""
                                  onClick={() => handleRemoveGroup(group)}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="bo-submit-container">
                <button
                  type="submit"
                  className="bo-button"
                  disabled={
                    !getValues("firstName") ||
                    !getValues("lastName") ||
                    !getValues("emailAddress")
                  }
                  onClick={() => handleSubmit(submit)()}>
                  Submit Request
                </button>
              </div>
            </div>
          </section>
        </div>
        <SuccessMessageModal
          isOpenProp={isSubmitted}
          closeModal={() => setIsSubmitted(false)}
        />
      </div>
    )
  );
};

export default connect(
  ({ profile, organizationalUnits, groups, subindustries, industries }) => ({
    profile,
    organizationalUnits,
    groups,
    subindustries,
    industries,
  }),
  dispatch => ({
    modifyUser: payload => dispatch(MODIFY_USER(payload)),
    getIndustries: () => dispatch(GET_INDUSTRIES()),
    getSubindustries: () => dispatch(GET_SUBINDUSTRIES()),
  })
)(UserDetail);
