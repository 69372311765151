import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { useTracking } from "react-tracking";
import { GET_GROUPS } from "redux/groups";
import { MODIFY_USER } from "redux/users";
import { putUser } from "services/user";
import { auditActions, auditTypes } from "utils/commons";
import { getGroupIdsByGroupsNames } from "utils/utils";
import CloseIcon, { ReactComponent as ClearSvg } from "images/cancel.svg";
import CustomSelect from "../CustomSelect";

import "./style.scss";

const AssociateWithGroup = ({
  isOpenProp,
  onClose,
  groups,
  modifyUser,
  setLoading,
  multipleAction = false,
  users,
  getGroups,
}) => {
  const [selectedGroups, setSelectedGroups] = useState(null);

  const { trackEvent } = useTracking();

  useEffect(() => {
    if (!multipleAction) {
      const filteredGroups = groups.filter(group =>
        users[0].groupsIds.includes(group.id)
      );
      const currentGroups = filteredGroups.map(group => group.name);
      setSelectedGroups(currentGroups.length === 0 ? null : currentGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, groups]);

  const handleClose = () => {
    setSelectedGroups(null);
    onClose();
  };

  const handleChange = val => {
    setSelectedGroups(val.length === 0 ? null : val);
  };

  const handleRemove = groupToRemove => {
    const current = selectedGroups.filter(group => group !== groupToRemove);
    setSelectedGroups(current.length === 0 ? null : current);
  };

  const onConfirm = () => {
    if (setLoading) {
      setLoading(true);
    }
    const groupsIds = getGroupIdsByGroupsNames(groups, selectedGroups);
    users.forEach((user, index) => {
      let preparedData;
      if (multipleAction) {
        preparedData = {
          ...user,
          groupsIds: [...new Set([...user.groupsIds, ...groupsIds])],
        };
      } else {
        preparedData = {
          ...user,
          groupsIds: groupsIds,
        };
      }
      putUser(preparedData)
        .then(response => {
          if (response.data) {
            modifyUser({
              ...response.data,
              orderIndex: preparedData.orderIndex,
            }); //request to update redux
            trackEvent({
              type: auditTypes.ADMIN,
              action: auditActions.MODIFY_USER,
              user: response.data.id,
            });
            getGroups();
            handleClose();
            if (index === users.length - 1 && setLoading) {
              setLoading(false);
            }
          } else {
            console.log(
              "Warning: Error updating user" + " " + response.message.toString()
            );
            if (index === users.length - 1 && setLoading) {
              setLoading(false);
            }
          }
        })
        .catch(e => {
          console.log(e);
          if (index === users.length - 1 && setLoading) {
            setLoading(false);
          }
        });
    });
  };

  return (
    <Modal
      isOpen={isOpenProp}
      className="custom-modal action-modal"
      ariaHideApp={false}>
      <div className="action-modal-content">
        <div className="action-modal-header">
          <div className="action-modal-title">
            {!multipleAction
              ? "Associate user with groups"
              : "Associate users with groups"}
          </div>
          <ClearSvg onClick={handleClose} />
        </div>
        <div className="action-modal-form">
          <div className="modal-input-container">
            <label className="modal-label-container">
              <span className="modal-label">
                Group<span className="mandatory">*</span>
              </span>
            </label>
            <CustomSelect
              label="Select group..."
              value={selectedGroups || []}
              items={groups.map(o => ({ id: o.id, value: o.name }))}
              onChange={val => handleChange(val)}
              isMultiple
            />
            <div className="bo-form-chip-list">
              {selectedGroups &&
                selectedGroups.map((group, index) => {
                  return (
                    <div className="bo-form-chip" key={`${index}`}>
                      {group}
                      <img
                        className="chip-icon"
                        src={CloseIcon}
                        alt=""
                        onClick={() => handleRemove(group)}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className={"footer-button-container"}>
        <button className={"footer-button button-delete"} onClick={handleClose}>
          Cancel
        </button>
        <button
          className={"footer-button button-confirm"}
          disabled={!Boolean(selectedGroups)}
          onClick={onConfirm}>
          Associate
        </button>
      </div>
    </Modal>
  );
};

export default connect(
  ({ groups }) => ({ groups }),
  dispatch => ({
    modifyUser: payload => dispatch(MODIFY_USER(payload)),
    getGroups: () => dispatch(GET_GROUPS()),
  })
)(AssociateWithGroup);
