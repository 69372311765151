import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { GET_EVENTS } from "redux/events";
import routes from "routes";
import moment from "moment";

import EventLink from "./EventLink";
import "./style.scss";

const EventList = ({ events, getEvents, organizationalUnits}) => {
  const [ous, setOus] = useState([]);

  useEffect(() => {
    setOus(organizationalUnits);
  }, [organizationalUnits]);

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  return (
    <div className="events">
      <div className="container">
        <div className="title">NEXT MYRACLE EVENTS</div>
        <div className="events-list">
          {events.map(ev => (
            <div key={ev.id}>
              <EventLink
                id={ev.id}
                date={`${moment(ev?.startDate).format("DD/MM/YYYY")}`}
                //status="OPEN"
                name={`${ev?.title.substr(0, 40)}${
                  ev?.title.length > 40 ? "..." : ""
                }`}
                manager={ous.find(x => x.id === ev.organizationalUnitId)?.name}
              />
              <div className="divider" />
            </div>
          ))}
        </div>
        <div className="view-all-container">
          <Link to={routes.EVENTS} className="view-all">
            VIEW ALL
          </Link>
        </div>
      </div>
    </div>
  );
};

export default connect(
  ({ events, organizationalUnits }) => ({ events, organizationalUnits }),
  dispatch => ({
    getEvents: () => dispatch(GET_EVENTS()),
  })
)(EventList);
