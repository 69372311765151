import { useHistory } from "react-router-dom";
import {useTracking} from "react-tracking";
import routes from "routes";

import {auditTypes} from "utils/commons";

import "./style.scss";
import ArrowImg from "images/arrow.svg";
import { ReactComponent as PersonasImage } from "images/Personas icon.svg";


const ClientPersonaCard = props => {
  const {trackEvent} = useTracking();
  const history = useHistory();
  return (
    <div
      className="clientpersona-card"
      onClick={() =>
      {history.push(`${routes.CLIENT_PERSONAS}/${props.id}`, {
          name: props.name,
        })
      trackEvent({
        id: props.id,
        name: props.name,
        code: props.code,
        type: auditTypes.CONTENT})}
      }>
      <div className="logo-img">
        {props.image ? (
          <img src={`data:image/png;base64,${props.image}`} alt={props.name} />
        ) : (
          <PersonasImage />
        )}
      </div>
      <div className="title">{props.name}</div>
      <div className="cta">
        <img src={ArrowImg} alt={props.name} />
      </div>
      <div className="description">{props.shortDescription}</div>
    </div>
  );
};

export default ClientPersonaCard;
