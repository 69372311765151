import React from "react";
import { errorsSeverities } from "utils/commons";
import "./style.scss";

const severityTooltip = {
  [errorsSeverities.HIGH]: 'This document contains a critical error that prevents it from being published. Please find a quick solution to the issue.',
  [errorsSeverities.MEDIUM]: 'This document has a warning that needs to be fixed before it is published. Kindly check and fix the issue to ensure error-free publication.',
  [errorsSeverities.LOW]: 'This document contains a minor information warning that does not prevent publication.',
}
const FileErrorsBox = ({
    errors,
    withLink = false,
    defaultIdKey = '',
  }) => {
  
  const renderItem = (error, index) => {
    const errorFields = Object.keys(error.fieldMapDtoList || {}) || [];
    return (
      <div key={`${index}-${error.issueCode}`}>
        {index === 0 && <div title={severityTooltip[`${error.severity}`]} className="title">{error.severity}</div>}
        <div className="description-container">
          {withLink
            ? <a
              href={`#handle-issues-${errorFields.length > 0 ? errorFields[0] : defaultIdKey}`}
              className="description text">
              {error.issueMessage}
            </a>
            : <div className="description text">{error.issueMessage}</div>
          }
          <div className="description arrow">{'\u2794'}</div>
          <div className="description solution">{`${error.suggestionMessage ? error.suggestionMessage : ''}`}</div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="errors-box">
      {errors?.errorIssueList && errors.errorIssueList.length > 0 && <div className="errors-box-high">
        {errors.errorIssueList.map(renderItem)}
      </div>}
      {errors?.warningIssueList && errors.warningIssueList.length > 0 && <div className="errors-box-medium">
        {errors.warningIssueList.map(renderItem)}
      </div>}
      {errors?.infoIssueList && errors.infoIssueList.length > 0 && <div className="errors-box-low">
        {errors.infoIssueList.map(renderItem)}
      </div>}
    </div>
  );
};

export default FileErrorsBox;
