import { serviceInterface } from "services/config";
import { stripHtml } from "string-strip-html";
import { getProfileInfo } from "./profile";

export const parseParamsToQuerystring = objectParams => {
  if (typeof objectParams === "object") {
    let strOut = "";
    let glue = "";
    Object.keys(objectParams).forEach(key => {
      strOut += `${glue}${key}=${objectParams[key]}`;
      glue = "&";
    });
    return `?${strOut}`;
  }
  return "";
};

export const getParsedParams = (
  selectedFilters,
  newKey = null,
  newValue = null
) => {
  const parsedFilters = {};
  const newParam = !newKey
    ? {}
    : { [newKey]: Array.isArray(newValue) ? newValue.join(";") : newValue };
  const toCheckObject = { ...selectedFilters, ...newParam };

  Object.keys(toCheckObject).forEach(k => {
    const val = toCheckObject[k];
    if (!val) return "";
    switch (k) {
      case "year":
        parsedFilters[k] = {
          year_from: val,
          year_to: val,
        };
        break;
      case "elapsed":
        const splitElapsed = val.split("-");
        parsedFilters.elapsed_from = splitElapsed[0];
        if (splitElapsed[1]) parsedFilters.elapsed_to = splitElapsed[1];
        break;
      case "fte":
        const splitFte = val.split("-");
        parsedFilters.fte_from = splitFte[0];
        if (splitFte[1]) parsedFilters.fte_to = splitFte[1];
        break;
      case "trendy_topic":
        parsedFilters[k] = val;
        break;
      case "project_types":
        parsedFilters[k] = Array.isArray(val) ? val : val.split(";");
        break;
      default:
        parsedFilters[k] = Array.isArray(val) ? val.join(";") : val;
        break;
    }
  });
  return parsedFilters;
};

export const getNumberActiveFilters = selectedFilters => {
  return (
    Object.keys(selectedFilters).filter(e => {
      if (Array.isArray(selectedFilters[e])) {
        return selectedFilters[e].length > 0;
      } else {
        return Boolean(selectedFilters[e]);
      }
    }).length - 1
  );
};

export const setLocalStorage = data => {
  if (typeof data === "object") {
    Object.keys(data).forEach(k => {
      if (k === "expires_in") {
        const d = new Date();
        localStorage.setItem(k, d.getTime() + data[k] * 1000);
      } else {
        localStorage.setItem(k, data[k]);
      }
    });
  }
};

export const downloadFile = async (url, name, isDraft = false) => {
  try {
    serviceInterface
      .get(`${url}${isDraft ? "?versionType=DRAFT" : ""}`, {
        responseType: "blob",
      })
      .then(async res => {
        if (res && res?.data) {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(res.data);
          link.download = name;
          link.click();
        }
      });
  } catch (err) {
    console.error("Warning: Error downloading file:", err);
  }
};

export const checkField = (name, event, max, setError, required = true) => {
  const value = event.target.value;
  if (value.length > max)
    return setError(name, {
      message: "Character limit has been exceeded",
      type: "maxLength",
    });
  if (!value && required)
    return setError(name, {
      message: "Field required",
      type: "invalid",
    });
  return setError(name, {
    message: "",
  });
};

export const checkFieldEditor = (
  name,
  field,
  max,
  setError,
  required = true
) => {
  const fieldAsString = stripHtml(field.replace("&nbsp;", " ")).result;
  if (fieldAsString.length > max)
    return setError(name, {
      message: "Character limit has been exceeded",
      type: "maxLength",
    });
  if (!fieldAsString && required)
    return setError(name, {
      message: "Field required",
      type: "invalid",
    });
  return setError(name, {
    message: "",
  });
};

export const checkDownload = (profile, bookmarkCode) => {
  if (getProfileInfo(profile).isDownloader) {
    return true;
  }
  return (
    getProfileInfo(profile).isUnitDownloader &&
    profile?.organizationalUnit.code === bookmarkCode?.slice(0, 2)
  );
};

export const getFullMonth = date => {
  const month = date.getMonth();
  const fullMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return fullMonths[month];
};

export const getGroupIdsByGroupsNames = (groups, names) => {
  let groupsIds = [];
  groups.forEach(el => {
    if (names?.includes(el.name)) {
      groupsIds.push(el.id);
    }
  });
  return groupsIds;
};

export const getOrganizationalUnitsIdsByNames = (
  OrganizationalUnits,
  names
) => {
  let OrganizationalUnitsIds = [];
  OrganizationalUnits.forEach(el => {
    if (names && names.includes(el.name)) {
      OrganizationalUnitsIds.push(el.id);
    }
  });
  return OrganizationalUnitsIds;
};

export const getOrganizationalUnitsIdsByCode = (OrganizationalUnits, names) => {
  let OrganizationalUnitsIds = [];
  OrganizationalUnits.forEach(el => {
    if (names && names.includes(el.code)) {
      OrganizationalUnitsIds.push(el.id);
    }
  });
  return OrganizationalUnitsIds;
};

export const getOrganizationalUnitsNameById = (OrganizationalUnits, id) => {
  let OrganizationalUnitsIds = [];
  OrganizationalUnits.forEach(el => {
    if (id && id === el?.id) {
      OrganizationalUnitsIds.push(el?.name);
    }
  });
  return OrganizationalUnitsIds;
};

export const getOrganizationalUnitsTypeById = (OrganizationalUnits, id) => {
  let OrganizationalUnitsIds = [];
  OrganizationalUnits.forEach(el => {
    if (id && id === el?.id) {
      OrganizationalUnitsIds.push(el?.type);
    }
  });
  return OrganizationalUnitsIds;
};

export const getSubIndustry = (subindustries, id) => {
  return subindustries?.find(sub => sub.id === id);
};

export const getIndustry = (industries, id) => {
  return industries?.find(sub => sub.id === id);
};

export const getOrgUnit = (org, id) => {
  return org.find(item => item.id === id);
};

export const objectToArray = industryTree => {
  const industryTreeKey = Object.keys(industryTree);
  let array = [];
  industryTreeKey.map(key => {
    array.push(key);
    array.push(...industryTree[key]);
  });
  return array;
};

export const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

export const findNodeLabel = (list, id) => {
  for (let i = 0; i < list?.length; i++) {
    if (list[i].id === id) {
      return list[i].label;
    }
    const found = findNodeLabel(list[i]?.children, id);

    if (found) return found;
  }
};

export const transformList = (list, deep = 0) => {
  return list?.map(item => {
    if (item?.childrenList?.length === 0) {
      return {
        id: item?.id,
        label: item?.label,
        level: deep, // forase si potrebbe non mettere e fare qualcosa nel drop ma per ora ok
        counter: item?.totalItems,
        expanded: true,
        children: [],
        sortNumber: item?.sortNumber,
        parentId: item?.parentId,
      };
    } else {
      return {
        id: item?.id,
        label: item?.label,
        level: deep, // todo
        counter: item?.totalItems,
        expanded: true,
        sortNumber: item?.sortNumber,
        parentId: item?.parentId,
        children: transformList(
          sortRecursive("childrenList")(sort("sortNumber", "asc"))(
            item?.childrenList
          ),
          deep + 1
        ),
      };
    }
  });
};

export const sort =
  (field, dir = "asc") =>
  xs =>
    [...xs].sort(
      dir === "asc"
        ? ({ [field]: a }, { [field]: b }) => a - b
        : ({ [field]: a }, { [field]: b }) => b - a
    );

export const sortRecursive = childField => sort => xs =>
  sort([...xs]).map(({ [childField]: cf, ...rest }) => ({
    ...rest,
    [childField]: sortRecursive(childField)(sort)(cf),
  }));
