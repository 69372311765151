import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getRoles} from "../services/backoffice";

export const GET_ROLES = createAsyncThunk(
  "@@ROLES/GET_ROLES",
  async (params, thunkAPI) => {
    const response = await getRoles();
    return [ ...response.data ];
  }
);

const roles = createSlice({
  name: "@@ROLES",
  initialState: [],
  reducers: {},
  extraReducers: {
    [GET_ROLES.fulfilled]: (state, action) => {
      const roles = {...action.payload};
      const reducerData = {};
      Object.values(roles).forEach((el, index) => {
        reducerData[el.id] = { ...el, orderIndex: index };
      });
      return roles
        ? Object.values({
          ...reducerData,
        })
        : { ...state };
    },
  },
});

export default roles.reducer;