import React, { useEffect, useMemo, useState } from "react";
import { filtersMyracle, errorsSeverities } from "utils/commons";
import Filters from "components/Filters";
import SelectedFilters from "components/SelectedFilters";
import TableErrors from "components/Tables/TableErrors";
import SearchIcon from "images/search.svg";
import useDebounce from "../../hooks/useDebounce";
import { getAllErrors, exportErrors } from "../../services/errors";
import "./style.scss";
const TabContent = ({ documentType }) => {
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [openFilters, setOpenFilters] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [totCount, setTotCount] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState({
    order_by: "ALPHABETIC",
  });

  const viewableFilters = useMemo(
    () => filtersMyracle[`ADMIN_ERRORS_${documentType}`],
    [documentType]
  );

  const debouncedValue = useDebounce(search, 800);

  const handleChange = event => {
    setSearch(event.target.value);
    setPageOffset(0);
  };

  useEffect(() => {
    if (viewableFilters) setShowFilters(viewableFilters.length > 0);
  }, [viewableFilters]);

  const handleResetFilters = () =>
    setSelectedFilters({ order_by: "ALPHABETIC" });

  const prepareFilters = filters => {
    const {
      error_severity,
      order_by,
      organizational_unit,
      subindustry,
      customer,
      technical_contact,
    } = filters;
    let params = {
      sortCriteria: order_by,
      documentType,
    };
    if (technical_contact) {
      params = {
        ...params,
        referenceContactIds: filters["id-technical_contact"],
      };
    }
    if (error_severity) {
      params = {
        ...params,
        severityType: error_severity,
      };
    }
    if (organizational_unit) {
      params = {
        ...params,
        organizationalUnitIds: filters["id-organizational_unit"],
      };
    }
    if (subindustry) {
      params = {
        ...params,
        subindustryIds: filters["id-subindustry"],
      };
    }
    if (customer) {
      params = {
        ...params,
        customers: filters["id-customer"],
      };
    }
    return params;
  };

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    const filters = prepareFilters(selectedFilters);
    getAllErrors({
      page_size: pageSize,
      page_number: pageOffset,
      search,
      ...filters,
    })
      .then(res => {
        if (!isMounted) return;
        setErrors(res?.data?.documentIssueSummaryList || []);
        setTotalPages(res?.data?.totalPages);
        setPageOffset(res?.data?.currentPage);
        setTotCount(res?.data?.totalElements);
        setLoading(false);
      })
      .catch(e => {
        if (!isMounted) return;
        console.log(e);
        setLoading(false);
      });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageOffset,
    setTotalPages,
    setPageOffset,
    selectedFilters,
    pageSize,
    debouncedValue,
  ]);

  const handleChangeFilter = (key, value, id) => {
    setSelectedFilters({
      ...selectedFilters,
      [key]: value,
      [`id-${key}`]: id,
    });
    setPageOffset(0);
  };

  const prepareData = data => {
    return data?.map(el => {
      let severityObject = {};
      if (el.errorIssueList?.length > 0) {
        severityObject[errorsSeverities.HIGH] = el.errorIssueList.length;
      }
      if (el.warningIssueList?.length > 0) {
        severityObject[errorsSeverities.MEDIUM] = el.warningIssueList.length;
      }
      if (el.infoIssueList?.length > 0) {
        severityObject[errorsSeverities.LOW] = el.infoIssueList.length;
      }
      return {
        ...el,
        severity: severityObject,
      };
    });
  };

  const handleExport = async () => {
    setDownloading(true);
    const params = {
      contentType: [documentType],
    };
    await exportErrors(params);
    setDownloading(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}>
        <div className="error-management-export">
          <button
            className="export-button"
            onClick={handleExport}
            disabled={downloading}>
            Export
          </button>
        </div>
      </div>
      <div className="errors-management-search">
        <div className="search">
          <div className="search-bar-container">
            <div className="search-icon">
              <img src={SearchIcon} alt="lens" />
            </div>
            <input
              type="text"
              className="search-bar"
              value={search || ""}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="results-container no-margin">
        {showFilters && (
          <div
            className={`side-filter-bar ${openFilters ? "open-filter" : ""}`}>
            <Filters
              handleChangeFilter={handleChangeFilter}
              viewableFilters={viewableFilters}
              selectedFilters={selectedFilters}
              closeFilters={() => setOpenFilters(false)}
              numberItems={errors?.length}
              handleResetFilters={handleResetFilters}
              handleById
            />
          </div>
        )}
        <div className="results errors-management-results">
          {showFilters && (
            <SelectedFilters
              selectedFilters={selectedFilters}
              handleChangeFilter={handleChangeFilter}
              elementsCount={totCount}
              openFilters={() => setOpenFilters(!openFilters)}
              errorsOrder={true}
              handleById
            />
          )}
          <TableErrors
            loading={loading}
            data={loading ? [] : prepareData(errors)}
            currentPage={pageOffset}
            setCurrentPage={setPageOffset}
            pageSize={pageSize}
            totalPages={totalPages}
            setPageSize={value => {
              setPageOffset(0);
              setPageSize(value);
            }}
            documentType={documentType}
          />
        </div>
      </div>
    </>
  );
};

export default TabContent;
