import React, { useEffect, useRef } from "react";
import { useTracking } from "react-tracking";

import EU from "images/industries/Energy & utilities.svg";
import FS from "images/industries/Financial Services.svg";
import LS from "images/industries/Lifescience.svg";
import AU from "images/industries/Manifacturing.svg";
import OT from "images/industries/Other.svg";
import PS from "images/industries/Public sector.svg";
import RE from "images/industries/Retail.svg";
import TE from "images/industries/Telco.svg";
import { auditActions, auditTypes, entityType } from "utils/commons";
import PlusIcon from "images/plus.svg";
import IndustryCard from "../IndustryCard";

import "./style.scss";

const IndustryAccordion = props => {
  const containerEl = useRef();
  const contentEl = useRef();
  const accordionEl = useRef();
  const { trackEvent } = useTracking();
  const { name, code, onClick, isSelected } = props;
  const images = {
    EU,
    FS,
    AU,
    LS,
    PS,
    RE,
    TE,
    OT,
  };

  useEffect(() => {
    if (isSelected) {
      setTimeout(() => {
        containerEl.current.style.maxHeight =
          contentEl.current.clientHeight + "px";
      }, 100);
    } else {
      containerEl.current.style.maxHeight = 0;
    }
  }, [isSelected]);

  return (
    <div ref={accordionEl} className="accordion-industry">
      <div
        className={isSelected ? "accordion-header open" : "accordion-header"}
        onClick={onClick}>
        <div
          className="industry-card"
          onClick={() => {
            trackEvent({
              code: props.code,
              action: auditActions.OPEN_DETAIL,
              resourceType: entityType.INDUSTRIES,
              name: props.code,
              type: auditTypes.CONTENT,
            });
          }}>
          <div className="logo-img">
            {code && <img src={images[code]} alt={props.name} />}
          </div>
          <div className="title">{name}</div>
          <img
            className={`plus-icon ${isSelected ? "open" : ""}`}
            src={PlusIcon}
            alt=""
          />
        </div>
      </div>
      <div ref={containerEl} className="accordion-content-container">
        <div ref={contentEl} className="accordion-content">
          {!!props.items?.length &&
            props.items.map(item => <IndustryCard {...item} key={item.id} legend={props.legend}/>)}
        </div>
      </div>
    </div>
  );
};

export default IndustryAccordion;
