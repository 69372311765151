import { toast } from "react-toastify";
import { EventType } from "@azure/msal-browser";
import Axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { msalInstance } from "index";
import config from "utils/config";

const PATH = `/api/`;

export const apis = {
  USER: `user`,
  ME: "me",
  GROUP: "group",
  ROLE: "role",
  DICTIONARY: "dictionary",
  PERSONA: "persona",
  QUAL: "qualification",
  ORGANIZATIONAL_UNIT: "organizational-unit",
  TEMPLATE: "template",
  OFFERING_TOPIC: "offering-topic",
  OFFERING_TOPIC_LIGHT: "offering-topic/light",
  OFFERING_DECK: "offering-deck",
  BUSINESS_CHALLENGE: "business-challenge",
  SEARCH: "search",
  SEARCH_OFFERING_TOPIC: "search/offering-topic",
  SEARCH_QUALIFICATION: "search/qualification",
  SEARCH_BUSINESS_CHALLENGE: "search/business-challenge",
  EVENT: "event",
  BOOKMARK: "bookmark",
  AUDIT: "audit",
  ONE_PAGER: "one-pager",
  COMMENT: "comment",
  LIKE: "like",
  CONTENT_REQUEST: "mail/contentRequest",
  INDUSTRY: "industry",
  SUBINDUSTRY: "subindustry",
  TREE_LEVEL: "tree-level",
};

export const serviceInterface = Axios.create({
  baseURL: `${document.env.BASE_URL}${PATH}`,
});

const failedRequestsFor401 = [];

createAuthRefreshInterceptor(
  serviceInterface,
  failedRequest => {
    return acquireTokenSilent()
      .then(token => {
        console.log("token");
        if (token) {
          //try to resolve - error401
          localStorage.clear();
          setRequestAuthToken(token, failedRequest);
          return token;
        } else {
          //if acquireTokenSilent returns 400, token is set to undefined but doesn't enter in catch
          const account = msalInstance.getAllAccounts();
          console.log("Error after acquireTokenSilent 400");
          localStorage.clear();
          msalInstance
            .acquireTokenRedirect({
              account: account[0],
              scopes: [`${config.SSO_GETCODE_PARAMS.scope}`],
            })
            .then(newTokenResponse => {
              setRequestAuthToken(newTokenResponse?.idToken, failedRequest);
              setAuthToken(newTokenResponse?.idToken);
              return newTokenResponse?.idToken;
            })
            .catch(e => console.log(e));
        }
      })
      .then(authToken => {
        if (authToken) {
          console.log("authToken");
          // we have to retry each failed request due to 401 with the new token
          while (failedRequestsFor401.length) {
            console.log("failedRequestsFor401 1", failedRequestsFor401);
            const { resolve, error } = failedRequestsFor401.pop();
            // update token
            setRequestAuthToken(authToken, error);
            // complete the promise resolve with the new axios call
            resolve(Axios(error.config));
          }
        } else {
          console.log("Error 401 - no authToken");
          failedRequestsFor401.length = 0;
        }
        return Promise.resolve();
      })
      .catch(e => {
        const account = msalInstance.getAllAccounts();
        console.log("Error after acquireTokenSilent", e);
        localStorage.clear();
        msalInstance
          .acquireTokenRedirect({
            account: account[0],
            scopes: [`${config.SSO_GETCODE_PARAMS.scope}`],
          })
          .then(newTokenResponse => {
            setRequestAuthToken(newTokenResponse?.idToken, failedRequest);
            setAuthToken(newTokenResponse?.idToken);
            return newTokenResponse?.idToken;
          })
          .catch(e => console.log(e));
      });
  },
  { pauseInstanceWhileRefreshing: true }
);

serviceInterface.interceptors.response.use(
  response => response,
  async error => {
    if (error?.response) {
      if (error.response?.status === 400) {
        if (
          error.response.config.url.includes(
            "generated-slide-download?templateId="
          )
        ) {
          let message = error.response?.data?.statusMessage;
          if (error.request.responseType === "blob") {
            message = JSON.parse(await error.response.data.text());
          }
          toast(error.response.data.statusMessage);
          return { data: null, message };
        } else {
          toast(
            `"NO RESPONSE FROM SERVER: BAD REQUEST", ${error.response.data.statusMessage}`
          );
          return { data: null, message: error.response.data.statusMessage };
        }
      }
      if (error.response?.status === 401) {
        toast(`"PLEASE REFRESH THE PAGE", ${error.response.statusText}`);
        // we have to wait the acquireTokenSilent
        if (!error.config._retry) {
          // retry only one time
          error.config._retry = true;

          // return a promise to the function
          return new Promise(resolve => {
            // save the promise resolve and the failed request until the refresh token is completed
            failedRequestsFor401.push({ resolve, error });
          });
        }
      }
      if (error.response?.status === 404) {
        if (error.response?.config.url.includes("ppt?versionType=")) {
          console.log(
            "error.response on ppt qual in draft 404 url:  ",
            error.response.config.url
          );
        } else {
          toast(
            `"REQUESTED RESOURCE NOT FOUND", ${error.response.data.statusMessage}`
          );
        }
      }
      if (error.response?.status === 429) {
        toast(`"Error: ", ${error.response.data.message}`);
      }
      if (error.response?.status === 450) {
        if (error.response.config.url.includes("tree-level")) {
          //if we need toast
          // toast(error.response.data.statusMessage);
          return { data: null, message: error.response.data.statusMessage };
        }
      }
      if (error.response?.status === 500) {
        toast(
          `"NO RESPONSE FROM SERVER!", ${error.response.data.statusMessage}`
        );
        return { data: null, message: error.response.data.statusMessage };
      }
    } else if (error?.request) {
      console.log("NO RESPONSE FROM SERVER! Here the request", error.request);
    } else {
      console.log("Generic Error", error?.message);
    }
  }
);

export const acquireTokenSilent = () => {
  const account = msalInstance.getAllAccounts();
  msalInstance.addEventCallback((event: any) => {
    if (
      event.eventType === EventType.SSO_SILENT_FAILURE &&
      event.error?.errorCode === "monitor_window_timeout"
    ) {
      msalInstance.acquireTokenRedirect({
        account: account[0],
        scopes: [`${config.SSO_GETCODE_PARAMS.scope}`],
      });
    }
  });
  return msalInstance
    .acquireTokenSilent({
      account: account[0],
      scopes: [`${config.SSO_GETCODE_PARAMS.scope}`],
    })
    .then(response => {
      setAuthToken(response.idToken);
      return response.idToken;
    })
    .catch(e => {
      console.log("Error while acquireTokenSilent", e);
    });
};

export const setAuthToken = token => {
  serviceInterface.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const setRequestAuthToken = (token, request) => {
  request.response.config.headers["Authorization"] = `Bearer ${token}`;
};
