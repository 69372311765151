import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBusinessChallenge } from "services/businessChallenge";

export const GET_BUSINESS_CHALLENGE = createAsyncThunk(
  "@@BUSINESS_CHALLENGE/GET_BUSINESS_CHALLENGE",
  async (code, thunkAPI) => {
    const state = thunkAPI.getState();
    if (!state.businessChallenges[code]) {
      const response = await getBusinessChallenge(code);
      if (response?.data) {
        return { ...response?.data };
      }
    }
    return state.businessChallenges[code];
  }
);

export const POPULATE_BUSINESS_CHALLENGE = createAsyncThunk(
  "@@BUSINESS_CHALLENGE/POPULATE_BUSINESS_CHALLENGE",
  async (code, thunkAPI) => {
    const state = thunkAPI.getState();
    if (Object.keys(state.businessChallenges).length === 0) {
      const response = await getBusinessChallenge();
      if (response?.data) {
        response.data.forEach(bc => {
          thunkAPI.dispatch(SET_BUSINESS_CHALLENGE(bc));
        });
      }
    }
  }
);

const BusinessChallenges = createSlice({
  name: "@@BUSINESS_CHALLENGE",
  initialState: {},
  reducers: {
    SET_BUSINESS_CHALLENGE: (state, action) => {
      if (!state[action.payload.code]) {
        return {
          ...state,
          [action.payload.code]: action.payload,
        };
      } else {
        return state;
      }
    },
    SET_BUSINESS_CHALLENGES: (state, action) => {
      const addThisState = {};
      const { payload } = action;
      payload.forEach(item => {
        addThisState[item.id] = item;
      });
      return { ...state, ...addThisState };
    },
    CLEAR_BUSINESS_CHALLENGE: (state, action) => {
      const newState = { ...state };
      delete newState[action.payload];
      return { ...newState };
    },
  },
  extraReducers: {
    [GET_BUSINESS_CHALLENGE.fulfilled]: (state, action) => {
      const { payload } = action;
      return payload
        ? {
            ...state,
            [payload.code]: payload,
          }
        : { ...state };
    },
  },
});

export const {
  SET_BUSINESS_CHALLENGES,
  SET_BUSINESS_CHALLENGE,
  CLEAR_BUSINESS_CHALLENGE,
} = BusinessChallenges.actions;

export default BusinessChallenges.reducer;
