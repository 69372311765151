import { connect } from "react-redux";
import { useTracking } from "react-tracking";
import { REMOVE_BOOKMARK } from "redux/bookmarks";
import { auditActions, auditTypes, entityType } from "utils/commons";
import { getProfileInfo } from "utils/profile";
import { checkDownload } from "utils/utils";
import Checked from "images/checked.svg";
import Trash from "images/trash.svg";
import Unchecked from "images/unchecked.svg";

const Offering = ({
  profile,
  handleClick,
  cardData,
  toggleChecked,
  removeBookmark,
  isChecked,
}) => {
  const { trackEvent } = useTracking();
  const isDownloadable = checkDownload(profile, cardData?.code);
  const handleRemoveBookmark = e => {
    e.stopPropagation();
    removeBookmark({
      documentType: entityType.OFFERING_TOPIC,
      documentId: cardData?.code,
    });
    trackEvent({
      code: cardData?.code,
      action: auditActions.DELETE_BOOKMARK,
      resourceType: entityType.OFFERING_TOPIC,
      type: auditTypes.ACTION,
    });
  };

  return (
    <div onClick={handleClick} className="bookmark-wrapper">
      <div className="bookmark-title-type">
        {isDownloadable && (
          <div
            className="checkbox-wrapper"
            onClick={e => {
              e.stopPropagation();
              toggleChecked();
            }}>
            <img src={isChecked ? Checked : Unchecked} alt="option-checkbox" />
          </div>
        )}
        <div className="type">{entityType.OFFERING_TOPIC}</div>
      </div>
      <div className="bookmark-infos">
        <div className="name">{`${cardData?.description.substr(0, 50)}${
          cardData?.description.length > 50 ? "..." : ""
        }`}</div>
      </div>
      <div className="bookmark-cta" onClick={e => handleRemoveBookmark(e)}>
        <img src={Trash} alt="delete" />
      </div>
      {getProfileInfo(profile).isUnitDownloader && !isDownloadable && (
        <div className="bookmark-warning">
          Not available for download under M/ASS
        </div>
      )}
    </div>
  );
};
export default connect(
  ({ organizationalUnits, profile }) => ({ organizationalUnits, profile }),
  dispatch => ({
    removeBookmark: params => dispatch(REMOVE_BOOKMARK(params)),
  })
)(Offering);
