import React from "react";
import { useTracking } from "react-tracking";
import routes from "routes";
import EventList from "components/EventList";
import MenuButton from "components/MenuButton";
import SearchBar from "components/SearchBar";
import StickerFixed from "components/StickerFixed";
import NewsList from "components/NewsList/NewsList";

import Logo from "images/Logo Myracle Rosso flat.svg";
import "./style.scss";

const Home = () => {
  const { Track } = useTracking({ page: "Home" });

  return (
    <div className="home-container">
      <div className="content">
        <div className="opening">
          <div className="container">
            <div className="logo">
              <img src={Logo} alt="Myracle" />
            </div>
            <div className="subtitle">
              BIP's capabilities and experience in one place, easily accessible,
              secure and centralized
            </div>
            <SearchBar />
            <NewsList />
            <div className="inspired">GET INSPIRED</div>
            <div className="inspirations">
              <Track>
                <MenuButton btnText="OFFERINGS" url={routes.OFFERING} />
                <MenuButton btnText="QUALIFICATIONS" url={routes.QUALS} />
                <MenuButton btnText="BIP COES" url={routes.BIP_UNITS} />
                <MenuButton
                  btnText="BUSINESS CHALLENGES"
                  url={routes.BUSINESS_CHALLENGE}
                />
                <MenuButton
                  btnText="CLIENT PERSONAS"
                  url={routes.CLIENT_PERSONAS}
                />
                <MenuButton btnText="INDUSTRIES" url={routes.INDUSTRIES} />
              </Track>
            </div>
          </div>
          {/*  <Link to="/" className="ask-button">
          <div className="ask-icon"></div>
        </Link> */}
        </div>
        <EventList />
        <StickerFixed />
      </div>
    </div>
  );
};

export default Home;
