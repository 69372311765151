import React, { useState } from "react";
import PropTypes from "prop-types";

import { getFullMonth } from "utils/utils";
import Avatar from "components/Avatar";
import ConfirmActionModal from "components/ActionModal/ConfirmActionModal";
import Trash from "images/trash.svg";

import "./style.scss";

const CommentCard = ({ comment, isMyComment, handleDelete, isAdmin }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const commentDate = new Date(comment.lastModifiedDate);

  return(
      <div className="comment-card">
        {(isMyComment || isAdmin) && <div className="delete-button" onClick={() => setIsOpenModal(true)}>
          <img src={Trash} alt="delete" />
        </div>}
        <div className="comment-author">
          <Avatar profile={comment}/>
          <div className="name-date-container">
            <div className="bold-text">{`${comment.name} ${comment.surname}`}</div>
            <div className="comment-date">
              {`${commentDate.getDate()} ${getFullMonth(commentDate)} ${commentDate.getFullYear()}`}
            </div>
          </div>
        </div>
        <div className="comment-title bold-text">{comment.title}</div>
        <div className="comment-body text">{comment.body}</div>
        <ConfirmActionModal
          onConfirm={() => handleDelete(comment.id)}
          isOpenProp={isOpenModal}
          text="Are you sure you want to delete this comment?"
          onClose={() => setIsOpenModal(false)} />
      </div>
  );
}

CommentCard.propTypes = {
  comment: PropTypes.object,
  isMyComment: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

export default CommentCard;
