import React, { useRef } from "react";
import { useTracking } from "react-tracking";
import moment from "moment";
import {
  auditActions,
  auditTypes,
  entityType,
  inputLength,
} from "utils/commons";
import Avatar from "components/Avatar";
import RelatedCardList from "components/RelatedCardList";
import SliderPpt from "components/SliderPpt";
import TeamsContact from "components/TeamsContact";
import { ReactComponent as CopySvg } from "images/copy.svg";
import ItalyFlag from "images/italy.png";
import { getSubIndustry } from "../../utils/utils";
import Comments from "../Comments";

import "./style.scss";

const Detail = ({
  infoDetail,
  subindustries,
  commercialReferenceData,
  isDownloadable,
  bcCardData,
  offeringCardData,
  userEditScope,
  loadMoreBc,
  loadMoreOffering,
  myLike,
  totalLikes,
  setCallbackLikes,
  setCallbackTotals,
}) => {
  const {
    code,
    headline,
    offeringTopicCodes,
    businessChallengeCodes,
    pptPreviews,
    subIndustryId,
    lastModifiedDate,
    technologies,
    fte,
    elapsed,
    elapsedUnit,
    achievements,
    personas,
    countries,
    industry,
    body,
    tags,
  } = infoDetail || {};

  const { name, surname, email, phone } = commercialReferenceData || {};

  const { Track, trackEvent } = useTracking({
    resourceType: entityType.QUALIFICATION,
    code,
  });
  const linkRef = useRef(null);

  const handleCopyLink = e => {
    trackEvent({
      type: auditTypes.ACTION,
      code,
      action: auditActions.COPY_LINK,
    });
    linkRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  };

  return (
    <>
      <div className="container-detail qual-data-container">
        <div className="main-info">
          <div className="reactions-container">
            <div className="reactions" />
            <img className="flag" src={ItalyFlag} alt="italy" />
          </div>
          <div className="qual-info">
            <div className="title">{headline}</div>
            <div className="tags">{tags.map(t => `#${t.trim()} `)}</div>
            <div
              className="content CKEditor-container"
              id="body-div"
              dangerouslySetInnerHTML={{ __html: body }}
            />
          </div>
          <div className="qual-categories">
            <div className="category-detail">
              <div className="label">Industry</div>
              <div className="value">
                {industry}&nbsp;{" - "}
                {getSubIndustry(subindustries, subIndustryId)?.name}
              </div>
            </div>
            <div className="category-detail">
              <div className="label">Country</div>
              <div className="value">
                {countries?.map((c, i) => (
                  <div key={i}>{c}</div>
                ))}
              </div>
            </div>
            <div className="category-detail">
              <div className="label">Client Personas</div>
              {personas.map((p, index) => (
                <div key={`${p?.name}-${index}`} className="value">
                  {p?.name}
                </div>
              ))}
            </div>
            <div className="category-detail reference-category">
              <div className="label">Qual Reference</div>
              <div className="value">
                {name} {surname}
              </div>
            </div>
          </div>
        </div>
        <div className="side-info">
          <div className="qual-stats">
            <div>
              Achievements
              {achievements?.length ? (
                achievements.map((item, idx) => (
                  <div key={`${item.value} + ${idx}`}>
                    {item.value}: <span>{item.description}</span>
                  </div>
                ))
              ) : (
                <span>N.D.</span>
              )}
            </div>
          </div>
          <div className="qual-stats">
            <div style={{ marginTop: "1rem" }}>
              Elapsed:
              <span>
                {elapsed} {elapsedUnit}
              </span>
            </div>
            <div>
              FTE: <span>{fte} FTE</span>{" "}
            </div>
            <div>
              Technologies:
              <span>{technologies?.toString()}</span>
            </div>
            <div>
              Qual Code: <span>{code}</span>
            </div>
            <div>
              Last modified date:
              <span>{`${moment(lastModifiedDate).format(
                "DD/MM/YYYY hh:mm"
              )}`}</span>
            </div>
          </div>
          <div className="qual-reference">
            <div className="title">QUAL REFERENCE</div>
            <div className="reference">
              <Avatar profile={commercialReferenceData} />
              <div className="info">
                <div className="name">
                  {name} {surname}
                </div>
                <div>Area of competence Myracle</div>
                <div>{email}</div>
                <div>{phone}</div>
                <TeamsContact email={email} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-detail">
        {pptPreviews?.length > 0 && (
          <Track>
            <SliderPpt
              code={code}
              previews={pptPreviews}
              isDownloadable={isDownloadable}
              documentType={entityType.QUALIFICATION}
            />
          </Track>
        )}
      </div>
      {businessChallengeCodes &&
      businessChallengeCodes.length &&
      bcCardData?.length ? (
        <RelatedCardList
          elemList={businessChallengeCodes}
          documentType="BUSINESS_CHALLENGE"
          editScope={userEditScope}
          data={bcCardData}
          onGoRight={loadMoreBc}
        />
      ) : null}
      {offeringTopicCodes &&
      offeringTopicCodes.length &&
      offeringCardData?.length ? (
        <RelatedCardList
          elemList={offeringTopicCodes}
          documentType="OFFERING_TOPIC"
          editScope={userEditScope}
          data={offeringCardData}
          onGoRight={loadMoreOffering}
        />
      ) : null}
      <Comments
        code={code}
        setCallbackTotals={setCallbackTotals}
        contentType={entityType.QUALIFICATION}
        myLikeData={myLike}
        totalLikes={totalLikes}
        setCallbackLikes={setCallbackLikes}
      />
      <div className="container-detail qual-footer-detail">
        <div className="contact">
          <div className="detail">GET IN TOUCH</div>
          <div className="title">Do you need any help?</div>
          <div className="info">
            Please contact the reference person and attach this link to your
            message so she/he will better understand your request.
          </div>
          <div className="link">
            <input
              type="text"
              readOnly
              value={window.location.href}
              ref={linkRef}
            />
            <CopySvg onClick={handleCopyLink} />
          </div>
        </div>
        <div className="qual-reference white">
          <div className="title">QUAL REFERENCE</div>
          <div className="reference">
            <Avatar profile={commercialReferenceData} />
            <div className="info">
              <div className="name">
                {name} {surname}
              </div>
              <div>Area of competence Myracle</div>
              <div>{email}</div>
              {phone?.length >= inputLength.PHONE ? <div>{phone}</div> : null}
              <TeamsContact email={email} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Detail;
