import { useHistory, useLocation } from "react-router-dom";
import { useTracking } from "react-tracking";

import routes from "routes";
import IconBurger from "components/IconBurger";
import ProfileCard from "components/ProfileCard";

import "./style.scss";

const Menu = ({ isVisible, toggleVisibility }) => {
  const history = useHistory();
  const location = useLocation();
  const { Track } = useTracking({ section: "Menu" });

  const handleRoute = path => {
    toggleVisibility();
    history.push(path);
  };
  return (
    <div className={`menu-container ${isVisible ? "visible" : ""}`}>
      <IconBurger
        toggleVisibility={toggleVisibility}
        isOpen={true}
        color="#fff"
      />
      <div className="menu-items">
        <div
          className={`menu-item ${
            location.pathname.indexOf(routes.OFFERING) > -1 ? "active" : ""
          }`}>
          <span onClick={() => handleRoute(routes.OFFERING)}>Offerings</span>
        </div>
        <div
          className={`menu-item ${
            location.pathname.indexOf(routes.QUALS) > -1 ? "active" : ""
          }`}>
          <span onClick={() => handleRoute(routes.QUALS)}>
            Qualifications
          </span>
        </div>
        <div
          className={`menu-item ${
            location.pathname.indexOf(routes.BIP_UNITS) > -1 ? "active" : ""
          }`}>
          <span onClick={() => handleRoute(routes.BIP_UNITS)}>BIP COEs</span>
        </div>
        <div
          className={`menu-item ${
            location.pathname.indexOf(routes.INDUSTRIES) > -1 ? "active" : ""
          }`}>
          <span onClick={() => handleRoute(routes.INDUSTRIES)}>Industries</span>
        </div>
        <div
          className={`menu-item ${
            location.pathname.indexOf(routes.BUSINESS_CHALLENGE) > -1
              ? "active"
              : ""
          }`}>
          <span onClick={() => handleRoute(routes.BUSINESS_CHALLENGE)}>
            Business Challenges
          </span>
        </div>
        <div
          className={`menu-item ${
            location.pathname.indexOf(routes.CLIENT_PERSONAS) > -1
              ? "active"
              : ""
          }`}>
          <span onClick={() => handleRoute(routes.CLIENT_PERSONAS)}>
            Client Personas
          </span>
        </div>
        <div
          className={`menu-item ${
            location.pathname === routes.EVENTS ? "active" : ""
          }`}>
          <span onClick={() => handleRoute(routes.EVENTS)}>Events</span>
        </div>
        <div className="menu-spacer" />
        {/*<div
          className={`menu-item ${
            location.pathname === routes.ABOUT ? "active" : ""
          }`}>
          <span onClick={() => handleRoute(routes.ABOUT)}>About</span>
        </div>*/}
        <div
          className={`menu-item ${
            location.pathname === routes.ASK_CONTENT ? "active" : ""
          }`}>
          <span onClick={() => handleRoute(routes.ASK_CONTENT)}>Ask for new content</span>
        </div>
        <div
          className={`menu-item ${
            location.pathname === routes.COLLABORATION_RULES ? "active" : ""
          }`}>
          <span onClick={() => handleRoute(routes.COLLABORATION_RULES)}>
            Collaboration Rules
          </span>
        </div>
        <div
          className="menu-item">
          <span onClick={() => window.location.href=routes.REPORTING}>
            Reporting
          </span>
        </div>
        <div
          className="menu-item">
          <span onClick={() => window.location.href=routes.HOWTO}>
            How To
          </span>
        </div>
        {/* <div className={`menu-item `}>
          <span onClick={() => null}>Quick start</span>
        </div> */}
      </div>
      <div className="menu-profile">
        <Track>
          <ProfileCard />
        </Track>
      </div>
    </div>
  );
};

export default Menu;
