const routes = {
  HOME: "/",
  OFFERING: "/offerings",
  SERP: "/serp",
  QUALS: "/quals",
  BIP_UNITS: "/bip-COEs",
  INDUSTRIES: "/industries",
  CLIENT_PERSONAS: "/client-personas",
  BUSINESS_CHALLENGE: "/business-challenges",
  EVENTS: "/events",
  BACK_OFFICE: "/back-office",
  CONTRIBUTOR_QUALS: "/contributor-quals",
  CONTRIBUTOR_BUSINESS_CHALLENGES: "/contributor-business-challenges",
  CONTRIBUTOR_OFFERINGS: "/contributor-offerings",
  ABOUT: "/about",
  COLLABORATION_RULES: "/collaboration-rules",
  ASK_CONTENT: "/ask-for-contents",
  ADMIN_GROUP: "/group-management",
  ADMIN_USER: "/user-management",
  ADMIN_ERRORS: "/errors-management",
  REPORTING: "/reporting",
  HOWTO: "/how-to",
};

export default routes;
