import React from "react";
import bo_file from "assets/backoffice_quickguide.pdf";
import fo_file from "assets/frontoffice_quickguide.pdf";
import video_pr from "assets/video/Myracle tutorial_v1.0_BR_low quality.mp4";
import video_en from "assets/video/Myracle tutorial_v1.0_EN_low quality.mp4";
import video_sp from "assets/video/Myracle tutorial_v1.0_ES_low quality.mp4";
import video_it from "assets/video/Myracle tutorial_v1.0_ITA_low quality.mp4";
import HeroTitle from "components/HeroTitle";

import "./style.scss";

const About = () => {
  const subtitle = () => (
    <div className="support">
      <p>
        MYracle is a Bip Group service that enables all Market Facing Units to
        access Bip’s expertise, providing support in scouting opportunities and
        engaging clients. The Myracle platform includes our service offering and
        qualifications, categorized by Industry, Client Persona, Burning
        Challenge, Bip Unit, or searcheable by their Metadata or through
        Cognitive Search.
      </p>
      <div className="row">
        <div className="half-section">
          <div className="contacts">
            <p className="contact">
              Contact Support:{" "}
              <a href="mailto:myracle-support@mail-bip.com">
                myracle-support@mail-bip.com
              </a>
            </p>
            <p className="contact-description">
              Communicate technical problems/bugs or malfunctioning at Myracle
              Tech Team (e.g. debug users not able to properly access)
            </p>
            <p className="contact">
              DB Curator:{" "}
              <a href="mailto:myracle-dbcurator@mail-bip.com">
                myracle-dbcurator@mail-bip.com
              </a>
            </p>
            <p className="contact-description">
              Communicate problems related to specific content or master data to
              Myracle DB Curator (e.g. add a new customer/technology, enable a
              new contributor for your OU) or ask for help on how to use the
              platform or a specific functionality
            </p>
          </div>
          <div className="contacts">
            <p className="contact">
              Frontoffice Quickguide:{" "}
              <a target="_blank" rel="noreferrer" href={fo_file}>
                download
              </a>
            </p>
            <p className="contact">
              Backoffice Quickguide:{" "}
              <a target="_blank" rel="noreferrer" href={bo_file}>
                download
              </a>
            </p>
          </div>
        </div>
        <div className="half-section right">
          <div className="contacts">
            <h2>Myracle Demo</h2>
            <br />
            <p>
              A 15 minutes recorded demo to go straight to the point and quickly
              discover all about Myracle:
            </p>
            <div className="contact">
              <ul>
                <li>
                  {" "}
                  ENGLISH:{" "}
                  <a target="_blank" rel="noreferrer" href={video_en}>
                    download
                  </a>
                </li>
                <li>
                  SPANISH:{" "}
                  <a target="_blank" rel="noreferrer" href={video_sp}>
                    download
                  </a>
                </li>
                <li>
                  PORTUGUESE:{" "}
                  <a target="_blank" rel="noreferrer" href={video_pr}>
                    download
                  </a>
                </li>
                <li>
                  ITALIAN:{" "}
                  <a target="_blank" rel="noreferrer" href={video_it}>
                    download
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className="container container-about">
      <HeroTitle
        type="ABOUT"
        title="What's this?"
        subtitle={subtitle()}
        blueVariant
      />
    </div>
  );
};

export default About;
