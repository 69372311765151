import React from "react";
import "./style.scss";

const Link = ({ label, href }) => {
  return (
    <a target="_blank" rel="noreferrer" href={href} className="myracle-link">
      {label}
    </a>
  );
};

export default Link;
