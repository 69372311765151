import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOrganizationalUnit } from "services/organizationalUnit";
import { getRelated } from "services/organizationalUnit";
import BackSave from "components/BackSave";
import CardContact from "components/CardContact";
import LegendButton from "components/LegendButton";
import RelatedCardList from "components/RelatedCardList";
import BipImage from "images/Bip unit icon.svg";
import Drop from "../../components/Drop";
import ImageCustomer from "../../components/ImageCustomer";
import { useTreeContext } from "../../contexts/Tree";
import useRelatedData from "../../hooks/useRelatedData";
import { TRIGGER_MODAL } from "../../redux/modal";
import { getBusinessChallenge } from "../../services/businessChallenge";
import { getOfferingTopic } from "../../services/offeringTopic";
import { getQualification } from "../../services/quals";
import { entityType } from "../../utils/commons";

import "./style.scss";

const BipUnitDetail = ({ type = entityType.TREE_LEVEL_VIEW }) => {
  const dispatch = useDispatch();
  const updateModalProps = payload => dispatch(TRIGGER_MODAL(payload));

  const [bipUnit, setBipUnit] = useState();
  const [related, setRelated] = useState({});
  const { bipUnitId } = useParams();

  const { businessChallengeCodes, qualificationCodes, offeringTopicCodes } =
    related || {};

  const { data: qualCardData, loadMore: loadMoreQuals } = useRelatedData(
    qualificationCodes,
    getQualification,
    "BipUnitDetail error: "
  );
  const { data: offeringCardData, loadMore: loadMoreOffering } = useRelatedData(
    offeringTopicCodes,
    getOfferingTopic,
    "BipUnitDetail error: "
  );

  const { data: bcCardData, loadMore: loadMoreBc } = useRelatedData(
    businessChallengeCodes,
    getBusinessChallenge,
    "BipUnitDetail error: "
  );
  const {
    shortDescription,
    longDescription,
    icon,
    name,
    tags,
    mission,
    projectTypes,
    customers,
    achievements,
    spocs,
    id,
  } = bipUnit || {};

  const { isTreeLevelViewModalOpen, setIsTreeLevelViewModalOpen } =
    useTreeContext();

    const unitsColorMap = useSelector((state) => state.unitsColor);
    const [sidebarColor, setSidebarColor] = useState();
    const [unitColor, setUnitColor] = useState({});

  const showModal = () => {
    updateModalProps({
      isOpen: true,
      modalProps: {
        type,
        code: id,
        versionType: type,
        selectedCoe: bipUnit,
      },
    });
  };

  const handleOnClick = () => {
    showModal();
    setIsTreeLevelViewModalOpen(true);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if(bipUnit){
      const type = (bipUnit.type && bipUnit.type === 'MFU' && bipUnit.isForeign) ? 'MFU_ESTERE' : bipUnit.type;
      setSidebarColor(unitsColorMap[type].color)
      const unitColor = {type: unitsColorMap[type]}
      setUnitColor(unitColor)
    }
  }, [bipUnit, unitsColorMap]);

  useEffect(() => {
    let isMounted = true;

    (async () => {
      if (!bipUnit) {
        try {
          const { data } = (await getOrganizationalUnit(bipUnitId)) || {};
          if (isMounted) {
            setBipUnit(data);
          }
        } catch (error) {
          console.error(` BipUnitDetail getOrganizationalUnit error: ${error}`);
        }
      }
      if (!Object.keys(related).length) {
        try {
          const { data } = (await getRelated(bipUnitId)) || {};
          if (isMounted) {
            setRelated(data);
          }
        } catch (error) {
          console.error(` BipUnitDetail getOrganizationalUnit error: ${error}`);
        }
      }
    })();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isTreeLevelViewModalOpen && id && type && bipUnit) {
      showModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type, bipUnit]);

  return (
    <div className="container detail">
      <div className="sidebar-color" style={{backgroundColor: `#${sidebarColor}`}}></div>
      <BackSave />
      {!bipUnit ? (
        <Skeleton width={"100%"} height={"45vw"} />
      ) : (
        <>
          <div className="bipunit-header">
            <div className="logo-img">
              {icon ? (
                <img src={`data:image/png;base64,${icon}`} alt={name} />
              ) : (
                <img src={BipImage} alt={name} />
              )}
            </div>
            <div className="title-section">
              <div className="type">BIP UNIT</div>
              <div className="title">{name}</div>
            </div>
          </div>
          <div className="bipunit-wrapper">
            <div>
              <div className="bipunit-description">
                <div
                  className="subtitle CKEditor-container"
                  id="rich-description1">
                  {shortDescription}
                </div>
                <div className="tags">
                  {(tags || []).map((tag, index) => (
                    <span key={`${index}-${tag}`} className="tag">
                      #{tag}
                    </span>
                  ))}
                </div>
                <div
                  className="desc CKEditor-container"
                  id="rich-description2"
                  dangerouslySetInnerHTML={{ __html: longDescription }}
                />
              </div>
              <div className="bipunit-mission">
                <div className="title">MISSION</div>
                <div className="mission">{mission}</div>
              </div>
              {projectTypes && projectTypes.length > 0 && (
                <div className="bipunit-types">
                  {projectTypes.map((type, index) => (
                    <div key={`${index}-${type}`} className="type">
                      <span>{type}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="bipunit-drop-container top">
              <div className="bipunit-drop">
                <div className="bipunit-drop-header">OFFERING LEVELS</div>

                <Drop selectedCoe={bipUnit} readOnly={true} />
                <div className="bipunit-drop-description">
                  For more info ...
                </div>
                <div
                  className="action"
                  onClick={() => {
                    return type === entityType.TREE_LEVEL_VIEW
                      ? handleOnClick()
                      : null;
                  }}>
                  <div className="view-more">VIEW MORE</div>
                </div>
              </div>

              {spocs.length > 0 && (
                <div className="bipunit-references">
                  <div className="title">REFERENCES</div>
                  {spocs.map(sp => (
                    <CardContact key={sp.userId} id={sp.userId} />
                  ))}
                </div>
              )}
            </div>
          </div>

          {achievements.length > 0 && (
            <div className="bipunit-achievements">
              <div className="title">KEY NUMBERS</div>
              <div className="archs">
                {achievements.map((arch, index) => (
                  <div key={index} className="arch">
                    <div className="value">{arch.value}</div>
                    <div className="description">{arch.description}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="bipunit-wrapper">
            {/* // TODO: check if we need this div*/}
            {/*<div className="bipunit-offering-topics"></div>*/}
            {customers.length > 0 && (
              <div className="bipunit-main-clients">
                <div className="title">MAIN CLIENTS</div>
                <div className="img-customers-container">
                  {customers.map(imgName => (
                    <ImageCustomer key={imgName} name={imgName} />
                  ))}
                </div>
              </div>
            )}
          </div>
          {businessChallengeCodes &&
          businessChallengeCodes?.length &&
          bcCardData?.length ? (
            <RelatedCardList
              elemList={businessChallengeCodes}
              documentType={entityType.BUSINESS_CHALLENGE}
              data={bcCardData}
              onGoRight={loadMoreBc}
            />
          ) : null}

          {offeringTopicCodes &&
          offeringTopicCodes.length > 0 &&
          offeringCardData?.length ? (
            <RelatedCardList
              elemList={offeringTopicCodes}
              documentType={entityType.OFFERING_TOPIC}
              data={offeringCardData}
              onGoRight={loadMoreOffering}
            />
          ) : null}

          {qualificationCodes &&
          qualificationCodes?.length > 0 &&
          qualCardData?.length ? (
            <RelatedCardList
              elemList={qualificationCodes}
              documentType={entityType.QUALIFICATION}
              data={qualCardData}
              onGoRight={loadMoreQuals}
            />
          ) : null}
        </>
      )}
      {unitColor &&
        <LegendButton legend={unitColor}/>
      }
    </div>
  );
};

export default React.memo(BipUnitDetail);
