import React, { useEffect, useState } from "react";
import Checked from "../../images/checked.svg";
import Unchecked from "../../images/unchecked.svg";

const CustomOption = ({
  value,
  onClick,
  selected,
  type,
  id,
  code,
  byCode,
  label = false,
}) => {
  const [checked, setChecked] = useState(selected);
  const isSelectedArray = Array.isArray(selected);

  const handleClick = () => {
    setChecked(!checked);
    isSelectedArray
      ? onClick(code, id, !checked)
      : byCode
      ? onClick(code, id, !checked)
      : onClick(value, id, !checked);
  };

  useEffect(() => {
    isSelectedArray
      ? setChecked(selected.includes(code) || selected.includes(id))
      : setChecked(selected);
  }, [code, id, isSelectedArray, selected]);

  return type ? (
    type === "option" ? (
      <div className="custom-select-option" onClick={handleClick}>
        {code && <>{code}&nbsp; - &nbsp;</>}
        {value}
        <div className="custom-select-option-checkbox">
          <img src={checked ? Checked : Unchecked} alt="option-checkbox" />
        </div>
      </div>
    ) : (
      <div className="custom-select-title">{value}</div>
    )
  ) : (
    <div className="custom-select-option" onClick={handleClick}>
      {label ? `${value} - ${label}` : value}
      <div className="custom-select-option-checkbox">
        <img src={checked ? Checked : Unchecked} alt="option-checkbox" />
      </div>
    </div>
  );
};

export default CustomOption;
