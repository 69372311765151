import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { GET_ORGANIZATIONAL_UNITS } from "redux/organizationalUnits";
import BipUnitCard from "components/BipUnitCard";
import HeroTitle from "components/HeroTitle";
import LegendButton from "components/LegendButton";
import LegendHeadbar from "components/LegendHeadbar";
import { entityType } from "../../utils/commons";

const BipUnits = () => {
  const dispatch = useDispatch();
  const getOrganizationalUnits = useCallback(
    () => dispatch(GET_ORGANIZATIONAL_UNITS()),
    [dispatch]
  );

  const organizationalUnits = useSelector(state => state.organizationalUnits);
  const unitsColorMap = useSelector((state) => state.unitsColor);

  const [unitsColor, setUnitsColor] = useState({});


  useEffect(() => {
    const units = ['VALUE_LINE', 'COE']  // manca mfu estere
    setUnitsColor(Object.fromEntries(  // filter unitsColorMap
      Object.entries(unitsColorMap)
            .filter(([key, value]) => units.includes(key))
  ))
  }, [unitsColorMap]);

  const { Track } = useTracking();
  useEffect(() => {
    if (!organizationalUnits.length) {
      getOrganizationalUnits();
    }
  }, [organizationalUnits, getOrganizationalUnits]);

  return (
    <div className="container">
      <HeroTitle
        type="Bip Units"
        title="Who makes up Bip"
        subtitle="List of Bip Units (e.g. Centres of Excellence,
        Market Facing Units, Countries) publishing content on the platform, with related descriptive metadata."
        blueVariant
      />
      <div style={{marginTop: '3rem'}}>
        <LegendHeadbar legend={unitsColor}/>
      </div>
      <div className="others-cards-list">
        {organizationalUnits
          .filter(
            item => item.type !== entityType.SUBINDUSTRY && item.isVisible
          )
          .map(bu => (
            <Track key={bu.id}>
              <BipUnitCard key={bu.id} {...bu} unitsColor={unitsColorMap}/>
            </Track>
          ))}
      </div>
      <LegendButton legend={unitsColor}/>
    </div>
  );
};

export default React.memo(BipUnits);
