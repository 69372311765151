import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { getUser } from "services/user";
import Avatar from "components/Avatar";
import TeamsContact from "../TeamsContact";
import "./style.scss";



const CardContact = ({ id, organizationalUnits, showTeamsContact = false, ...others }) => {
  const [contact, setContact] = useState(others);

  useEffect(() => {
    if (id) {
      getUser(id)
        .then(res => {
          if (res.data) setContact(res.data);
        })
        .catch(e => console.log("Error retrieving contact data for ", id));
    }
  }, [id]);

  return (
    <div className="cardcontact-wrapper">
      <Avatar profile={contact ? contact : { name: "A", surname: "B" }} />
      <div className="infos">
        <div className="name">{`${contact?.name} ${contact?.surname}`}</div>
        <div className="area">
          {contact?.organizationalUnitId
            ? (organizationalUnits || []).find(
                u => u.id === contact.organizationalUnitId
              ).name
            : ""}
        </div>
        <div className="email">{contact.email}</div>
        <div className="phone">{contact.phone}</div>
        {showTeamsContact && <TeamsContact email={contact.email} />}
      </div>
    </div>
  );
};

export default connect(
  ({ organizationalUnits }) => ({ organizationalUnits }),
  () => ({})
)(CardContact);
