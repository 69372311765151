import React, { useRef, useState } from "react";
import Modal from "react-modal";
import moment from "moment";
import { ReactComponent as ClearSvg } from "images/cancel.svg";
import { ReactComponent as CopySvg } from "images/copy.svg";

import "./styles.scss";

const ModalEvent = ({ isOpen, event, onClose }) => {
  const linkRef = useRef(null);
  const [copyStatus, setCopyStatus] = useState(false);

  const handleCopyLink = e => {
    linkRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 2000);
  };
  return (
    <Modal isOpen={isOpen} className="custom-modal" ariaHideApp={false}>
      <div className="custom-modal-header">
        <ClearSvg onClick={onClose} />
      </div>
      <div className="custom-modal-content">
        <div className="title">{event?.title}</div>
        <div className="date">
          {moment(event?.startDate).format("DD/MM/YY")} <br />{" "}
          {moment(event?.startDate).format("H:mm") +
            "-" +
            moment(event?.endDate).format("H:mm") +
            " (GMT" +
            String(moment().local().format("Z")) +
            ")"}
        </div>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: event?.description }}
        />
        {event?.organizationalUnit?.name && (
          <div>
            <div className="bu-label">Bip Unit</div>
            <div className="bu-link">{event?.organizationalUnit?.name}</div>
          </div>
        )}
        {event?.link ? (
          <div className="link-section">
            <div className="link-section-title">Event Link</div>
            <div className="link-section-event">
              <input
                type="text"
                readOnly
                value={event?.link || ""}
                ref={linkRef}
              />
              <CopySvg onClick={handleCopyLink} />
            </div>
            <div className="copy-status">{copyStatus && "copied"}</div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default ModalEvent;
