import { useEffect } from "react";
import { connect } from "react-redux";
import {useTracking} from "react-tracking";

import { GET_PERSONAS } from "redux/personas";
import ClientPersonaCard from "components/ClientPersonaCard";
import HeroTitle from "components/HeroTitle";


const ClientPersonas = ({ personas, getPersonas }) => {
  const {Track} = useTracking({section: "personas"});
  useEffect(() => {
    if (personas.length === 0) {
      getPersonas();
    }
  }, [personas, getPersonas]);

  return (
    <div className="container">
      <HeroTitle
        type="Client personas"
        title="Who we work for"
        subtitle="List of Client Representatives (typically a C*O) holding a distinctive role in the Client's organization, whom Bip Group Sales People typically target during the pre-sales and sales process."
        blueVariant
      />
      <div className="others-cards-list">
        {personas.map(cp => (
          <Track key={cp.id}>
            <ClientPersonaCard key={cp.id} {...cp} />
          </Track>
        ))}
      </div>
    </div>
  );
};

export default connect(
  ({ personas }) => ({ personas }),
  dispatch => ({
    getPersonas: () => dispatch(GET_PERSONAS()),
  })
)(ClientPersonas);
