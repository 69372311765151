import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTracking } from "react-tracking";

import Avatar from "components/Avatar";
import ProfileInfo from "components/ProfileInfo";
import { ReactComponent as ArrowIcon } from "images/arrow.svg";

import "./style.scss";
import { GET_ORGANIZATIONAL_UNITS } from "../../redux/organizationalUnits";

const ProfileCard = ({
  profile,
  organizationalUnits,
  getOrganizationalUnits,
}) => {
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const [timeout, setTimeoutID] = useState();
  const { Track } = useTracking({ subsection: "Profile" });

  useEffect(() => {
    !organizationalUnits.length && getOrganizationalUnits();
  }, []);

  const handleMouseOver = e => {
    if (e) e.stopPropagation();
    if (timeout) {
      clearTimeout(timeout);
      setTimeoutID(null);
    }
    if (!showProfileInfo) setShowProfileInfo(true);
  };

  const handleOnBlur = () => {
    const tm = setTimeout(() => {
      setShowProfileInfo(false);
    }, 250);
    setTimeoutID(tm);
  };

  return (
    <div className="profile-container" onClick={e => handleMouseOver(e)}>
      <div className="title">profile</div>
      <div
        className="card"
        onMouseLeave={handleOnBlur}
        onMouseOver={handleMouseOver}>
        <div className="avatar">
          <Avatar profile={profile} />
        </div>
        <div className="info">
          <div className="name">
            {profile?.name} {profile?.surname}
          </div>
          {profile?.organizationalUnit && (
            <>
              <div className="area-title">Area of competence</div>
              <div className="area">
                {profile?.organizationalUnit
                  ? profile.organizationalUnit.name
                  : ""}
              </div>
            </>
          )}
          <div className="open-down">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.751 55.751">
              <path d="M31.836 43.006c.282-.281.518-.59.725-.912L54.17 20.485a5.403 5.403 0 000-7.638 5.403 5.403 0 00-7.638 0l-18.608 18.61L9.217 12.753A5.4 5.4 0 000 16.571a5.363 5.363 0 001.582 3.816l21.703 21.706c.207.323.445.631.729.913a5.367 5.367 0 003.91 1.572 5.37 5.37 0 003.912-1.572z" />
            </svg>
          </div>
        </div>
        <Track>
          <ProfileInfo
            profile={profile}
            open={showProfileInfo}
            handleClose={() => setShowProfileInfo(false)}
            organizationalUnits={organizationalUnits}
          />
        </Track>
        <div className="cta">
          <ArrowIcon />
        </div>
      </div>
    </div>
  );
};

export default connect(
  ({ profile, organizationalUnits }) => ({ profile, organizationalUnits }),
  dispatch => ({
    getOrganizationalUnits: () => dispatch(GET_ORGANIZATIONAL_UNITS()),
  })
)(ProfileCard);
