import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { TRIGGER_MODAL, CLEAN_STORE } from "redux/modal";
import { SET_SEARCH } from "redux/search";
import { deleteBusinessChallenge } from "services/businessChallenge";
import { searchBusinessChallenge } from "services/serp";
import {
  auditActions,
  auditTypes,
  entityType,
  filtersMyracle,
} from "utils/commons";
import HeroTitle from "components/HeroTitle";
import SearchView from "components/SearchView";
import ConfirmActionModal from "components/ActionModal/ConfirmActionModal";
import useLoadData from "../../hooks/useLoadData";

const BusinessChallenge = () => {
  const dispatch = useDispatch();
  const setSearch = payload =>
    dispatch(SET_SEARCH({ BUSINESS_CHALLENGES: payload }));
  const updateModalProps = payload => dispatch(TRIGGER_MODAL(payload));
  const cleanStore = payload => dispatch(CLEAN_STORE(payload));

  const searchBusinessChallenges = useSelector(
    state => state.searchBusinessChallenges
  );
  const modal = useSelector(state => state.modal);

  const { trackEvent } = useTracking({
    resourceType: entityType.BUSINESS_CHALLENGE,
  });
  const [selectedFilters, setSelectedFilters] = useState({
    order_by: "LATEST",
    ...searchBusinessChallenges,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [code, setCode] = useState();

  const {
    loadData,
    items: businessChallenges,
    setItems: setBusinessChallenges,
    total,
    setPageNumber,
  } = useLoadData({
    searchFunction: searchBusinessChallenge,
    params: { ...selectedFilters },
  });

  useEffect(() => {
    if (modal.shouldReloadOfferings) {
      setPageNumber(0);
      setBusinessChallenges([]);

      setSelectedFilters({
        order_by: "LATEST",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.shouldReloadOfferings]);

  useEffect(() => {
    setSearch(selectedFilters);
    void loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  const handleResetFilters = () => {
    setPageNumber(0);
    setBusinessChallenges([]);

    setSelectedFilters({
      order_by: "LATEST",
    });
  };

  const handleChangeFilter = (key, value) => {
    setPageNumber(0);
    setBusinessChallenges([]);
    setSelectedFilters({
      ...selectedFilters,
      [key]: value,
    });
  };

  const businessChallengeCallback = params => {
    const { action, code } = params;
    switch (action) {
      case "edit":
        trackEvent({
          action: auditActions.EDIT_AND_PUBLISH,
          code: code,
          type: auditTypes.ACTION,
        });
        updateModalProps({
          isOpen: true,
          modalProps: {
            code: code,
            type: entityType.BUSINESS_CHALLENGE,
          },
        });
        break;
      case "delete":
        setIsModalOpen(true);
        setCode(code);
        break;
      default:
        break;
    }
  };

  return (
    <div className="container">
      <HeroTitle
        type="Business Challenges"
        title="What keeps our clients up at night"
        subtitle="List of compelling needs of client Personas (e.g. C*O) addressed by Bip Group"
        grayVariant
      />
      <SearchView
        selectedFilters={selectedFilters}
        handleChangeFilter={handleChangeFilter}
        dataList={businessChallenges}
        viewableFilters={filtersMyracle.BUSINESS_CHALLENGES}
        handleResetFilters={handleResetFilters}
        //searchKey="BUSINESS_CHALLENGES"
        callback={businessChallengeCallback}
        documentType={entityType.BUSINESS_CHALLENGE}
        loadData={loadData}
        total={total}
      />
      <ConfirmActionModal
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => {
          deleteBusinessChallenge(code)
            .then(res => {
              if (!res || res?.data === null) {
                console.error(
                  `Error deleting content ${res?.message ? res.message : ""}`
                );
                setIsModalOpen(false);
              } else {
                trackEvent({
                  action: auditActions.DELETE,
                  code,
                  type: auditTypes.ACTION,
                });
                setIsModalOpen(false);
                setBusinessChallenges(
                  businessChallenges.filter(item => item.code !== code)
                );
                cleanStore({
                  code,
                  documentType: entityType.BUSINESS_CHALLENGE,
                });
              }
            })
            .catch(error => {
              console.error(`Error deleting content: ${error}`);
            });
          setIsModalOpen(false);
        }}
        isOpenProp={isModalOpen}
      />
    </div>
  );
};

export default React.memo(BusinessChallenge);
