import { apis, serviceInterface } from "./config";

export const getComments = code => {
  return serviceInterface.get(
    `${apis.COMMENT}?code=${code}`
  );
};

export const createComment = (params) => {
  return serviceInterface.post(
    `${apis.COMMENT}`, { ...params }
  );
};

export const updateComment = (id, params) => {
  return serviceInterface.put(
    `${apis.COMMENT}/${id}`, { ...params }
  );
};

export const deleteComment = (id) => {
  return serviceInterface.delete(
    `${apis.COMMENT}/${id}`
  );
};
