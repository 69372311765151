import React, { useState } from "react";
import { useTracking } from "react-tracking";
import PropTypes from "prop-types";
import { removeLike, addLike, resetContentLikes } from "services/like";
import { auditActions, auditTypes } from "utils/commons";
import LikeImage from "images/like.svg";
import LikeImageFull from "images/like_reverse.svg";
import "./style.scss";

const Likes = ({
  code,
  contentType,
  myLikeData,
  setCallbackLikes,
  canReset = false,
  commentsSection = false,
  totalLikes,
}) => {
  const [total, setTotal] = useState(totalLikes || 0);
  const [myLike, setMyLike] = useState(myLikeData || false);

  const { trackEvent } = useTracking();

  const toggleLike = event => {
    event.preventDefault();
    event.stopPropagation();
    if (myLike) {
      trackEvent({
        type: auditTypes.ACTION,
        action: auditActions.REMOVE_LIKE,
      });
      removeLike(code, contentType)
        .then(() => {
          setMyLike(false);
          if (total) {
            setTotal(total - 1);
          }
          if (setCallbackLikes && total) {
            setCallbackLikes(total - 1, false);
          }
        })
        .catch(e => {
          console.error("Warning: Error updating content", e);
        });
    } else {
      trackEvent({
        type: auditTypes.ACTION,
        action: auditActions.ADD_LIKE,
      });
      addLike({ contentCode: code, contentType: contentType })
        .then(response => {
          if (response && response.data) {
            setMyLike(true);
            setTotal(total + 1);
            if (setCallbackLikes) {
              setCallbackLikes(total + 1, true);
            }
          }
        })
        .catch(e => {
          console.error("Warning: Error updating content", e);
        });
    }
  };

  const resetLikes = event => {
    event.preventDefault();
    event.stopPropagation();
    trackEvent({
      type: auditTypes.ADMIN,
      action: auditActions.RESET_LIKES,
    });
    resetContentLikes(code, contentType)
      .then(response => {
        if (response && response.data !== null) {
          setMyLike(false);
          setTotal(0);
          if (setCallbackLikes) {
            setCallbackLikes(0, false);
          }
        }
      })
      .catch(e => {
        console.error("Warning: Error updating content", e);
      });
  };

  return (
    <div
      id="like-click"
      className="likes-container"
      onClick={e => toggleLike(e)}>
      <div className="like-reset-container">
        <img
          className={commentsSection ? "big-like-image" : "like-image"}
          src={myLike ? LikeImageFull : LikeImage}
          alt="like"
        />
        {canReset && (
          <button className="reset-button" onClick={e => resetLikes(e)}>
            <span>Reset likes</span>
          </button>
        )}
      </div>
      <div className={commentsSection ? "hide" : "card-like-number"}>
        {total}
      </div>
    </div>
  );
};

Likes.propTypes = {
  code: PropTypes.string || PropTypes.number,
  contentType: PropTypes.string,
};

export default Likes;
