import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import { useTracking } from "react-tracking";
import { SET_CONTRIBUTOR } from "redux/contributor";
import { TRIGGER_MODAL } from "redux/modal";
import { getContributorQualsCounts, getContributorQualsWithPagination } from "services/backoffice";
import {
  deleteBusinessChallenge,
  getBusinessChallenge,
} from "services/businessChallenge";
import {
  deleteOffering,
  getOfferingTopicByOrganizationalUnitId,
} from "services/offeringTopic";
import { deleteQualification, publish, unPublish } from "services/quals";
import {
  entityType,
  entityLabels,
  auditTypes,
  auditActions,
  treeLevelDescriptions,
} from "utils/commons";
import { getProfileInfo } from "utils/profile";
import CustomSelect from "components/CustomSelect";
import SearchView from "components/SearchView";
import ConfirmActionModal from "components/ActionModal/ConfirmActionModal";
import SearchIcon from "images/search.svg";
import GoBackButton from "../../components/GoBackButton";
import { TreeProvider } from "../../contexts/Tree";
import useDebounce from "../../hooks/useDebounce";
import BipUnitImage from "../../images/Bip unit icon.svg";
import { getOrganizationalUnit } from "../../services/organizationalUnit";
import { objectToArray } from "../../utils/utils";

import "./style.scss";

const QUALIFICATION_PAGE_SIZE = 20;

const Contributor = ({
  profile,
  organizationalUnits,
  type,
  contributor,
  updateContributor,
  updateModalProps,
  modal,
}) => {
  const { Track, trackEvent } = useTracking();
  const [tabSelected, setTabSelected] = useState("all");
  const [all, setAll] = useState([]);
  const [editModalCode, setEditModalCode] = useState(null);
  const [selectedCoe, setSelectedCoe] = useState(
    contributor[type].selectedCoe || null
  );
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(contributor[type].search || "");
  const [filtered, setFiltered] = useState([]);
  const [codeToBeUnpublish, setCodeToBeUnpublish] = useState("");
  const [codeToBeDelete, setCodeToBeDelete] = useState("");
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const profileInfo = getProfileInfo(profile);
  const canEditQuals = profileInfo.canEditQuals(organizationalUnits);
  const canEditOfferings = profileInfo.canEditOfferings(organizationalUnits);
  const isAdmin = profileInfo.isAdmin;
  const isEditor = profileInfo.isEditor;
  const canEditTreeLevel = profileInfo.canEditTreeLevel;
  const [action, setAction] = useState("");
  const [versionType, setVersionType] = useState(type);
  const [qualsCurrentPage, setQualsCurrentPage] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [qualsCounts, setQualsCounts] = useState();
  const [loadingQualCurrentPage, setLoadingQualCurrentPage] = useState(false);
  
  const ouTree = { COE: [], MFU: [], VALUE_LINE: [], SUBINDUSTRY: [] };
  const [ouCoeArray, setOuCoeArray] = useState([]);
  
  const debouncedValue = useDebounce(search, 800);
  
  //filters
  const filterSearch = e => {
    const rg = new RegExp(search, "i");
    return (
      (e.title && rg.test(e.title)) ||
      (e.description && rg.test(e.description)) ||
      rg.test(e.code)
    );
  };

  const clearAll = () => {
    setQualsCurrentPage(0);
    setIsLastPage(false);
    setAll([]);
    setFiltered([]);
  };
  
  const getQualsCurrentPage = (currentParams = {}) => {
    if (selectedCoe?.id) {
      setLoadingQualCurrentPage(true);
      // prepare params
      let params = {
        pageNumber: currentParams.currentPage ?? qualsCurrentPage,
        pageSize: QUALIFICATION_PAGE_SIZE,
        search: currentParams.currentSearchValue ?? search,
        versionTypes: (currentParams.currentSelectedTab || tabSelected).toUpperCase(),
      }
      if (params.versionTypes === 'ALL') {// remove in case og all
        delete (params.versionTypes);
      }
      getContributorQualsWithPagination(selectedCoe?.id, {...params})
        .then(res => {
          if (res?.data?.qualifications) {
            if (res?.data.currentPage + 1 === res.data.totalPages) {
              setIsLastPage(true);
            }
            const quals = res.data.qualifications?.map(q => ({
              ...q,
              documentType: entityType.QUALIFICATION,
            }));
            setAll(prevState => currentParams.currentData
              ? [...currentParams.currentData, ...quals]
              : [...prevState, ...quals]);
            setQualsCurrentPage(prevState => (currentParams.currentPage ?? prevState) + 1)
          } else {
            clearAll();
          }
          setLoading(false);
          setLoadingQualCurrentPage(false);
        })
        .catch(err => {
          console.log("Error get backoffice", err);
          setLoading(false);
          setLoadingQualCurrentPage(false);
        });
    }
  };
  const loadAll = () => {
    setLoading(true);
    switch (type) {
      case entityType.QUALIFICATION:
        getQualsCurrentPage({currentPage: 0, currentData: []});
        break;
      case entityType.OFFERING_TOPIC:
        getOfferingTopicByOrganizationalUnitId(selectedCoe?.id)
          .then(res => {
            const offerings = res.data.map(q => ({
              ...q,
              documentType: entityType.OFFERING_TOPIC,
            }));
            setAll(offerings);
            setFiltered(search ? offerings.filter(filterSearch) : offerings);
            setLoading(false);
          })
          .catch(err => {
            console.log("Error get backoffice", err);
            setLoading(false);
          });
        break;
      case entityType.BUSINESS_CHALLENGE:
        getBusinessChallenge()
          .then(res => {
            const businessChallenges = res.data.map(q => ({
              ...q,
              documentType: entityType.BUSINESS_CHALLENGE,
            }));
            setAll(businessChallenges);
            setFiltered(
              search
                ? businessChallenges.filter(filterSearch)
                : businessChallenges
            );
            setLoading(false);
          })
          .catch(err => {
            console.log("Error get backoffice", err);
            setLoading(false);
          });
        break;
      default:
        break;
    }
  };

  //modal actions
  const showModal = create => {
    if (create) setEditModalCode(null);
    updateModalProps({
      isOpen: true,
      modalProps: {
        type,
        code: create ? null : editModalCode,
        selectedCoe,
        versionType: tabSelected,
      },
    });
  };

  //callbacks
  const qualCallback = params => {
    const { action, code, versionType } = params;
    setVersionType(versionType);
    switch (action) {
      case "edit":
        setAction("edit");
        setEditModalCode(code);
        updateModalProps({
          isOpen: true,
          modalProps: {
            code,
            type,
            selectedCoe,
            versionType,
          },
        });
        break;
      case "delete":
        setAction("delete");
        setIsOpenConfirmModal(true);
        setCodeToBeDelete(code);
        break;
      case "publish":
        setAction("publish");
        publish(code).then(() => {
          trackEvent({
            action: auditActions.EDIT_AND_PUBLISH,
            code,
            type: auditTypes.ACTION,
            resourceType: entityType.QUALIFICATION,
          });
          clearAll();
          loadAll();
        });
        break;
      case "unpublish":
        setAction("unpublish");
        setIsOpenConfirmModal(true);
        setCodeToBeUnpublish(code);
        break;
      default:
        break;
    }
  };
  const offeringCallback = params => {
    const { action, code, versionType } = params;
    setVersionType(versionType);
    switch (action) {
      case "edit":
        setAction("edit");
        setEditModalCode(code);
        updateModalProps({
          isOpen: true,
          modalProps: {
            code,
            type,
            selectedCoe,
            versionType,
          },
        });
        break;
      case "delete":
        setAction("delete");
        setIsOpenConfirmModal(true);
        setCodeToBeDelete(code);
        break;
      default:
        break;
    }
  };
  const businessChallengeCallback = params => {
    const { action, code, versionType } = params;
    setVersionType(versionType);
    switch (action) {
      case "edit":
        setAction("edit");
        setEditModalCode(code);
        updateModalProps({
          isOpen: true,
          modalProps: {
            code,
            type,
            selectedCoe,
            versionType,
          },
        });
        break;
      case "delete":
        setAction("delete");
        setIsOpenConfirmModal(true);
        setCodeToBeDelete(code);
        break;
      default:
        break;
    }
  };

  //handlers
  const handleSelectCoe = value => {
    const coe = organizationalUnits.find(c => c.name === value)
      ? organizationalUnits.find(c => c.name === value)
      : organizationalUnits.find(c => c.id === profile?.organizationalUnitId);
    setSelectedCoe(coe);
    updateContributor({ [type]: { ...contributor[type], selectedCoe: coe } });
    clearAll();
  };
  
  const onChangeHandler = e => {
    const val = e?.currentTarget?.value || "";
    setSearch(val);
    updateContributor({ [type]: { ...contributor[type], search: val } });
    if (val.length > 2 && type !== entityType.QUALIFICATION) {
      const filterFn = e => {
        const rg = new RegExp(val, "i");
        return (
          (e.title && rg.test(e.title)) ||
          (e.description && rg.test(e.description)) ||
          rg.test(e.code)
        );
      };
      setFiltered(all.filter(filterFn));
    } else {
      setFiltered(all);
    }
  };
  
  // get quals count and quals when selectedCoe and debouncedValue change
  useEffect(() => {
    if (selectedCoe?.id && type === entityType.QUALIFICATION) {
      clearAll();
      let params = {
        search: debouncedValue,
      }
      setLoading(true);
      getContributorQualsCounts(selectedCoe?.id, params)
        .then(res => {
          if (res?.data) {
            setQualsCounts(res.data);
          }
        })
        .catch(err => {
          console.log("Error get backoffice quals count", err);
        });
      getQualsCurrentPage({currentPage: 0, currentSearchValue: debouncedValue, currentData: []});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoe, debouncedValue, type]);
  
  const handleSelectTab = tab => {
    setTabSelected(tab);
    updateContributor({ [type]: { ...contributor[type], selectedTab: tab } });
    clearAll();
    setLoading(true);
    getQualsCurrentPage({currentPage: 0, currentSelectedTab: tab, currentData: []});
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (profile?.organizationalUnitIds.length === 1 && !selectedCoe) {
        try {
          const { data } = await getOrganizationalUnit(
            profile?.organizationalUnitIds[0]
          );
          if (!isMounted) return;
          setSelectedCoe(data);
        } catch (e) {
          console.error(e);
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [profile?.organizationalUnitIds, selectedCoe]);

  useEffect(() => {
    if (profile?.organizationalUnit) {
      setSelectedCoe(profile?.organizationalUnit);
    }
  }, [profile]);

  useEffect(() => {
    if (modal.shouldReloadOfferings && selectedCoe) {
      loadAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.shouldReloadOfferings, selectedCoe]);

  useEffect(() => {
    if (
      !loading &&
      all.length === 0 &&
      (selectedCoe || type === entityType.BUSINESS_CHALLENGE)
    ) {
      loadAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoe, versionType]);

  useEffect(() => {
    if (contributor[type].selectedCoe) {
      setSelectedCoe(contributor[type].selectedCoe);
    }
    if (contributor[type].search && type !== entityType.QUALIFICATION) {
      const filterFn = e => {
        const rg = new RegExp(contributor[type].search, "i");
        return (
          (e.title && rg.test(e.title)) ||
          (e.description && rg.test(e.description)) ||
          rg.test(e.code)
        );
      };
      setFiltered(all.filter(filterFn));
    }
    if (contributor[type].selectedTab) {
      setTabSelected(contributor[type].selectedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoe]);

  useEffect(() => {
    organizationalUnits.length &&
      organizationalUnits &&
      organizationalUnits.map(ou => ouTree[ou.type.toUpperCase()].push(ou));
    setOuCoeArray(
      objectToArray({
        COE: ouTree.COE,
        MFU: ouTree.MFU,
        VALUE_LINE: ouTree.VALUE_LINE,
        SUBINDUSTRY: ouTree.SUBINDUSTRY,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationalUnits]);

  return (
    <TreeProvider>
      <div className="container contributor">
        <div className="contributor-header ">
          <div className="contributor-banner">
            <span>CONTRIBUTOR MODE</span>
          </div>
          {isAdmin && type !== entityType.BUSINESS_CHALLENGE && (
            <div className="coe-select">
              <CustomSelect
                value={selectedCoe?.name}
                items={
                  ouCoeArray &&
                  ouCoeArray.map(v => {
                    if (v.id) {
                      return {
                        type: "option",
                        id: v.id,
                        value: v.name,
                        code: v.code,
                      };
                    }
                    return {
                      type: "title",
                      id: v,
                      value: v,
                      code: "",
                    };
                  })
                }
                label={selectedCoe ? selectedCoe?.name : "Select a Coe"}
                onChange={val => handleSelectCoe(val)}
                closeOnSelect
                absoluteOptions
              />
            </div>
          )}
          {!isAdmin && isEditor && type !== entityType.BUSINESS_CHALLENGE && (
            <div className="coe-select">
              <CustomSelect
                value={selectedCoe?.name}
                items={
                  type === entityType.OFFERING_TOPIC
                    ? canEditOfferings.map(o => ({
                        id: o.id,
                        value: o.name,
                      }))
                    : canEditQuals.map(o => ({
                        id: o.id,
                        value: o.name,
                      }))
                }
                label={selectedCoe ? selectedCoe?.name : "Select a Coe"}
                onChange={val => handleSelectCoe(val)}
                closeOnSelect
                absoluteOptions
              />
            </div>
          )}

          {versionType === entityType.OFFERING_TREE_VIEW ? (
            <GoBackButton type={true} setVersionType={setVersionType} />
          ) : null}

          <div className="organizational-unit">
            {selectedCoe &&
              (versionType === entityType.OFFERING_TOPIC ||
                type === entityType.OFFERING_TOPIC) && (
                <>
                  <div className="image">
                    {selectedCoe?.icon ? (
                      <img
                        src={`data:image/png;base64,${selectedCoe?.icon}`}
                        alt={selectedCoe?.name}
                      />
                    ) : (
                      <img src={BipUnitImage} alt={selectedCoe?.name} />
                    )}
                  </div>
                  <div className="name">{selectedCoe?.name}</div>
                </>
              )}
          </div>

          <div className="title">
            {entityLabels[type].plural}
            {type === entityType.OFFERING_TOPIC ? (
              <>
                <br /> <span> (editing mode with drag & drop)</span>
              </>
            ) : null}
          </div>
          <div className="action">
            <div
              className={`add-item ${
                selectedCoe || type === entityType.BUSINESS_CHALLENGE
                  ? ""
                  : "disabled"
              }`}
              onClick={() => {
                return selectedCoe || type === entityType.BUSINESS_CHALLENGE
                  ? showModal(true)
                  : null;
              }}>
              ADD NEW {entityLabels[type].singolar.toUpperCase()}
            </div>
          </div>
          <div className="search">
            <div className="search-bar-container">
              <div className="search-icon">
                <img src={SearchIcon} alt="lens" />
              </div>
              <input
                type="text"
                className="search-bar"
                value={search || ""}
                onChange={onChangeHandler}
              />
            </div>
          </div>
          {type === entityType.QUALIFICATION && (
            <div className="tabs-container">
              <div
                className={`tab ${tabSelected === "all" ? "selected" : ""}`}
                onClick={() => handleSelectTab("all")}>{`All (${qualsCounts?.allItemsCount || 0})`}</div>
              <div
                className={`tab ${tabSelected === "draft" ? "selected" : ""}`}
                onClick={() => handleSelectTab("draft")}>
                {`DRAFT (${qualsCounts?.draftItemsCount || 0})`}
              </div>
              <span
                className={`tab ${
                  tabSelected === "published" ? "selected" : ""
                }`}
                onClick={() => handleSelectTab("published")}>
                {`PUBLISHED (${qualsCounts?.publishedItemsCount || 0})`}
              </span>
            </div>
          )}
        </div>

        {selectedCoe &&
          type === entityType.OFFERING_TOPIC &&
          versionType !== entityType.OFFERING_TREE_VIEW && (
            <div className="contributor-description">
              <p>{treeLevelDescriptions.CONTRIBUTOR_DESCRIPTION}</p>
              <p>{treeLevelDescriptions.CONTRIBUTOR_DESCRIPTION_LEVEL_1}</p>
              <p>{treeLevelDescriptions.CONTRIBUTOR_DESCRIPTION_LEVEL_2}</p>
              <p>{treeLevelDescriptions.CONTRIBUTOR_DESCRIPTION_LEVEL_3}</p>
            </div>
          )}
        {selectedCoe && versionType === entityType.OFFERING_TREE_VIEW && (
          <div className="contributor-description">
            <p>{treeLevelDescriptions.MANAGE_VIEW_DESCRIPTION}</p>
          </div>
        )}
        {selectedCoe && type === entityType.OFFERING_TOPIC && (
          <div className="contributor-buttons_container">
            <div className="action">
              {versionType !== entityType.OFFERING_TREE_VIEW &&
              (canEditTreeLevel || isAdmin) ? (
                <div
                  className={`add-item ${
                    selectedCoe || type === entityType.BUSINESS_CHALLENGE
                      ? ""
                      : "disabled"
                  }`}
                  onClick={() => {
                    setVersionType(entityType.OFFERING_TREE_VIEW);
                  }}>
                  MANAGE O.U. TREE {entityLabels[type].singolar.toUpperCase()}
                </div>
              ) : null}
            </div>
          </div>
        )}
        {loading ? (
          <Skeleton height={"100vh"} width={"100%"} />
        ) : (
          <Track>
            {type === entityType.QUALIFICATION && (
              <>
                <div
                  className={`tab-content ${
                    tabSelected === "all" ? "visible-tab" : ""
                  }`}>
                  <SearchView
                    dataList={all}
                    callback={qualCallback}
                    isContributor
                    total={isLastPage ? all.length : (qualsCounts?.allItemsCount || 0)}
                    documentType={type}
                    selectedCoe={selectedCoe}
                    loadData={getQualsCurrentPage}
                    isLoadingData={loadingQualCurrentPage}
                    showFileWithErrors
                    showEditLegend
                  />
                </div>
                <div
                  className={`tab-content ${
                    tabSelected === "draft" ? "visible-tab" : ""
                  }`}>
                  <SearchView
                    dataList={all}
                    callback={qualCallback}
                    isContributor
                    total={isLastPage ? all.length : (qualsCounts?.draftItemsCount || 0)}
                    documentType={type}
                    selectedCoe={selectedCoe}
                    loadData={getQualsCurrentPage}
                    isLoadingData={loadingQualCurrentPage}
                    showFileWithErrors
                    showEditLegend
                  />
                </div>
                <div
                  className={`tab-content ${
                    tabSelected === "published" ? "visible-tab" : ""
                  }`}>
                  <SearchView
                    dataList={all}
                    callback={qualCallback}
                    isContributor
                    total={isLastPage ? all.length : (qualsCounts?.publishedItemsCount || 0)}
                    documentType={type}
                    selectedCoe={selectedCoe}
                    loadData={getQualsCurrentPage}
                    isLoadingData={loadingQualCurrentPage}
                    showFileWithErrors
                    showEditLegend
                  />
                </div>
              </>
            )}
            {type === entityType.OFFERING_TOPIC && (
              <SearchView
                dataList={filtered}
                callback={offeringCallback}
                isContributor
                total={filtered.length}
                documentType={type}
                selectedCoe={selectedCoe}
                versionType={versionType}
                showFileWithErrors
                showEditLegend
              />
            )}
            {type === entityType.BUSINESS_CHALLENGE && (
              <SearchView
                dataList={filtered}
                callback={businessChallengeCallback}
                isContributor
                total={filtered.length}
                documentType={type}
                selectedCoe={selectedCoe}
              />
            )}
          </Track>
        )}
        <ConfirmActionModal
          onClose={() => setIsOpenConfirmModal(false)}
          onConfirm={() => {
            if (action === "delete") {
              if (versionType === "BUSINESS_CHALLENGE") {
                deleteBusinessChallenge(codeToBeDelete).then(() => {
                  trackEvent({
                    action: auditActions.DELETE,
                    code: codeToBeDelete,
                    type: auditTypes.ACTION,
                    resourceType: entityType.BUSINESS_CHALLENGE,
                  });
                  clearAll();
                  loadAll();
                });
              }
              if (versionType === "OFFERING_TOPIC") {
                deleteOffering(codeToBeDelete)
                  .then(res => {
                    if (!res || res?.data === null) {
                      console.log(
                        "Error deleting content" +
                          " " +
                          res?.message?.toString()
                      );
                      setIsOpenConfirmModal(false);
                    } else {
                      trackEvent({
                        action: auditActions.DELETE,
                        code: codeToBeDelete,
                        type: auditTypes.ACTION,
                        resourceType: entityType.OFFERING_TOPIC,
                      });
                      setIsOpenConfirmModal(false);
                      clearAll();
                      loadAll();
                    }
                  })
                  .catch(e => {
                    console.error("Error deleting content", e);
                  });
              }
              if (versionType === "DRAFT") {
                deleteQualification(codeToBeDelete).then(() => {
                  trackEvent({
                    action: auditActions.DELETE,
                    code: codeToBeDelete,
                    type: auditTypes.ACTION,
                    resourceType: entityType.QUALIFICATION,
                  });
                  clearAll();
                  loadAll();
                });
              }
            } else {
              if (action === "unpublish") {
                unPublish(codeToBeUnpublish)
                  .then(res => {
                    if (!res || res.data === null) {
                      console.error(
                        "Error hiding content: " + res?.message?.toString()
                      );
                      setIsOpenConfirmModal(false);
                    } else {
                      trackEvent({
                        action: auditActions.DELETE,
                        code: codeToBeUnpublish,
                        type: auditTypes.ACTION,
                      });
                      setIsOpenConfirmModal(false);
                      clearAll();
                      loadAll();
                    }
                  })
                  .catch(e => {
                    console.error("Error hiding content", e);
                  });
              }
            }
            setIsOpenConfirmModal(false);
          }}
          text={
            action === "delete"
              ? "Are you sure you want to delete this content?"
              : "Are you sure you want to hide this content? The corresponding draft will be overwritten"
          }
          isOpenProp={isOpenConfirmModal}
        />
      </div>
    </TreeProvider>
  );
};

export default connect(
  ({ profile, organizationalUnits, contributor, modal }) => ({
    profile,
    organizationalUnits,
    contributor,
    modal,
  }),
  dispatch => ({
    updateContributor: payload => dispatch(SET_CONTRIBUTOR(payload)),
    updateModalProps: payload => dispatch(TRIGGER_MODAL(payload)),
  })
)(Contributor);
