import React from "react";
import { useTypography } from "components/Typography";
import "./style.scss";

const VideoCard = ({
  url,
  speakers,
  thumbnailUrl,
  comingSoon,
  enableBorder,
}) => {
  const { RegularBold, TextSmall } = useTypography();
  const overlayOnClick = e => {
    if (comingSoon) return;
    e.target.style.display = "none";
  };
  return (
    <div className="video-container">
      <div className="player">
        <iframe
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          style={{
            border: enableBorder ? "1px solid red" : "none",
            filter: comingSoon ? "blur(5px)" : "none",
          }}
        />
        <div
          className="overlay"
          style={{
            backgroundImage: `url(${thumbnailUrl})`,
            border: enableBorder ? "1px solid red" : "none",
            filter: comingSoon ? "blur(8px)" : "none",
          }}
          onClick={overlayOnClick}></div>
        {comingSoon && <div className="coming-soon">COMING SOON</div>}
      </div>
      {!comingSoon && speakers && speakers.length > 0 && (
        <div className="info">
          {speakers.map(({ name, occupation, location }) => (
            <div>
              {name && <RegularBold>{name}</RegularBold>}
              {occupation && <TextSmall>{occupation}</TextSmall>}
              {location && <TextSmall>{location}</TextSmall>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VideoCard;
