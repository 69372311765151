import "./style.scss";

export const useTypography = () => {
  const Type = ({children}) => {
    return <p className="type">{children}</p>
  }

  const HeadingOne = ({children}) => {
    return <h1 className="heading_one">{children}</h1>
  }

  const HeadingTwo = ({children}) => {
    return <h2 className="heading_two">{children}</h2>;
  };

  const Regular = ({children}) => {
    return <p className="regular">{children}</p>
  }

  const RegularBold = ({children}) => {
    return <p className="regular bold">{children}</p>
  }

  const TextSmall = ({children}) => {
    return <p className="small">{children}</p>
  }
  const LinkUnderlinedBold = ({children, href}) => {
    return <a href={href} className="link-underlined bold">{children}</a>
  }

  return {
    Type,
    HeadingOne,
    HeadingTwo,
    Regular,
    RegularBold,
    TextSmall,
    LinkUnderlinedBold,
  };
};
