import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {useTracking} from "react-tracking";

import { GET_EVENTS } from "redux/events";
import {auditActions, auditTypes, entityType} from "utils/commons";
import ContentCard from "components/ContentCard";
import HeroTitle from "components/HeroTitle";



import ModalEvent from "./ModalEvent";

const Events = ({ events, organizationalUnits, getEvents }) => {
  const [event, setEvent] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const {trackEvent} = useTracking();

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  const handleOnClickEvent = selectedEvent => {
    const selEvent = events.find(e => e.id === selectedEvent);
    setEvent({
      ...selEvent,
      organizationalUnit: organizationalUnits.find(
        ou => ou.id === selEvent.organizationalUnitId
      ),
    });
    trackEvent({
      type: auditTypes.ROUTE,
      path: location.pathname,
      id: selEvent.id,
      title: selEvent.title,
      action: auditActions.OPEN_DETAIL,
    });
    setModalOpen(true);
  };

  const handleOnCloseModal = () => {
    setEvent(null);
    setModalOpen(false);
  };

  useEffect(() => {
    if (location.search.split("=")[1] && events.length > 0)
      handleOnClickEvent(location.search.split("=")[1]);
    //eslint-disable-next-line
  }, [location.search, events]);

  return (
    <div className="container">
      <HeroTitle
        type="Events"
        title="Find out what's next for Myracle"
        subtitle="List of planned future and past Events about explaining and upgrading Myracle "
        blueVariant
      />
      <div className="others-cards-list">
        {events.map(ev => (
          <ContentCard
            key={ev.id}
            {...ev}
            documentType={entityType.EVENT}
            onEventClick={() => handleOnClickEvent(ev.id)}
          />
        ))}
      </div>
      <ModalEvent
        isOpen={modalOpen}
        event={event}
        onClose={handleOnCloseModal}
      />
    </div>
  );
};

export default connect(
  ({ events, organizationalUnits }) => ({ events, organizationalUnits }),
  dispatch => ({
    getEvents: () => dispatch(GET_EVENTS()),
  })
)(Events);
