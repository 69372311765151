import { apis, serviceInterface } from "./config";

export const getBusinessChallenge = code => {
  return serviceInterface.get(
    `${apis.BUSINESS_CHALLENGE}/${code ? "/" + code : ""}`
  );
};
export const getBusinessChallengeNoGraphic = code => {
  return serviceInterface.get(
    `${apis.BUSINESS_CHALLENGE}/${code}?no_graphic=true`
  );
};

export const getRelated = id => {
  return serviceInterface.get(`${apis.BUSINESS_CHALLENGE}/${id}/links`);
};

export const save = params => {
  return serviceInterface.post(apis.BUSINESS_CHALLENGE, { ...params });
};
export const update = (code, params) => {
  return serviceInterface.put(`${apis.BUSINESS_CHALLENGE}/${code}`, {
    ...params,
  });
};
export const deleteBusinessChallenge = code => {
  return serviceInterface.delete(`${apis.BUSINESS_CHALLENGE}/${code}`);
};
