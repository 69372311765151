import { useHistory } from "react-router-dom";
import {useTracking} from "react-tracking";
import routes from "routes";
import {auditActions, auditTypes, entityType} from "utils/commons";
import ColorBar from "components/ColorBar";
import ArrowIcon from "images/arrow.svg";
import BipUnitImage from "images/Bip unit icon.svg";

import "./style.scss";


const IndustryCard = props => {
  const {trackEvent} = useTracking();
  const history = useHistory();


  const stripHtml = (html) =>
  {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  const descriptionCopy = props?.shortDescription ? stripHtml(props?.shortDescription) : "";

  return (
    <div>
      <div
        className="bipunit-card"
        onClick={() => {
          history.push(`${routes.INDUSTRIES}/${props.id}`, {
            name: props.name,
            code: props.code,
          })
          trackEvent({
            code: props.code,
            action: auditActions.OPEN_DETAIL,
            resourceType: entityType.INDUSTRIES,
            name: props.code,
            type: auditTypes.CONTENT})
        }
        }>
        <div className="type">{props.projectTypes.join(" ")}</div>
        <div className="logo-img">
          {props.icon ? (
            <img src={`data:image/png;base64,${props.icon}`} alt={props.name} />
          ) : (
            <img src={BipUnitImage} alt={props.name} />
          )}
        </div>
        <div className="title">{props?.name.length > 38 ? props?.name.substr(0, 38) + '...' : props?.name}</div>
        <div className="cta">
          <img src={ArrowIcon} alt={props.name} />
        </div>
  
        <div className="description">{`${descriptionCopy.substr(0, 70)}${
          descriptionCopy.length > 70 ? "..." : ""
        }`}</div>
      </div>
      <ColorBar id={props.key} legend={props.legend} category={[props.type]}/>
    </div>
  );
};

export default IndustryCard;
