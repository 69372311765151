import { useEffect, useRef, useState } from "react";

import "./style.scss";
import ChevronRight from "images/chevron_right.svg";



const Accordion = ({ title, content }) => {
  const containerEl = useRef();
  const contentEl = useRef();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      containerEl.current.style.maxHeight =
        contentEl.current.clientHeight + "px";
    } else {
      containerEl.current.style.maxHeight = 0;
    }
  });

  return (
    <div className="accordion-container">
      <div className={open ? "accordion-header open" : "accordion-header"} onClick={() => setOpen(!open)}>
        {title}
        <img className={open ? "open" : ""} src={ChevronRight} alt=""/>
      </div>
      <div ref={containerEl} className="accordion-content-container">
        <div ref={contentEl} className="accordion-content">
          {content}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
