import { createSlice } from "@reduxjs/toolkit";

export const initialSerpState = { selectedTab: "offerings" };

const search = createSlice({
  name: "@@SEARCH",
  initialState: { serp: initialSerpState },
  reducers: {
    SET_SEARCH: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: {},
});

export const { SET_SEARCH } = search.actions;

export default search.reducer;
