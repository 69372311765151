import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getEvents } from "services/events";

export const GET_EVENTS = createAsyncThunk(
  "@@EVENTS/GET_EVENTS",
  async (...args) => {
    const state = args[1].getState();
    if (state.events.length === 0) {
      const response = await getEvents();
      if (response?.data) return [...response?.data];
    }
    return state.events;
  }
);

const events = createSlice({
  name: "@@EVENTS",
  initialState: [],
  reducers: {},
  extraReducers: {
    [GET_EVENTS.fulfilled]: (state, action) => {
      return action.payload;
    },
  },
});

export default events.reducer;
