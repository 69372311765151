import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { useTracking } from "react-tracking";

import "react-table-6/react-table.css";

import { MODIFY_USER } from "redux/users";
import { putUser } from "services/user";
import { auditActions, auditTypes } from "utils/commons";
import PageSize from "components/SelectedFilters/PageSize";

import UncheckedWhite from "images/checkbox_white.svg";
import CheckedWhite from "images/checkbox_white_full.svg";
import Checked from "images/checked.svg";
import Unchecked from "images/unchecked.svg";
import AssociateWithGroup from "../ActionModal/AssociateWithGroup";
import Avatar from "../Avatar";
import TableActionDropdown from "../TableActionDropdown";

import "./style.scss";

const TableUser = ({
  organizationalUnits,
  loading = false,
  setCurrentPage,
  currentPage,
  totalPages,
  pageSize,
  modifyUser,
  setPageSize,
  data,
  groups,
}) => {
  const [checked, setChecked] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isOpenGroupModal, setIsOpenGroupModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { trackEvent } = useTracking();

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const enableUsers = isEnabled => {
    setIsLoading(true);
    Object.values(data)
      .filter(el => checked.includes(el.id))
      .forEach((user, index) => {
        putUser({ ...user, isEnabled: isEnabled })
          .then(response => {
            trackEvent({
              type: auditTypes.ADMIN,
              action: response.data.isEnabled
                ? auditActions.ENABLE_USER
                : auditActions.DISABLE_USER,
              user: user.id,
            });
            modifyUser({ ...response.data, orderIndex: user.orderIndex });
            if (index === checked.length - 1) {
              setIsLoading(false);
              setChecked([]);
              setSelectAll(false);
            }
          })
          .catch(e => {
            console.log(e);
            if (index === checked.length - 1) {
              setIsLoading(false);
              setChecked([]);
              setSelectAll(false);
            }
          });
      });
  };

  const getOrganizationalUnits = id => {
    const currentOrgUnit = organizationalUnits.find(x => x.id === id);
    if (currentOrgUnit) {
      return currentOrgUnit.name;
    } else {
      return "";
    }
  };

  const getGroups = ids => {
    const groupsNames = groups
      .map(group => {
        if (ids.includes(group.id)) {
          return group.name;
        }
        return null;
      })
      .filter(el => el != null);
    if (groupsNames && groupsNames.length > 0) {
      return groupsNames.join(", ");
    } else {
      return "";
    }
  };

  const toggleChecked = id => {
    let i = checked.indexOf(id);
    if (i === -1) {
      setChecked([...checked, id]);
    } else {
      setChecked(checked.filter(el => el !== id));
    }
  };

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectAll(false);
      setChecked([]);
    } else {
      setSelectAll(true);
      setChecked(Object.values(data).map(el => el.id));
    }
  };

  const columns = [
    {
      id: "checkbox",
      Header: props => (
        <div
          className="checkbox-wrapper"
          onClick={e => {
            e.stopPropagation();
            toggleSelectAll();
          }}>
          <img
            src={selectAll ? CheckedWhite : UncheckedWhite}
            alt="option-checkbox"
          />
        </div>
      ),
      accessor: "id",
      minWidth: 30,
      Cell: props => (
        <div
          className="checkbox-wrapper"
          onClick={e => {
            e.stopPropagation();
            toggleChecked(props.value);
            if (selectAll) {
              setSelectAll(false);
            }
          }}>
          <img
            src={checked.includes(props.value) ? Checked : Unchecked}
            alt="option-checkbox"
          />
        </div>
      ),
    },
    {
      id: "name",
      Header: "Name",
      accessor: d => d,
      minWidth: 150,
      Cell: props => (
        <div className="user-container">
          <div>
            <Avatar profile={props.value} />
          </div>
          <span className="table-text-color">{`${props.value.surname} ${props.value.name}`}</span>
        </div>
      ),
    },
    {
      id: "status",
      Header: "Status",
      accessor: "isEnabled",
      minWidth: 50,
      Cell: props => (
        <div
          className={
            props.value
              ? "status-container active"
              : "status-container disabled"
          }>
          {props.value ? "Active" : "Disabled"}
        </div>
      ),
    },
    {
      id: "Email",
      Header: "Email address",
      accessor: "email",
      Cell: props => <span className="table-text-color">{props.value}</span>,
    },
    {
      id: "phone",
      Header: "Phone number",
      accessor: "phone",
      Cell: props => <span className="table-text-color">{props.value}</span>,
    },
    {
      id: "groups",
      Header: "Groups",
      accessor: "groupsIds",
      minWidth: 80,
      Cell: props => {
        const groups = getGroups(props.value);
        return <span className="table-text-color">{groups}</span>;
      },
    },
    {
      id: "coe",
      Header: "Coe",
      accessor: "organizationalUnitId",
      minWidth: 80,
      Cell: props => {
        const organizationalUnit = getOrganizationalUnits(props.value);
        return <span className="table-text-color">{organizationalUnit}</span>;
      },
    },
    {
      id: "activeTrees",
      Header: "Active Trees",
      accessor: "isSynced",
      Cell: props => (
        <div className={`active-trees ${props.value ? "synced" : ""}`}>
          {props.value ? "YES" : "NO"}
        </div>
      ),
      minWidth: 80,
    },
    {
      id: "actions",
      Header: "Actions",
      accessor: d => d,
      Cell: props => <TableActionDropdown user={props.value} />,
      minWidth: 50,
    },
  ];

  return (
    <div className="table-container">
      <div className="pre-header-data">
        <div className="massive-action-container">
          <div
            className={
              checked.length > 0
                ? "massive-action massive-action-active"
                : "massive-action"
            }
            onClick={() => {
              if (checked.length > 0) {
                setIsOpenGroupModal(true);
              }
            }}>
            Associate with a group
          </div>
          <div
            className={
              checked.length > 0
                ? "massive-action massive-action-active"
                : "massive-action"
            }
            onClick={() => checked.length > 0 ? enableUsers(true) : null}>
            enable users
          </div>
          <div
            className={
              checked.length > 0
                ? "massive-action massive-action-active"
                : "massive-action"
            }
            onClick={() => checked.length > 0 ? enableUsers(false) : null}>
            disable users
          </div>
        </div>
        <PageSize pageSize={pageSize} setPageSize={setPageSize} />
      </div>
      <ReactTable
        data={Object.values(data).sort((a, b) =>
          a.orderIndex > b.orderIndex ? 1 : b.orderIndex > a.orderIndex ? -1 : 0
        )}
        columns={columns}
        minRows={1}
        showPageSizeOptions={false}
        showPageJump={false}
        pageSize={pageSize}
        collapseOnPageChange={false}
        PadRowComponent={() => null}
        noDataText="No user found"
        loading={isLoading}
        sortable={false}
        headerClassName="table-header"
        PaginationComponent={props => {
          return (
            <div className="pagination">
              <button
                disabled={currentPage === 0}
                className="change-page"
                onClick={() => {
                  props.onPageChange(currentPage - 1);
                  setCurrentPage(currentPage - 1);
                  setSelectAll(false);
                  setChecked([]);
                }}>
                <div className="arrow left" />
              </button>
              <span className="page-number">{`${
                totalPages === 0 ? currentPage : currentPage + 1
              } / ${totalPages}`}</span>
              <button
                disabled={currentPage + 1 === totalPages || totalPages === 0}
                className="change-page"
                onClick={() => {
                  props.onPageChange(currentPage + 1);
                  setCurrentPage(currentPage + 1);
                  setSelectAll(false);
                  setChecked([]);
                }}>
                <div className="arrow right" />
              </button>
            </div>
          );
        }}
      />
      <AssociateWithGroup
        multipleAction={true}
        users={Object.values(data).filter(el => checked.includes(el.id))}
        isOpenProp={isOpenGroupModal}
        setLoading={setIsLoading}
        onClose={() => {
          setIsOpenGroupModal(false);
          setChecked([]);
          setSelectAll(false);
        }}
      />
    </div>
  );
};

export default connect(
  ({ organizationalUnits, groups }) => ({
    organizationalUnits,
    groups,
  }),
  dispatch => ({
    modifyUser: payload => dispatch(MODIFY_USER(payload)),
  })
)(TableUser);
