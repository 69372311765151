import { useEffect, useState } from "react";
import { connect } from "react-redux";

import Bookmarks from "components/Bookmarks";

import BookmarkIcon from "images/bookmark library empty.svg";
import "./style.scss";


const IconBookmarks = ({ bookmarks }) => {
  const [open, setOpen] = useState(false);
  const [bmElements, setBmElements] = useState(0);
  const [timeout, setTimeoutID] = useState();

  const handleClick = e => {
    if (timeout) {
      clearTimeout(timeout);
      setTimeoutID(null);
    }
    setOpen(!open);
  };
  const handleMouseOver = () => {
    if (timeout) {
      clearTimeout(timeout);
      setTimeoutID(null);
    }
    if (!open) setOpen(true);
  };

  const handleOnBlur = () => {
    const tm = setTimeout(() => {
      setOpen(false);
    }, 250);
    setTimeoutID(tm);
  };

  useEffect(() => {
    let bm = 0;
    Object.keys(bookmarks).forEach(k => {
      bm += bookmarks[k].length;
    });
    setBmElements(bm);
  }, [bookmarks]);

  return (
    <div
      className="iconbookmarks-container"
      onMouseLeave={handleOnBlur}
      onMouseOver={handleMouseOver}>
      <img src={BookmarkIcon} alt="bookmark" onClick={handleClick} />
      {bmElements > 0 && (
        <div className="iconbookmarks-couter">{bmElements}</div>
      )}
      <Bookmarks toggleClose={handleClick} isOpen={open} />
    </div>
  );
};

export default connect(
  ({ bookmarks }) => ({ bookmarks }),
  () => ({})
)(IconBookmarks);
