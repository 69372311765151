import { apis, serviceInterface } from "./config";

export const getTreeLevels = id => {
  return serviceInterface.get(
    `${apis.ORGANIZATIONAL_UNIT}/${id}/${apis.TREE_LEVEL}`
  );
};

export const assignOfferingToTree = (code, treeLevelId) => {
  return serviceInterface.patch(
    `${apis.OFFERING_TOPIC}/${code}/${apis.TREE_LEVEL}/link/${treeLevelId}`
  );
};

export const assignOfferingToUncategorized = code => {
  return serviceInterface.patch(
    `${apis.OFFERING_TOPIC}/${code}/${apis.TREE_LEVEL}/unlink`
  );
};

export const updateTreeLevels = (id, code, params) => {
  return serviceInterface.put(
    `${apis.ORGANIZATIONAL_UNIT}/${id}/${apis.TREE_LEVEL}/${code}`,
    { ...params }
  );
};

export const createTreeLevel = (id, { label, parentId }) => {
  return serviceInterface.post(
    `${apis.ORGANIZATIONAL_UNIT}/${id}/${apis.TREE_LEVEL}`,
    { label, parentId }
  );
};

export const deleteTreeLevel = (id, code) => {
  return serviceInterface.delete(
    `${apis.ORGANIZATIONAL_UNIT}/${id}/${apis.TREE_LEVEL}/${code}`
  );
};
