import { useCallback, useState } from "react";
import { getParsedParams } from "../utils/utils";

const useLoadData = ({ searchFunction, params }) => {
  const [items, setItems] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [total, setTotal] = useState(0);

  const loadData = useCallback(async () => {
    const { data } = await searchFunction(
      getParsedParams({
        page_size: 24,
        page_number: pageNumber + 1,
        ...params,
      })
    );
    try {
      // if new search replace all, otherwise - pagination
      setItems(
        pageNumber === 0 ? [...data?.items] : [...items, ...data?.items]
      );
      setPageNumber(pageNumber + 1);
      setTotal(data?.totalItems);
    } catch (e) {
      console.error("useLoadData error: ", e);
    }
  }, [items, pageNumber, params, searchFunction]);

  return { loadData, items, setItems, total, pageNumber, setPageNumber };
};
export default useLoadData;
