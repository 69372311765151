import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getAllSubindustries} from "../services/subindustry";

export const GET_SUBINDUSTRIES = createAsyncThunk(
  "@@SUBINDUSTRIES/GET_SUBINDUSTRIES",
  async (...args) => {
    const state = args[1].getState();
    if (state.subindustries.length === 0) {
      const response = await getAllSubindustries();
      if (response?.data) return [...response?.data];
    }
    return state.subindustries;
  }
);

const subindustries = createSlice({
  name: "@@SUBINDUSTRIES",
  initialState: [],
  reducers: {},
  extraReducers: {
    [GET_SUBINDUSTRIES.fulfilled]: (state, action) => {
      return action.payload;
    },
  },
});

export default subindustries.reducer;
