import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import routes from "routes";
import { getProfileInfo } from "utils/profile";
import Avatar from "components/Avatar";

import "./style.scss";
import ArrowIcon from "images/arrow.svg";

const ProfileInfo = ({ handleClose, open, profile, organizationalUnits }) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(open);

  const profileInfo = getProfileInfo(profile);
  const canEditQuals = profileInfo.canEditQuals(organizationalUnits);
  const canEditOfferings = profileInfo.canEditOfferings(organizationalUnits);
  const isAdmin = profileInfo.isAdmin;
  const isEditor = profileInfo.isEditor;

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <div
      className={`profile-block ${isOpen ? "visible" : ""}`}
      onMouseOver={() => setIsOpen(true)}>
      <div className="icon-back" onClick={handleClose}>
        <img src={ArrowIcon} alt="arrow" />
      </div>
      <div className="profile-info-block">
        <div className="profile-card">
          <div className="image-profile">
            <Avatar profile={profile} />
          </div>
          <div className="title">PROFILE</div>
          <div className="link-edit-block">
            {/*  <div className="link-edit" onClick={() => {}}>
              EDIT
            </div> */}
          </div>
          <div className="infos-block">
            <h3>
              {profile?.name} {profile?.surname}
            </h3>
            {profile?.organizationalUnitIds.length === 1 && (
              <>
                <h4>{profile.organizationalUnit?.name}</h4>
              </>
            )}
          </div>
        </div>
        <div className="contacts-block">
          <div className="title">CONTACTS</div>
          {profile?.email && <div className="label">{profile?.email}</div>}
          {profile?.phone && <div className="label">{profile?.phone}</div>}
        </div>

        {isEditor && (
          <div className="contributor-mode-block">
            <div className="title">CONTRIBUTOR MODE</div>
            {isAdmin || canEditQuals.length ? (
              <div
                className="contributor-link"
                onClick={() => {
                  history.push(routes.CONTRIBUTOR_QUALS);
                }}>
                <div className="label">
                  {canEditQuals.length > 1 || isAdmin
                    ? "Qualifications"
                    : canEditQuals[0].name + "- Quals"}
                </div>
                <div className="contributor-arrow">
                  <img src={ArrowIcon} alt="arrow" />
                </div>
              </div>
            ) : null}

            {isAdmin || canEditOfferings.length ? (
              <div
                className="contributor-link"
                onClick={() => history.push(routes.CONTRIBUTOR_OFFERINGS)}>
                <div className="label">
                  {isAdmin || canEditOfferings.length > 1
                    ? "Offerings"
                    : canEditOfferings[0].name + "- Offerings"}
                </div>
                <div className="contributor-arrow">
                  <img src={ArrowIcon} alt="arrow" />
                </div>
              </div>
            ) : null}
            {isAdmin && (
              <div
                className="contributor-link"
                onClick={() =>
                  history.push(routes.CONTRIBUTOR_BUSINESS_CHALLENGES)
                }>
                <div className="label">Business Challenges</div>
                <div className="contributor-arrow">
                  <img src={ArrowIcon} alt="arrow" />
                </div>
              </div>
            )}
            {isAdmin && (
              <div
                className="contributor-link"
                onClick={() => history.push(routes.ADMIN_USER)}>
                <div className="label">Users Management</div>
                <div className="contributor-arrow">
                  <img src={ArrowIcon} alt="arrow" />
                </div>
              </div>
            )}
            {isAdmin && (
              <div
                className="contributor-link"
                onClick={() => history.push(routes.ADMIN_GROUP)}>
                <div className="label">Groups Management</div>
                <div className="contributor-arrow">
                  <img src={ArrowIcon} alt="arrow" />
                </div>
              </div>
            )}
            {isEditor && (
              <div
                className="contributor-link"
                onClick={() => history.push(routes.ADMIN_ERRORS)}>
                <div className="label">Quality Management</div>
                <div className="contributor-arrow">
                  <img src={ArrowIcon} alt="arrow" />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="cta-section">
          {/* <div className="contributor-link">
            <div className="contributor-arrow">
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.98785 0C12.7287 0 15.7895 3.12533 15.7895 6.94517C17.004 6.94517 17.9757 7.96214 18 9.20235V12.4269C18 13.5183 17.247 14.4112 16.2267 14.6097C15.9109 16.0483 14.6478 17.1397 13.1417 17.1397H11.9271C11.6113 18.2063 10.6154 19 9.47368 19C8.06478 19 6.92308 17.8342 6.92308 16.3956C6.92308 14.9569 8.06478 13.7911 9.44939 13.7663C10.6154 13.7663 11.587 14.5601 11.9028 15.6266H13.1174C13.7976 15.6266 14.3806 15.2298 14.6478 14.6345H13.3603V6.94517H14.332C14.332 3.94386 11.9271 1.48825 8.98785 1.48825C6.04858 1.48825 3.64372 3.94386 3.64372 6.94517H4.61538V14.6345H2.18623C0.97166 14.6345 0 13.6423 0 12.4021V9.17755C0 7.93734 0.97166 6.94517 2.18623 6.94517C2.18623 3.12533 5.24696 0 8.98785 0ZM2.18623 13.171H3.15789V8.45822H2.18623C1.77328 8.45822 1.45749 8.78068 1.45749 9.20235V12.4269C1.45749 12.8486 1.77328 13.171 2.18623 13.171ZM9.47368 17.5117C8.8664 17.5117 8.38057 17.0157 8.38057 16.3956C8.38057 15.7755 8.8664 15.2794 9.47368 15.2794C10.081 15.2794 10.5668 15.7755 10.5668 16.3956C10.5668 16.9909 10.081 17.5117 9.47368 17.5117ZM15.7895 13.171C16.2024 13.171 16.5182 12.8486 16.5182 12.4269V9.20235C16.5182 8.78068 16.2024 8.45822 15.7895 8.45822H14.8178V13.171H15.7895Z"
                  fill="#E40046"
                />
              </svg>
            </div>
            <div className="label">Contact Myracle admin</div>
          </div> */}
          {/* <div className="contributor-link">
            <div className="contributor-arrow">
              <svg
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.92045 4.78571H7.32841V1.35714H18.592L18.592 17.6429H7.32841V14.2143H5.92045V19H20L20 0H5.92045V4.78571ZM5.01232 12.4111L4.01267 13.3746L0 9.5L4.01267 5.62536L5.01232 6.58893L2.68919 8.82143H11.5523V10.1786H2.68919L5.01232 12.4111Z"
                  fill="#E40046"
                />
              </svg>
            </div>
            <div className="label">Sign out</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
