import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useTracking } from "react-tracking";

import { createComment, updateComment } from "services/comments";
import { auditActions, auditTypes } from "utils/commons";
import { ReactComponent as ClearSvg } from "images/cancel.svg";

import "./style.scss";

const AddCommentModal = ({
  isOpenProp,
  onClose,
  contentType,
  myComment,
  contentCode,
  callback,
}) => {
  const [body, setBody] = useState("");
  const [title, setTitle] = useState("");

  const { trackEvent } = useTracking();

  useEffect(() => {
    setBody(myComment && myComment.body ? myComment.body : "");
    setTitle(myComment && myComment.title ? myComment.title : "");
  }, [myComment]);

  const handleClose = () => {
    setBody("");
    setTitle("");
    onClose();
  };

  const onConfirm = () => {
    if (myComment) {
      updateComment(myComment.id, { title, body, contentType, contentCode })
        .then(response => {
          if (response.data) {
            callback();
            handleClose();
            trackEvent({
              type: auditTypes.ACTION,
              action: auditActions.UPDATE_COMMENT,
            });
          } else {
            console.log(
              "Warning: Error updating comment" +
                " " +
                response.message.toString()
            );
          }
        })
        .catch(e => {
          console.log("Error updating comment", e);
        });
    } else {
      trackEvent({
        type: auditTypes.ACTION,
        action: auditActions.ADD_COMMENT,
      });
      createComment({ title, body, contentType, contentCode })
        .then(response => {
          if (response.data) {
            callback();
            handleClose();
          } else {
            console.log(
              "Warning: Error creating comment" +
                " " +
                response.message.toString()
            );
          }
        })
        .catch(e => {
          console.log("Error creating comment", e);
        });
    }
  };

  return (
    <Modal
      isOpen={isOpenProp}
      className="custom-modal action-modal"
      ariaHideApp={false}>
      <div className="action-modal-content">
        <div className="action-modal-header">
          <div className="action-modal-title">Write a comment</div>
          <ClearSvg onClick={handleClose} />
        </div>
        <div className="action-modal-form">
          <div className="modal-input-container">
            <label className="modal-label-container">
              <span className="modal-label">
                Review title<span className="mandatory">*</span>
              </span>
              <span className="counter">{title.length}/50</span>
            </label>
            <input
              type="text"
              onChange={event => setTitle(event.target.value.slice(0, 50))}
              max={50}
              maxLength={50}
              value={title}
              placeholder="Write review title here..."
            />
          </div>
          <div className="modal-input-container">
            <label className="modal-label-container">
              <span className="modal-label">
                Review body<span className="mandatory">*</span>
              </span>
              <span className="counter">{body.length}/250</span>
            </label>
            <textarea
              name="body"
              placeholder="Write your review here..."
              rows={10}
              value={body}
              maxLength={250}
              onChange={event => setBody(event.target.value.slice(0, 250))}
            />
          </div>
        </div>
      </div>
      <div className={"footer-button-container"}>
        <button className={"footer-button button-delete"} onClick={handleClose}>
          Cancel
        </button>
        <button
          className={"footer-button button-confirm"}
          disabled={!Boolean(body) || !Boolean(title)}
          onClick={onConfirm}>
          Publish review
        </button>
      </div>
    </Modal>
  );
};

export default AddCommentModal;
