import { useEffect, useState } from "react";
import { chunk } from "../utils/utils";

const useRelatedData = (codes, getFunction, errorText = "") => {
  const [data, setData] = useState([]);
  const [currentGroup, setCurrentGroup] = useState(0);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      setTimeout(async () => {
        if (codes?.length) {
          const groups = chunk(codes, 10);
          if (isMounted && groups[currentGroup]) {
            const promisesArray = groups[currentGroup]?.map(getFunction);
            try {
              const data = await Promise.all(promisesArray);
              const response = data?.map(resData => resData?.data);
              setData(oldState => [...oldState, ...response]);
            } catch (e) {
              console.error(errorText, e);
            }
          }
        }
      }, 1000);
    })();
    return () => {
      isMounted = false;
    };
  }, [codes, currentGroup, errorText, getFunction]);

  return { data, loadMore: () => setCurrentGroup(currentGroup + 1) };
};

export default useRelatedData;
