const config = {
  SSO_BASE_URL: "https://login.microsoftonline.com",
  SSO_GETCODE_PARAMS: {
    client_id: document.env.CLIENT_ID,
    response_type: "code",
    redirect_uri: document.env.REDIRECT_URI,
    scope: "offline_access openid email profile",
    response_mode: "fragment",
    nonce: 678910,
  },
  TENANT: document.env.TENANT,
};

export default config;
