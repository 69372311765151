import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getAllIndustries} from "../services/industry";

export const GET_INDUSTRIES = createAsyncThunk(
  "@@INDUSTRIES/GET_INDUSTRIES",
  async (...args) => {
    const state = args[1].getState();
    if (state.industries.length === 0) {
      const response = await getAllIndustries();
      if (response?.data) return [...response?.data];
    }
    return state.industries;
  }
);

const industries = createSlice({
  name: "@@INDUSTRIES",
  initialState: [],
  reducers: {},
  extraReducers: {
    [GET_INDUSTRIES.fulfilled]: (state, action) => {
      return action.payload;
    },
  },
});

export default industries.reducer;
