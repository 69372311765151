import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useTreeContext } from "../../contexts/Tree";
import {
  entityType,
  treeLevelsLabels,
  treeLevelTitles,
} from "../../utils/commons";
import { findNodeLabel } from "../../utils/utils";
import Offering from "../ContentCard/Offering";

import "./style.scss";

const Drag = (
  {
    dataList,
    isContributor,
    callback,
    documentType,
    readOnly,
    onCardSelect,
    isModal,
    canLike,
    scrollToComponent,
  },
  ref
) => {
  const {
    selectedId: selectedTree,
    treeList,
    setSelectedId,
  } = useTreeContext();
  const [dragList, setDragList] = useState(dataList);
  const [label, setLabel] = useState(treeLevelTitles.ALL_OFFERINGS);

  const { isDidMount } = useTreeContext();
  const myRef = useRef(null);

  const { matches: isMobile } = window.matchMedia("(max-width: 912px)");

  const handleAction = ({ code, action, versionType }) => {
    if (callback) {
      callback({ action, code, documentType, versionType });
    }
  };

  useEffect(() => {
    switch (selectedTree) {
      case treeLevelsLabels.ALL_OFFERINGS:
        setLabel(treeLevelTitles.ALL_OFFERINGS);
        break;
      case treeLevelsLabels.UNCATEGORIZED:
        setLabel(treeLevelTitles.UNCATEGORIZED);
        break;
      default:
        if (treeList?.length) {
          const name = findNodeLabel(treeList, selectedTree);
          setLabel(name?.toUpperCase());
        }
    }
  }, [treeList, selectedTree]);

  useEffect(() => {
    setDragList(dataList);
  }, [dataList]);

  useEffect(() => {
    if (
      (!isDidMount && isModal && documentType === entityType.TREE_LEVEL_VIEW) ||
      !selectedTree
    ) {
      setSelectedId(treeLevelsLabels.ALL_OFFERINGS);
    }
  }, [documentType, isDidMount, isModal, selectedTree, setSelectedId]);

  useEffect(() => {
    if (
      isMobile &&
      myRef &&
      myRef.current.id === selectedTree &&
      scrollToComponent &&
      isDidMount
    ) {
      scrollToComponent(selectedTree, myRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, selectedTree]);

  return (
    <div className="results-list-contributor" ref={myRef} id={selectedTree}>
      <div className="results-list-contributor-header">
        <span>{label}</span>
      </div>
      <div>
        <ul className="drag scroll-down" ref={ref}>
          {dragList?.map(el => {
            return selectedTree === el.treeLevelId ||
              selectedTree === treeLevelsLabels.ALL_OFFERINGS ||
              (el.treeLevelId === "" &&
                selectedTree === treeLevelsLabels.UNCATEGORIZED) ? (
              <li key={el.id} data-id={el.code}>
                <Offering
                  code={el.code}
                  data-id={el.code}
                  isContributor={isContributor}
                  isDownloader={el.isDownloader}
                  canLike={canLike}
                  userEditScope={el.userEditScope}
                  callback={handleAction}
                  totalLikes={el.totalLikes}
                  cardData={{ ...el }}
                  treeLevelId={el.treeLevelId}
                  label={label}
                  readOnly={readOnly}
                  onCardSelect={onCardSelect}
                  isModal={isModal}
                  myLike={el.myLike}
                  showFileWithErrors
                />
              </li>
            ) : null;
          })}
        </ul>
      </div>
    </div>
  );
};

export default forwardRef(Drag);
