import React from "react";
import { useState } from "react";

import { entityType } from "utils/commons";
import GoBackButton from "components/GoBackButton";

import "./style.scss";
import EditIcon from "images/edit.svg";
import savePlus from "images/save_plus.png";
import saved from "images/saved.png";
import Trash from "images/trash.svg";
import Unpublish from "images/view-off.svg";
import ConfirmActionModal from "../ActionModal/ConfirmActionModal";

const BackSave = ({
  handleAddBookmark,
  isSaved,
  isEditable = null,
  editFunction = null,
  deleteFunction = null,
  documentType,
  isEditor,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  return (
    <div className={`detail-back-save`}>
      <GoBackButton />
      <div className="icons">
        {(isEditable || isEditor) && (
          <>
            <div className="edit-backsave">
              <img
                src={EditIcon}
                className="edit-backsave__image"
                alt="Edit Content"
                onClick={editFunction}
              />
            </div>
            <div className="delete-backsave">
              {documentType === entityType.QUALIFICATION ? (
                <img
                  src={Unpublish}
                  className="delete-backsave__image"
                  alt="Unpublish Content"
                  onClick={() => setIsOpenModal(true)}
                />
              ) : (
                <img
                  src={Trash}
                  className="delete-backsave__image"
                  alt="Delete Content"
                  onClick={() => setIsOpenModal(true)}
                />
              )}
            </div>
          </>
        )}
        {handleAddBookmark && (
          <div className="add-bookmark" onClick={handleAddBookmark}>
            <img src={isSaved ? saved : savePlus} alt="save" />
          </div>
        )}
      </div>
      <ConfirmActionModal
        onConfirm={deleteFunction}
        isOpenProp={isOpenModal}
        text={`Are you sure you want to ${
          documentType === entityType.QUALIFICATION ? "hide" : "delete"
        } this content?`}
        onClose={() => setIsOpenModal(false)}
      />
    </div>
  );
};

export default React.memo(BackSave);
