import React from "react";
import Logo from "images/Logo Myracle Rosso flat.svg";

import styles from "./style.module.scss";

const Maintenance = () => {
  return (
    <div className={styles.maintenance}>
      <div className="home-container">
        <div className="content">
          <div className="opening">
            <div className="container">
              <div className="logo">
                <img src={Logo} alt="Myracle" />
              </div>
              <div className="subtitle">Site under maintenance.</div>
              <div>
                <center>
                  <h2>{document.env.MAINTENANCE_TEXT}</h2>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(Maintenance);
