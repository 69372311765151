import { apis, serviceInterface } from "./config";

export const getOrganizationalUnit = id => {
  return serviceInterface.get(
    `${apis.ORGANIZATIONAL_UNIT}${id ? "/" + id : ""}`
  );
};

export const getRelated = id => {
  return serviceInterface.get(
    `${apis.ORGANIZATIONAL_UNIT}/${id}/links`
  );
};
