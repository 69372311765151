import React from "react";
import Modal from "react-modal";
import { ReactComponent as ClearSvg } from "images/cancel.svg";
import "./style.scss";

const ConfirmActionModal = ({
  isOpenProp,
  onConfirm,
  onClose,
  text = "Are you sure you want to delete this content?",
}) => {
  return (
    <Modal
      isOpen={isOpenProp}
      className="custom-modal action-modal"
      ariaHideApp={false}>
      <div className="action-modal-content">
        <div className="action-modal-header">
          <div className="action-modal-title">Warning</div>
          <ClearSvg onClick={onClose} />
        </div>
        <div className="description">{text}</div>
      </div>
      <div className={"footer-button-container"}>
        <button className={"footer-button button-delete"} onClick={onClose}>
          Cancel
        </button>
        <button className={"footer-button button-confirm"} onClick={onConfirm}>
          Confirm
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmActionModal;
