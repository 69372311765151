import React from "react";
import routes from "routes";
import {useTypography} from "components/Typography";
import { ReactComponent as Logo } from "images/Logo Myracle Rosso flat.svg";

import "./styles.scss";

export const NoLuck = () => {
  const {Regular} = useTypography();
  return (
    <div className="no-luck">
      <div className="wrapper">
        <Logo />
        <h1>Your profile is not able to explore the contents.</h1>
        <a href={routes.HOME} title="refresh page" className="no-luck__button">
          Refresh Page
        </a>
        <Regular>If you need help or troubleshooting, please contact <a href="mailto:myracle-dbcurator@mail-bip.com" className="no-luck__mailto">myracle-dbcurator@mail-bip.com</a> </Regular>
      </div>
    </div>
  );
};
