import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useTracking } from "react-tracking";
import PropTypes from "prop-types";
import { getComments, deleteComment } from "services/comments";
import { auditActions, auditTypes } from "utils/commons";
import CommentCard from "components/CommentCard";
import Likes from "components/Likes";
import AddCommentModal from "components/ActionModal/AddCommentModal";

import "./style.scss";
import { useProfileContext } from "../../contexts/Profile";

const Comments = ({
  code,
  setCallbackTotals,
  contentType,
  myLikeData,
  totalLikes,
  setCallbackLikes,
}) => {
  const { canComment, canLike, isAdmin } = useProfileContext();
  const { trackEvent } = useTracking();

  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [totals, setTotals] = useState();
  const [myComment, setMyComment] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visibleComments, setVisibleComments] = useState(4);

  const getData = async code => {
    try {
      setIsLoading(true);
      const { data } = (await getComments(code)) || {};
      setComments(data.comments);
      setTotals(data.total);
      setCallbackTotals(data.total);
      setMyComment(data.myComment);
    } catch (error) {
      console.error(`Comments getData: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void getData(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async id => {
    trackEvent({
      type: auditTypes.ACTION,
      action: auditActions.DELETE_COMMENT,
    });

    try {
      const data = await deleteComment(id);
      if (Object.keys(data).length) {
        getData();
      }
    } catch (e) {
      console.error("handleDelete", e);
    }
  };

  // const { canComment, canLike, isAdmin } = getProfileInfo(profile);

  return (
    <div className="comments-container" id="comments">
      {isLoading ? (
        <Skeleton width="100%" height={250} />
      ) : (
        <>
          <div className="comments-section-header">
            <div className="comments-section-title">
              <div className="title">Did you find this content useful?</div>
              {canLike && (
                <Likes
                  code={code}
                  contentType={contentType}
                  commentsSection={true}
                  myLikeData={myLikeData}
                  setCallbackLikes={setCallbackLikes}
                  canReset={isAdmin}
                  totalLikes={totalLikes}
                />
              )}
            </div>
            {canComment && (
              <div
                className="comments-section-link"
                onClick={() => setIsModalOpen(true)}>
                {myComment ? "EDIT YOUR REVIEW" : "WRITE A REVIEW"}
              </div>
            )}
          </div>
          <div className="comments-number">
            <span className="likes-number">
              {canLike && `${totalLikes} likes • `}
            </span>
            {`${totals} comments`}
          </div>
          <div className="comments-list">
            {comments.slice(0, visibleComments).map((comment, index) => (
              <CommentCard
                comment={comment}
                key={`${comment.id} - ${index}`}
                isMyComment={myComment ? comment.id === myComment.id : false}
                handleDelete={handleDelete}
                isAdmin={isAdmin}
              />
            ))}
          </div>
        </>
      )}
      {comments.length > visibleComments && (
        <div className="load-button-container">
          <button
            className="load-more-button"
            onClick={() => setVisibleComments(visibleComments + 2)}>
            <span>Load more</span>
          </button>
        </div>
      )}
      <AddCommentModal
        onClose={() => setIsModalOpen(false)}
        callback={getData}
        contentType={contentType}
        contentCode={code}
        myComment={myComment ? myComment : null}
        isOpenProp={isModalOpen}
      />
    </div>
  );
};

Comments.propTypes = {
  code: PropTypes.string || PropTypes.number,
  setCallbackTotals: PropTypes.func,
  contentType: PropTypes.string,
};

export default React.memo(Comments);
