export const getProfileInfo = ({ id, capabilities, organizationalUnitIds }) => {
  const isAdmin = () => !!capabilities?.includes("ADMINISTRATOR");
  const isDownloader = () =>
    !!capabilities?.includes("DOWNLOAD_ALL_CONTENTS") ||
    !!capabilities?.includes("DOWNLOAD_CONTENTS");
  const canComment = () => !!capabilities?.includes("COMMENT");
  const canLike = () => !!capabilities?.includes("LIKE");

  const canEditOfferings = (units = []) =>
    organizationalUnitIds
      ?.map(userOU => units?.find(ou => ou.id === userOU))
      ?.filter(ou => ou?.type !== "MFU");

  const canEditQuals = (units = []) =>
    organizationalUnitIds
      ?.map(userOU => units?.find(ou => ou.id === userOU))
      ?.filter(ou => ou?.type !== "SUBINDUSTRY");

  const isEditor = () =>
    !!capabilities?.includes("EDIT CONTENTS") ||
    !!capabilities?.includes("EDIT_ALL_CONTENTS") ||
    !!capabilities?.includes("ADMINISTRATOR");

  const isEditorOf = id =>
    !!(
      capabilities?.includes("EDIT CONTENTS") &&
      organizationalUnitIds?.includes(id)
    ) ||
    !!capabilities?.includes("EDIT_ALL_CONTENTS") ||
    !!capabilities?.includes("ADMINISTRATOR");

  const isUnitDownloader = () =>
    !!capabilities?.includes("EDIT CONTENTS") ||
    !!capabilities?.includes("EDIT_CONTENTS") ||
    !!capabilities?.includes("ADMINISTRATOR");

  const isViewer = () =>
    !!(
      capabilities?.includes("EDIT CONTENTS") ||
      capabilities?.includes("EDIT_ALL_CONTENTS")
    ) ||
    !!capabilities?.includes("ADMINISTRATOR") ||
    !!(
      capabilities?.includes("VIEW CONTENTS") ||
      capabilities?.includes("VIEW_CONTENTS")
    );
  // serve per gestire il pulsante MANAGE O.U. TREE in page Contributor
  const canEditTreeLevel = () => !!capabilities?.includes("EDIT_TREE_LEVEL");

  return {
    isEditor: isEditor(),
    isViewer: isViewer(),
    isAdmin: isAdmin(),
    isDownloader: isDownloader(),
    isUnitDownloader: isUnitDownloader(),
    canComment: canComment(),
    canLike: canLike(),
    isEditorOf: isEditorOf(id),
    canEditOfferings,
    canEditQuals,
    canEditTreeLevel: canEditTreeLevel(),
  };
};
