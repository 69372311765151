import { apis, serviceInterface } from "./config";

export const getContributorQualsWithPagination = (code, params) => {
  return serviceInterface.get(
    `${apis.ORGANIZATIONAL_UNIT}/${code}/qualifications/fetch`,
    {params},
  );
};

export const getContributorQualsCounts = (code, params) => {
  return serviceInterface.get(
    `${apis.ORGANIZATIONAL_UNIT}/${code}/qualifications/count`,
    {params},
  );
};

export const getContributorQualsLight = () => {
  return serviceInterface.get(
    `qualification/light`
  );
};

export const getSubIndustries = industry => {
  return serviceInterface.get("search/subindustry?industry=" + industry);
};

export const getGroups = name => {
  return serviceInterface.get(`${apis.GROUP}${name ? `/?name=${name}` : ""}`);
};

export const deleteGroup = id => {
  return serviceInterface.delete(`${apis.GROUP}/${id}`);
};

export const putGroup = (groupId, data) => {
  delete data["orderIndex"];
  delete data["groupName"];
  delete data["userCount"];
  delete data["id"];
  delete data["createdBy"];
  delete data["createdDate"];
  delete data["lastModifiedBy"];
  delete data["lastModifiedDate"];
  return serviceInterface.put(`${apis.GROUP}/${groupId}`, { ...data });
};

export const addNewGroup = data => {
  return serviceInterface.post(`${apis.GROUP}`, { ...data });
};

export const getRoles = () => {
  return serviceInterface.get(`${apis.ROLE}`);
};
