import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { entityType } from "utils/commons";

const Event = ({ organizationalUnits, data }) => {
  const [cardData, setCardData] = useState(data);

  useEffect(() => {
    const organizationalUnit = organizationalUnits.find(
      x => x.id === data.organizationalUnitId
    );

    setCardData({
      ...data,
      documentType: entityType.EVENT,
      organizationalUnit,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationalUnits]);

  return (
    <div
      onClick={cardData?.onEventClick}
      className="result-card qual-card event-card">
      <div className="name">{`${cardData?.title.substr(0, 50)}${
        cardData?.title.length > 50 ? "..." : ""
      }`}</div>
      <div className="body">
        <div className="manager">{cardData?.organizationalUnit?.name}</div>
      </div>

      <div className="qual-footer">
        <div className="info">
          <div className="label">DATE</div>
          <div className="value">
            {moment(cardData?.startDate).format("DD/MM/YY")} <br />{" "}
            {moment(cardData?.startDate).format("H:mm") +
              "-" +
              moment(cardData?.endDate).format("H:mm") +
              " (GMT" +
              String(moment().local().format("Z")) +
              ")"}
          </div>
        </div>
        {cardData?.organizationalUnit?.type &&
          cardData?.organizationalUnit?.name && (
            <div className="info">
              <div className="label">{cardData?.organizationalUnit?.type}</div>
              <div className="value">{cardData?.organizationalUnit?.name}</div>
            </div>
          )}
      </div>
    </div>
  );
};

export default connect(
  ({ organizationalUnits }) => ({
    organizationalUnits,
  }),
  () => ({})
)(Event);
