import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getQualificationNoGraphic } from "services/quals";

export const GET_QUAL = createAsyncThunk(
  "@@QUALS/GET_QUAL",
  async ({ code, isDraft }, thunkAPI) => {
    const state = thunkAPI.getState();
    if (!state.quals[code]) {
      const response = await getQualificationNoGraphic(code, isDraft);
      if (response?.data) {
        const organizationalUnit = state.organizationalUnits.find(
          x => x.id === response.data.organizationalUnitId
        );
        return { ...response?.data, organizationalUnit };
      }
    }
    return state.quals[code];
  }
);

const quals = createSlice({
  name: "@@QUALS",
  initialState: {},
  reducers: {
    SET_QUAL: (state, action) => {
      if (!state[action.payload.code]) {
        return {
          ...state,
          [action.payload.id]: action.payload,
        };
      } else {
        return state;
      }
    },
    SET_QUALS: (state, action) => {
      const addThisState = {};
      const { payload } = action;
      payload.forEach(item => {
        addThisState[item.id] = item;
      });
      return { ...state, ...addThisState };
    },
    CLEAR_QUAL: (state, action) => {
      const newState = { ...state };
      delete newState[action.payload];
      return { ...newState };
    },
  },
  extraReducers: {
    [GET_QUAL.fulfilled]: (state, action) => {
      const { payload } = action;
      return payload
        ? {
            ...state,
            [payload.code]: payload,
          }
        : { ...state };
    },
  },
});

export const { SET_QUAL, SET_QUALS, CLEAR_QUAL } = quals.actions;

export default quals.reducer;
