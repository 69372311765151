import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addBookmark,
  getBookmarks,
  removeAllBookmarks,
  removeBookmark,
} from "services/bookmarks";

export const GET_USER_BOOKMARKS = createAsyncThunk(
  "@@BOOKMARKS/GET_USER_BOOKMARKS",
  async (params, thunkAPI) => {
    const response = await getBookmarks();
    return { ...response.data };
  }
);

export const ADD_BOOKMARK = createAsyncThunk(
  "@@BOOKMARKS/ADD_BOOKMARK",
  async (params, thunkAPI) => {
    const response = await addBookmark(params);
    return { ...response.data};
  }
);

export const REMOVE_BOOKMARK = createAsyncThunk(
  "@@BOOKMARKS/REMOVE_BOOKMARK",
  async (params, thunkAPI) => {
    const response = await removeBookmark(params);
    return { ...response.data };
  }
);

export const REMOVE_ALL_BOOKMARK = createAsyncThunk(
  "@@BOOKMARKS/REMOVE_ALL_BOOKMARK",
  async (params, thunkAPI) => {
    const response = await removeAllBookmarks(params);
    return { ...response.data };
  }
);

const bookmarks = createSlice({
  name: "@@BOOKMARKS",
  initialState: {},
  reducers: {},
  extraReducers: {
    [GET_USER_BOOKMARKS.fulfilled]: (state, action) => {
      return action.payload;
    },
    [ADD_BOOKMARK.fulfilled]: (state, action) => {
      return action.payload;
    },
    [REMOVE_BOOKMARK.fulfilled]: (state, action) => {
      return action.payload;
    },
    [REMOVE_ALL_BOOKMARK.fulfilled]: (state, action) => {
      return action.payload;
    },
  },
});

export default bookmarks.reducer;
