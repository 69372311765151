import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { CLEAN_STORE, TRIGGER_MODAL } from "redux/modal";
import { SET_FILTERS, RESET_FILTERS } from "redux/qualificationsFilters";
import { SET_SEARCH } from "redux/search";
import { publish, unPublish } from "services/quals";
import { searchQualification } from "services/serp";
import {
  auditActions,
  auditTypes,
  entityType,
  filtersMyracle,
} from "utils/commons";
import HeroTitle from "components/HeroTitle";
import LegendButton from "components/LegendButton";
import SearchView from "components/SearchView";
import ConfirmActionModal from "components/ActionModal/ConfirmActionModal";
import useLoadData from "../../hooks/useLoadData";
import { GET_INDUSTRIES } from "../../redux/industries";
import { SET_INDUSTRY_TREE } from "../../redux/industryTree";
import { GET_SUBINDUSTRIES } from "../../redux/subindustries";

const Quals = () => {
  const dispatch = useDispatch();
  const setSearch = payload => dispatch(SET_SEARCH({ QUALS: payload }));
  const updateModalProps = payload => dispatch(TRIGGER_MODAL(payload));
  const cleanStore = payload => dispatch(CLEAN_STORE(payload));
  const getIndustries = () => dispatch(GET_INDUSTRIES());
  const getSubindustries = () => dispatch(GET_SUBINDUSTRIES());
  const setIndustryTree = payload => dispatch(SET_INDUSTRY_TREE(payload));

  const searchQuals = useSelector(state => state.searchQuals);
  const industries = useSelector(state => state.industries);
  const subindustries = useSelector(state => state.subindustries);
  const modal = useSelector(state => state.modal);
  const industryTree = useSelector(state => state.industryTree);
  const unitsColorMap = useSelector((state) => state.unitsColor);

  const { trackEvent } = useTracking({
    resourceType: entityType.QUALIFICATION,
  });
  const selectedFilters = useSelector(state => state.qualificationsFilters);
  const setSelectedFilters = payload => dispatch(SET_FILTERS(payload));
  const resetSelectedFilters = () => dispatch(RESET_FILTERS());
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [code, setCode] = useState();
  
  const {
    loadData,
    items: infoQuals,
    setItems: setInfoQuals,
    total,
    setPageNumber,
  } = useLoadData({
    searchFunction: searchQualification,
    params: { ...selectedFilters },
  });
  
  useEffect(() => {
    setSelectedFilters({
      order_by: "LATEST",
      ...searchQuals,
    })
  }, [])
  
  useEffect(() => {
    if (modal.shouldReloadOfferings) {
      setPageNumber(0);
      setInfoQuals([]);

      setSelectedFilters({
        order_by: "LATEST",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.shouldReloadOfferings]);

  useEffect(() => {
    setSearch(selectedFilters);
    void loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  useEffect(() => {
    if (subindustries.length === 0) {
      getSubindustries();
    }
    if (industries.length === 0) {
      getIndustries();
    }
    if (industryTree.length === 0) {
      setIndustryTree({
        industries,
        subindustries,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetFilters = () => {
    setPageNumber(0);
    setInfoQuals([]);

    resetSelectedFilters()
    setSelectedFilters({
      order_by: "LATEST",
    });
  };

  const handleChangeFilter = (key, value) => {
    setPageNumber(0);
    setInfoQuals([]);

    setSelectedFilters({
      ...selectedFilters,
      [key]: value,
    });
  };

  const qualCallback = params => {
    const { action, code } = params;
    switch (action) {
      case "edit":
        updateModalProps({
          isOpen: true,
          modalProps: {
            code: code,
            type: entityType.QUALIFICATION,
            versionType: "published",
          },
        });
        break;
      case "publish":
        publish(code).then(() => {
          trackEvent({
            action: auditActions.EDIT_AND_PUBLISH,
            code: code,
            type: auditTypes.ACTION,
          });
        });
        break;
      case "unpublish":
        setIsModalOpen(true);
        setCode(code);
        break;
      default:
        break;
    }
  };

  return (
    <div className="container">
      <HeroTitle
        type="Quals"
        title="What we've done before"
        subtitle="List of Project Qualifications, with related
        descriptive metadata and attachments.
        Attachments are powerpoint cards illustrating
        real projects/experiences, and are available in non protected mode (i.e. editable) "
        grayVariant
      />
      <SearchView
        selectedFilters={selectedFilters}
        handleChangeFilter={handleChangeFilter}
        dataList={infoQuals}
        viewableFilters={filtersMyracle.QUALS}
        handleResetFilters={handleResetFilters}
        callback={qualCallback}
        documentType={entityType.QUALIFICATION}
        loadData={loadData}
        total={total}
        showEditLegend
        showFileWithErrors
      />
      <ConfirmActionModal
        onConfirm={() => {
          unPublish(code)
            .then(res => {
              if (!res || res?.data === null) {
                console.error(
                  "Error hiding content :" + res?.message?.toString()
                );
                setIsModalOpen(false);
              } else {
                trackEvent({
                  action: auditActions.DELETE,
                  code: code,
                  type: auditTypes.ACTION,
                });
                setIsModalOpen(false);
                setInfoQuals(infoQuals.filter(item => item.code !== code));
                cleanStore({ code, documentType: entityType.QUALIFICATION });
              }
            })
            .catch(e => {
              console.error("Error hiding content: ", e);
            });
          setIsModalOpen(false);
        }}
        text="Are you sure you want to hide this content? The corresponding draft will be overwritten"
        isOpenProp={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <LegendButton legend={unitsColorMap}/>
    </div>
  );
};

export default React.memo(Quals);
