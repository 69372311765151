import React, { useCallback, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTracking } from "react-tracking";
import { ADD_BOOKMARK, REMOVE_BOOKMARK } from "redux/bookmarks";
import { TRIGGER_MODAL } from "redux/modal";
import routes from "routes";
import { getOfferingDeck } from "services/offeringDeck";
import { deleteOffering, getOfferingTopic } from "services/offeringTopic";
import { getOnePager } from "services/onePager";
import {
  auditActions,
  auditTypes,
  contentCardLabels,
  entityType,
  unitTypes,
} from "utils/commons";
import { checkDownload, getIndustry, getOrgUnit } from "utils/utils";
import BackSave from "components/BackSave";
import CardContact from "components/CardContact";
import Comments from "components/Comments";
import LegendButton from "components/LegendButton";
import LegendHeadbar from "components/LegendHeadbar";
import RelatedCardList from "components/RelatedCardList";
import SliderPpt from "components/SliderPpt";
import FlameSvg from "images/flame.svg";
import InstitutionalIcon from "images/institution_icon.svg";
import LikeImage from "images/like.svg";
import LikeImageFull from "images/like_reverse.svg";
import { useProfileContext } from "../../contexts/Profile";
import useRelatedData from "../../hooks/useRelatedData";
import { getBusinessChallenge } from "../../services/businessChallenge";
import { getLikes } from "../../services/like";
import { getQualification } from "../../services/quals";

import "./style.scss";

const OfferingDetail = () => {
  const dispatch = useDispatch();
  const addBookmark = params => dispatch(ADD_BOOKMARK(params));
  const removeBookmark = params => dispatch(REMOVE_BOOKMARK(params));
  const updateModalProps = payload => dispatch(TRIGGER_MODAL(payload));

  const organizationalUnits = useSelector(state => state.organizationalUnits);
  const profile = useSelector(state => state.profile);
  const bookmarks = useSelector(state => state.bookmarks);
  const modal = useSelector(state => state.modal);
  const offerings = useSelector(state => state.offerings);

  const [offering, setOffering] = useState();
  const [offeringDeck, setOfferingDeck] = useState();
  const [totalComments, setTotalComments] = useState(0);
  const [totalLikes, setTotalLikes] = useState(0);
  const [myLike, setMyLike] = useState(false);
  const [industry, setIndustry] = useState([]);
  const [contributorUnits, setContributorUnits] = useState([]);
  const [loadOd, setLoadOd] = useState(false);
  const [loadOp, setLoadOp] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [onePager, setOnePager] = useState();

  const history = useHistory();
  const { offeringCode } = useParams();
  const { isEditable, userEditScope, categories } = useLocation().state || {};

  const { Track, trackEvent } = useTracking({
    resourceType: entityType.OFFERING_TOPIC,
    code: offering?.code,
  });

  const { isEditor, canLike } = useProfileContext();
  const { qualificationCodes, businessChallengeCodes } = offering || [];

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingPage(true);
        const { data } = await getLikes(offeringCode);
        setMyLike(data?.myLike);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingPage(false);
      }
    })();
  }, [offeringCode]);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (!isLoadingPage) {
        setIsLoadingPage(true);
        try {
          const res1 = await getOfferingTopic(offeringCode);
          if (!isMounted) return;
          setTotalLikes(res1?.data?.totalLikes);
          if (res1?.data?.organizationalUnitId && isMounted) {
            const secOU = res1.data?.secondaryOrganizationalUnitIds;
            const arrayIndustry = [];
            const contributorUnitsArray = [];
            secOU.forEach(item => {
              const fullOU = getOrgUnit(organizationalUnits, item);
              contributorUnitsArray.push(fullOU.name);
              setContributorUnits(contributorUnitsArray);
              if (fullOU.type.toUpperCase() === unitTypes.SUBINDUSTRY) {
                arrayIndustry.push(fullOU.name);
              }
            });
            const id = res1.data.organizationalUnitId;
            const industry = await getIndustry(organizationalUnits, id);
            if (industry.type.toUpperCase() === unitTypes.SUBINDUSTRY) {
              arrayIndustry.push(industry.name);
            }
            setIndustry([...arrayIndustry]);
          }
          if (!isMounted) return;
          setOffering(res1?.data);
          if (res1.data?.offeringDeckId && !loadOd && isMounted) {
            setLoadOd(true);
            const od = await getOfferingDeck(res1.data.offeringDeckId);
            if (od?.data) setOfferingDeck(od.data);
            setLoadOd(false);
          }
          if (res1?.data?.onePagerId && !loadOp && isMounted) {
            setLoadOp(true);
            const op = await getOnePager(res1?.data?.onePagerId);
            if (op?.data && isMounted) setOnePager(op.data);
            if (!isMounted) return;
            setLoadOp(false);
          }
          if (!isMounted) return;
          setIsLoadingPage(false);
        } catch (error) {
          console.error("OfferingDetail error: ", error);
          setIsLoadingPage(false);
          history.push(routes.OFFERING);
        }
      }
    })();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offeringCode, offerings, modal.shouldReloadOfferings]);

  const handleAddBookmark = () => {
    if (
      bookmarks.offeringTopicsCodes &&
      bookmarks.offeringTopicsCodes.includes(offering?.code)
    ) {
      trackEvent({
        type: auditTypes.ACTION,
        action: auditActions.DELETE_BOOKMARK,
      });
      removeBookmark({
        documentType: entityType.OFFERING_TOPIC,
        documentId: offeringCode,
      });
    } else {
      trackEvent({
        type: auditTypes.ACTION,
        action: auditActions.ADD_BOOKMARK,
      });
      addBookmark({
        documentType: entityType.OFFERING_TOPIC,
        documentId: offeringCode,
      });
    }
  };

  const { data: bcCardData, loadMore: loadMoreBc } = useRelatedData(
    businessChallengeCodes,
    getBusinessChallenge,
    "OfferingDetail error: "
  );
  const { data: qualCardData, loadMore: loadMoreQuals } = useRelatedData(
    qualificationCodes,
    getQualification,
    "OfferingDetail error: "
  );

  const renderOnePager = () =>
    loadOp || !onePager ? (
      <Skeleton width={"100%"} height={"45vw"} />
    ) : (
      <Track>
        <SliderPpt
          title="ONE PAGER"
          fileName={onePager.originalFileName}
          previews={onePager.filePreviews}
          fileType={onePager.fileType}
          isDownloadable={checkDownload(profile, offeringCode)}
          code={offering.onePagerId}
        />
      </Track>
    );

  const renderOfferingDeck = () =>
    loadOd || !offeringDeck ? (
      <Skeleton width={"100%"} height={"45vw"} />
    ) : (
      <Track>
        <SliderPpt
          title="OFFERING DECK"
          fileName={offeringDeck.originalFileName}
          previews={offeringDeck.filePreviews}
          fileType={offeringDeck.fileType}
          isDownloadable={checkDownload(profile, offeringCode)}
          documentType={entityType.OFFERING_TOPIC}
          code={offeringDeck?.id}
        />
      </Track>
    );

  const setCallbackLikes = useCallback((total, myLike) => {
    setMyLike(myLike);
    setTotalLikes(total);
  }, []);
  
  const [categoryLegend, setCategoryLegend] = useState({})

  const unitsColorMap = useSelector((state) => state.unitsColor)

  useEffect(() => {
    if(categories && categories.length > 0) {
      const legend = {}
      categories.forEach((category) => {
        legend[category] = unitsColorMap[category]
      })
      setCategoryLegend(legend)
    }
  }, [categories]);

  return (
    <div className="container detail">
      {offering?.isTrendy || offering?.isInstitutional ? (
        <div className="offering-trendy desktop">
          {offering?.isTrendy && (
            <>
              <img src={FlameSvg} alt="" className="status-icon" />
              <div className="status-label">{contentCardLabels.TRENDING}</div>
            </>
          )}
          {offering?.isInstitutional && (
            <>
              <img
                src={InstitutionalIcon}
                alt=""
                className="status-icon-institutional"
              />
              <div className="status-label">
                {contentCardLabels.INSTITUTIONAL}
              </div>
            </>
          )}
        </div>
      ) : null}

      <BackSave
        handleAddBookmark={handleAddBookmark}
        isSaved={
          bookmarks.offeringTopicsCodes &&
          bookmarks.offeringTopicsCodes.includes(offering?.code)
        }
        isEditable={isEditable}
        editFunction={() => {
          updateModalProps({
            isOpen: true,
            modalProps: {
              code: offering?.code,
              selectedCoe: offering.coe,
              type: entityType.OFFERING_TOPIC,
              versionType: "PUBLISHED",
            },
          });
        }}
        deleteFunction={() => {
          deleteOffering(offeringCode).then(() => {
            trackEvent({
              action: auditActions.DELETE,
              code: offeringCode,
              type: auditTypes.ACTION,
              resourceType: entityType.OFFERING_TOPIC,
            });
            history.push("/offerings/");
          });
        }}
        isEditor={isEditor}
      />
      <div className="legend-headbar">
        <LegendHeadbar legend={categoryLegend} />
      </div>
      {!offering && !isLoadingPage ? (
        <Skeleton width={"100%"} height={"45vw"} />
      ) : (
        <>
          {offering?.isTrendy || offering?.isInstitutional ? (
            <div className="offering-trendy mobile">
              {offering?.isTrendy && (
                <>
                  <img src={FlameSvg} alt="" className="status-icon" />
                  <div className="status-label">
                    {contentCardLabels.TRENDING}
                  </div>
                </>
              )}
              {offering?.isInstitutional && (
                <>
                  <img
                    src={InstitutionalIcon}
                    alt=""
                    className="status-icon-institutional"
                  />
                  <div className="status-label">
                    {contentCardLabels.INSTITUTIONAL}
                  </div>
                </>
              )}
            </div>
          ) : null}
          <div className="offering-wrapper">
            <div className="section-title">
              <div className="type">OFFERING</div>
              {offering?.organizationalUnitId && (
                <div className="org-units">
                  <div className="unit">
                    <span className="label">
                      {contentCardLabels.OWNER_UNIT}
                    </span>
                    &nbsp;
                    {
                      organizationalUnits?.find(
                        ou => ou.id === offering?.organizationalUnitId
                      )?.name
                    }
                  </div>
                  {contributorUnits.length ? (
                    <div className="unit">
                      <span className="label">
                        {contentCardLabels.CONTRIBUTOR_UNITS}
                      </span>
                      <span className="sub-industry">
                        &nbsp;&nbsp;
                        {contributorUnits.map((ouOff, index) => {
                          return index !== contributorUnits.length - 1
                            ? ouOff + `,\xa0\xa0`
                            : ouOff;
                        })}
                      </span>
                    </div>
                  ) : null}
                  {
                    <div className="unit">
                      <span className="label">
                        {contentCardLabels.INDUSTRIES}
                      </span>
                      <span className="sub-industry">
                        &nbsp;&nbsp;
                        {industry.length > 0
                          ? industry.map((ouOff, index) => {
                              return index !== industry.length - 1
                                ? ouOff + `,\xa0\xa0`
                                : ouOff;
                            })
                          : " CROSS INDUSTRY"}
                      </span>
                    </div>
                  }
                </div>
              )}
              <div className="title">{offering?.description}</div>
              <div className="likes-comments-total-container">
                {canLike && totalLikes && (
                  <>
                    <img
                      className={"like-image"}
                      src={myLike ? LikeImageFull : LikeImage}
                      alt="like"
                    />
                    <div className="black-dot">{`${totalLikes} likes •`}</div>
                  </>
                )}
                {totalComments && (
                  <div
                    className={"total-comments"}
                    onClick={() =>
                      document.getElementById("comments").scrollIntoView()
                    }>
                    {`${totalComments} comments`}
                  </div>
                )}
              </div>
            </div>
            <div className="section-tags">
              {(offering?.tags || []).map((tag, index) => (
                <div key={`${index}-${tag}`} className="tag">
                  #{tag.trim()}
                </div>
              ))}
            </div>
            <div className="section-epics">
              {(offering?.epics || []).map((epic, index) => (
                <div key={`${index}-${epic}`} className="epic">
                  {epic}
                </div>
              ))}
            </div>
            {offering?.referenceContactIds.length > 0 && (
              <div className="section-references">
                <div className="title">REFERENCES</div>
                {offering?.spocs.map((rid, index) => (
                  <CardContact
                    key={index}
                    id={rid.userId}
                    showTeamsContact={true}
                  />
                ))}
              </div>
            )}
          </div>

          {offering?.onePagerId && renderOnePager()}

          {offering?.offeringDeckId && renderOfferingDeck()}

          {businessChallengeCodes &&
          businessChallengeCodes?.length > 0 &&
          bcCardData?.length ? (
            <RelatedCardList
              elemList={businessChallengeCodes}
              documentType="BUSINESS_CHALLENGE"
              editScope={userEditScope}
              data={bcCardData}
              onGoRight={loadMoreBc}
            />
          ) : null}

          {qualificationCodes &&
          qualificationCodes?.length > 0 &&
          qualCardData?.length ? (
            <RelatedCardList
              elemList={qualificationCodes}
              documentType="QUALIFICATION"
              editScope={userEditScope}
              data={qualCardData}
              onGoRight={loadMoreQuals}
            />
          ) : null}

          <Comments
            code={offeringCode}
            setCallbackTotals={setTotalComments}
            contentType={entityType.OFFERING_TOPIC}
            myLikeData={myLike}
            totalLikes={totalLikes}
            setCallbackLikes={setCallbackLikes}
          />
        </>
      )}
      {categoryLegend &&
        <LegendButton legend={categoryLegend}/>
      }
    </div>
  );
};
//quals, bc and offerings are necessary in connect to see immediate update in case of edit
export default React.memo(OfferingDetail);
