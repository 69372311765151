import React, { useEffect, useState, Fragment, forwardRef } from "react";
import { useTreeContext } from "../../contexts/Tree";
import { getTreeLevels } from "../../services/treeView";
import { entityType, treeLevelsLabels } from "../../utils/commons";
import { transformList } from "../../utils/utils";
import Level from "../Level";

import "./style.scss";

const Drop = (
  { selectedCoe, total, dataList, readOnly, industryName, isManagePage, type },
  ref
) => {
  const { selectedId: selectedTree, setTreeList, treeList } = useTreeContext();
  const [updatedDragList, setUpdatedDragList] = useState(dataList);

  useEffect(() => {
    let isMounted = true;
    if (selectedCoe?.id) {
      try {
        (async () => {
          const res = await getTreeLevels(selectedCoe?.id);
          if (!isMounted) return;
          setTreeList(transformList([res?.data]));
          setUpdatedDragList(dataList);
        })();
      } catch (err) {
        console.error("Error Drop: ", err);
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoe?.id, dataList]);

  const renderNode = el => {
    return (
      <Level
        label={el.label}
        counter={el.counter}
        children={el.children}
        id={el.id}
        expanded={el.expanded}
        isActive={el.id === selectedTree}
        level={el.level}
        readOnly={readOnly}
        isManagePage={isManagePage}
        type={type}
        sortNumber={el.sortNumber}
        idx={el.idx}
        lastElem={el.children.length - 1}
        currentLevels={treeList}
        selectedCoeId={selectedCoe?.id}
      />
    );
  };

  return (
    <div className="categories-container">
      <ul className={`drop ${readOnly ? "scroll-down" : ""}`} ref={ref}>
        {selectedCoe?.type === entityType.SUBINDUSTRY && industryName ? (
          <div className="industry-header">{industryName}</div>
        ) : null}
        {!readOnly && !isManagePage ? (
          <>
            <Level
              id={treeLevelsLabels.ALL_OFFERINGS}
              label="All offerings"
              counter={total}
              isActive={selectedTree === treeLevelsLabels.ALL_OFFERINGS}
            />
            <Level
              id={treeLevelsLabels.UNCATEGORIZED}
              label="Uncategorized"
              counter={
                updatedDragList?.filter(el => el.treeLevelId === "").length
              }
              isActive={selectedTree === treeLevelsLabels.UNCATEGORIZED}
            />
          </>
        ) : null}
        {treeList?.map(el => {
          return <Fragment key={el.id}>{renderNode(el)}</Fragment>;
        })}
      </ul>
    </div>
  );
};

export default forwardRef(Drop);
