import React, { useEffect, useState, useCallback } from "react";
import { getDictionary } from "../../services/dictionary";

const ImageCustomer = ({ name }) => {
  const [imageProp, setImage] = useState({});

  const handleGetDictionary = useCallback(async () => {
    const response = await getDictionary({ type: "CUSTOMER", name });
    if (response?.data) {
      setImage(response.data[0]);
    }
  }, [name]);

  useEffect(() => {
    handleGetDictionary();
  }, [handleGetDictionary]);

  return imageProp?.graphic ? (
    <div className="img-customers-wrapper">
      <img src={`data:image/png;base64,${imageProp?.graphic}`} alt={name} />
    </div>
  ) : null;
};

export default ImageCustomer;
