import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { SET_SEARCH } from "redux/search";
import routes from "routes";
import SearchIcon from "images/search.svg";
import "./style.scss";

const SearchBar = ({ search, setSearch }) => {
  const history = useHistory();
  const location = useLocation();

  const [searchValue, setSearchValue] = useState("");

  const onKeyUpHandler = (e, isButton = false) => {
    if (e.key === "Enter" || isButton) {
      setSearch({
        ...search,
        quals: { q: searchValue },
        businessChallenges: { q: searchValue },
        offerings: { q: searchValue },
      });
      e.currentTarget.blur();
      history.push(`${routes.SERP}?q=${searchValue}`);
    }
  };

  useEffect(() => {
    if (location.pathname !== routes.SERP) {
      setSearchValue("");
    } else {
      setSearchValue(search?.quals?.q);
    }
  }, [location, search]);

  return (
    <div className="search-bar-container">
      <div
        className="search-icon"
        onClick={e => {
          onKeyUpHandler(e, true);
        }}>
        <img src={SearchIcon} alt="lens" />
      </div>
      <input
        type="text"
        className="search-bar"
        value={searchValue}
        placeholder="Write here the topic you are searching for"
        onChange={e => setSearchValue(e.currentTarget.value)}
        onKeyUp={onKeyUpHandler}
        id="input-search"
      />
    </div>
  );
};

export default connect(
  ({ search: { serp = {} } }) => ({ search: serp }),
  dispatch => ({
    setSearch: payload =>
      dispatch(
        SET_SEARCH({
          serp: payload,
        })
      ),
  })
)(SearchBar);
