import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTracking } from "react-tracking";

import { GET_USER_BOOKMARKS, REMOVE_ALL_BOOKMARK } from "redux/bookmarks";

import { apis } from "services/config";

import { auditActions, auditTypes, entityType } from "utils/commons";
import { checkDownload, downloadFile } from "utils/utils";
import BookmarkCard from "components/BookmarkCard";
import { ReactComponent as ClearSvg } from "images/cancel.svg";

import "./style.scss";
import Trash from "../../images/trash.svg";

const Bookmarks = ({
  profile,
  toggleClose,
  getBookmarks,
  bookmarks,
  removeAllBookmarks,
  isOpen,
}) => {
  const { trackEvent } = useTracking();
  const [downloading, setDownloading] = useState();
  const [offeringTopicsChecked, setOfferingTopicsChecked] = useState([]);
  const [qualificationsChecked, setQualificationsChecked] = useState([]);
  useEffect(() => {
    getBookmarks();
  }, [getBookmarks]);

  useEffect(() => {
    setOfferingTopicsChecked(
      bookmarks?.offeringTopicsCodes ? bookmarks?.offeringTopicsCodes : []
    );
    setQualificationsChecked(
      bookmarks?.qualificationsCodes ? bookmarks?.qualificationsCodes : []
    );
  }, [bookmarks]);

  const toggleChecked = (type, code) => {
    if (type === entityType.OFFERING_TOPIC) {
      let i = offeringTopicsChecked.indexOf(code);
      if (i === -1) {
        setOfferingTopicsChecked([...offeringTopicsChecked, code]);
      } else {
        setOfferingTopicsChecked(
          offeringTopicsChecked.filter(el => el !== code)
        );
      }
    }
    if (type === entityType.QUALIFICATION) {
      let i = qualificationsChecked.indexOf(code);
      if (i === -1) {
        setQualificationsChecked([...qualificationsChecked, code]);
      } else {
        setQualificationsChecked(
          qualificationsChecked.filter(el => el !== code)
        );
      }
    }
  };

  const saved =
    (bookmarks?.offeringTopicsCodes?.length || 0) +
    (bookmarks?.qualificationsCodes?.length || 0);

  const downloadCount =
    offeringTopicsChecked.filter(code => checkDownload(profile, code)).length +
    qualificationsChecked.filter(code => checkDownload(profile, code)).length;

  const handleDownload = async withoutMfu => {
    trackEvent({
      action: auditActions.DOWNLOAD_SET,
      codes: { offerings: offeringTopicsChecked, quals: qualificationsChecked },
      type: auditTypes.DOWNLOAD,
    });
    setDownloading(true);
    let queryParams = withoutMfu ? "?withoutMfu=true&" : "?";
    offeringTopicsChecked.forEach(offering => {
      queryParams = `${queryParams}offeringTopicList[]=${offering}&`;
    });
    qualificationsChecked.forEach(qual => {
      queryParams = `${queryParams}qualificationList[]=${qual}&`;
    });
    await downloadFile(
      `${apis.BOOKMARK}/download/${queryParams}`,
      `Myracle-${+new Date()}.zip`
    );
    setDownloading(false);
  };

  const handleRemoveAll = () => {
    trackEvent({
      action: auditActions.DELETE_BOOKMARK,
      codes: {
        offerings: bookmarks?.offeringTopicCodes || [],
        quals: bookmarks?.qualificationsCodes || [],
      },
      type: auditTypes.ACTION,
    });
    removeAllBookmarks();
  };

  const close = e => {
    if (e) e.stopPropagation();
    if (toggleClose) toggleClose();
  };

  return (
    <div className={`bookmarks-panel ${isOpen ? "visible" : ""}`}>
      <div className="bookmarks-content">
        <div className="bookmarks-content-title">
          <ClearSvg onClick={e => close(e)} />
          <h3>{`SAVED (${saved.toString()})`}</h3>
        </div>
        {bookmarks?.qualificationsCodes &&
          bookmarks?.qualificationsCodes.map(q => (
            <BookmarkCard
              key={q}
              code={q}
              isChecked={qualificationsChecked.includes(q)}
              toggleChecked={toggleChecked}
              documentType={entityType.QUALIFICATION}
              closeBookmarks={e => close(e)}
            />
          ))}
        {bookmarks?.offeringTopicsCodes &&
          bookmarks?.offeringTopicsCodes.map(o => (
            <BookmarkCard
              key={o}
              code={o}
              isChecked={offeringTopicsChecked.includes(o)}
              toggleChecked={toggleChecked}
              documentType={entityType.OFFERING_TOPIC}
              closeBookmarks={e => close(e)}
            />
          ))}
      </div>

      <div className="footer-button">
        {downloadCount && (
          <>
            <div
              className="button-download"
              onClick={() => handleDownload(false)}>
              {downloading
                ? "downloading data..."
                : `DOWNLOAD ALL (${downloadCount.toString()})`}
            </div>
            <div
              className="button-download"
              onClick={() => handleDownload(true)}>
              {downloading
                ? "downloading data..."
                : `DOWNLOAD ALL WITH HIDDEN OU NAME (${downloadCount.toString()})`}
            </div>
          </>
        )}
        <div
          className="button-delete-all"
          onClick={() => (downloading ? null : handleRemoveAll())}>
          <img src={Trash} alt="delete" />({downloadCount.toString()})
        </div>
      </div>
    </div>
  );
};

export default connect(
  ({ bookmarks, profile }) => ({ bookmarks, profile }),
  dispatch => ({
    getBookmarks: () => dispatch(GET_USER_BOOKMARKS()),
    removeAllBookmarks: () => dispatch(REMOVE_ALL_BOOKMARK()),
  })
)(Bookmarks);
