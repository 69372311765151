import { apis, serviceInterface } from "./config";

export const getPersonas = id => {
  return serviceInterface.get(`${apis.PERSONA}${id ? "/" + id : ""}`);
};

export const getRelated = id => {
  return serviceInterface.get(
    `${apis.PERSONA}/${id}/links`
  );
};