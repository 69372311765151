import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTracking } from "react-tracking";
import Flags from "country-flag-icons/react/3x2";
import { ADD_BOOKMARK, REMOVE_BOOKMARK } from "redux/bookmarks";
import { CLEAN_STORE } from "redux/modal";
import routes from "routes";
import moment from "moment";
import {
  auditActions,
  auditTypes,
  contentCardLabels,
  entityType,
} from "utils/commons";
import ColorBar from "components/ColorBar";
import DeleteIcon from "images/delete.png";
import Globe from "images/earth-icon.svg";
import EditIcon from "images/edit.svg";
import EditWithAlert from 'images/editWithAlert.svg'
import savePlus from "images/save_plus.png";
import saved from "images/saved.png";
import Unpublish from "images/view-off.svg";
import {
  getIndustry,
  getOrganizationalUnitsNameById,
  getOrganizationalUnitsTypeById,
  getSubIndustry,
} from "../../utils/utils";
import Likes from "../Likes";

const Qual = ({
  code,
  callback = null,
  dictionaries,
  isContributor,
  userEditScope,
  isDraft,
  cardData,
  isDownloader,
  canLike,
  bookmarks,
  addBookmark,
  removeBookmark,
  clearStore,
  industries,
  subindustries,
  organizationalUnits,
  setCallbackLikes,
  myLike,
  totalLikes,
  unitsColor,
  categories,
  showFileWithErrors = false,
}) => {
  const { trackEvent } = useTracking({
    code,
    resourceType: entityType.QUALIFICATION,
  });
  const [qualData, setQualData] = useState();

  const {
    subIndustryId,
    organizationalUnitId,
    code: qualDataCode,
    versionType,
    title,
    customer,
    customerAlias,
    year,
    category,
    flagNationCodes,
    lastModifiedDate,
    hasIssues,
  } = qualData || {};

  const getNationCodes = q => {
    const filteredNations = (dictionaries?.COUNTRY || [])
      .filter(c => q.countries?.includes(c.name))
      .slice(0, 2);

    const flagNationCodes = filteredNations.map(fn => ({
      flagFn:
        fn.name !== "Global" ? Flags[fn.standardCode.toUpperCase()] : () => {},
      name: fn.standardCode.toUpperCase()
        ? fn.standardCode.toUpperCase()
        : "GLO",
    }));
    return { flagNationCodes };
  };

  useEffect(() => {
    if (isContributor) {
      setQualData({ ...cardData });
    } else {
      setQualData({ ...cardData, ...getNationCodes(cardData) });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dictionaries]);

  const subindustry = getSubIndustry(subindustries, subIndustryId);
  const industry = getIndustry(industries, subindustry?.industryId);
  const organizationalUnitType = getOrganizationalUnitsTypeById(
    organizationalUnits,
    cardData.organizationalUnitId
  );
  const organizationalUnitName = getOrganizationalUnitsNameById(
    organizationalUnits,
    cardData.organizationalUnitId
  );
  const canUserEdit =
    userEditScope &&
    userEditScope.orgIds?.length > 0 &&
    userEditScope?.orgIds.includes(organizationalUnitId);
  const isAdmin = userEditScope && userEditScope?.orgIds === "all";

  const handleAddBookmark = event => {
    event.preventDefault();
    event.stopPropagation();
    if (
      bookmarks.qualificationsCodes &&
      bookmarks.qualificationsCodes.includes(qualDataCode)
    ) {
      trackEvent({
        type: auditTypes.ACTION,
        action: auditActions.DELETE_BOOKMARK,
      });
      removeBookmark({
        documentType: entityType.QUALIFICATION,
        documentId: qualDataCode,
      });
    } else {
      trackEvent({
        type: auditTypes.ACTION,
        action: auditActions.ADD_BOOKMARK,
      });
      addBookmark({
        documentType: entityType.QUALIFICATION,
        documentId: qualDataCode,
        isDownloadable: isDownloader || canUserEdit,
      });
    }
  };

  const isSaved =
    bookmarks &&
    bookmarks?.qualificationsCodes &&
    bookmarks.qualificationsCodes.includes(qualDataCode);

  const handleAction = (event, action) => {
    event.stopPropagation();
    event.preventDefault();
    callback({
      action,
      versionType,
      code,
    });
    clearStore(code);
  };

  return (
    <div className="qual-div">
      <Link
        to={{
          pathname: `${routes.QUALS}/${code}`,
          state: {
            code,
            title,
            isEditable: canUserEdit || isAdmin,
            userEditScope: userEditScope,
            industry,
            subindustry,
            categories
          },
        }}
        onClick={() =>
          trackEvent({
            type: auditTypes.CONTENT,
            action: auditActions.OPEN_DETAIL,
          })
        }
        className={"result-card qual-card" + (isDraft ? " qual-draft" : "")}>
        <div className="card-header">
          <div className="status-banner">
            <div className="status">
              <div className="status-label">
                {industry?.name.toUpperCase()}
                <br />
                {subindustry?.name}
              </div>
            </div>
            <div className="likes-bookmarks-icons-container">
              {canLike && !isContributor && (
                <Likes
                  contentType={entityType.QUALIFICATION}
                  code={code}
                  myLikeData={myLike}
                  totalLikes={totalLikes}
                  setCallbackLikes={setCallbackLikes}
                  totalData={totalLikes}
                />
              )}
              {!isContributor && handleAddBookmark && (
                <div
                  className="add-bookmark"
                  onClick={event => handleAddBookmark(event)}>
                  <img src={isSaved ? saved : savePlus} alt="save" />
                </div>
              )}
            </div>
          </div>
        </div>
        {title && (
          <div className="name">{`${title?.substr(0, 50)}${
            title?.length > 50 ? "..." : ""
          }`}</div>
        )}
        <div className="body">
          <div className="manager">{customerAlias ?? customer}</div>
          {Boolean(year) && (
            <>
              <div className="dot">•</div>
              <div className="year">{year}</div>
            </>
          )}
        </div>
        {(!canUserEdit || !isContributor) && (
          <div className="qual-footer">
            <div className="info">
              <div className="label">{organizationalUnitType}</div>
              <div className="value">{organizationalUnitName}</div>
            </div>

            <div className="info">
              {category && (
                <div className="label">{contentCardLabels.CATEGORY}</div>
              )}
              <div className="value">{category}</div>
            </div>

            <div className="info flags">
              {flagNationCodes && (
                <>
                  <div className="label">
                    {Object.values(flagNationCodes)
                      .map(f => f.name)
                      ?.join("/")}
                  </div>
                  <div className="value">
                    {Object.values(flagNationCodes).map((f, i) =>
                      typeof f.flagFn === "function" ? (
                        <span key={i}>
                          {f.flagFn({}) || <img src={Globe} alt="globe" />}
                        </span>
                      ) : (
                        <span key={i}>
                          <img src={Globe} alt="globe" />
                        </span>
                      )
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {(isContributor || userEditScope?.orgIds === "all" || canUserEdit) && (
          <div className="qual-footer draft-footer">
            <div className="info">
              <div className="label">{contentCardLabels.CODE}</div>
              <div className="value">{code}</div>
            </div>
            <div className="info">
              <div className="label">{contentCardLabels.LAST_EDIT}</div>
              <div className="value">{`${moment(lastModifiedDate).format(
                "DD/MM/YYYY"
              )}`}</div>
            </div>
            <div className="info icons">
              <img
                src={(showFileWithErrors && hasIssues) ? EditWithAlert : EditIcon}
                alt="Edit Qual"
                onClick={callback ? e => handleAction(e, "edit") : null}
              />
              {versionType?.toLowerCase() === "published" ? (
                <img
                  src={Unpublish}
                  alt="Unpublish Qual"
                  onClick={callback ? e => handleAction(e, "unpublish") : null}
                />
              ) : (
                <img
                  src={DeleteIcon}
                  alt="Delete Qual"
                  onClick={callback ? e => handleAction(e, "delete") : null}
                />
              )}
            </div>
          </div>
        )}
      </Link>
      <ColorBar id={code} legend={unitsColor} category={categories}/>
    </div>
  );
};

export default connect(
  ({
    dictionaries,
    bookmarks,
    industries,
    subindustries,
    organizationalUnits,
  }) => ({
    dictionaries,
    bookmarks,
    industries,
    subindustries,
    organizationalUnits,
  }),
  dispatch => ({
    addBookmark: params => dispatch(ADD_BOOKMARK(params)),
    removeBookmark: params => dispatch(REMOVE_BOOKMARK(params)),
    clearStore: code =>
      dispatch(
        CLEAN_STORE({ code: code, documentType: entityType.QUALIFICATION })
      ),
  })
)(Qual);
