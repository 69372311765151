import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useTracking } from "react-tracking";

import { getBusinessChallenge, save, update } from "services/businessChallenge";
import { auditActions, auditTypes, entityType } from "utils/commons";
import { checkField } from "utils/utils";
import CustomSelect from "components/CustomSelect";
import TagsInput from "components/TagsInput";

import CloseIcon from "images/cancel.svg";
import Step from "./Step";

import "./style.scss";

const multipleCustomSelectFields = ["personasIds", "tags"];

const mandatoryMultipleFields = ["personasIds"];

const BusinessChallengesModal = ({
  handleClose,
  show,
  code,
  personas,
  cleanStore,
}) => {
  const [selectedValues, setSelectedValues] = useState({});
  const [cardDescription, setCardDescription] = useState("");
  const [loader, setLoader] = useState(false);
  const [canPublish, setCanPublish] = useState(false);
  const { trackEvent } = useTracking();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    errors,
    reset,
    getValues,
    watch,
  } = useForm();

  const watchDescription = watch("description");

  const handleOption = (selectName, option) => {
    setSelectedValues({ ...selectedValues, [selectName]: option });
  };

  const handleRemoveOptionValue = (inputName, value = null) => {
    setSelectedValues({
      ...selectedValues,
      [inputName]: value
        ? selectedValues[inputName].filter(v => v !== value)
        : undefined,
    });
  };

  const onSubmit = data => {
    setLoader(true);
    if (!code) {
      delete data.progressive;
      save(data)
        .then(response => {
          if (response?.data) {
            trackEvent({
              code,
              action: auditActions.EDIT_AND_PUBLISH,
              resourceType: entityType.BUSINESS_CHALLENGE,
              type: auditTypes.ACTION,
            });
            handleClose();
          } else {
            console.error(
              `Warning! Error uploading content: ${response?.message.toString()}`
            );
          }
          setLoader(false);
        })
        .catch(error => {
          console.error(`BusinessChallengesModal: ${error}`);
          setLoader(false);
        });
    } else {
      data.code = code;
      data.progressive = parseInt(data.progressive);
      update(code, data)
        .then(response => {
          if (response?.data) {
            trackEvent({
              code: code,
              action: auditActions.EDIT_AND_PUBLISH,
              resourceType: entityType.BUSINESS_CHALLENGE,
              type: auditTypes.ACTION,
            });
            cleanStore({ code, documentType: entityType.BUSINESS_CHALLENGE });
            handleClose();
          } else {
            console.error(
              `Warning! Error uploading content: ${response?.message.toString()}`
            );
          }
          setLoader(false);
        })
        .catch(error => {
          console.error(`BusinessChallengesModal: ${error}`);
          setLoader(false);
        });
    }
  };

  const sectionHeaders = {
    main_data: useRef(null),
  };

  const [sectionStates, setSectionStates] = useState({
    main_data: "active",
  });

  useEffect(() => {
    if (show && code) {
      reset();
      getBusinessChallenge(code).then(res => {
        const data = res?.data;
        let locSelectedValues = selectedValues;
        Object.keys(data).forEach(k => {
          if (multipleCustomSelectFields.includes(k)) {
            locSelectedValues[k] = data[k];
          } else setValue(k, data[k]);
        });
        const desc =
          data?.description?.substr(0, 80) +
          (data?.description?.length > 80 ? "..." : "");
        setCardDescription(desc);
      });
    } else if (show && !code) {
      setSelectedValues({});
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, show]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (show) {
        let states = {};
        let foundActive = false;
        let sectionNames = Object.keys(sectionHeaders);
        for (let i = sectionNames.length - 1; i >= 0; i--) {
          let pos =
            sectionHeaders[sectionNames[i]].current?.getBoundingClientRect().y;
          if (pos < window.innerHeight / 2 || i === 0) {
            if (!foundActive) {
              states[sectionNames[i]] = "active";
              foundActive = true;
            } else states[sectionNames[i]] = "completed";
          } else states[sectionNames[i]] = null;
        }
        setSectionStates({ ...sectionStates, ...states });
      }
    }, 300);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    let publishable = true;
    mandatoryMultipleFields.forEach(f => {
      publishable = publishable && selectedValues[f]?.length > 0;
    });

    publishable = publishable && !!getValues("description");
    setCanPublish(publishable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDescription, selectedValues]);

  return (
    show && (
      <div className="modal">
        <section className="modal-main">
          <div className="bo-header">
            <h1 className="bo-title">{code ? "Editing of" : "Creating of"}</h1>
            <h1 className="bo-title-value">
              {code ? cardDescription.toString() : "New Business Challenge"}
            </h1>

            <div className="bo-header-close" onClick={handleClose}>
              <img src={CloseIcon} alt="" />
            </div>
          </div>
          <div className="bo-container">
            <div className="legend">
              <div>
                <label>
                  <span>*</span>mandatory for saving
                </label>
              </div>
            </div>
            <Step sectionStates={sectionStates} type="businessChallenges" />
            <form className="bo-form-container bo-offering-form">
              <div
                className="bo-form-section-title"
                ref={sectionHeaders.unique_code}>
                1. MAIN DATA
              </div>
              <div className="bo-form-section">
                <div className="bo-form-subtitle">
                  Business Challenge unique code
                </div>
                <div className="bo-form-input-group">
                  {code && (
                    <div className="bo-form-input-container">
                      <label>
                        progressive number<span>*</span>
                      </label>
                      <input
                        name="progressive"
                        type="number"
                        ref={register}
                        readOnly
                      />
                      <div className="bo-form-code">
                        <div className="bo-form-code-label">
                          Unique code generated:
                        </div>
                        <div className="bo-form-code-value">{code}</div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="bo-form-input-container">
                  <label className="bo-large-label">
                    Business Challenge description<span>*</span>
                    <span className="bo-input-counter">
                      {getValues("description")?.length || 0}/255
                    </span>
                  </label>
                  <textarea
                    name="description"
                    placeholder="Write Business Challenge description here..."
                    rows={10}
                    maxLength={255}
                    onChange={event =>
                      checkField("description", event, 255, setError)
                    }
                    onFocus={event =>
                      checkField("description", event, 255, setError)
                    }
                    ref={register({ maxLength: 255 })}
                  />
                  {errors.description && errors.description.message && (
                    <p>{errors.description.message}</p>
                  )}
                </div>
                <div className="bo-form-input-group">
                  <div className="bo-form-input-container">
                    <label>Business Challenge keywords</label>
                    <TagsInput
                      label="Hit enter to add each keyword"
                      value={selectedValues["tags"] || []}
                      onChange={val => handleOption("tags", val)}
                      icon={"add-icon"}
                      id="input-with-icon__input-bc"
                    />
                    {selectedValues["tags"] &&
                      selectedValues["tags"].map((v, i) => (
                        <input
                          key={i}
                          type="hidden"
                          name={"tags." + i}
                          ref={register}
                          value={v}
                        />
                      ))}
                    {selectedValues["tags"] && (
                      <div className="bo-form-chip-list">
                        {selectedValues["tags"].map((v, i) => (
                          <div key={i} className="bo-form-chip">
                            {v}
                            <img
                              className="chip-icon"
                              src={CloseIcon}
                              alt=""
                              onClick={() => handleRemoveOptionValue("tags", v)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="bo-form-input-container">
                  <label>
                    client personas<span>*</span>
                  </label>
                  <CustomSelect
                    label=""
                    value={selectedValues["personasIds"] || []}
                    items={personas.map(o => ({ id: o.id, value: o.name }))}
                    onChange={val => handleOption("personasIds", val)}
                    isMultiple
                    isAutocomplete
                  />
                  {selectedValues["personasIds"] &&
                    selectedValues["personasIds"].map((v, i) => (
                      <input
                        key={i}
                        type="hidden"
                        name={"personasIds." + i}
                        ref={register}
                        value={
                          personas.find(p =>
                            Number(v) ? p.id === v : p.name === v
                          )?.id
                        }
                      />
                    ))}
                  {selectedValues["personasIds"] && (
                    <div className="bo-form-chip-list">
                      {selectedValues["personasIds"].map((v, i) => (
                        <div key={i} className="bo-form-chip">
                          {Number(v)
                            ? personas?.find(p => p.id === v)?.name
                            : v}
                          <img
                            className="chip-icon"
                            src={CloseIcon}
                            alt=""
                            onClick={() =>
                              handleRemoveOptionValue("personasIds", v)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </form>
            <div className="bo-submit-container">
              <button
                className="bo-button"
                disabled={!canPublish || loader}
                onClick={() => {
                  handleSubmit(onSubmit)();
                }}>
                {loader
                  ? "Saving Business Challenge"
                  : "Submit Business Challenge"}
              </button>
            </div>
          </div>
        </section>
      </div>
    )
  );
};

export default connect(
  ({ personas }) => ({ personas }),
  () => ({})
)(BusinessChallengesModal);
