import React from "react";
import { useTypography } from "components/Typography";
import "./style.scss";

const ContactCard = ({ title, description, email }) => {
  const { HeadingTwo, Regular, LinkUnderlinedBold } = useTypography();
  return (
    <div className="contact-card-container">
      {title && <HeadingTwo>{title}</HeadingTwo>}
      {description && <Regular>{description}</Regular>}
      {email && <LinkUnderlinedBold href={`mailto:${email}`}>{email}</LinkUnderlinedBold>}
    </div>
  );
};

export default ContactCard;
