import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const Avatar = ({ profile }) => {
  const { photo, name, surname } = profile || {};
  const getIntial = (str = "") => {
    return str.slice(0, 1).toUpperCase();
  };

  return (
    <div className="avatar-container">
      {photo ? (
        <img
          src={"data:image/png;base64," + photo}
          className="avatar-img"
          alt={`${name} ${surname}`}
        />
      ) : (
        <div className="avatar-placeholder">{`${getIntial(name)} ${getIntial(
          surname
        )}`}</div>
      )}
    </div>
  );
};

Avatar.propTypes = {
  profile: PropTypes.object,
};

export default Avatar;
