import { createSlice } from "@reduxjs/toolkit";
import { entityType } from "utils/commons";

export const initialContributor = {
  [entityType.QUALIFICATION]: {},
  [entityType.OFFERING_TOPIC]: {},
  [entityType.BUSINESS_CHALLENGE]: {},
};

const contributor = createSlice({
  name: "@@CONTRIBUTOR",
  initialState: initialContributor,
  reducers: {
    SET_CONTRIBUTOR: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: {},
});

export const { SET_CONTRIBUTOR } = contributor.actions;

export default contributor.reducer;
