import React, { useCallback, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useProfileContext } from "../../../contexts/Profile";
import { useTreeContext } from "../../../contexts/Tree";
import CloseIcon from "../../../images/cancel.svg";
import { SET_CONTRIBUTOR } from "../../../redux/contributor";
import routes from "../../../routes";
import { getOfferingTopicByOrganizationalUnitId } from "../../../services/offeringTopic";
import { entityType } from "../../../utils/commons";
import ArrowUp from "../../ArrowUp";
import Drag from "../../Drag";
import Drop from "../../Drop";

import "./style.scss";

const TreeLevelViewModal = ({ handleClose, coe, type }) => {
  const dispatch = useDispatch();
  const updateContributor = payload => dispatch(SET_CONTRIBUTOR(payload));

  const {
    modalProps: { industryName },
  } = useSelector(state => state.modal);

  const [isLoading, setIsLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [showButton, setShowButton] = useState(false);

  const { canLike, isEditor, canEditOfferings } = useProfileContext();
  const history = useHistory();

  const { matches: isMobile } = window.matchMedia("(max-width: 912px)");

  const elRef = useRef();

  const { isDidMount, setIsDidMount, setIsTreeLevelViewModalOpen } =
    useTreeContext();

  const handleScroll = () => {
    if (elRef.current?.scrollTop >= 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToComponent = (id, ref) => {
    if (
      isMobile &&
      ref?.current?.id.toUpperCase() === id.toUpperCase() &&
      isDidMount
    ) {
      ref?.current?.scrollIntoView({ behavior: "smooth" });
      ref?.current?.focus();
    }
  };

  const handleArrowUpClick = useCallback(() => {
    elRef.current?.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleCloseIcon = useCallback(() => {
    handleClose();
    setIsDidMount(false);
    setIsTreeLevelViewModalOpen(false);
  }, [handleClose, setIsDidMount, setIsTreeLevelViewModalOpen]);

  useEffect(() => {
    const currentRef = elRef.current;
    if (elRef && currentRef) {
      elRef.current?.addEventListener("scroll", handleScroll);
    }
    return () => {
      currentRef?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (coe?.id && !isLoading) {
      setIsLoading(true);
      try {
        (async () => {
          const res = await getOfferingTopicByOrganizationalUnitId(coe?.id);
          if (res?.data && isMounted) {
            setDataList(res.data);
            setIsLoading(false);
          }
          updateContributor({
            [entityType.OFFERING_TOPIC]: { selectedCoe: coe },
          });
        })();
      } catch (err) {
        console.error("Error Drop: ", err);
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coe?.id]);

  return (
    <div className="tree-view-modal">
      <section className="modal-main" ref={elRef} id="main">
        <div className="modal-header">
          <h1 className="modal-title">
            Offering Levels <span>(display mode)</span>
          </h1>
          <div className="modal-header-close" onClick={handleCloseIcon}>
            <img src={CloseIcon} alt="CloseIcon" />
          </div>
        </div>
        <div className="modal-container">
          <div className="contributor-description">
            <p>{coe?.shortDescription}</p>
          </div>
          {isEditor && canEditOfferings && !isMobile ? (
            <div className="contributor-buttons_container">
              <div className="action">
                <div
                  className={`add-item`}
                  onClick={() => {
                    handleClose();
                    history.push(routes.CONTRIBUTOR_OFFERINGS);
                  }}>
                  TO MAINTAIN THE OFFERING/HIERARCHY ASSOCIATIONS,
                  <br />
                  CLICK HERE
                </div>
              </div>
            </div>
          ) : null}
          {isLoading ? (
            <Skeleton width={"100%"} height={"45vw"} />
          ) : (
            <div className="results-container">
              <Drop
                selectedCoe={coe}
                total={dataList?.length}
                dataList={dataList}
                industryName={industryName}
              />
              <Drag
                dataList={dataList}
                isContributor={true}
                documentType={type}
                total={dataList?.length}
                readOnly={true}
                onCardSelect={handleClose}
                isModal={true}
                canLike={canLike}
                elRef={elRef}
                scrollToComponent={scrollToComponent}
              />
            </div>
          )}
        </div>
      </section>
      {isMobile && showButton ? <ArrowUp onClick={handleArrowUpClick} /> : null}
    </div>
  );
};

export default React.memo(TreeLevelViewModal);
