import { serviceInterface } from "./config";

export const getAllErrors = (params) => {
  const paramsNames = Object.keys(params);
  let queryParams = "?";
  paramsNames.forEach(param => {
    if (Array.isArray(params[param])) {
      params[param].forEach(el => {
        queryParams = `${queryParams}${param}[]=${el}&`;
      })
    } else {
      queryParams = `${queryParams}${param}=${params[param]}&`;
    }
  })
  return serviceInterface.get(
    `document-issue/summary${queryParams}`
  );
};

export const exportErrors = async (params) => {
  const paramsNames = Object.keys(params);
  let queryParams = "?";
  paramsNames.forEach(param => {
    if (Array.isArray(params[param])) {
      params[param].forEach(el => {
        queryParams = `${queryParams}${param}[]=${el}&`;
      })
    } else {
      queryParams = `${queryParams}${param}=${params[param]}&`;
    }
  })
  const response = await serviceInterface.get(
    `document-issue/export${queryParams}`, {responseType: 'blob'}
  );
  if (response?.data) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${params['contentType'][0]}_qualityManagement.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
};
