import { apis, serviceInterface } from "./config";

export const getUser = id => {
  return serviceInterface.get(`${apis.USER}/${id ? "/" + id : ""}`);
};

export const getAllUsers = (params) => {
  // params keys:
  // search, pageSize, pageOffset, groups, organizationalUnitId, creationDateFrom,creationDateTo,sortDirection,sortCriteria
  const paramsNames = Object.keys(params);
  let queryParams = "?";
  paramsNames.forEach(param => {
    if (param !== 'groups') {
      queryParams = `${queryParams}${param}=${params[param]}&`;
    }
  })
  if(params.groups) {
    params.groups.forEach(group => {
      queryParams = `${queryParams}groups[]=${group}&`;
    })
  }
  return serviceInterface.get(
    `${apis.USER}/${queryParams}`
  );
};

export const getUserByEmail = email => {
  return serviceInterface.get(`${apis.USER}/${email ? "?search=" + email : ""}`, {params:{pageOffset: 0, pageSize: 2}});
};

export const postUser = params => {
  return serviceInterface.post(apis.USER, { ...params });
};

export const deleteUser = id => {
  return serviceInterface.delete(`${apis.USER}/${id}`);
};

export const putUser = ({ id, ...data }) => {
  delete data['createdBy'];
  delete data['createdDate'];
  delete data['lastModifiedBy'];
  delete data['lastModifiedDate'];
  delete data['isSynced'];
  return serviceInterface.put(`${apis.USER}/${id}`, { ...data });
};

export const getUserImage = id => {
  return serviceInterface.post(`${apis.POST}/${id}`);
};
