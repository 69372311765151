import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GET_ALL_USERS } from "redux/users";
import moment from "moment";
import { filtersMyracle } from "utils/commons";
import {
  getGroupIdsByGroupsNames,
  getOrganizationalUnitsIdsByCode,
} from "utils/utils";
import Filters from "components/Filters";
import SelectedFilters from "components/SelectedFilters";
import UserDetail from "components/UserDetail";
import TableUser from "components/Tables/TableUser";
import SearchIcon from "images/search.svg";
import useDebounce from "../../hooks/useDebounce";
import "./style.scss";

const UsersManagement = ({ getUsers, users, groups, organizationalUnits }) => {
  const [loading, setLoading] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    order_by: "ALPHABETIC",
  });
  const [rangeError, setRangeError] = useState();

  const viewableFilters = filtersMyracle.ADMIN_USER;

  const debouncedValue = useDebounce(search, 800);

  const handleChange = event => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    if (viewableFilters) setShowFilters(viewableFilters.length > 0);
  }, [viewableFilters]);

  const handleResetFilters = () =>
    setSelectedFilters({ order_by: "ALPHABETIC" });

  const filterDateFrom = date => {
    const now = new Date();
    const isBeforeNow = moment(date).isSameOrBefore(now);
    let isBeforeDateTo = true;
    if (selectedFilters.create_date_to) {
      isBeforeDateTo = moment(date).isSameOrBefore(
        selectedFilters.create_date_to
      );
    }
    return isBeforeNow && isBeforeDateTo;
  };

  const filterDateTo = date => {
    const now = new Date();
    const isBeforeNow = moment(date).isSameOrBefore(now);
    let isAfterDateFrom = true;
    if (selectedFilters.create_date_from) {
      isAfterDateFrom = moment(date).isSameOrAfter(
        selectedFilters.create_date_from
      );
    }
    return isBeforeNow && isAfterDateFrom;
  };

  const prepareFilters = ({
    order_by,
    group,
    organizational_unit,
    create_date_from,
    create_date_to,
  }) => {
    // filters keys:
    // groups, organizationalUnitId, creationDateFrom, creationDateTo, sortDirection, sortCriteria
    let params = {};
    params.sortCriteria = order_by;
    params.sortDirection = "ASC";
    if (group && group.length > 0) {
      params.groups = getGroupIdsByGroupsNames(groups, group);
    }
    if (organizational_unit && organizational_unit.length > 0) {
      params.organizationalUnitId = getOrganizationalUnitsIdsByCode(
        organizationalUnits,
        organizational_unit
      );
    }
    if (create_date_from) {
      params.creationDateFrom = moment(create_date_from)
        .toISOString()
        .replace(".000", "");
      if (!create_date_to) {
        const now = new Date();
        params.creationDateTo = moment(now).toISOString().replace(".000", "");
      }
    }
    if (create_date_to) {
      params.creationDateTo = moment(create_date_to)
        .toISOString()
        .replace(".000", "");
    }
    return params;
  };

  useEffect(() => {
    let isMounted = true;
    setRangeError(undefined);
    if (
      !(!selectedFilters.create_date_from && selectedFilters.create_date_to)
    ) {
      setLoading(true);
      const filters = prepareFilters(selectedFilters);
      getUsers({ pageSize, pageOffset, search, ...filters })
        .then(res => {
          if (!isMounted) return;
          setTotalPages(res.payload.totalPages);
          setPageOffset(res.payload.currentPage);
          setLoading(false);
        })
        .catch(e => {
          if (!isMounted) return;
          console.log(e);
          setLoading(false);
        });
    } else {
      setRangeError("Select initial date");
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageOffset,
    setTotalPages,
    setPageOffset,
    selectedFilters,
    pageSize,
    debouncedValue,
  ]);

  const handleChangeFilter = (key, value) => {
    setSelectedFilters({
      ...selectedFilters,
      [key]: value,
    });
    setPageOffset(0);
  };

  return (
    <div className="container contributor admin">
      <div className="contributor-header ">
        <div className="contributor-banner">
          <span>ADMIN MODE</span>
        </div>
        <div className="title">Users Management</div>
        <div className="action">
          <div className={`add-item`} onClick={() => setIsOpenUserModal(true)}>
            ADD NEW USER
          </div>
        </div>
        <div className="search">
          <div className="search-bar-container">
            <div className="search-icon">
              <img src={SearchIcon} alt="lens" />
            </div>
            <input
              type="text"
              className="search-bar"
              value={search || ""}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="results-container no-margin">
        {showFilters && (
          <div
            className={`side-filter-bar ${openFilters ? "open-filter" : ""}`}>
            <Filters
              handleChangeFilter={handleChangeFilter}
              viewableFilters={viewableFilters}
              selectedFilters={selectedFilters}
              closeFilters={() => setOpenFilters(false)}
              numberItems={users?.length}
              handleResetFilters={handleResetFilters}
              filterDateFrom={filterDateFrom}
              filterDateTo={filterDateTo}
              rangeError={rangeError}
              isCoeMultiple={false}
            />
          </div>
        )}
        <div className="results users-management-results">
          {showFilters && (
            <SelectedFilters
              selectedFilters={selectedFilters}
              handleChangeFilter={handleChangeFilter}
              elementsCount={users?.length || 0}
              openFilters={() => setOpenFilters(!openFilters)}
              userOrder={true}
            />
          )}
          <TableUser
            loading={loading}
            data={users}
            currentPage={pageOffset}
            setCurrentPage={setPageOffset}
            pageSize={pageSize}
            totalPages={totalPages}
            setPageSize={value => {
              setPageOffset(0);
              setPageSize(value);
            }}
          />
        </div>
      </div>
      <UserDetail
        isOpenProp={isOpenUserModal}
        onClose={() => setIsOpenUserModal(false)}
      />
    </div>
  );
};

export default connect(
  ({ admin, users, groups, organizationalUnits }) => ({
    admin,
    users,
    groups,
    organizationalUnits,
  }),
  dispatch => ({
    getUsers: params => dispatch(GET_ALL_USERS(params)),
  })
)(UsersManagement);
