import React from "react";

import PropTypes from "prop-types";

import "./style.scss";


const TitleContainerWrapper = ({ blueVariant, grayVariant, children }) => {
  return (
    <div
      className={`title-container-wrapper ${blueVariant ? "blue-image" : ""} ${
        grayVariant ? "gray-image" : ""
      }`}>
      {children}
    </div>
  );
};

TitleContainerWrapper.propTypes = {
  blueVariant: PropTypes.bool,
  grayVariant: PropTypes.bool,
};

export default TitleContainerWrapper;
