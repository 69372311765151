import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOrganizationalUnit } from "services/organizationalUnit";
import { getRelated } from "services/organizationalUnit";
import BackSave from "components/BackSave";
import CardContact from "components/CardContact";
import LegendButton from "components/LegendButton";
import RelatedCardList from "components/RelatedCardList";
import BipImage from "images/Bip unit icon.svg";
import Drop from "../../components/Drop";
import ImageCustomer from "../../components/ImageCustomer";
import { TreeProvider, useTreeContext } from "../../contexts/Tree";
import useRelatedData from "../../hooks/useRelatedData";
import { getIndustryById } from "../../redux/industryTree";
import { TRIGGER_MODAL } from "../../redux/modal";
import { getBusinessChallenge } from "../../services/businessChallenge";
import { getOfferingTopic } from "../../services/offeringTopic";
import { entityType } from "../../utils/commons";
import { getIndustry } from "../../utils/utils";

import "./style.scss";

const IndustryDetail = ({
  updateModalProps,
  type = entityType.TREE_LEVEL_VIEW,
  industries,
}) => {
  const [industry, setIndustry] = useState();
  const [related, setRelated] = useState({});
  const { industryId } = useParams();
  const { isTreeLevelViewModalOpen, setIsTreeLevelViewModalOpen } =
    useTreeContext();

  const { businessChallengeCodes, offeringTopicCodes } = related || {};
  const {
    shortDescription,
    longDescription,
    mission,
    icon,
    name,
    tags,
    projectTypes,
    spocs,
    achievements,
    customers,
    id,
    subIndustryId,
  } = industry || {};

  const industryItem = useSelector(state =>
    getIndustryById(state, subIndustryId)
  );

  const industryName = getIndustry(industries, industryItem)?.name;
  const unitsColorMap = useSelector((state) => state.unitsColor);

  const { data: offeringCardData, loadMore: loadMoreOffering } = useRelatedData(
    offeringTopicCodes,
    getOfferingTopic,
    "BipUnitDetail error: "
  );

  const { data: bcCardData, loadMore: loadMoreBc } = useRelatedData(
    businessChallengeCodes,
    getBusinessChallenge,
    "BipUnitDetail error: "
  );

  const showModal = () => {
    updateModalProps({
      isOpen: true,
      modalProps: {
        type,
        code: id,
        versionType: type,
        selectedCoe: industry,
        industryName,
      },
    });
  };

  const handleOnClick = () => {
    showModal();
    setIsTreeLevelViewModalOpen(true);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (!industry) {
      getOrganizationalUnit(industryId).then(res1 => {
        if (res1.data) {
          setIndustry(res1.data);
          getRelated(industryId).then(res2 => {
            if (res2.data) setRelated(res2.data);
          });
        }
      });
    }
  }, [industry, industryId]);

  useEffect(() => {
    if (isTreeLevelViewModalOpen && id && type && industry) {
      showModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type, industry]);

  return (
    <TreeProvider>
      <div className="container detail">
        <div className="sidebar-color" style={{backgroundColor: `#${unitsColorMap['SUBINDUSTRY'].color}`}}></div>
        <BackSave />
        {!industry ? (
          <Skeleton width={"100%"} height={"45vw"} />
        ) : (
          <>
            <div className="bipunit-header">
              <div className="logo-img">
                {icon ? (
                  <img src={`data:image/png;base64,${icon}`} alt={name} />
                ) : (
                  <img src={BipImage} alt={name} />
                )}
              </div>
              <div className="title-section">
                <div className="type">INDUSTRY</div>
                <div className="title">{name}</div>
              </div>
            </div>
            <div className="bipunit-wrapper">
              <div>
                <div className="bipunit-description">
                  <div
                    className="subtitle CKEditor-container"
                    id="rich-description1">
                    {shortDescription}
                  </div>
                  <div className="tags">
                    {(tags || []).map((tag, index) => (
                      <span key={`${index}-${tag}`} className="tag">
                        #{tag}
                      </span>
                    ))}
                  </div>
                  <div
                    className="desc CKEditor-container"
                    id="rich-description2">
                    {longDescription}
                  </div>
                </div>
                <div className="bipunit-mission">
                  <div className="title">MISSION</div>
                  <div className="mission">{mission}</div>
                </div>
                {projectTypes && projectTypes.length > 0 && (
                  <div className="bipunit-types">
                    {projectTypes.map((type, index) => (
                      <div key={`${index}-${type}`} className="type">
                        <span>{type}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="bipunit-drop-container top">
                <div className="bipunit-drop">
                  <div className="bipunit-drop-header">OFFERING LEVELS</div>

                  <Drop
                    selectedCoe={industry}
                    readOnly={true}
                    industryName={industryName}
                  />
                  <div className="bipunit-drop-description">
                    For more info ...
                  </div>
                  <div
                    className="action"
                    onClick={() => {
                      return type === entityType.TREE_LEVEL_VIEW
                        ? handleOnClick()
                        : null;
                    }}>
                    }>
                    <div className="view-more">VIEW MORE</div>
                  </div>
                </div>
                {spocs?.length > 0 && (
                  <div className="bipunit-references">
                    <div className="title">REFERENCES</div>
                    {spocs.map(sp => (
                      <CardContact key={sp.userId} id={sp.userId} />
                    ))}
                  </div>
                )}
              </div>
            </div>

            {achievements.length > 0 && (
              <div className="bipunit-achievements">
                <div className="title">KEY NUMBERS</div>
                <div className="archs">
                  {achievements.map((arch, index) => (
                    <div key={index} className="arch">
                      <div className="value">{arch.value}</div>
                      <div className="description">{arch.description}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="bipunit-wrapper">
              <div className="bipunit-offering-topics">
                {/* <div className="title">OFFERING TOPICS</div>
              <div className="text">
                With online sales becoming the major battleground for fashion,
                too, Benetton had to increase the performance of its ecommerce
                site: how do you convey a sense of physical quality that
                justifies a higher price in an entirely digital space? We
                redesigned the online shopping funnel to glorify the product.
                See more
              </div> */}
              </div>

              {customers.length > 0 && (
                <div className="bipunit-main-clients">
                  <div className="title">MAIN CLIENTS</div>
                  <div className="img-customers-container">
                    {customers.map(imgName => (
                      <ImageCustomer key={imgName} name={imgName} />
                    ))}
                  </div>
                </div>
              )}
            </div>
            {businessChallengeCodes &&
            businessChallengeCodes?.length &&
            bcCardData?.length ? (
              <RelatedCardList
                elemList={businessChallengeCodes}
                documentType={entityType.BUSINESS_CHALLENGE}
                data={bcCardData}
                onGoRight={loadMoreBc}
              />
            ) : null}

            {offeringTopicCodes &&
            offeringTopicCodes.length > 0 &&
            offeringCardData?.length ? (
              <RelatedCardList
                elemList={offeringTopicCodes}
                documentType={entityType.OFFERING_TOPIC}
                data={offeringCardData}
                onGoRight={loadMoreOffering}
              />
            ) : null}
          </>
        )}
      </div>
      {unitsColorMap &&
        <LegendButton legend={{SUBINDUSTRY: unitsColorMap['SUBINDUSTRY']}}/>
      }
    </TreeProvider>
  );
};

export default connect(
  ({ modal, industries }) => ({
    modal,
    industries,
  }),
  dispatch => ({
    updateModalProps: payload => dispatch(TRIGGER_MODAL(payload)),
  })
)(IndustryDetail);
