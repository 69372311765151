import React from "react";
import { useTypography } from "components/Typography";
import "./style.scss";

const HowToSection = ({
                        id,
                        type,
                        title,
                        subtitle,
                        sticker,
                        content,
                      }) => {
  const { Type, HeadingOne, Regular } = useTypography();
  return (
    <div
      className={(sticker) ? "how-to__section how-to__section-with-stickers" : "how-to__section"}
      id={id}>
      <div className="how-to__section__intro">
        <div className="how-to__section__intro__content">
          <Type>{type}</Type>
          <HeadingOne>{title}</HeadingOne>
        </div>
        {sticker?.image && (
          <img
            className="how-to__section__sticker-end"
            src={sticker.image}
            alt={sticker.image}
            height={sticker.height}
            width={sticker.width} />
        )}
      </div>
      <Regular>{subtitle}</Regular>
      <div className="how-to__section__content">
        {content && content}
      </div>
    </div>
  );
};

export default HowToSection;
