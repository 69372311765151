import { apis, serviceInterface } from "./config";

export const getOfferingDeck = id => {
  return serviceInterface.get(`${apis.OFFERING_DECK}/${id}`);
};

export const getOfferingDeckByOrganizationalUnitId = id => {
  return serviceInterface.get(
    `${apis.OFFERING_DECK}?organizationalUnitId=${id}&no_graphic=true`
  );
};

export const addOfferingDeck = params => {
  return serviceInterface.post(apis.OFFERING_DECK, { ...params });
};
