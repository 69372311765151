import React, { createContext, useContext } from "react";
import { useSelector } from "react-redux";
import { getProfileInfo } from "../../utils/profile";

const initialContextState = {};

const ProfileContext = createContext(initialContextState);

const useProfileContext = () => useContext(ProfileContext);

const ProfileProvider = ({ children }) => {
  const profile = useSelector(state => state.profile);
  const organizationalUnits = useSelector(state => state.organizationalUnits);

  const profileInfo = getProfileInfo(profile);
  const { canLike, canComment, isAdmin, isEditor, isViewer, isDownloader } =
    getProfileInfo(profile);
  const canEditOfferings = profileInfo.canEditOfferings(organizationalUnits);
  const canEditQuals = profileInfo.canEditQuals(organizationalUnits);

  return (
    <ProfileContext.Provider
      value={{
        canEditOfferings,
        canEditQuals,
        canLike,
        canComment,
        isAdmin,
        isEditor,
        isViewer,
        isDownloader,
      }}>
      {children}
    </ProfileContext.Provider>
  );
};

export { ProfileProvider, useProfileContext };
