import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { getAllUsers } from "../services/user";

export const GET_ALL_USERS = createAsyncThunk(
  "@@USERS/GET_ALL_USERS",
  async (params, thunkAPI) => {
    const response = await getAllUsers(params);
    return { ...response.data};
  }
);

const users = createSlice({
  name: "@@USERS",
  initialState: {},
  reducers: {
    MODIFY_USER: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          [action.payload.id]: action.payload,
        };
      } else {
        return state;
      }
    },},
  extraReducers: {
    [GET_ALL_USERS.fulfilled]: (state, action) => {
      const { payload: {users} } = action;
      const reducerData = {};
      users.forEach((el, index) => {
         reducerData[el.id] = { ...el, orderIndex: index };
      })
      return users
        ? {
          ...reducerData,
        }
        : { ...state };
    },
  },
});

export const { MODIFY_USER } = users.actions;

export default users.reducer;
