import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTracking } from "react-tracking";
import { TRIGGER_MODAL } from "redux/modal";
import routes from "routes";
import {
  deleteBusinessChallenge,
  getBusinessChallenge,
} from "services/businessChallenge";
import { auditActions, auditTypes, entityType } from "utils/commons";
import BackSave from "components/BackSave";
import Comments from "components/Comments";
import RelatedCardList from "components/RelatedCardList";
import LikeImage from "images/like.svg";
import LikeImageFull from "images/like_reverse.svg";
import { useProfileContext } from "../../contexts/Profile";
import useRelatedData from "../../hooks/useRelatedData";
import { getLikes } from "../../services/like";
import { getOfferingTopic } from "../../services/offeringTopic";

import "./style.scss";

const BusinessChallengeDetail = () => {
  const dispatch = useDispatch();
  const updateModalProps = payload => dispatch(TRIGGER_MODAL(payload));

  const personas = useSelector(state => state.personas);

  const { trackEvent } = useTracking();
  const [businessChallenge, setBusinessChallenge] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [totalComments, setTotalComments] = useState();
  const [totalLikes, setTotalLikes] = useState(0);
  const [myLike, setMyLike] = useState(false);

  const { businessChallengeCode } = useParams();
  const history = useHistory();
  const { isEditable } = useLocation().state || {};

  const { canLike } = useProfileContext();
  const { offeringTopicsCodes } = businessChallenge || [];

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { data } = (await getLikes(businessChallengeCode)) || {};
        setMyLike(data?.myLike);
        setIsLoading(false);
      } catch (error) {
        console.error(`BusinessChallengeDetail getLikes error: ${error}`);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessChallengeCode]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { data } =
          (await getBusinessChallenge(businessChallengeCode)) || {};
        setTotalLikes(data?.totalLikes);
        setBusinessChallenge(data);
        setIsLoading(false);
      } catch (error) {
        console.error(
          `BusinessChallengeDetail getBusinessChallenge error: ${error}`
        );
        setIsLoading(false);
        history.push(routes.BUSINESS_CHALLENGE);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessChallengeCode]);

  const setCallbackLikes = (total, myLike) => {
    setMyLike(myLike);
    setTotalLikes(total);
  };

  const { data: offeringCardData, loadMore: loadMoreOffering } = useRelatedData(
    offeringTopicsCodes,
    getOfferingTopic,
    "BusinessChallengeDetail error: "
  );

  return (
    <div className="container detail">
      <BackSave
        isEditable={isEditable}
        editFunction={() =>
          updateModalProps({
            isOpen: true,
            modalProps: {
              code: businessChallenge?.code,
              coe: "",
              type: entityType.BUSINESS_CHALLENGE,
            },
          })
        }
        deleteFunction={() => {
          deleteBusinessChallenge(businessChallengeCode).then(() => {
            trackEvent({
              action: auditActions.DELETE,
              code: businessChallengeCode,
              type: auditTypes.ACTION,
              resourceType: entityType.OFFERING_TOPIC,
            });
            history.push("/business-challenges/");
          });
        }}
      />
      {!businessChallenge && !isLoading ? (
        <Skeleton width={"100%"} height={"45vw"} />
      ) : (
        <>
          <div className="business-chall-wrapper">
            <div className="section-title">
              <div className="type">BUSINESS CHALLENGE</div>
              <div className="title">{businessChallenge?.description}</div>
              <div className="likes-comments-total-container">
                {canLike && totalLikes !== undefined && (
                  <>
                    <img
                      className={"like-image"}
                      src={myLike ? LikeImageFull : LikeImage}
                      alt="like"
                    />
                    <div className="black-dot">{`${totalLikes} likes •`}</div>
                  </>
                )}
                {totalComments && (
                  <div
                    className={"total-comments"}
                    onClick={() =>
                      document.getElementById("comments").scrollIntoView()
                    }>
                    {`${totalComments} comments`}
                  </div>
                )}
              </div>
              <div className="org-units">
                <div className="label">Personas: </div>
                {businessChallenge?.personasIds.length &&
                  businessChallenge?.personasIds.map((ouOff, index) => (
                    <div key={`${index}`} className="unit">
                      {personas?.find(ou => ou.id === ouOff)?.name}
                    </div>
                  ))}
              </div>
            </div>
            <div className="section-tags">
              {(businessChallenge?.tags || []).map((tag, index) => (
                <div key={`${index}-${tag}`} className="tag">
                  #{tag.trim()}
                </div>
              ))}
            </div>
          </div>

          {offeringTopicsCodes &&
          offeringTopicsCodes?.length &&
          offeringCardData?.length ? (
            <RelatedCardList
              elemList={offeringTopicsCodes}
              documentType={entityType.OFFERING_TOPIC}
              data={offeringCardData}
              onGoRight={loadMoreOffering}
            />
          ) : null}
          <Comments
            code={businessChallengeCode}
            setCallbackTotals={setTotalComments}
            contentType={entityType.BUSINESS_CHALLENGE}
            myLikeData={myLike}
            totalLikes={totalLikes}
            setCallbackLikes={setCallbackLikes}
          />
        </>
      )}
    </div>
  );
};
//quals, bc and offerings are necessary in connect to see immediate update in case of edit
export default React.memo(BusinessChallengeDetail);
