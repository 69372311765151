import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getMe } from "services/me";

export const GET_PROFILE = createAsyncThunk(
  "@@PROFILE/GET_PROFILE",
  async (code, thunkAPI) => {
    const state = thunkAPI.getState();
    if (Object.keys(state.profile).length === 0) {
      const response = await getMe();
      if (response?.data) {
        const organizationalUnit = state.organizationalUnits.find(
          x => x.id === response.data.organizationalUnitId
        );
        return { ...response?.data, organizationalUnit };
      }
    }
    return state.profile;
  }
);

const profile = createSlice({
  name: "@@PROFILE",
  initialState: {},
  extraReducers: {
    [GET_PROFILE.fulfilled]: (state, action) => {
      const { payload } = action;
      return payload;
    },
  },
});

export default profile.reducer;
