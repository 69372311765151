import React from "react";
import "./style.scss";

const TagsInput = ({ label, value = [], onChange, icon, id }) => {
  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      if (!value.includes(event.target.value)) {
        value.push(event.target.value);
        onChange(value);
      }
      event.target.value = "";
      event.preventDefault();
    }
  };

  const handleClick = event => {
    let nextValue = document.getElementById(id).value;
    if (nextValue) {
      value.push(nextValue);
      onChange(value);
      document.getElementById(id).value = "";
    }
    event.preventDefault();
  };

  return (
    <>
      {icon ? (
        <div className="input-with-icon__container">
          <input
            type="text"
            className="input-with-icon__input"
            id={id}
            onKeyDown={handleKeyDown}
            placeholder={label}
          />
          <div className="input-with-icon__icon" onClick={handleClick} />
        </div>
      ) : (
        <input type="text" onKeyDown={handleKeyDown} placeholder={label} />
      )}
    </>
  );
};

export default TagsInput;
