import { apis, serviceInterface } from "./config";

export const getDictionary = params => {
  return serviceInterface.get(apis.DICTIONARY, {
    params,
  });
};

export const getTechnicalReferenceContacts = () => {
  return serviceInterface.get('user/technicalReferenceContacts');
};
