import { connect } from "react-redux";
import { useTracking } from "react-tracking";
import { REMOVE_BOOKMARK } from "redux/bookmarks";
import { auditActions, auditTypes, entityType } from "utils/commons";
import { getProfileInfo } from "utils/profile";
import { checkDownload } from "utils/utils";
import Checked from "images/checked.svg";
import Trash from "images/trash.svg";
import Unchecked from "images/unchecked.svg";

const Qual = ({
  profile,
  handleClick,
  cardData,
  toggleChecked,
  organizationalUnits,
  removeBookmark,
  isChecked,
}) => {
  const { trackEvent } = useTracking();
  const handleRemoveBookmark = e => {
    e.stopPropagation();
    removeBookmark({
      documentType: entityType.QUALIFICATION,
      documentId: cardData?.code,
    });
    trackEvent({
      code: cardData?.code,
      action: auditActions.DELETE_BOOKMARK,
      resourceType: entityType.QUALIFICATION,
      type: auditTypes.ACTION,
    });
  };
  const isDownloadable = checkDownload(profile, cardData?.code);

  return (
    <div onClick={handleClick} className="bookmark-wrapper">
      <div className="bookmark-title-type">
        {isDownloadable && (
          <div
            className="checkbox-wrapper"
            onClick={e => {
              e.stopPropagation();
              toggleChecked();
            }}>
            <img src={isChecked ? Checked : Unchecked} alt="option-checkbox" />
          </div>
        )}
        <div className="type">{entityType.QUALIFICATION}</div>
      </div>
      <div className="bookmark-header">
        {cardData?.pptPreviews && cardData?.pptPreviews[0] && (
          <div className="bookmark-header-img">
            <img
              src={`data:image/png;base64,${cardData?.pptPreviews[0]}`}
              alt={cardData?.title}
            />
          </div>
        )}
      </div>
      <div className="bookmark-infos">
        <div className="organization">
          {organizationalUnits &&
            organizationalUnits.find(
              o => o.id === cardData?.organizationalUnitId
            )?.name}
        </div>
        <div className="name">{`${cardData?.title}`}</div>
      </div>
      <div className="bookmark-cta" onClick={e => handleRemoveBookmark(e)}>
        <img src={Trash} alt="delete" />
      </div>
      {getProfileInfo(profile).isUnitDownloader && !isDownloadable && (
        <div className="bookmark-warning">
          Not available for download under M/ASS
        </div>
      )}
    </div>
  );
};

export default connect(
  ({ organizationalUnits, profile }) => ({ organizationalUnits, profile }),
  dispatch => ({
    removeBookmark: params => dispatch(REMOVE_BOOKMARK(params)),
  })
)(Qual);
