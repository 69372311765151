import { apis, serviceInterface } from "./config";

export const search = params => {
  return serviceInterface.get(apis.SEARCH, {
    params: { sort_direction: "DESC", ...params },
  });
};

export const searchOfferingTopic = params => {
  return serviceInterface.get(apis.SEARCH_OFFERING_TOPIC, {
    params: { sort_direction: "DESC", ...params },
  });
};

export const searchQualification = params => {
  return serviceInterface.get(apis.SEARCH_QUALIFICATION, {
    params: { sort_direction: "DESC", ...params },
  });
};

export const searchBusinessChallenge = params => {
  return serviceInterface.get(apis.SEARCH_BUSINESS_CHALLENGE, {
    params: { sort_direction: "DESC", ...params },
  });
};
