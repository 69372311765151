import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useTracking } from "react-tracking";

import { TRIGGER_MODAL } from "redux/modal";

import { deleteOffering } from "services/offeringTopic";
import { publish } from "services/quals";
import { auditActions, auditTypes, entityType } from "utils/commons";
import { getProfileInfo } from "utils/profile";

import ArrowIcon from "images/arrow-left.png";
import ContentCard from "../ContentCard";
import "./style.scss";

const RelatedCardList = ({
  updateModalProps,
  editScope,
  documentType,
  data,
  onGoRight,
  profile,
}) => {
  const [scroll, setScroll] = useState(0);
  const [title, setTitle] = useState("RELATED");
  const [scrollActive, setScrollActive] = useState(false);
  const [scrollStep, setScrollStep] = useState(0);
  const [maxScroll, setMaxScroll] = useState(0);
  const cardList = useRef(null);
  const [viewables, setViewables] = useState([]);
  const { trackEvent } = useTracking();

  const { canLike } = getProfileInfo(profile);

  const organizationalUnits = useSelector(state => state.organizationalUnits);
  const unitsColorMap = useSelector(state => state.unitsColor);

  useEffect(() => {
    if (Array.isArray(data) && data?.length) {
      setViewables(data);
    }
  }, []);

  useEffect(() => {
    let typeTitle = "";
    if (documentType) {
      switch (documentType) {
        case "QUALIFICATION":
          typeTitle += "QUALS";
          break;
        case "OFFERING_TOPIC":
          typeTitle += "OFFERINGS";
          break;
        case "BUSINESS_CHALLENGE":
          typeTitle += "BUSINESS CHALLENGES";
          break;
        default:
          typeTitle = null;
      }
      setTitle(`${title} ${typeTitle}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentType]);

  useEffect(() => {
    setScrollStep(cardList.current.clientWidth + 28);
    setMaxScroll(
      cardList.current.scrollWidth - cardList.current.clientWidth + 19
    );
    const interval = setInterval(() => {
      if (scrollActive && cardList.current) {
        const actualScroll = cardList.current?.scrollLeft;
        cardList.current.scrollLeft += (scroll - actualScroll) / 20;
      }
    }, 5);
    return () => clearInterval(interval);
  }, [scroll, scrollActive]);

  const handleScroll = e => {
    const { target } = e;
    if (target.offsetWidth + target.scrollLeft >= target.scrollWidth) addMore();
  };
  const handleGoRight = () => {
    if (typeof onGoRight === "function") {
      onGoRight();
    }
    if (!(scroll + scrollStep < maxScroll)) addMore();
    setScroll(
      scroll === 0
        ? scrollStep
        : scroll + scrollStep < maxScroll
        ? scroll + scrollStep
        : maxScroll
    );
    if (scrollActive) return;
    setScrollActive(true);
  };
  const handleGoLeft = () => {
    setScroll(scroll - scrollStep > 0 ? scroll - scrollStep : 0);
    if (scrollActive) return;
    setScrollActive(true);
  };

  const addMore = () => {
    setViewables([
      ...viewables,
      ...data?.slice(viewables.length, viewables.length + 5),
    ]);
  };

  const callback = (action, code) => {
    switch (action) {
      case "edit":
        trackEvent({
          action: auditActions.EDIT_SAVE_DRAFT,
          code: code,
          resourceType: documentType,
          type: auditTypes.CONTENT,
        });
        updateModalProps({
          isOpen: true,
          modalProps: {
            code: code,
            type: documentType,
          },
        });
        break;
      case "delete":
        if (documentType === entityType.OFFERING_TOPIC) {
          deleteOffering(code).then(res => {
            trackEvent({
              action: auditActions.DELETE,
              code: code,
              resourceType: entityType.OFFERING_TOPIC,
              type: auditTypes.CONTENT,
            });
            window.location.reload();
          });
        }
        break;
      case "publish":
        publish(code).then(res => {
          trackEvent({
            action: auditActions.EDIT_AND_PUBLISH,
            code: code,
            resourceType: documentType,
            type: auditTypes.CONTENT,
          });
        });
        break;
      default:
        break;
    }
  };
  return (
    <div className="related-cards">
      {title && viewables && viewables?.length > 0 ? (
        <div className="title">{title}</div>
      ) : null}
      <div
        className="cards-list"
        ref={cardList}
        onScroll={e => handleScroll(e)}>
        {viewables?.map((elem, idx) => {
          const unitsIds = (elem.secondaryOrganizationalUnitsIds) ?
              [elem.organizationalUnitId].concat(elem?.secondaryOrganizationalUnitsIds) :
              [elem.organizationalUnitId]
          let categories = []
          for (let id of unitsIds) {
            const index = organizationalUnits.findIndex(obj => obj.id === id);
            const type =(organizationalUnits[index]?.type === 'MFU' && organizationalUnits[index].isForeign) ? 'MFU_ESTERE' : organizationalUnits[index]?.type
            categories.push(type)
          }
          return(
            <ContentCard
              callback={callback}
              key={`${elem?.id} + ${idx}`}
              documentType={documentType}
              userEditScope={editScope}
              canLike={canLike}
              code={elem?.code}
              {...elem}
              unitsColor={unitsColorMap}
              categories={categories}
            />
          )
        }
        )}
      </div>
      <div
        className={
          scroll > 0
            ? "scroll-button left-scroll active"
            : "scroll-button left-scroll"
        }
        onClick={handleGoLeft}>
        <img src={ArrowIcon} alt="" />
      </div>
      <div
        className={
          scroll < maxScroll
            ? "scroll-button right-scroll active"
            : "scroll-button right-scroll"
        }
        onClick={handleGoRight}>
        <img src={ArrowIcon} alt="" />
      </div>
    </div>
  );
};

export default connect(
  ({ profile }) => ({ profile }),
  dispatch => ({
    updateModalProps: payload => {
      dispatch(TRIGGER_MODAL(payload));
    },
  })
)(RelatedCardList);
