import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { connect, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTracking } from "react-tracking";
import { ADD_BOOKMARK, REMOVE_BOOKMARK } from "redux/bookmarks";
import { TRIGGER_MODAL } from "redux/modal";
import routes from "routes";
import { getOrganizationalUnit } from "services/organizationalUnit";
import { getQualification, unPublish } from "services/quals";
import { getUser } from "services/user";
import { auditActions, auditTypes, entityType } from "utils/commons";
import {
  checkDownload,
  getIndustry,
  getOrgUnit,
  getSubIndustry,
} from "utils/utils";
import BackSave from "components/BackSave";
import Detail from "components/Detail";
import LegendButton from "components/LegendButton";
import LegendHeadbar from "components/LegendHeadbar";
import LikeImage from "images/like.svg";
import LikeImageFull from "images/like_reverse.svg";
import { useProfileContext } from "../../contexts/Profile";
import useRelatedData from "../../hooks/useRelatedData";
import { getBusinessChallenge } from "../../services/businessChallenge";
import { getLikes } from "../../services/like";
import { getOfferingTopic } from "../../services/offeringTopic";
import "./style.scss";

const QualDetail = ({
  profile,
  addBookmark,
  bookmarks,
  removeBookmark,
  personas,
  updateModalProps,
  quals,
  businessChallenges,
  offerings,
  subindustries,
  organizationalUnits,
  industries,
}) => {
  const { canEditQuals: isEditor, canLike } = useProfileContext();
  const { qualCode } = useParams();
  const { isEditable, userEditScope, industry, subindustry, categories } =
    useLocation().state || {};
  const history = useHistory();

  const [infoDetail, setInfoDetail] = useState();
  const [organizationalUnitData, setOrganizationUnitData] = useState();
  const [commercialReferenceData, setCommercialReferenceData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [totalComments, setTotalComments] = useState();
  const [totalLikes, setTotalLikes] = useState(0);
  const [myLike, setMyLike] = useState(false);
  const [secOrgUnits, setSecOrgUnits] = useState([]);

  const { businessChallengeCodes, offeringTopicCodes } = infoDetail || [];
  const { trackEvent } = useTracking({
    resourceType: entityType.QUALIFICATION,
    code: infoDetail?.code,
  });

  const unitsColorMap = useSelector((state) => state.unitsColor)
  const [categoryLegend, setCategoryLegend] = useState({})


  useEffect(() => {
    if(categories && categories.length > 0) {
      const legend = {}
      categories.forEach((category) => {
        legend[category] = unitsColorMap[category]
      })
      setCategoryLegend(legend)
    }
  }, [categories]);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (!isLoading && isMounted) {
        setIsLoading(true);
        try {
          const res1 = await getQualification(qualCode);
          setTotalLikes(res1?.data?.totalLikes);
          const { data } = res1 || {};
          setInfoDetail({
            ...data,
            personas: data.personasIds?.map(id =>
              personas.find(p => p.id === id)
            ),
            industry:
              industry?.name ||
              getIndustry(
                industries,
                getSubIndustry(subindustries, data.subIndustryId)
              )?.name,
            subindustry:
              subindustry?.name ||
              getSubIndustry(subindustries, data.subIndustryId)?.name,
          });
          if (res1.data?.organizationalUnitId) {
            const res2 = await getOrganizationalUnit(
              res1.data.organizationalUnitId
            );
            if (res2 && res2?.data) {
              setOrganizationUnitData(res2.data);
            }
          }
          if (res1.data?.secondaryOrganizationalUnitsIds?.length > 0) {
            const secOU = res1.data.secondaryOrganizationalUnitsIds;
            let array = [];
            secOU.forEach(item =>
              array.push(getOrgUnit(organizationalUnits, item).name)
            );
            setSecOrgUnits(array);
          }
          if (res1.data?.spocs && res1.data?.spocs?.length > 0) {
            const res3 = await getUser(res1.data?.spocs[0]?.userId);
            if (res3 && res3?.data) {
              setCommercialReferenceData(res3.data);
            }
          }
          setIsLoading(false);
        } catch (error) {
          console.error("QualDetail error: ", error);
          if (!isMounted) return;
          history.push(routes.QUALS);
          setIsLoading(false);
        }
      }
    })();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualCode, personas, quals, businessChallenges, offerings]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      try {
        (async () => {
          const { data } = await getLikes(qualCode);
          setMyLike(data?.myLike);
        })();
      } catch (e) {
        console.error("Qual detail", e);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [myLike, qualCode, totalLikes]);

  const handleAddBookmark = () => {
    if (
      bookmarks.qualificationsCodes &&
      bookmarks.qualificationsCodes.includes(infoDetail?.code)
    ) {
      trackEvent({
        type: auditTypes.ACTION,
        action: auditActions.DELETE_BOOKMARK,
      });
      removeBookmark({
        documentType: entityType.QUALIFICATION,
        documentId: qualCode,
      });
    } else {
      trackEvent({
        type: auditTypes.ACTION,
        action: auditActions.ADD_BOOKMARK,
      });
      addBookmark({
        documentType: entityType.QUALIFICATION,
        documentId: qualCode,
      });
    }
  };

  const setCallbackLikes = (total, myLike) => {
    setMyLike(myLike);
    setTotalLikes(total);
  };

  const { data: offeringCardData, loadMore: loadMoreOffering } = useRelatedData(
    offeringTopicCodes,
    getOfferingTopic,
    "BipUnitDetail error: "
  );

  const { data: bcCardData, loadMore: loadMoreBc } = useRelatedData(
    businessChallengeCodes,
    getBusinessChallenge,
    "BipUnitDetail error: "
  );

  return (
    <div className="container detail">
      <BackSave
        handleAddBookmark={handleAddBookmark}
        isSaved={
          bookmarks.qualificationsCodes &&
          bookmarks.qualificationsCodes.includes(infoDetail?.code)
        }
        documentType={entityType.QUALIFICATION}
        isEditable={isEditable}
        editFunction={() =>
          updateModalProps({
            isOpen: true,
            modalProps: {
              code: infoDetail?.code,
              selectedCoe: infoDetail?.coe,
              type: entityType.QUALIFICATION,
              versionType: "PUBLISHED",
            },
          })
        }
        deleteFunction={() =>
          unPublish(qualCode).then(() => {
            trackEvent({
              action: auditActions.DELETE,
              code: qualCode,
              type: auditTypes.ACTION,
              resourceType: entityType.OFFERING_TOPIC,
            });
            history.push("/quals/");
          })
        }
        isEditor={isEditor}
      />
      <div className="legend-headbar">
        <LegendHeadbar legend={categoryLegend} />
      </div>
      {!infoDetail ? (
        <Skeleton width="100%" height="45vw" />
      ) : (
        <>
          <div className="container-detail">
            <div className="qual-header">
              <div className="category-detail">{infoDetail?.category}</div>

              <div className="title">{infoDetail?.title}</div>
              <div className="likes-comments-total-container">
                {canLike && totalLikes && (
                  <>
                    <img
                      className={"like-image"}
                      src={myLike ? LikeImageFull : LikeImage}
                      alt="like"
                    />
                    <div className="black-dot">{`${totalLikes} likes •`}</div>
                  </>
                )}
                {totalComments && (
                  <div
                    className={"total-comments"}
                    onClick={() =>
                      document.getElementById("comments").scrollIntoView()
                    }>
                    {`${totalComments} comments`}
                  </div>
                )}
              </div>
              <div className="reactions-client">
                <div className="client">
                  Client:
                  <span>
                    &nbsp; {infoDetail?.customer}&nbsp;
                    {Boolean(infoDetail?.year)
                      ? ` • ${infoDetail?.year}`
                      : null}
                  </span>
                  <br />
                  OU:
                  <span>&nbsp;&nbsp;{organizationalUnitData?.name}</span>
                  &nbsp;&nbsp;
                  {secOrgUnits && secOrgUnits.length > 0 && (
                    <span>
                      - &nbsp;&nbsp;
                      {secOrgUnits.map((ou, index) =>
                        index === secOrgUnits.length - 1
                          ? ou + `\xa0 `
                          : ou + `,\xa0 `
                      )}
                    </span>
                  )}
                </div>
              </div>
              <div className="chips">
                {infoDetail?.tags?.map((value, index) => {
                  return <span key={index}>{value}</span>;
                })}
              </div>
            </div>
          </div>
          <Detail
            subindustries={subindustries}
            infoDetail={infoDetail}
            organizationalUnitData={organizationalUnitData}
            commercialReferenceData={commercialReferenceData}
            userEditScope={userEditScope}
            isDownloadable={checkDownload(profile, qualCode)}
            myLike={myLike}
            totalLikes={totalLikes}
            code={qualCode}
            setCallbackTotals={setTotalComments}
            contentType={entityType.QUALIFICATION}
            setCallbackLikes={setCallbackLikes}
            bcCardData={bcCardData}
            offeringCardData={offeringCardData}
            loadMoreOffering={loadMoreOffering}
            loadMoreBc={loadMoreBc}
          />
        </>
      )}
      {categoryLegend && Object.keys(categoryLegend).length > 0 &&
        <LegendButton legend={categoryLegend}/>
      }
    </div>
  );
};
//quals, bc and offerings are necessary in connect to see immediate update in case of edit
export default connect(
  ({
    bookmarks,
    personas,
    modal,
    profile,
    quals,
    businessChallenges,
    offerings,
    subindustries,
    industries,
    organizationalUnits,
  }) => ({
    bookmarks,
    personas,
    modal,
    profile,
    quals,
    businessChallenges,
    offerings,
    industries,
    subindustries,
    organizationalUnits,
  }),
  dispatch => ({
    addBookmark: params => dispatch(ADD_BOOKMARK(params)),
    removeBookmark: params => dispatch(REMOVE_BOOKMARK(params)),
    updateModalProps: payload => dispatch(TRIGGER_MODAL(payload)),
  })
)(QualDetail);
