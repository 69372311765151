import { useEffect, useState } from "react";

const { useLocation } = require("react-router-dom");

const useQuery = () => {
  const location = useLocation();
  const [loc, setLoc] = useState(null);

  useEffect(() => {
    const urlsp = new URLSearchParams(location.search);
    setLoc(urlsp);
  }, [location]);

  return loc;
};

export default useQuery;
