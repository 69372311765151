import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { GET_DICTIONARY } from "redux/dictionaries";
import { CLEAN_STORE, TRIGGER_MODAL } from "redux/modal";
import { GET_ORGANIZATIONAL_UNITS } from "redux/organizationalUnits";
import { GET_PERSONAS } from "redux/personas";
import { entityType } from "utils/commons";
import { GET_ALL_OFFERINGS_TOPICS } from "../../redux/filters";
import BusinessChallengesModal from "./businessChallenges";
import OfferingModal from "./offerings";
import QualModal from "./quals";
import TreeLevelViewModal from "./TreeLevelViewModal";

import "./style.scss";

const BackOfficeModal = ({
  show,
  code,
  profile,
  coe,
  personas,
  organizationalUnits,
  dictionaries,
  getPersonas,
  getDictionary,
  getOrganizationalUnits,
  type,
  businessChallenges,
  offerings,
  versionType,
  updateModalProps,
  cleanStore,
  industryTree,
  industries,
  subindustries,
  getAllOfferingTopics,
}) => {
  const handleModalClose = useCallback(() => {
    updateModalProps({
      isOpen: false,
      modalProps: {},
      shouldReloadOfferings: true,
    });
  }, [updateModalProps]);

  useEffect(() => {
    if (!offerings.length) {
      getAllOfferingTopics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    show && (
      <div className="modal">
        <section className="modal-main">
          {type === entityType.QUALIFICATION && (
            <QualModal
              handleClose={handleModalClose}
              show={show}
              code={code}
              profile={profile}
              coe={coe}
              personas={personas}
              organizationalUnits={organizationalUnits}
              dictionaries={dictionaries}
              getPersonas={getPersonas}
              getDictionary={getDictionary}
              getOrganizationalUnits={getOrganizationalUnits}
              businessChallenges={businessChallenges}
              offerings={offerings}
              versionType={versionType}
              cleanStore={cleanStore}
              industries={industries}
              subindustries={subindustries}
              industryTree={industryTree}
            />
          )}
          {type === entityType.OFFERING_TOPIC && (
            <OfferingModal
              handleClose={handleModalClose}
              show={show}
              code={code}
              profile={profile}
              coe={coe}
              organizationalUnits={organizationalUnits}
              dictionaries={dictionaries}
              getDictionary={getDictionary}
              getOrganizationalUnits={getOrganizationalUnits}
              businessChallenges={businessChallenges}
              cleanStore={cleanStore}
              industries={industries}
              subindustries={subindustries}
              industryTree={industryTree}
            />
          )}
          {type === entityType.BUSINESS_CHALLENGE && (
            <BusinessChallengesModal
              handleClose={handleModalClose}
              show={show}
              code={code}
              profile={profile}
              coe={coe}
              organizationalUnits={organizationalUnits}
              dictionaries={dictionaries}
              getDictionary={getDictionary}
              getOrganizationalUnits={getOrganizationalUnits}
              cleanStore={cleanStore}
            />
          )}
          {type === entityType.TREE_LEVEL_VIEW && (
            <TreeLevelViewModal
              handleClose={handleModalClose}
              show={show}
              code={code}
              profile={profile}
              coe={coe}
              organizationalUnits={organizationalUnits}
              dictionaries={dictionaries}
              getDictionary={getDictionary}
              getOrganizationalUnits={getOrganizationalUnits}
              businessChallenges={businessChallenges}
              cleanStore={cleanStore}
              industries={industries}
              subindustries={subindustries}
              industryTree={industryTree}
              type={type}
            />
          )}
        </section>
      </div>
    )
  );
};

BackOfficeModal.Id = "BackOfficeModal";

export default connect(
  ({
    dictionaries,
    profile,
    personas,
    organizationalUnits,
    businessChallenges,
    filters: { offerings = [] },
    industryTree,
    industries,
    subindustries,
  }) => ({
    dictionaries,
    profile,
    personas,
    organizationalUnits,
    businessChallenges,
    offerings,
    industryTree,
    industries,
    subindustries,
  }),
  dispatch => ({
    getAllOfferingTopics: () => dispatch(GET_ALL_OFFERINGS_TOPICS()),
    getDictionary: payload => dispatch(GET_DICTIONARY(payload)),
    getPersonas: () => dispatch(GET_PERSONAS()),
    getOrganizationalUnits: () => dispatch(GET_ORGANIZATIONAL_UNITS()),
    updateModalProps: payload => dispatch(TRIGGER_MODAL(payload)),
    cleanStore: payload => dispatch(CLEAN_STORE(payload)),
  })
)(React.memo(BackOfficeModal));
