import React, { useState } from "react";
import { connect } from "react-redux";
import { useTracking } from "react-tracking";

import { MODIFY_USER } from "redux/users";
import { putUser } from "services/user";
import { auditActions, auditTypes } from "utils/commons";
import AssociateWithGroup from "../ActionModal/AssociateWithGroup";
import UserDetail from "../UserDetail";

import "./style.scss";

const TableActionDropdown = ({ user, modifyUser }) => {
  const { trackEvent } = useTracking();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenGroupModal, setIsOpenGroupModal] = useState(false);
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);

  return (
    <div className="table-action-container">
      <div
        className={
          isOpen ? "open-action-button active-button" : "open-action-button"
        }
        onMouseOver={() => setIsOpen(true)}
        onMouseOut={() => setIsOpen(false)}>
        <div
          className={isOpen ? "action-point active-point" : "action-point"}
        />
        <div
          className={isOpen ? "action-point active-point" : "action-point"}
        />
        <div
          className={isOpen ? "action-point active-point" : "action-point"}
        />
      </div>
      <div
        className={`actions-block ${isOpen ? "visible" : ""}`}
        onMouseOver={() => setIsOpen(true)}
        onMouseOut={() => setIsOpen(false)}>
        <div className="action-label" onClick={() => setIsOpenUserModal(true)}>
          Edit user
        </div>
        <div
          className="action-label odd"
          onClick={() =>
            putUser({ ...user, isEnabled: !user.isEnabled })
              .then(response => {
                if (response?.data) {
                  trackEvent({
                    type: auditTypes.ADMIN,
                    action: response.data.isEnabled
                      ? auditActions.ENABLE_USER
                      : auditActions.DISABLE_USER,
                    user: user.id,
                  });
                  modifyUser({ ...response.data, orderIndex: user.orderIndex });
                } else {
                  console.log(
                    "Warning: Error updating content" +
                      " " +
                      response?.message?.toString()
                  );
                }
              })
              .catch(e => {
                console.log(e);
              })
          }>
          {`${user.isEnabled ? "Disable user" : "Enable user"}`}
        </div>
        <div className="action-label" onClick={() => setIsOpenGroupModal(true)}>
          Associate with a group
        </div>
      </div>
      <AssociateWithGroup
        users={[user]}
        isOpenProp={isOpenGroupModal}
        onClose={() => setIsOpenGroupModal(false)}
      />
      <UserDetail
        user={user}
        isOpenProp={isOpenUserModal}
        onClose={() => setIsOpenUserModal(false)}
      />
    </div>
  );
};

export default connect(
  ({}) => ({}),
  dispatch => ({
    modifyUser: payload => dispatch(MODIFY_USER(payload)),
  })
)(TableActionDropdown);
