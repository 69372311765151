import React from "react";
import { connect } from "react-redux";
import { SET_SEARCH } from "redux/search";
import moment from "moment";

import { elapsed, fte } from "utils/commons";
import { getNumberActiveFilters } from "utils/utils";
import CancelIcon from "images/cancel.png";
import FiltersIcon from "images/filters.png";

import "./style.scss";

const SelectedFilters = ({
  selectedFilters,
  handleChangeFilter,
  elementsCount,
  openFilters,
  userOrder = false,
  errorsOrder = false,
  handleById = false,
}) => {
  const getLabel = (k, v) => {
    if (!v) return null;
    switch (k) {
      case "order_by":
        return "";
      case "year_from":
        return `Year from ${v}`;
      case "year_to":
        return `Year to ${v}`;
      case "elapsed":
        return `Elapsed in ${elapsed.find(elm => elm.value === v).label}`;
      case "fte":
        const f = fte.find(elm => elm.value === v);
        return `${k} ${f.label ? f.label : f.value}`;
      case "trendy_topic":
        return `Trendy topic ${v === "true" ? "yes" : "no"}`;
      case "create_date_from":
        return `Created from ${moment(v).format("DD MMM YYYY")}`;
      case "create_date_to":
        return `Created to ${moment(v).format("DD MMM YYYY")}`;
      default:
        return v;
    }
  };
  
  const changeFilter = (key, value, id) => {
      if (handleChangeFilter) {
        if (handleById) {
          handleChangeFilter(key, value, id);
        } else {
          handleChangeFilter(key, value);
        }
      }
    };
  
  return (
    <div>
      {Object.keys(selectedFilters).length > 0 && (
        <div className="filter-chips">
          {Object.keys(selectedFilters).filter(filterName => !filterName.includes('id-')).map(key =>
            Array.isArray(selectedFilters[key])
              ? selectedFilters[key].map((v, index) => (
                  <div key={`${key}-${v}`} className="chip">
                    <div className="chip-label">{getLabel(key, v)}</div>
                    <img
                      src={CancelIcon}
                      alt="cancel"
                      className="chip-clear"
                      onClick={() => {
                        changeFilter(
                          key,
                          selectedFilters[key].filter(elm => elm !== v),
                          selectedFilters[`id-${key}`]?.filter((elm, idx)=> index !== idx),
                        )
                      }
                      }
                    />
                  </div>
                ))
              : getLabel(key, selectedFilters[key]) && (
                  <div key={key} className="chip">
                    <div className="chip-label">
                      {getLabel(key, selectedFilters[key])}
                    </div>
                    <img
                      src={CancelIcon}
                      alt="cancel"
                      className="chip-clear"
                      onClick={() => changeFilter(key, "", "")}
                    />
                  </div>
                )
          )}
        </div>
      )}
      <div className="filters-container">
        <div className="results-count">
          <div className="results-label">{elementsCount} results</div>
        </div>
        <div className="filter-bar">
          <div className="select-container">
            <div className="label">SORT BY:&nbsp;</div>
            {userOrder ? (
              <select
                value={selectedFilters.order_by || ""}
                className="sort-select"
                onChange={e =>
                  handleChangeFilter("order_by", e.currentTarget.value)
                }>
                <option value="ALPHABETIC">ALPHABETIC</option>
                <option value="CREATION DATE">CREATION DATE</option>
              </select>
            ) : errorsOrder ? (
              <select
                value={selectedFilters.order_by || ""}
                className="sort-select"
                onChange={e =>
                  handleChangeFilter("order_by", e.currentTarget.value)
                }>
                <option value="ALPHABETIC">ALPHABETIC</option>
                <option value="CREATION DATE">CREATION DATE</option>
                <option value="SEVERITY">SEVERITY</option>
              </select>
            ) : (
              <select
                value={selectedFilters.order_by || ""}
                className="sort-select"
                onChange={e =>
                  handleChangeFilter("order_by", e.currentTarget.value)
                }>
                <option value="LATEST">LAST UPDATED</option>
                <option value="RANKING">RANKING</option>
              </select>
            )}
          </div>
          <span onClick={openFilters} className="filter-settings">
            <img src={FiltersIcon} className="filter-icon" alt="test" />
            {getNumberActiveFilters(selectedFilters) > 0 && (
              <div className="filters-number">
                {getNumberActiveFilters(selectedFilters)}
              </div>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default connect(
  ({ dictionaries }) => ({
    dictionaries,
  }),
  dispatch => ({ setSearch: payload => dispatch(SET_SEARCH(payload)) })
)(SelectedFilters);
