import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import { useTracking } from "react-tracking";
import * as ReactDOMServer from "react-dom/server";
import { apis } from "services/config";
import {
  auditActions,
  auditTypes,
  entityType,
  reactToolTip,
} from "utils/commons";
import { downloadFile } from "utils/utils";
import ChevronRight from "images/chevron_right.svg";
import CircleDownload from "images/circle_download.png";

import "./style.scss";

const SliderPpt = ({
  previews,
  code,
  fileType,
  fileName,
  title = "PRESENTATION DECK",
  isDownloadable,
  documentType,
}) => {
  const { trackEvent } = useTracking();
  const [visibleImg, setVisibleImg] = useState(0);
  const [showBack, setShowBack] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(false);

  const handleDownload = async (
    name,
    code,
    documentType,
    title,
    withoutMFU
  ) => {
    trackEvent({
      action: auditActions.DOWNLOAD,
      type: auditTypes.DOWNLOAD,
    });
    documentType === entityType.QUALIFICATION
      ? withoutMFU
        ? await downloadFile(`${apis.QUAL}/${code}/ppt?withoutMfu=true`, name)
        : await downloadFile(`${apis.QUAL}/${code}/ppt`, name)
      : await downloadFile(
          `${
            title === "OFFERING DECK" ? apis.OFFERING_DECK : apis.ONE_PAGER
          }/${code}/ppt`,
          name
        );
  };

  useEffect(() => {
    if (visibleImg) {
      setShowBack(true);
    } else {
      setShowBack(false);
    }
  }, [visibleImg]);

  useEffect(() => {
    setIsLoadingImg(true);
    setTimeout(() => {
      setIsLoadingImg(false);
    }, 250);
  }, [visibleImg]);

  return (
    <div className="presentation-deck">
      <div className="title">{title}</div>
      {isDownloadable && (
        <>
          <div>
            <div
              className={
                documentType === entityType.OFFERING_TOPIC
                  ? "download-bip"
                  : "download"
              }
              data-tip={ReactDOMServer.renderToString(
                <div>
                  I am <b>JSX</b> content
                </div>
              )}
              data-for="registerTip"
              onClick={() =>
                handleDownload(
                  `${
                    fileName
                      ? fileName
                      : `presentation.${fileType ? fileType : "pptx"}`
                  }`,
                  code,
                  documentType,
                  title,
                  false
                )
              }>
              <img src={CircleDownload} alt="" />
            </div>
            <ReactTooltip
              className="tool-tip"
              id="registerTip"
              place="top"
              effect="solid"
              multiline
              border
              textColor="black"
              arrowColor="lightGray">
              {reactToolTip.DOWNLOAD_PPT}
            </ReactTooltip>
          </div>
          {documentType === entityType.QUALIFICATION ? (
            <div>
              <div
                className="download-bip"
                data-tip={ReactDOMServer.renderToString(
                  <div>
                    I am <b>JSX</b> content
                  </div>
                )}
                data-for="registerTipWithoutMFU"
                onClick={() =>
                  handleDownload(
                    `${
                      fileName
                        ? fileName
                        : `presentation.${fileType ? fileType : "pptx"}`
                    }`,
                    code,
                    documentType,
                    title,
                    true
                  )
                }>
                <img src={CircleDownload} alt="" />
              </div>

              <ReactTooltip
                className="tool-tip"
                id="registerTipWithoutMFU"
                place="top"
                effect="solid"
                multiline
                border
                textColor="black"
                arrowColor="lightGray">
                {reactToolTip.DOWNLOAD_PPT_WITHOUT_OU}
              </ReactTooltip>
            </div>
          ) : null}
        </>
      )}
      <div className="image-container">
        {previews?.length &&
          previews[visibleImg] &&
          (isLoadingImg ? (
            <Skeleton width={"100%"} height={"45vw"} />
          ) : (
            <img
              src={"data:image/png;base64," + previews[visibleImg]}
              alt={fileName}
            />
          ))}
      </div>
      {previews?.length > 1 && (
        <>
          {visibleImg + 1 !== previews.length && (
            <div
              className="arrow-button right-scroll"
              onClick={() => setVisibleImg(visibleImg + 1)}>
              <img src={ChevronRight} alt="" />
            </div>
          )}
          {showBack && (
            <div
              className="arrow-button left-scroll"
              onClick={() => setVisibleImg(visibleImg - 1)}>
              <img src={ChevronRight} alt="" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SliderPpt;
