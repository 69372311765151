import React, { useEffect, useState } from "react";
import MidArrow from "images/arrow.svg";
import CustomOption from "../CustomOption";

import "./style.scss";

const CustomIndustrySelect = ({
  value,
  items,
  onChange,
  label,
  isAutocomplete = false,
  closeOnSelect,
  absoluteOptions,
}) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const { matches: isMobile } = window.matchMedia("(max-width: 912px)");

  useEffect(() => {
    if (Array.isArray(value)) {
      setSelectedItems([...value]);
    } else {
      setSelectedItems([value]);
    }
  }, [value]);

  const handleOnChange = (code, id) => {
    if (onChange) {
      //val is subindustry id --> code
      onChange(code, id);
    }
    if (closeOnSelect) setOpenOptions(false);
  };

  return (
    <div className="custom-select-wrapper">
      <div
        onClick={() => setOpenOptions(!openOptions)}
        className="custom-select-input">
        <div className="custom-select-label-wrapper">
          {<div className="label">{label}</div>}
          {Array.isArray(selectedItems) && selectedItems?.length ? (
            <div className="filter-number">
              {" "}
              {Array.isArray(value) && value.length > 0 ? value.length : "1"}
            </div>
          ) : (
            <div className="filter-numbeer"> </div>
          )}
        </div>

        <div className="custom-select-input-arrow">
          <img src={MidArrow} alt="arrow-control" />
        </div>
      </div>

      {openOptions && (!isAutocomplete || !isMobile) && (
        <div
          className={`custom-select-options ${
            absoluteOptions ? "absolute" : ""
          }`}>
          <div
            onClick={() => setOpenOptions(!openOptions)}
            className="custom-select-input">
            <div className="custom-select-input-arrow">
              <img src={MidArrow} alt="arrow-control" />
            </div>
            {label}
          </div>
          {items.map(({ id, type, value, code }) => (
            <CustomOption
              key={id}
              id={id}
              type={type}
              onClick={handleOnChange}
              value={value}
              code={code}
              selected={selectedItems}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomIndustrySelect;
