import React from "react";
import PropTypes from "prop-types";
import Teams from "images/Teams.png";
import "./style.scss";

const TeamsContact = ({ email }) => {
  return (
    <div className="contact-via-teams-container">
      {email &&
        <>
          <img src={Teams} alt="Teams" className={"teams-image"}/>
          <a
            target={'_blank'}
            rel="noreferrer"
            href={`https://teams.microsoft.com/l/chat/0/0?users=${email}`}>
            Contact via Teams
          </a>
        </>
      }
    </div>
  );
};

TeamsContact.propTypes = {
  email: PropTypes.string,
};

export default TeamsContact;
