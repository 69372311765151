import React, { createContext, useContext, useState } from "react";
import { treeLevelsLabels } from "../../utils/commons";

const initialContextState = {
  selectedId: treeLevelsLabels.ALL_OFFERINGS,
  setSelectedId: () => {
    throw new Error("Context not initialised");
  },
};

const TreeContext = createContext(initialContextState);

const useTreeContext = () => useContext(TreeContext);

const TreeProvider = ({ children }) => {
  const [isTreeLevelViewModalOpen, setIsTreeLevelViewModalOpen] =
    useState(false);
  const [isDidMount, setIsDidMount] = useState(false);
  const [selectedId, setSelectedId] = useState(treeLevelsLabels.ALL_OFFERINGS);
  const [treeList, setTreeList] = useState();

  return (
    <TreeContext.Provider
      value={{
        selectedId,
        setSelectedId,
        treeList,
        setTreeList,
        isTreeLevelViewModalOpen,
        setIsTreeLevelViewModalOpen,
        isDidMount,
        setIsDidMount,
      }}>
      {children}
    </TreeContext.Provider>
  );
};

export { TreeProvider, useTreeContext };
