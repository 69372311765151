import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTracking } from "react-tracking";
import { ADD_BOOKMARK, REMOVE_BOOKMARK } from "redux/bookmarks";
import routes from "routes";
import moment from "moment";
import {
  auditActions,
  auditTypes,
  contentCardLabels,
  entityType,
  treeLevelsLabels,
} from "utils/commons";
import ColorBar from "components/ColorBar";
import DeleteIcon from "images/delete.png";
import EditIcon from "images/edit.svg";
import EditWithAlert from 'images/editWithAlert.svg'
import FlameSvg from "images/flame.svg";
import InstitutionalIcon from "images/institution_icon.svg";
import savePlus from "images/save_plus.png";
import saved from "images/saved.png";
import { useTreeContext } from "../../contexts/Tree";
import { findNodeLabel } from "../../utils/utils";
import Likes from "../Likes";

const Offering = ({
  code,
  isContributor,
  userEditScope,
  callback,
  organizationalUnits,
  cardData,
  bookmarks,
  addBookmark,
  removeBookmark,
  isDownloader,
  canLike,
  setCallbackLikes,
  myLike,
  totalLikes,
  treeLevelId,
  readOnly,
  onCardSelect,
  isModal,
  unitsColor,
  categories,
  showFileWithErrors = false,
}) => {
  const { trackEvent } = useTracking({
    code,
    resourceType: entityType.OFFERING_TOPIC,
  });

  const {
    organizationalUnitId,
    code: offeringCode,
    description,
    isTrendy,
    lastModifiedDate,
    isInstitutional,
    hasIssues,
  } = cardData || {};

  const canUserEdit =
    userEditScope &&
    userEditScope?.orgIds?.length > 0 &&
    userEditScope?.orgIds.includes(organizationalUnitId);
  const isAdmin = userEditScope && userEditScope?.orgIds === "all";

  const { treeList } = useTreeContext();
  const [category, setCategory] = useState("");

  const { matches: isMobile } = window.matchMedia("(max-width: 912px)");

  useEffect(() => {
    if (treeLevelId === "") {
      setCategory("UNCATEGORIZED");
    }
    if (treeLevelId !== treeLevelsLabels.UNCATEGORIZED) {
      if (treeList?.length) {
        const name = findNodeLabel(treeList, treeLevelId);
        setCategory(name?.toUpperCase());
      }
    }
  }, [treeLevelId, treeList]);

  const handleAction = (event, action) => {
    event.preventDefault();
    event.stopPropagation();
    if (callback) {
      callback({
        action,
        organizationalUnit: organizationalUnits.find(
          ou => ou.id === organizationalUnitId
        ),
        code: offeringCode,
        versionType: entityType.OFFERING_TOPIC,
      });
    }
  };

  const handleAddBookmark = event => {
    event.preventDefault();
    event.stopPropagation();
    if (
      bookmarks?.offeringTopicsCodes &&
      bookmarks.offeringTopicsCodes?.includes(offeringCode)
    ) {
      trackEvent({
        type: auditTypes.ACTION,
        action: auditActions.DELETE_BOOKMARK,
      });
      removeBookmark({
        documentType: entityType.OFFERING_TOPIC,
        documentId: offeringCode,
      });
    } else {
      trackEvent({
        type: auditTypes.ACTION,
        action: auditActions.ADD_BOOKMARK,
      });
      addBookmark({
        documentType: entityType.OFFERING_TOPIC,
        documentId: offeringCode,
        isDownloadable: isDownloader || canUserEdit,
      });
    }
  };

  const isSaved =
    bookmarks &&
    bookmarks?.offeringTopicsCodes &&
    bookmarks.offeringTopicsCodes.includes(offeringCode);

  const manager = organizationalUnits?.find(
    ou => ou?.id === organizationalUnitId
  )?.name;

  return (
    <div className="offering-div">
      <Link
        to={{
          pathname: `${routes.OFFERING}/${code}`,
          state: {
            code,
            title: description,
            isEditable: canUserEdit || isAdmin,
            userEditScope: userEditScope,
            categories
          },
        }}
        onClick={() => {
          trackEvent({
            type: auditTypes.CONTENT,
            action: auditActions.OPEN_DETAIL,
          });
          onCardSelect?.();
        }}
        className={
          isContributor
            ? "result-card offering-card-contributor offering-draft"
            : "result-card offering-card"
        }>
        <div className={isModal ? "status-banner-modal" : "status-banner"}>
          {isModal && (
            <div className="likes-bookmarks-icons-container">
              {canLike ? (
                <Likes
                  contentType={entityType.OFFERING_TOPIC}
                  code={code}
                  myLikeData={myLike}
                  totalLikes={totalLikes}
                  setCallbackLikes={setCallbackLikes}
                  totalData={totalLikes}
                />
              ) : null}
              {handleAddBookmark ? (
                <div
                  className="add-bookmark"
                  onClick={event => handleAddBookmark(event)}>
                  <img src={isSaved ? saved : savePlus} alt="save" />
                </div>
              ) : null}
            </div>
          )}
          <div className="status-container">
            {isTrendy ? (
              <div className="status">
                <img src={FlameSvg} alt="" className="status-icon" />
                <div className="status-label">{contentCardLabels.TRENDING}</div>
              </div>
            ) : null}
  
            {isInstitutional ? (
              <div className="status">
                <img
                  src={InstitutionalIcon}
                  alt="bip-favicon"
                  className="status-icon-institutional"
                />
                <div className="status-label">
                  {contentCardLabels.INSTITUTIONAL}
                </div>
              </div>
            ) : null}
          </div>
          {!isModal ? (
            <div className="likes-bookmarks-icons-container">
              {canLike && !isContributor && (
                <Likes
                  contentType={entityType.OFFERING_TOPIC}
                  code={code}
                  myLikeData={myLike}
                  totalLikes={totalLikes}
                  setCallbackLikes={setCallbackLikes}
                  totalData={totalLikes}
                />
              )}
              {!isContributor && handleAddBookmark && (
                <div
                  className="add-bookmark"
                  onClick={event => handleAddBookmark(event)}>
                  <img src={isSaved ? saved : savePlus} alt="save" />
                </div>
              )}
            </div>
          ) : null}
        </div>
        {description && (
          <div className="name">{`${description?.substr(0, 100)}${
            description?.length > 100 ? "..." : ""
          }`}</div>
        )}
        {!isContributor || (isModal && isMobile) ? (
          <div className="body">
            <div className="manager">{manager}</div>
          </div>
        ) : null}
        {isContributor ? (
          <div className="qual-footer-contributor draft-footer">
            <div className="footer-info">
              <div className="manager">
                {manager.length > 27 ? manager.slice(0, 26) + "..." : manager}
              </div>
              <div className="info">
                <div className="label">{contentCardLabels.CONTRIBUTOR_CODE}</div>
                <div className="value">{code}</div>
              </div>
              <div className="info">
                <div className="label">
                  {contentCardLabels.CONTRIBUTOR_LAST_EDIT}
                </div>
                <div className="value">{`${moment(lastModifiedDate).format(
                  "DD/MM/YYYY"
                )}`}</div>
              </div>
            </div>
  
            <div className="footer-icons">
              <div className={readOnly ? "info icons" : "level"}>
                {treeLevelId && category?.length ? (
                  <div className="level-tag">
                    {category?.length > 20
                      ? category?.slice(0, 17) + "..."
                      : category}
                  </div>
                ) : (
                  <p>Uncategorized</p>
                )}
              </div>
              {!readOnly ? (
                <div className="info icons">
                  <img
                    src={(showFileWithErrors && hasIssues) ? EditWithAlert : EditIcon}
                    alt="Edit Qual Draft"
                    onClick={callback ? e => handleAction(e, "edit") : null}
                  />
                  <img
                    src={DeleteIcon}
                    alt="Delete Qual Draft"
                    onClick={callback ? e => handleAction(e, "delete") : null}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {!isContributor && (userEditScope?.orgIds === "all" || canUserEdit) && (
          <div className="qual-footer draft-footer">
            <div className="info">
              <div className="label">{contentCardLabels.CODE}</div>
              <div className="value">{code}</div>
            </div>
            <div className="info">
              <div className="label">{contentCardLabels.LAST_EDIT}</div>
              <div className="value">{`${moment(lastModifiedDate).format(
                "DD/MM/YYYY"
              )}`}</div>
            </div>
            <div className="info icons">
              <img
                src={(showFileWithErrors && hasIssues) ? EditWithAlert : EditIcon}
                alt="Edit Qual Draft"
                onClick={callback ? e => handleAction(e, "edit") : null}
              />
              <img
                src={DeleteIcon}
                alt="Delete Qual Draft"
                onClick={callback ? e => handleAction(e, "delete") : null}
              />
            </div>
          </div>
        )}
      </Link>
      <ColorBar id={code} legend={unitsColor} category={categories}/>
    </div>
  );
};

export default connect(
  ({ organizationalUnits, bookmarks }) => ({
    organizationalUnits,
    bookmarks,
  }),
  dispatch => ({
    addBookmark: params => dispatch(ADD_BOOKMARK(params)),
    removeBookmark: params => dispatch(REMOVE_BOOKMARK(params)),
  })
)(Offering);
