import React, { useCallback, useEffect, useRef, useState } from "react";
import Dragula from "react-dragula";
import { connect, useSelector } from "react-redux";
import CardList from "components/CardList";
import Filters from "components/Filters";
import SelectedFilters from "components/SelectedFilters";
import EditIcon from "images/edit.svg";
import EditIconWithWarning from "images/editWithAlert.svg";
import { TreeProvider } from "../../contexts/Tree";
import { getIndustryById } from "../../redux/industryTree";
import { getOfferingTopicByOrganizationalUnitId } from "../../services/offeringTopic";
import {
  assignOfferingToTree,
  assignOfferingToUncategorized,
} from "../../services/treeView";
import { entityType, treeLevelsLabels } from "../../utils/commons";
import { getIndustry } from "../../utils/utils";
import Drag from "../Drag";
import Drop from "../Drop";
import TreeViewManageLevels from "../TreeViewManageLevels";

import "./style.scss";
import "../Level/style.scss";

const SearchView = ({
  handleChangeFilter,
  selectedFilters = {},
  dataList,
  viewableFilters = [],
  isSerp = false,
  callback = null,
  handleResetFilters,
  isContributor,
  offering,
  documentType,
  loadData,
  isLoadingData,
  total,
  selectedCoe,
  versionType,
  industries,
  showEditLegend = false,
  showFileWithErrors = false
}) => {
  const [openFilters, setOpenFilters] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [dragList, setDragList] = useState(dataList);
  const [updatedDragList, setUpdatedDragList] = useState(dataList);

  const industryItem = useSelector(state =>
    getIndustryById(state, selectedCoe?.subIndustryId)
  );

  const industryName = getIndustry(industries, industryItem)?.name;

  const newTreeView =
    isContributor && documentType === entityType.OFFERING_TOPIC;

  const { id } = selectedCoe || {};

  const dropzoneRef = useRef();
  const draggableItemsRef = useRef();
  const initializedRef = useRef(false);

  const scrollTimerRef = useRef(null);

  const onMouseEnter = useCallback(event => {
    event?.target?.parentElement?.classList.add("level-highlighted");
  }, []);

  const onMouseLeave = useCallback(event => {
    event?.target?.parentElement?.classList.remove("level-highlighted");
  }, []);

  const onScrollListenerMouseEnter = useCallback(event => {
    const isUpDirection = event.target.classList.contains("cursor-listener-up");
    scrollTimerRef.current = setInterval(() => {
      window.scrollTo(0, window.pageYOffset + (isUpDirection ? -20 : 20));
    }, 100);
  }, []);

  const onScrollListenerMouseLeave = useCallback(() => {
    clearInterval(scrollTimerRef.current);
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (dropzoneRef.current && draggableItemsRef.current && isMounted) {
      initializedRef.current = true;

      const drake = Dragula([dropzoneRef.current, draggableItemsRef.current], {
        moves: (_el, source) => source === draggableItemsRef.current, // permette di trascinare solo se la source è la draggable
        accepts: (el, target, source) => source !== target, // previene drag su stessa lista
        copy: true,
      })
        .on("drop", (el, _target, _source, sibling) => {
          console.log(
            "draggegEl: ",
            el.getAttribute("data-id"),
            "targetEl: ",
            sibling?.getAttribute("data-id")
          );

          if (!document.querySelector(".level-highlighted")) {
            drake.cancel(true);
            return;
          }

          if (
            sibling?.getAttribute("data-id") !==
              treeLevelsLabels.ALL_OFFERINGS &&
            !sibling
              ?.getAttribute("data-id")
              .includes(el.getAttribute("data-id").slice(0, 2))
          ) {
            if (sibling?.getAttribute("data-id")) {
              el.classList.add("test");

              if (
                sibling?.getAttribute("data-id") ===
                treeLevelsLabels.UNCATEGORIZED
              ) {
                assignOfferingToUncategorized(el.getAttribute("data-id")).then(
                  () => {
                    console.log("chiamata uncategorized finita");

                    getOfferingTopicByOrganizationalUnitId(id).then(res => {
                      setDragList(res?.data);
                      setUpdatedDragList(res?.data);
                    });
                  }
                );
              } else {
                assignOfferingToTree(
                  el.getAttribute("data-id"),
                  sibling?.getAttribute("data-id")
                ).then(() => {
                  console.log("chiamata finita");

                  getOfferingTopicByOrganizationalUnitId(id).then(res => {
                    setDragList(res?.data);
                    setUpdatedDragList(res?.data);
                  });
                });
              }
            } else {
              drake.cancel(true);
            }
          } else {
            drake.cancel(true);
          }
        })
        .on("drag", () => {
          const upEdge = document.createElement("div");
          const downEdge = document.createElement("div");

          upEdge.setAttribute(
            "style",
            "position: fixed; top: 0; left: 0; right: 0; height: 50px;"
          );
          downEdge.setAttribute(
            "style",
            "position: fixed; bottom: 0; left: 0; right: 0; height: 50px;"
          );

          upEdge.classList.add("cursor-listener-up");
          downEdge.classList.add("cursor-listener-down");

          upEdge.addEventListener("mouseenter", onScrollListenerMouseEnter);
          upEdge.addEventListener("mouseleave", onScrollListenerMouseLeave);

          downEdge.addEventListener("mouseenter", onScrollListenerMouseEnter);
          downEdge.addEventListener("mouseleave", onScrollListenerMouseLeave);

          document.body.appendChild(upEdge);
          document.body.appendChild(downEdge);

          const allLabels = [
            ...document.querySelectorAll("ul.drop > li .label-container"),
          ];
          const dragList = document.querySelector("ul.drag");
          dragList.classList.add("ignore-events");

          allLabels.map(label => {
            label.addEventListener("mouseenter", onMouseEnter);
            label.addEventListener("mouseleave", onMouseLeave);
          });
        })
        .on("dragend", () => {
          const upEdge = document.querySelector(".cursor-listener-up");
          const downEdge = document.querySelector(".cursor-listener-down");

          upEdge?.parentElement.removeChild(upEdge);
          downEdge?.parentElement.removeChild(downEdge);

          const allLabels = [
            ...document.querySelectorAll("ul.drop > li .label-container"),
          ];
          const dragList = document.querySelector("ul.drag");
          dragList.classList.remove("ignore-events");

          allLabels.map(label => {
            label.removeEventListener("mouseenter", onMouseEnter);
            label.removeEventListener("mouseleave", onMouseLeave);
            label.parentElement.classList.remove("level-highlighted");
          });
          clearInterval(scrollTimerRef.current);
        });
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropzoneRef, draggableItemsRef, id, versionType]);

  useEffect(() => {
    setDragList(dataList);
  }, [dataList]);

  useEffect(() => {
    if (viewableFilters) setShowFilters(viewableFilters.length > 0);
  }, [viewableFilters]);

  return (
    <TreeProvider>
      <div className="results-container">
        {showFilters && (
          <div
            className={`side-filter-bar ${openFilters ? "open-filter" : ""}`}>
            <Filters
              handleChangeFilter={handleChangeFilter}
              viewableFilters={viewableFilters}
              selectedFilters={selectedFilters}
              closeFilters={() => setOpenFilters(false)}
              numberItems={dataList?.length}
              handleResetFilters={handleResetFilters}
              offering={offering}
            />
          </div>
        )}
        <div className="results">
          {showFilters && (
            <SelectedFilters
              selectedFilters={selectedFilters}
              handleChangeFilter={handleChangeFilter}
              elementsCount={total}
              hideInputSearch={isSerp}
              openFilters={() => setOpenFilters(!openFilters)}
            />
          )}
          {showEditLegend && (
            <div className="search-view-legend">
              <div className="legend-item">
                <img
                  src={EditIcon}
                  className="ciao"
                  alt="Edit document"
                />
                <span className="legend-text">Edit document</span>
              </div>
              <div className="legend-item">
                <img
                  src={EditIconWithWarning}
                  className="ciao"
                  alt="Edit document with issues"
                />
                <span className="legend-text">Edit document with issues</span>
              </div>
            </div>
          )}
          {newTreeView &&
            selectedCoe &&
            versionType !== entityType.OFFERING_TREE_VIEW && (
              <div className="results-container">
                <Drop
                  ref={dropzoneRef}
                  selectedCoe={selectedCoe}
                  total={total}
                  dataList={updatedDragList}
                  industryName={industryName}
                />
                <Drag
                  ref={draggableItemsRef}
                  dataList={dragList}
                  viewAll={
                    !selectedFilters || Object.keys(selectedFilters).length > 1
                  }
                  callback={callback}
                  isContributor={isContributor}
                  documentType={documentType}
                  total={total}
                  offering={offering}
                  newTreeView={newTreeView}
                />
              </div>
            )}

          {versionType === entityType.OFFERING_TREE_VIEW && (
            <div className="manage-contributor">
              <TreeViewManageLevels
                type={versionType}
                selectedCoe={selectedCoe}
                total={total}
                dataList={updatedDragList}
              />
            </div>
          )}

          {!newTreeView && (
            <div className="results-list">
              <CardList
                elemList={dataList}
                viewAll={
                  !selectedFilters || Object.keys(selectedFilters).length > 1
                }
                showFileWithErrors={showFileWithErrors}
                callback={callback}
                isContributor={isContributor}
                documentType={documentType}
                loadData={loadData}
                total={total}
                offering={offering}
                newTreeView={newTreeView}
                isLoadingData={isLoadingData}
              />
            </div>
          )}
        </div>
      </div>
    </TreeProvider>
  );
};

export default connect(({ industries }) => ({
  industries,
}))(SearchView);
