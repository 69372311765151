import { apis, serviceInterface } from "./config";

export const getOnePager = id => {
  return serviceInterface.get(`${apis.ONE_PAGER}/${id}`);
};

export const getOnePagerByOrganizationalUnitId = id => {
  return serviceInterface.get(
    `${apis.ONE_PAGER}?organizationalUnitId=${id}&no_graphic=true`
  );
};

export const addOnePager = params => {
  return serviceInterface.post(apis.ONE_PAGER, { ...params });
};
