import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTracking } from "react-tracking";
import { SET_SEARCH } from "redux/search";
import { auditActions, auditTypes, metaData } from "utils/commons";
import Header from "components/Header";

import "./style.scss";

const Layout = ({ children }) => {
  const { trackEvent } = useTracking();
  const location = useLocation();
  useEffect(() => {
    const { search } = location;
    if (location.pathname.match("^/[^/]+/[^/]+[a-zA-Z0-9]$")) {
      return;
    }
    trackEvent({
      action: auditActions.NAVIGATE,
      type: auditTypes.ROUTE,
      path: location.pathname,
      search: search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getOnlyBase = path => {
    const deep = (path.match(/\//g) || []).length;
    if (deep > 1) {
      const li = path.lastIndexOf("/");
      return path.substr(0, li);
    }
    return path;
  };

  return (
    <div className="main-relative">
      <Helmet
        titleTemplate="%s | Myracle"
        title={metaData[getOnlyBase(location.pathname)]?.title}
      />
      <Header />
      {children}
    </div>
  );
};

Layout.propTypes = {};

export default connect(
  () => ({}),
  dispatch => ({
    setSearch: payload => dispatch(SET_SEARCH({ serp: payload })),
  })
)(Layout);
