import "./style.scss";

const LegendHeadbar = props => {
    const { legend } = props;

    return (
        <div className="bip-units-legend">
            { legend && Object.entries(legend).map(([key, item]) => {
                return (
                    <div key={key}>
                        <span className="color-legend" style={{backgroundColor: `#${item.color}`}}/>
                        {item.name}
                    </div>
                )
            })
            }
        </div>
    )
}

export default LegendHeadbar;