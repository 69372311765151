import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { GET_OFFERING } from "redux/offerings";
import routes from "routes";
import { getQualification } from "services/quals";
import { entityType } from "utils/commons";
import Offering from "./Offering";
import Qual from "./Qual";

import "./style.scss";

const BookmarkCard = ({
  documentType,
  code,
  isChecked,
  toggleChecked,
  getOffering,
  offerings,
  closeBookmarks,
}) => {
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState();
  const [linkDetail, setLinkDetail] = useState("");
  const history = useHistory();

  const handleClick = () => {
    closeBookmarks();
    history.push(linkDetail, {
      code: cardData?.code,
      name: cardData?.name,
      title: cardData?.title,
    });
  };

  useEffect(() => {
    if (!cardData) {
      switch (documentType) {
        case entityType.QUALIFICATION: {
          getQualification(code)
            .then(res => {
              if (res?.data) {
                setCardData(res?.data);
              }
              setLoading(false);
            })
            .catch(e => {
              console.log("error retrieve quals", e);
              setLoading(false);
            });
          setLinkDetail(`${routes.QUALS}/${code}`);
          break;
        }
        case entityType.OFFERING_TOPIC: {
          if (offerings[code]) {
            setCardData(offerings[code]);
            setLinkDetail(`${routes.OFFERING}/${code}`);
            setLoading(false);
          } else {
            if (!loading) {
              setLoading(true);
              getOffering(code);
            }
          }
          break;
        }
        default:
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData, offerings]);

  const renderCard = type => {
    switch (type) {
      case entityType.QUALIFICATION:
        return (
          <Qual
            handleClick={handleClick}
            cardData={cardData}
            isChecked={isChecked}
            toggleChecked={() => toggleChecked(entityType.QUALIFICATION, code)}
          />
        );
      case entityType.OFFERING_TOPIC:
        return (
          <Offering
            handleClick={handleClick}
            cardData={cardData}
            isChecked={isChecked}
            toggleChecked={() => toggleChecked(entityType.OFFERING_TOPIC, code)}
          />
        );
      default:
        return <div className="no-card" />;
    }
  };
  return loading && !cardData ? (
    <Skeleton height={200} />
  ) : (
    renderCard(documentType)
  );
};

export default connect(
  ({ dictionaries, offerings }) => ({
    dictionaries,
    offerings,
  }),
  dispatch => ({
    getOffering: payload => dispatch(GET_OFFERING(payload)),
  })
)(BookmarkCard);
