import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { connect } from "react-redux";

//import {MODIFY_USER} from "redux/users";
//import {getUserByEmail, putUser} from "services/user";
import { useTracking } from "react-tracking";

import "./style.scss";
import { GET_GROUPS, MODIFY_GROUP } from "redux/groups";
import { putGroup, addNewGroup } from "services/backoffice";
import { auditActions, auditTypes } from "utils/commons";
import { getGroupIdsByGroupsNames } from "utils/utils";
import CustomSelect from "components/CustomSelect";
import SuccessMessageModal from "components/SuccessMessageModal";
import CloseIcon from "images/cancel.svg";

const GroupDetail = ({
  group,
  isOpenProp,
  onClose,
  roles,
  modifyGroup,
  modifyUser,
  getGroups,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState();
  //const [searchUser, setSearchUser] = useState("");
  //const [searchUserMessage, setSearchUserMessage] = useState("");
  //const [usersToAdd, setUsersToAdd] = useState([]);
  const [groupName, setGroupName] = useState();

  const { trackEvent } = useTracking();

  useEffect(() => {
    const filteredRoles = Object.values(roles).filter(role =>
      group?.rolesIds?.includes(role.id)
    );
    setSelectedRoles(filteredRoles.map(role => role.name));
    setGroupName(group?.name);
  }, [roles, group]);

  /*
  const handleSearchUser = (event) => {
    event?.preventDefault();
    setSearchUserMessage("");
    if( usersToAdd.filter(user => user.email === searchUser).length > 0) {
      setSearchUserMessage("User already included");
      setSearchUser("");
      return;
    }
    getUserByEmail(searchUser).then(res => {
      if (res.data?.users?.length) {
        setUsersToAdd([...usersToAdd, res.data.users[0]]);
        setSearchUser("");
      } else {
        setSearchUserMessage("No user found");
      }
    });
  };

  const handleRemoveUserToAdd = (userId) => {
    setUsersToAdd(usersToAdd.filter(user => user.id !== userId));
  }
 */

  const handleRemoveRole = roleToRemove => {
    setSelectedRoles(selectedRoles.filter(role => role !== roleToRemove));
  };

  const clearAll = () => {
    //setUsersToAdd([]);
    //setSearchUser("");
    setSelectedRoles([]);
    setGroupName(undefined);
    //setSearchUserMessage("");
  };

  const handleClose = () => {
    clearAll();
    onClose();
  };

  const { handleSubmit, register } = useForm();

  const submit = () => {
    const preparedData = {
      name: groupName,
      rolesIds: getGroupIdsByGroupsNames(roles, selectedRoles), //as array
    };
    /*
    if(usersToAdd) {
      usersToAdd.forEach(user => putUser({...user, groupsIds: [...user.groupsIds, group.id]})
        .then(res => modifyUser({...user, groupsIds: [...user.groupsIds, group.id]}))
        .catch(e => console.log(e))
      )
    }
     */

    if (group) {
      // modify group
      trackEvent({
        type: auditTypes.ADMIN,
        action: auditActions.MODIFY_GROUP,
        group: group.id,
      });
      putGroup(group.id, preparedData)
        .then(response => {
          //http request
          if (response && response?.data) {
            return modifyGroup({
              ...group,
              ...response.data,
              orderIndex: group.orderIndex,
              userCount: group.userCount,
            }); //request to update redux
          } else {
            console.log(
              "Warning: Error updating content" +
                " " +
                response?.message?.toString()
            );
          }
        })
        .then(res => {
          if (res) {
            setIsSubmitted(true); //open message
            setTimeout(() => handleClose(), 2500);
          } //await for the modal to close}
        })
        .catch(e => {
          console.log("Warning: Error updating content");
          console.log(e);
        });
    } else {
      // add new group
      trackEvent({
        type: auditTypes.ACTION,
        action: auditActions.ADD_GROUP,
      });
      addNewGroup(preparedData)
        .then(response => {
          getGroups();
          if (response && response?.data) {
            setIsSubmitted(true); //open message
            setTimeout(() => handleClose(), 2500);
          } else {
            console.log(
              "Warning: Error uploading content" +
                " " +
                response?.message?.toString()
            );
          }
        })
        .catch(e => {
          console.log("Warning: Error uploading content");
          console.log(e);
        });
    }
  };

  return (
    isOpenProp && (
      <div className="container group-detail">
        <div className="modal">
          <section className="modal-main">
            <div className="bo-header">
              <h1 className="bo-title">
                {group ? "Editing of" : "Add new group"}
              </h1>
              {group && <h1 className="bo-title-value"> {`${group.name}`}</h1>}
              <div className="bo-header-close" onClick={handleClose}>
                <img src={CloseIcon} alt="" />
              </div>
            </div>
            <div className="bo-container">
              <form className="bo-form-container">
                <div className="bo-form-section-title">1. GROUP DATA</div>
                <div className="bo-form-section">
                  <div className="bo-form-input-group">
                    <div className="bo-form-input-container">
                      <label>
                        group name<span>*</span>
                      </label>
                      <input
                        name="groupName"
                        type="text"
                        value={groupName}
                        className="enabled"
                        onChange={event => {
                          event.persist();
                          setGroupName(event.target.value);
                        }}
                        ref={register}
                      />
                    </div>

                    <div className="bo-form-input-container">
                      <label>
                        roles associated<span>*</span>
                      </label>
                      <CustomSelect
                        value={selectedRoles}
                        onChange={items => setSelectedRoles(items)}
                        items={Object.values(roles).map(o => ({
                          id: o.id,
                          value: o.name,
                        }))}
                        isMultiple
                        label={"Select roles"}
                      />
                      <div className="bo-form-chip-list">
                        {selectedRoles &&
                          selectedRoles?.map((role, index) => {
                            return (
                              <div
                                className="bo-form-chip"
                                key={`${role}-${index}`}>
                                {role}
                                <img
                                  className="chip-icon"
                                  src={CloseIcon}
                                  alt=""
                                  onClick={() => handleRemoveRole(role)}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    {/*<div className="bo-form-input-container">
                  <label>
                    Add new user
                  </label>
                  <input
                    type="text"
                    onChange={event => setSearchUser(event.target.value)}
                    value={searchUser}
                    placeholder="Email of new user"
                    className="add-user"
                  />
                  <button
                    className="bo-button"
                    onClick={event => handleSearchUser(event)}>
                    FIND
                  </button>
                  {searchUserMessage && (
                    <div className="error-msg">{searchUserMessage}</div>
                  )}
                  <div className="bo-form-chip-list">
                    {usersToAdd && (usersToAdd.map((user) => {
                        return <div className="bo-form-chip" key={user.id}>
                          {user.email}
                          <img
                            className="chip-icon"
                            src={CloseIcon}
                            alt=""
                            onClick={() => handleRemoveUserToAdd(user.id)}
                          />
                        </div>;
                      })
                    )}
                  </div>
                </div>
                */}

                    <div className="bo-form-input-container">
                      <label>Total group members</label>
                      <div className="bo-form-code-value">
                        {group ? group.userCount : "0"}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="bo-submit-container">
                <button
                  type="submit"
                  className="bo-button"
                  disabled={selectedRoles.length === 0 || !Boolean(groupName)}
                  onClick={() => handleSubmit(submit)()}>
                  Submit Request
                </button>
              </div>
            </div>
          </section>
        </div>
        <SuccessMessageModal
          isOpenProp={isSubmitted}
          closeModal={() => setIsSubmitted(false)}
        />
      </div>
    )
  );
};

export default connect(
  ({ profile, organizationalUnits, roles }) => ({
    profile,
    organizationalUnits,
    roles,
  }),
  dispatch => ({
    modifyGroup: payload => dispatch(MODIFY_GROUP(payload)),
    getGroups: () => dispatch(GET_GROUPS()),
    //modifyUser: (payload) => dispatch(MODIFY_USER(payload)),
  })
)(GroupDetail);
