import "./style.scss";

const Step = prop => {
  if (prop.type === "qual") {
    return (
      <div className="bo-steps-container">
        <div className="bo-steps-title">FORM SECTIONS</div>
        <div
          className={
            prop.sectionStates["header"]
              ? "bo-step bo-step-" +
                prop.sectionStates["header"] +
                " bo-step-first"
              : "bo-step bo-step-active bo-step-first"
          }>
          <div className="bo-step-number">1</div>
          <div className="bo-step-name">Header</div>
        </div>
        <div
          className={
            prop.sectionStates["client"]
              ? "bo-step bo-step-" + prop.sectionStates["client"]
              : "bo-step"
          }>
          <div className="bo-step-number">2</div>
          <div className="bo-step-name">Client</div>
        </div>
        <div
          className={
            prop.sectionStates["bip_categorization"]
              ? "bo-step bo-step-" + prop.sectionStates["bip_categorization"]
              : "bo-step"
          }>
          <div className="bo-step-number">3</div>
          <div className="bo-step-name">BIP Categorization</div>
        </div>
        <div
          className={
            prop.sectionStates["details"]
              ? "bo-step bo-step-" + prop.sectionStates["details"]
              : "bo-step"
          }>
          <div className="bo-step-number">4</div>
          <div className="bo-step-name">Details</div>
        </div>
        <div
          className={
            prop.sectionStates["other"]
              ? "bo-step bo-step-" + prop.sectionStates["other"]
              : "bo-step"
          }>
          <div className="bo-step-number">5</div>
          <div className="bo-step-name">Other</div>
        </div>
      </div>
    );
  } else if (prop.type === "offering") {
    return (
      <div className="bo-steps-container">
        <div className="bo-steps-title">FORM SECTIONS</div>
        <div
          className={
            prop.sectionStates["unique_code"]
              ? "bo-step bo-step-" +
                prop.sectionStates["unique_code"] +
                " bo-step-first"
              : "bo-step bo-step-active bo-step-first"
          }>
          <div className="bo-step-number">1</div>
          <div className="bo-step-name">Unique Code</div>
        </div>
        <div
          className={
            prop.sectionStates["description"]
              ? "bo-step bo-step-" + prop.sectionStates["description"]
              : "bo-step"
          }>
          <div className="bo-step-number">2</div>
          <div className="bo-step-name">Offering description</div>
        </div>
      </div>
    );
  } else if (prop.type === "businessChallenges") {
    return (
      <div className="bo-steps-container">
        <div className="bo-steps-title">FORM SECTIONS</div>
        <div
          className={
            prop.sectionStates["main_data"]
              ? "bo-step bo-step-" +
                prop.sectionStates["main_data"] +
                " bo-step-first"
              : "bo-step bo-step-active bo-step-first"
          }>
          <div className="bo-step-number">1</div>
          <div className="bo-step-name">Main Data</div>
        </div>
      </div>
    );
  }
};

export default Step;
