import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";

import HeroTitle from "components/HeroTitle";
import LegendButton from "components/LegendButton";
import LegendHeadbar from "components/LegendHeadbar";
import IndustryAccordion from "../../components/IndustryAccordion";
import { GET_INDUSTRIES } from "../../redux/industries";
import { SET_INDUSTRY_TREE } from "../../redux/industryTree";
import { GET_ORGANIZATIONAL_UNITS } from "../../redux/organizationalUnits";
import { GET_SUBINDUSTRIES } from "../../redux/subindustries";

const Industries = () => {
  const dispatch = useDispatch();
  const getOrganizationalUnits = useCallback(
    () => dispatch(GET_ORGANIZATIONAL_UNITS()),
    [dispatch]
  );
  const getIndustries = useCallback(
    () => dispatch(GET_INDUSTRIES()),
    [dispatch]
  );
  const getSubindustries = useCallback(
    () => dispatch(GET_SUBINDUSTRIES()),
    [dispatch]
  );
  const setIndustryTree = useCallback(
    payload => dispatch(SET_INDUSTRY_TREE(payload)),
    [dispatch]
  );

  const industries = useSelector(state => state.industries);
  const subindustries = useSelector(state => state.subindustries);
  const industryTree = useSelector(state => state.industryTree);
  const organizationalUnits = useSelector(state => state.organizationalUnits);
  const unitsColorMap = useSelector((state) => state.unitsColor);

  const [unitsColor, setUnitsColor] = useState({});
  const { Track } = useTracking();
  const [bipSubindustries, setBipSubindustries] = useState([]);
  let { history } = window;
  const [selected, setSelected] = useState(history.state?.selected);



  useEffect(() => {
    const units = ['SUBINDUSTRY']  // manca mfu estere
    setUnitsColor(Object.fromEntries(  // filter unitsColorMap
      Object.entries(unitsColorMap)
            .filter(([key, value]) => units.includes(key))
    ))
  }, [unitsColorMap]);

  useEffect(() => {
    if (industries.length === 0) {
      getIndustries();
    }
  }, [industries, getIndustries]);

  useEffect(() => {
    if (subindustries.length === 0) {
      getSubindustries();
    }
  }, [subindustries, getSubindustries]);

  useEffect(() => {
    if (industryTree.length === 0) {
      setIndustryTree({
        industries,
        subindustries,
      });
    }
  }, [industryTree, setIndustryTree, industries, subindustries]);

  useEffect(() => {
    history.pushState({ selected: selected }, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (!organizationalUnits?.length) {
      getOrganizationalUnits();
    } else {
      setBipSubindustries(
        organizationalUnits.filter(org => org.type === "SUBINDUSTRY")
      );
    }
  }, [organizationalUnits, getOrganizationalUnits]);

  return (
    industries.length > 0 &&
    organizationalUnits.length > 0 && (
      <div className="container">
        <HeroTitle
          type="INDUSTRIES"
          title="The industries we are transforming"
          subtitle="Our industry expertise, with related offering and quals"
          blueVariant
        />
      <div className="legend-headbar">
        <LegendHeadbar legend={unitsColor} />
      </div>
        <div className="list-industries">
          {industries.map(ind => {
            const array = [];
            industryTree[ind.id]?.forEach(item => array.push(item?.id));
            return (
              <Track key={ind.id}>
                <IndustryAccordion
                  items={bipSubindustries.filter(sub =>
                    array.includes(sub?.subIndustryId)
                  )}
                  {...ind}
                  isSelected={selected === ind.id}
                  onClick={() => {
                    if (selected === ind.id) {
                      return setSelected(0);
                    }
                    return setSelected(ind.id);
                  }}
                  legend={unitsColorMap}
                />
              </Track>
            );
          })}
        </div>
        <LegendButton legend={unitsColor} />
      </div>
    )
  );
};

export default React.memo(Industries);
