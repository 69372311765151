import { Link } from "react-router-dom";
import routes from "routes";
import ChevronRight from "images/chevron_right.svg";


import "./style.scss";

const EventLink = props => {
  return (
    <Link
      to={{
        pathname: routes.EVENTS,
        search: `?id=${props.id}`,
      }}
      className="event-entry">
      <div>
        <div className="event-date">{props.date}</div>
        <div className="event-name">{props.name}</div>
        <div className="event-manager">{props.manager}</div>
      </div>
      <div>
        <img src={ChevronRight} alt="" className="event-arrow" />
      </div>
    </Link>
  );
};

export default EventLink;
