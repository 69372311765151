import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTracking } from "react-tracking";
import {
  addOfferingDeck,
  getOfferingDeckByOrganizationalUnitId,
} from "services/offeringDeck";
import {
  getOfferingTopic,
  save,
  update,
  getOfferingTopicError,
} from "services/offeringTopic";
import {
  addOnePager,
  getOnePagerByOrganizationalUnitId,
} from "services/onePager";
import { getUser, getUserByEmail } from "services/user";
import { auditActions, auditTypes, entityType, unitTypes } from "utils/commons";
import {
  checkField,
  getOrganizationalUnitsIdsByNames,
  getOrgUnit,
  getSubIndustry,
  objectToArray,
} from "utils/utils";
import CustomSelect from "components/CustomSelect";
import TagsInput from "components/TagsInput";
import CloseIcon from "images/cancel.svg";
import { getContributorQualsLight } from "../../services/backoffice";
import { getTreeLevels } from "../../services/treeView";
import CustomOUSelect from "../CustomOUSelect";
import FileErrorsBox from "../FileErrorsBox";

import Step from "./Step";
import "./style.scss";

const toggleFields = ["isCustomerPublishable", "isTrendy", "isInstitutional"];
const multipleCustomSelectFields = [
  "epics",
  "tags",
  "qualificationCodes",
  "businessChallengeCodes",
  "secondaryOrganizationalUnitIds",
  "industries",
];
const mandatoryMultipleFields = ["epics"];
const mandatorySingleFields = ["offeringDeckId"];
const singleCustomSelectFields = ["treeLevelId"];

const OfferingModal = ({
  handleClose,
  show,
  code,
  profile,
  coe,
  organizationalUnits,
  dictionaries,
  getDictionary,
  getOrganizationalUnits,
  businessChallenges,
  cleanStore,
  industries,
  subindustries,
  industryTree,
}) => {
  const [selectedValues, setSelectedValues] = useState({});
  const [referenceEmails, setReferenceEmails] = useState({});
  const [toggles, setToggles] = useState({
    isTrendy: false,
    isInstitutional: false,
  });
  const [references, setReferences] = useState({});
  const [deckFile, setDeckFile] = useState(null);
  const [cardDescription, setCardDescription] = useState("");
  const [selectedCoe, setSelectedCoe] = useState(null);
  const [offeringDecks, setOfferingDecks] = useState([]);
  const [deckUploadError, setDeckUploadError] = useState(false);
  const [deckUploadUnsupportedFile, setDeckUploadUnsupportedFile] =
    useState(false);
  const [loader, setLoader] = useState(false);
  const [isLoadingQuals, setIsLoadingQuals] = useState(false);
  const [searchMessage, setSearchMessage] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [arrBC, setArrayBC] = useState([]);
  const [quals, setQuals] = useState([]);
  const [arrQuals, setArrQuals] = useState([]);
  const [onePagers, setOnePagers] = useState([]);
  const [pagerUploadError, setPagerUploadError] = useState(false);
  const [pagerUploadUnsupportedFile, setPagerUploadUnsupportedFile] =
    useState(false);
  const [pagerFile, setPagerFile] = useState(null);
  const [isUploadingPager, setIsUploadingPager] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [treeListArray, setTreeListArray] = useState();
  const ouTree = { COE: [], VALUE_LINE: [] };
  const [ouArray, setOuArray] = useState([]);
  const [sectionStates, setSectionStates] = useState({
    unique_code: "active",
    description: null,
  });
  const [documentErrors, setDocumentErrors] = useState();

  const {
    register,
    handleSubmit,
    setValue,
    errors,
    setError,
    reset,
    getValues,
    watch,
  } = useForm();

  const { trackEvent } = useTracking();

  const [canPublish, setCanPublish] = useState(false);
  const watchOrganizationalUnitId = watch("organizationalUnitId");
  const watchDescription = watch("description");
  const watchPPT = watch("ppt");
  const [bibSubArray, setBipSubArray] = useState([]);
  const bipSubIndustries = [];

  const sectionHeaders = {
    unique_code: useRef(null),
    description: useRef(null),
  };

  const subIndustryTree = () => {
    let obj = {};
    Object.keys(industryTree).map(item => (obj[item] = []));
    bipSubIndustries.length > 0 &&
      bipSubIndustries.map(bipSub => {
        const industryId = getSubIndustry(
          subindustries,
          bipSub.subIndustryId
        )?.industryId;
        obj[industryId].push(bipSub);
      });
    return obj;
  };

  const transformTreeListArray = childrenList => {
    const arr = [];
    const asd = array => {
      array?.map(item => {
        if (item?.childrenList) {
          asd(item.childrenList);
        }
        arr.push({ id: item.id, value: item.label });
      });
    };
    asd(childrenList);
    return arr;
  };

  const handleOption = (selectName, option) => {
    setSelectedValues({ ...selectedValues, [selectName]: option });
    if (selectName === "offeringDeckId") {
      setDeckUploadError(false);
      setDeckUploadUnsupportedFile(false);
    }
    if (selectName === "onePagerId") {
      setPagerUploadError(false);
      setPagerUploadUnsupportedFile(false);
    }
  };

  const handleRemoveOptionValue = (inputName, value = null) => {
    setSelectedValues({
      ...selectedValues,
      [inputName]: value
        ? selectedValues[inputName].filter(v => v !== value)
        : undefined,
    });
  };

  const handleDeckFileChange = event => {
    event.persist();
    if (event.target.files[0]) {
      if (
        event.target.files[0].name.endsWith(".pptx") ||
        event.target.files[0].name.endsWith(".pdf")
      ) {
        setIsUploading(true);
        setDeckUploadUnsupportedFile(false);
        try {
          const reader = new FileReader();
          const fileDetected = event.target.files[0].name.endsWith(".pptx")
            ? "PPTX"
            : "PDF";
          reader.onload = () => {
            const params = {
              originalFileName: event.target.files[0].name,
              file: reader.result.toString().split("base64,")[1],
              organizationalUnitId: selectedCoe.id,
              fileType: fileDetected,
            };
            addOfferingDeck(params)
              .then(res => {
                if (res?.data) {
                  getOfferingDeckByOrganizationalUnitId(
                    coe ? coe.id : res.data?.organizationalUnitId
                  ).then(resD => {
                    if (resD?.data) {
                      setDeckUploadError(false);
                      setOfferingDecks(resD.data);
                      setSelectedValues({
                        ...selectedValues,
                        offeringDeckId: resD.data.find(
                          d => d.id === res.data.id
                        )?.originalFileName,
                      });
                      setDeckFile({
                        name: event.target.files[0].name,
                      });
                    } else {
                      setDeckUploadError(true);
                      setErrorMsg(res?.message);
                    }
                  });
                } else {
                  setDeckUploadError(true);
                  setErrorMsg(res?.message);
                }
                setIsUploading(false);
              })
              .catch(error => {
                setIsUploading(false);
                console.error(`Error Uploading file: ${error}`);
              });
          };
          reader.readAsDataURL(event.target.files[0]);
        } catch (error) {
          setIsUploading(false);
          setDeckUploadError(true);
          console.error(`Warning! Error uploading file: ${error}`);
          console.error(`Error addOfferingDeck: ${error}`);
        }
      } else {
        setDeckUploadUnsupportedFile(true);
      }
    }
  };

  const handlePagerFileChange = event => {
    event.persist();
    if (event.target.files[0]) {
      if (
        event.target.files[0].name.endsWith(".pptx") ||
        event.target.files[0].name.endsWith(".pdf")
      ) {
        setIsUploadingPager(true);
        setPagerUploadUnsupportedFile(false);
        try {
          const reader = new FileReader();
          const fileDetected = event.target.files[0].name.endsWith(".pptx")
            ? "PPTX"
            : "PDF";
          reader.onload = () => {
            const params = {
              originalFileName: event.target.files[0].name,
              file: reader.result.toString().split("base64,")[1],
              organizationalUnitId: selectedCoe.id,
              fileType: fileDetected,
            };
            addOnePager(params)
              .then(res => {
                if (res?.data) {
                  getOnePagerByOrganizationalUnitId(
                    coe ? coe.id : profile?.organizationalUnitId
                  ).then(resD => {
                    if (resD?.data) {
                      setPagerUploadError(false);
                      setOnePagers(resD.data);
                      setSelectedValues({
                        ...selectedValues,
                        onePagerId: resD.data.find(d => d.id === res.data.id)
                          ?.originalFileName,
                      });
                      setPagerFile({
                        name: event.target.files[0].name,
                      });
                    } else {
                      setPagerUploadError(true);
                      setErrorMsg(resD?.data.message);
                    }
                  });
                } else {
                  setPagerUploadError(true);
                  setErrorMsg(res?.message);
                }
                setIsUploadingPager(false);
              })
              .catch(error => {
                setIsUploadingPager(false);
                console.error(`Warning! Error uploading file: ${error}`);
              });
          };
          reader.readAsDataURL(event.target.files[0]);
        } catch (error) {
          setIsUploadingPager(false);
          setPagerUploadError(true);
          console.error(`Error addOfferingDeck: ${error}`);
        }
      } else {
        setPagerUploadUnsupportedFile(true);
      }
    }
  };

  const handleReferenceSearch = (event, referenceName) => {
    event?.preventDefault();
    setSearchMessage("");
    getUserByEmail(referenceEmails[referenceName]).then(res => {
      if (res?.data?.users?.length) {
        setReferences({ ...references, [referenceName]: res.data.users[0] });
      } else {
        setSearchMessage("No user found");
      }
    });
  };

  const handleReferenceEmailInput = (referenceName, value) => {
    setReferenceEmails({ ...referenceEmails, [referenceName]: value });
  };

  const handleToggle = toggleName => {
    setToggles({ ...toggles, [toggleName]: !toggles[toggleName] });
  };

  const onSubmit = data => {
    setLoader(true);
    data.isTrendy = data.isTrendy === "true";
    data.isInstitutional = data.isInstitutional === "true";
    data.treeLevelId = treeListArray?.find(
      d => d.value === selectedValues["treeLevelId"]
    )?.id;

    Object.keys(data).forEach(k => {
      if (
        !data[k] &&
        k !== "isTrendy" &&
        k !== "isInstitutional" &&
        k !== "secondaryOrganizationalUnitIds" &&
        k !== "industries"
      ) {
        delete data[k];
      }
      if (k === "secondaryOrganizationalUnitIds") {
        data[k] = getOrganizationalUnitsIdsByNames(organizationalUnits, [
          ...(selectedValues[k] ?? []),
          ...(selectedValues["industries"] ?? []),
        ]);
        delete data["industries"];
      }
    });
    data.referenceContactIds = references.main
      ? [references.main.id]
      : undefined;
    if (!code) {
      delete data.progressive;
      save(data)
        .then(response => {
          if (response?.data) {
            trackEvent({
              code: code,
              action: auditActions.EDIT_AND_PUBLISH,
              resourceType: entityType.OFFERING_TOPIC,
              type: auditTypes.ACTION,
            });
            handleClose();
            setLoader(false);
          } else {
            console.error(
              `Warning! Error updating content: ${response?.message?.toString()} `
            );
            setLoader(false);
          }
        })
        .catch(error => {
          console.error(`OfferingModal error : ${error}`);
          setLoader(false);
        });
    } else {
      data.code = code;
      data.progressive = parseInt(data.progressive);
      update(code, data)
        .then(response => {
          if (response?.data) {
            trackEvent({
              code,
              action: auditActions.EDIT_AND_PUBLISH,
              resourceType: entityType.OFFERING_TOPIC,
              type: auditTypes.ACTION,
            });
            cleanStore({ code, documentType: entityType.OFFERING_TOPIC });
            handleClose();
          } else {
            console.error(
              `Warning! Error updating content: ${response?.message?.toString()} `
            );
          }
          setLoader(false);
        })
        .catch(error => {
          console.error(`OfferingModal error : ${error}`);
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    organizationalUnits.length
      ? organizationalUnits.map(ou =>
          ou.type.toUpperCase() === unitTypes.SUBINDUSTRY
            ? bipSubIndustries.push(ou)
            : ou.type.toUpperCase() !== unitTypes.MFU &&
              ouTree[ou.type.toUpperCase()].push(ou)
        )
      : getOrganizationalUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => setOuArray(objectToArray(ouTree)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => setBipSubArray(objectToArray(subIndustryTree())),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setArrayBC(
      Object.keys(businessChallenges).map(k => ({
        id: k,
        value: businessChallenges[k].description,
      }))
    );
  }, [businessChallenges]);

  useEffect(() => {
    setArrQuals(
      quals
        ?.filter(q => {
          if (selectedCoe) {
            return selectedCoe.type === "SUBINDUSTRY"
              ? q.organizationalUnitType === "MFU"
              : q.organizationalUnitId === selectedCoe.id;
          }
          return true;
        })
        ?.map(v => ({
          id: v.code,
          value: `${v.code.split("-")[0]} - ${v.title}`,
          type: v.organizationalUnitType,
        }))
    );
  }, [quals, selectedCoe]);

  useEffect(() => {
    let isMounted = true;
    if (selectedCoe) {
      try {
        (async () => {
          setIsLoadingQuals(true);
          const { data } = await getContributorQualsLight();
          if (data && data.length) {
            const quals = data.map(q => ({
              ...q,
              documentType: entityType.QUALIFICATION,
            }));
            if (!isMounted) return;
            setQuals(quals);
            setIsLoadingQuals(false);
          }
        })();
      } catch (error) {
        console.error(`Error offering quals data: ${error}`);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [selectedCoe]);

  useEffect(() => {
    let isMounted = true;
    if (selectedCoe?.id) {
      try {
        (async () => {
          const res = await getTreeLevels(selectedCoe?.id);
          if (!isMounted) return;
          setTreeListArray([
            ...transformTreeListArray(res?.data?.childrenList),
            { id: res?.data.id, value: res?.data.label },
          ]);
        })();
      } catch (error) {
        console.error(`Error Drop: ${error}`);
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoe?.id]);

  useEffect(() => {
    let isMounted = true;
    if (show && code) {
      reset();
      getOfferingTopic(code).then(res => {
        const data = res?.data;
        const locSelectedValues = { ...selectedValues };
        let locToggles = toggles;

        Object.keys(data).forEach(k => {
          if (
            multipleCustomSelectFields.includes(k) ||
            ["offeringDeckId", "onePagerId"].includes(k)
          ) {
            if (k === "secondaryOrganizationalUnitIds") {
              let secOU = data[k];
              let arrayOU = [];
              let arrayIndustry = [];
              secOU.forEach(item => {
                const fullOU = getOrgUnit(organizationalUnits, item);
                if (fullOU.type.toUpperCase() === "SUBINDUSTRY") {
                  arrayIndustry.push(fullOU.name);
                } else {
                  arrayOU.push(fullOU.name);
                }
              });
              locSelectedValues[k] = arrayOU;
              locSelectedValues["industries"] = arrayIndustry;
            } else if (k === "industries") {
            } else {
              locSelectedValues[k] = data[k];
            }
          } else if (singleCustomSelectFields.includes(k)) {
            locSelectedValues[k] = treeListArray?.find(
              item => item.id === data[k]
            )?.value;
          } else if (toggleFields.includes(k)) {
            locToggles[k] = data[k];
          } else if (
            k === "referenceContactIds" &&
            data[k]?.length &&
            isMounted
          ) {
            getUser(data[k][0]).then(res => {
              const user = res?.data;
              setReferenceEmails({ ...referenceEmails, main: user.email });
              setReferences({ ...references, main: user });
            });
          } else {
            if (!isMounted) return;
            setValue(k, data[k]);
          }
        });
        const desc =
          data?.description?.substr(0, 80) +
          (data?.description?.length > 80 ? "..." : "");
        if (!isMounted) return;
        setCardDescription(desc);

        const organizationalUnit = organizationalUnits.find(
          x => x.id === data.organizationalUnitId
        );
        if (!isMounted) return;
        setSelectedValues({
          ...locSelectedValues,
        });
        if (!isMounted) return;
        setSelectedCoe(organizationalUnit);
        /* setSubindustry({
          ...subindustries.find(sub => sub.id === selectedValues["subIndustryId"]),
          industry: industries.find(
            ind => ind.id === subindustries.find(
              sub => sub.id === selectedValues["subIndustryId"])?.industryId)?.name});*/
      });
      getOfferingTopicError(code).then(errorResponse => {
        setDocumentErrors(errorResponse?.data);
      });
    } else if (show && !code && isMounted) {
      setSelectedValues({});
      setReferences({});
      setReferenceEmails({});
      //setSearchDocuments({});
      reset();
      const organizationalUnit = coe ? coe : profile?.organizationalUnit;
      setSelectedCoe(organizationalUnit);
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, show, treeListArray]);

  useEffect(() => {
    let isMounted = true;
    //todo - check if  selectedValues.offeringDeckId is needed
    //if (selectedCoe && selectedValues.offeringDeckId)
    if (selectedCoe) {
      getOnePagerByOrganizationalUnitId(selectedCoe.id).then(res1 => {
        if (res1?.data) {
          getOfferingDeckByOrganizationalUnitId(selectedCoe.id).then(res2 => {
            if (res2?.data && isMounted) {
              setOfferingDecks(res2.data);
              const offeringDeckId = res2.data.find(
                d =>
                  d.id === selectedValues.offeringDeckId ||
                  d.originalFileName === selectedValues.offeringDeckId
              )?.originalFileName;
              const onePagerId = res1?.data.find(
                d =>
                  d.id === selectedValues.onePagerId ||
                  d.originalFileName === selectedValues.onePagerId
              )?.originalFileName;
              setSelectedValues(prevState => ({
                ...prevState,
                onePagerId,
                offeringDeckId,
              }));
            }
          });
          if (!isMounted) return;
          setOnePagers(res1.data);
        }
      });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoe, selectedValues.offeringDeckId]);

  useEffect(() => {
    let isMounted = true;

    getDictionary({ type: "EPIC" });
    getOrganizationalUnits();

    const interval = setInterval(() => {
      if (show && isMounted) {
        let states = {};
        let foundActive = false;
        let sectionNames = Object.keys(sectionHeaders);
        for (let i = sectionNames.length - 1; i >= 0; i--) {
          let pos =
            sectionHeaders[sectionNames[i]].current?.getBoundingClientRect().y;
          if (pos < window.innerHeight / 2 || i === 0) {
            if (!foundActive) {
              states[sectionNames[i]] = "active";
              foundActive = true;
            } else states[sectionNames[i]] = "completed";
          } else states[sectionNames[i]] = null;
        }
        if (!isMounted) return;
        setSectionStates({ ...sectionStates, ...states });
      }
    }, 300);
    return () => {
      clearInterval(interval);
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    let publishable = true;

    mandatoryMultipleFields.forEach(f => {
      publishable = publishable && selectedValues[f]?.length > 0;
    });
    mandatorySingleFields.forEach(f => {
      publishable = publishable && !!selectedValues[f];
    });
    publishable =
      publishable &&
      !!getValues("organizationalUnitId") &&
      !!getValues("description") &&
      Object.keys(references).length > 0 &&
      (!documentErrors || documentErrors?.errorIssueList?.length === 0);
    setCanPublish(publishable);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    watchOrganizationalUnitId,
    watchDescription,
    watchPPT,
    selectedValues,
    references,
  ]);

  function hasIssues(issueList) {
    return issueList?.length > 0;
  }

  function getAlertCardClass(documentErrors) {
    if (hasIssues(documentErrors?.errorIssueList)) {
      return "issue-alert-with-errors";
    } else if (hasIssues(documentErrors?.warningIssueList)) {
      return "issue-alert-with-warnings";
    } else if (hasIssues(documentErrors?.infoIssueList)) {
      return "issue-alert-with-info";
    } else {
      return "issue-alert-no-issues";
    }
  }

  function renderIssueListItem(issueList, issueType) {
    return (
      hasIssues(issueList) && (
        <li>
          {issueList?.length} {issueType}
        </li>
      )
    );
  }

  return (
    show && (
      <div className="modal">
        <section className="modal-main">
          <div className="bo-header">
            <h1 className="bo-title">{code ? "Editing of" : "Creating of"}</h1>
            <h1 className="bo-title-value">
              {code ? cardDescription.toString() : "New Offering"}
            </h1>

            <div className="bo-header-close" onClick={handleClose}>
              <img src={CloseIcon} alt="" />
            </div>
          </div>
          <div className="bo-container">
            <Step sectionStates={sectionStates} type="offering" />
            <div className="legend">
              <div>
                <label>
                  <span>*</span>mandatory for saving
                </label>
              </div>
              {(hasIssues(documentErrors?.errorIssueList) ||
                hasIssues(documentErrors?.warningIssueList) ||
                hasIssues(documentErrors?.infoIssueList)) && (
                <div
                  className={`issue-alert ${getAlertCardClass(
                    documentErrors
                  )}`}>
                  <label>
                    <span>Attached Document quality check:</span>
                    <ul>
                      {renderIssueListItem(
                        documentErrors?.warningIssueList,
                        "warnings"
                      )}
                      {renderIssueListItem(
                        documentErrors?.errorIssueList,
                        "blocking errors"
                      )}
                      {renderIssueListItem(
                        documentErrors?.infoIssueList,
                        "informational issues"
                      )}
                    </ul>
                    click <a href="#documentErrorBox">HERE</a> to check them.
                  </label>
                </div>
              )}
            </div>
            <form className="bo-form-container bo-offering-form">
              <div
                className="bo-form-section-title"
                ref={sectionHeaders.unique_code}>
                1. UNIQUE CODE
              </div>
              <div className="bo-form-section">
                <div className="bo-form-subtitle">Offering unique code</div>
                <div className="bo-form-input-group">
                  <div className="bo-form-input-container">
                    <label>
                      owner unit<span>*</span>
                    </label>
                    <input
                      name="competence-ceo"
                      type="text"
                      value={selectedCoe?.name || ""}
                      readOnly
                    />
                    <input
                      name="organizationalUnitId"
                      type="hidden"
                      value={selectedCoe?.id || ""}
                      ref={register}
                    />
                  </div>
                  <div className="bo-form-input-container">
                    <label>Contributor units (if applicable)</label>
                    <CustomOUSelect
                      label="Select one or more"
                      value={
                        selectedValues["secondaryOrganizationalUnitIds"] || []
                      }
                      items={
                        ouArray &&
                        ouArray?.reduce((acc, el) => {
                          if (el?.id !== selectedCoe?.id) {
                            if (el.id) {
                              acc.push({
                                type: "option",
                                id: el.id,
                                value: el.name,
                                code: el.code,
                              });
                            } else {
                              acc.push({
                                type: "title",
                                id: el,
                                value: el,
                                code: "",
                              });
                            }
                          }
                          return acc;
                        }, [])
                      }
                      onChange={val =>
                        handleOption("secondaryOrganizationalUnitIds", val)
                      }
                      isMultiple
                      isAutocomplete
                    />
                    <input
                      name="secondaryOrganizationalUnitIds"
                      type="hidden"
                      value={
                        selectedValues["secondaryOrganizationalUnitIds"] || ""
                      }
                      ref={register}
                    />
                    {selectedValues["secondaryOrganizationalUnitIds"] && (
                      <div className="bo-form-chip-list">
                        {selectedValues["secondaryOrganizationalUnitIds"].map(
                          (v, i) => (
                            <div key={i} className="bo-form-chip">
                              {v}
                              <img
                                className="chip-icon"
                                src={CloseIcon}
                                alt=""
                                onClick={() =>
                                  handleRemoveOptionValue(
                                    "secondaryOrganizationalUnitIds",
                                    v
                                  )
                                }
                              />
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                  <div className="bo-form-input-container">
                    <label>
                      industries for which offering is relevant <br /> (in case
                      offering is cross industry, leave blank)
                    </label>
                    <CustomOUSelect
                      value={selectedValues["industries"] || []}
                      items={bibSubArray.map(v => {
                        if (v.id) {
                          return {
                            type: "option",
                            id: v.id,
                            value: v.name,
                            code: v.code,
                          };
                        }
                        return {
                          type: "title",
                          id: v,
                          value: industries.find(i => i.id === v).name,
                          code: industries.find(i => i.id === v).code,
                        };
                      })}
                      onChange={val => handleOption("industries", val)}
                      isMultiple
                      closeOnSelect
                    />
                    <input
                      type="hidden"
                      name="industries"
                      value={selectedValues["industries"] || ""}
                      ref={register}
                    />
                    {selectedValues["industries"] && (
                      <div className="bo-form-chip-list">
                        {selectedValues["industries"].map((item, index) => (
                          <div key={index} className="bo-form-chip">
                            {item}
                            <img
                              className="chip-icon"
                              src={CloseIcon}
                              alt=""
                              onClick={() =>
                                handleRemoveOptionValue("industries", item)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {code && (
                    <div className="bo-form-input-container">
                      <label>
                        progressive number<span>*</span>
                      </label>
                      <input
                        name="progressive"
                        type="number"
                        ref={register}
                        readOnly
                      />
                      <div className="bo-form-code">
                        <div className="bo-form-code-label">
                          Unique code generated:
                        </div>
                        <div className="bo-form-code-value">{code}</div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="bo-form-input-container">
                  <label>Offering Level</label>
                  <CustomSelect
                    label="Select an offering level"
                    value={selectedValues["treeLevelId"] || ""}
                    items={treeListArray}
                    onChange={val => handleOption("treeLevelId", val)}
                    isAutocomplete
                    closeOnSelect
                  />
                  <input
                    type="hidden"
                    name="treeLevelId"
                    value={
                      selectedValues["treeLevelId"]
                        ? treeListArray?.find(
                            d => d.id === selectedValues["treeLevelId"]
                          )?.id || ""
                        : ""
                    }
                    ref={register}
                  />
                  <div className="bo-form-chip-list">
                    {selectedValues["treeLevelId"] && (
                      <div className="bo-form-chip">
                        {selectedValues["treeLevelId"]}
                        <img
                          className="chip-icon"
                          src={CloseIcon}
                          alt=""
                          onClick={() => handleRemoveOptionValue("treeLevelId")}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="bo-form-section-title"
                ref={sectionHeaders.description}>
                2. OFFERING DESCRIPTION
              </div>
              <div className="bo-form-section">
                <div className="bo-form-input-group">
                  <div className="bo-text-hint large">
                    Here you can specify every topic related to your offering
                    deck. Each topic will become a content item on Myracle (you
                    will be able to manage them from "offering" page in
                    contributor section).
                  </div>
                  <br />
                  <div className="bo-form-input-container">
                    <label className="bo-large-label">
                      Offering brief description<span>*</span>
                      <span className="bo-input-counter">
                        {getValues("description")?.length || 0}/255
                      </span>
                    </label>
                    <textarea
                      name="description"
                      placeholder="Write offering description here..."
                      rows={10}
                      maxLength={255}
                      onChange={event => {
                        checkField("description", event, 255, setError);
                      }}
                      onFocus={event => {
                        checkField("description", event, 255, setError);
                      }}
                      ref={register({ maxLength: 255 })}
                    />
                    {errors.description && errors.description.message && (
                      <p>{errors.description.message}</p>
                    )}
                  </div>
                  <div className="bo-form-input-container">
                    <label>Offering keywords</label>
                    <TagsInput
                      label="Hit enter to add each keyword"
                      value={selectedValues["tags"] || []}
                      onChange={val => handleOption("tags", val)}
                      icon={"add-icon"}
                      id="input-with-icon__input-ot"
                    />
                    {selectedValues["tags"] &&
                      selectedValues["tags"].map((v, i) => (
                        <input
                          key={i}
                          type="hidden"
                          name={"tags." + i}
                          ref={register}
                          value={v}
                        />
                      ))}
                    {selectedValues["tags"] && (
                      <div className="bo-form-chip-list">
                        {selectedValues["tags"].map((v, i) => (
                          <div key={i} className="bo-form-chip">
                            {v}
                            <img
                              className="chip-icon"
                              src={CloseIcon}
                              alt=""
                              onClick={() => handleRemoveOptionValue("tags", v)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="bo-form-input-container">
                  <label>
                    offering epics<span>*</span>
                  </label>
                  <CustomSelect
                    label="Select epics"
                    value={selectedValues["epics"] || []}
                    items={
                      dictionaries?.EPIC &&
                      dictionaries?.EPIC.map(o => ({
                        id: o.id,
                        value: o.name,
                      }))
                    }
                    onChange={val => handleOption("epics", val)}
                    isMultiple
                    isAutocomplete
                  />
                  {selectedValues["epics"] &&
                    selectedValues["epics"].map((v, i) => (
                      <input
                        key={i}
                        type="hidden"
                        name={"epics." + i}
                        ref={register}
                        value={v}
                      />
                    ))}
                  {selectedValues["epics"] && (
                    <div className="bo-form-chip-list">
                      {selectedValues["epics"].map((v, i) => (
                        <div key={i} className="bo-form-chip">
                          {v}
                          <img
                            className="chip-icon"
                            src={CloseIcon}
                            alt=""
                            onClick={() => handleRemoveOptionValue("epics", v)}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="bo-form-input-container">
                  <label>offering related business challenges</label>
                  <CustomSelect
                    label="Select business challenges"
                    value={
                      selectedValues["businessChallengeCodes"]
                        ? arrBC
                            .filter(
                              bc =>
                                selectedValues[
                                  "businessChallengeCodes"
                                ].includes(bc.id) ||
                                selectedValues[
                                  "businessChallengeCodes"
                                ].includes(bc.value)
                            )
                            .map(bc => bc.value)
                        : []
                    }
                    items={arrBC}
                    onChange={val =>
                      handleOption("businessChallengeCodes", val)
                    }
                    isMultiple
                    isAutocomplete
                  />
                  {selectedValues["businessChallengeCodes"] &&
                    selectedValues["businessChallengeCodes"].map((v, i) => (
                      <input
                        key={i}
                        type="hidden"
                        name={"businessChallengeCodes." + i}
                        ref={register}
                        value={
                          arrBC.find(bc => bc.id === v || bc.value === v)?.id
                        }
                      />
                    ))}
                  {selectedValues["businessChallengeCodes"] && (
                    <div className="bo-form-chip-list">
                      {selectedValues["businessChallengeCodes"].map((v, i) => (
                        <div key={i} className="bo-form-chip">
                          {arrBC
                            .find(bc => bc.id === v || bc.value === v)
                            ?.value.slice(0, 40) + "..."}
                          <img
                            className="chip-icon"
                            src={CloseIcon}
                            alt=""
                            onClick={() =>
                              handleRemoveOptionValue(
                                "businessChallengeCodes",
                                v
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="bo-form-input-container">
                  <label>offering related quals</label>
                  <CustomSelect
                    label="Type your search criteria"
                    value={
                      selectedValues["qualificationCodes"]
                        ? arrQuals
                            .filter(
                              q =>
                                selectedValues["qualificationCodes"].includes(
                                  q.id
                                ) ||
                                selectedValues["qualificationCodes"].includes(
                                  q.value
                                )
                            )
                            .map(q => q.value)
                        : []
                    }
                    items={arrQuals}
                    onChange={val => handleOption("qualificationCodes", val)}
                    isMultiple
                    searchOnTyping
                    isAutocomplete
                  />
                  {selectedValues["qualificationCodes"] &&
                    selectedValues["qualificationCodes"].map((v, i) => (
                      <input
                        key={i}
                        type="hidden"
                        name={"qualificationCodes." + i}
                        ref={register}
                        value={
                          arrQuals.find(q => q.id === v || q.value === v)?.id ||
                          ""
                        }
                      />
                    ))}
                  {selectedValues["qualificationCodes"] && (
                    <div className="bo-form-chip-list">
                      {selectedValues["qualificationCodes"].map((v, i) => {
                        const el = arrQuals.find(
                          q => q.id === v || q.value === v
                        )?.value;
                        if (el)
                          return (
                            <div key={i} className="bo-form-chip">
                              {el}
                              <img
                                className="chip-icon"
                                src={CloseIcon}
                                alt=""
                                onClick={() =>
                                  handleRemoveOptionValue(
                                    "qualificationCodes",
                                    v
                                  )
                                }
                              />
                            </div>
                          );
                      })}
                    </div>
                  )}
                </div>

                <div className="bo-form-input-group">
                  <div className="bo-form-subtitle">Main reference person</div>
                  <div className="bo-form-input-container">
                    <div className="bo-form-input-container">
                      <label>
                        e-mail<span>*</span>
                      </label>
                      <input
                        type="text"
                        onChange={event =>
                          handleReferenceEmailInput("main", event.target.value)
                        }
                        value={referenceEmails.main || ""}
                        placeholder="E-mail of qual main reference"
                      />
                      <button
                        className="bo-button"
                        onClick={event => handleReferenceSearch(event, "main")}>
                        FIND
                      </button>
                      {searchMessage && (
                        <div className="error-msg">{searchMessage}</div>
                      )}
                    </div>
                    <label>name</label>
                    <input
                      type="text"
                      readOnly
                      value={references.main?.name || ""}
                      placeholder="Name of qual main reference"
                    />
                  </div>
                  <div className="bo-form-input-container">
                    <label>surname</label>
                    <input
                      type="text"
                      readOnly
                      value={references.main?.surname || ""}
                      placeholder="Surname of qual main reference"
                    />
                  </div>
                  <div className="bo-form-input-container">
                    <label>phone number</label>
                    <input
                      type="text"
                      readOnly
                      value={references.main?.phone || ""}
                      placeholder="Phone number of qual main reference"
                    />
                  </div>
                  <div className="bo-form-input-container">
                    <label>microsoft teams account</label>
                    <input
                      type="text"
                      readOnly
                      placeholder="Link to microsoft teams account"
                    />
                  </div>
                </div>
                <div className="bo-form-input-group">
                  <div className="bo-form-input-container">
                    <label>is Trendy?</label>
                    <div
                      className="bo-form-toggle-input"
                      onClick={_ => handleToggle("isTrendy")}>
                      <div
                        className={
                          toggles["isTrendy"] ? "toggle active" : "toggle"
                        }>
                        <div className="handle" />
                      </div>
                      <div className="text-value">
                        {toggles["isTrendy"] ? "YES" : "NO"}
                      </div>
                      <input
                        type="hidden"
                        name="isTrendy"
                        value={toggles["isTrendy"]?.toString()}
                        ref={register}
                      />
                    </div>
                  </div>
                </div>
                <div className="bo-form-input-group">
                  <div className="bo-form-input-container">
                    <label>is Institutional?</label>
                    <div
                      className="bo-form-toggle-input"
                      onClick={_ => handleToggle("isInstitutional")}>
                      <div
                        className={
                          toggles["isInstitutional"]
                            ? "toggle active"
                            : "toggle"
                        }>
                        <div className="handle" />
                      </div>
                      <div className="text-value">
                        {toggles["isInstitutional"] ? "YES" : "NO"}
                      </div>
                      <input
                        type="hidden"
                        name="isInstitutional"
                        value={toggles["isInstitutional"]?.toString()}
                        ref={register}
                      />
                    </div>
                  </div>
                </div>
                <div className="bo-form-input-group">
                  <div className="bo-form-input-container">
                    <label>One Pager</label>
                    <CustomSelect
                      label="Select a one pager"
                      value={selectedValues["onePagerId"] || ""}
                      items={
                        onePagers &&
                        onePagers.map(i => ({
                          id: i.id,
                          value: i.originalFileName,
                        }))
                      }
                      onChange={val => {
                        handleOption("onePagerId", val);
                      }}
                      isAutocomplete
                      closeOnSelect
                    />
                    <input
                      type="hidden"
                      name="onePagerId"
                      value={
                        selectedValues["onePagerId"]
                          ? onePagers?.find(
                              d =>
                                d.originalFileName ===
                                selectedValues["onePagerId"]
                            )?.id || ""
                          : ""
                      }
                      ref={register}
                    />
                    <div className="bo-form-chip-list">
                      {selectedValues["onePagerId"] && (
                        <div className="bo-form-chip">
                          {selectedValues["onePagerId"]}
                          <img
                            className="chip-icon"
                            src={CloseIcon}
                            alt=""
                            onClick={() =>
                              handleRemoveOptionValue("onePagerId")
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="bo-form-input-container">
                    <label>if no present, upload one Pager here</label>
                    <label htmlFor="pagerFileInput" className="file-label">
                      {pagerFile
                        ? "File: " + pagerFile.name
                        : "Upload .pptx / .pdf file here"}
                    </label>
                    <input
                      id="pagerFileInput"
                      name="pagerFile"
                      type="file"
                      accept=".pptx, .pdf"
                      onChange={handlePagerFileChange}
                    />
                    {pagerUploadError && (
                      <p>
                        `An error occurred while uploading deck: {errorMsg} `
                      </p>
                    )}
                    {pagerUploadUnsupportedFile && (
                      <p>
                        Unsupported file type, please upload a .pptx or .pdf
                        file
                      </p>
                    )}
                  </div>
                  <div className="bo-button-list">
                    <label
                      htmlFor="pagerFileInput"
                      className="bo-button"
                      onClick={isUploadingPager ? null : undefined}>
                      {isUploadingPager
                        ? "Uploading file.."
                        : "Upload One Pager file"}
                    </label>
                  </div>
                </div>

                <div className="bo-form-input-group">
                  <div className="bo-form-input-container">
                    <label>
                      Offering Deck<span>*</span>
                    </label>
                    <CustomSelect
                      label="Select an offering deck"
                      value={selectedValues["offeringDeckId"] || ""}
                      items={
                        offeringDecks &&
                        offeringDecks.map(i => ({
                          id: i.id,
                          value: i.originalFileName,
                        }))
                      }
                      onChange={val => {
                        handleOption("offeringDeckId", val);
                      }}
                      isAutocomplete
                      closeOnSelect
                    />
                    <input
                      type="hidden"
                      name="offeringDeckId"
                      value={
                        selectedValues["offeringDeckId"]
                          ? offeringDecks?.find(
                              d =>
                                d.originalFileName ===
                                selectedValues["offeringDeckId"]
                            )?.id || ""
                          : ""
                      }
                      ref={register}
                    />
                    <div className="bo-form-chip-list">
                      {selectedValues["offeringDeckId"] && (
                        <div className="bo-form-chip">
                          {selectedValues["offeringDeckId"]}
                          <img
                            className="chip-icon"
                            src={CloseIcon}
                            alt=""
                            onClick={() =>
                              handleRemoveOptionValue("offeringDeckId")
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="bo-form-input-container">
                    <label>if no present, upload deck here</label>
                    <label htmlFor="deckFileInput" className="file-label">
                      {deckFile
                        ? "File: " + deckFile.name
                        : "Upload .pptx / .pdf file here"}
                    </label>
                    <input
                      id="deckFileInput"
                      name="deckFile"
                      type="file"
                      accept=".pptx, .pdf"
                      onChange={handleDeckFileChange}
                    />
                    {deckUploadError && (
                      <p>
                        `An error occurred while uploading deck: {errorMsg} `
                      </p>
                    )}
                    {deckUploadUnsupportedFile && (
                      <p>
                        Unsupported file type, please upload a .pptx or .pdf
                        file
                      </p>
                    )}
                  </div>
                  <div className="bo-button-list">
                    <label
                      htmlFor="deckFileInput"
                      className="bo-button"
                      onClick={isUploading ? null : undefined}>
                      {isUploading ? "Uploading file.." : "upload deck file"}
                    </label>
                  </div>
                </div>
                <div className="bo-form-input-container">
                  <div className="bo-form-subtitle">Page range within deck</div>
                  <label>pages</label>
                  <div className="bo-form-input-line">
                    <input
                      type="text"
                      name="offeringDeckPages"
                      ref={register}
                    />
                  </div>
                </div>
                {documentErrors && (
                  <div id="documentErrorBox">
                    <FileErrorsBox errors={documentErrors} />
                  </div>
                )}
              </div>
            </form>
            <div className="bo-submit-container">
              <button
                className="bo-button"
                disabled={!canPublish || loader || isLoadingQuals}
                onClick={() => {
                  handleSubmit(onSubmit)();
                }}>
                {loader ? "Saving offering" : "Submit offering"}
              </button>
            </div>
          </div>
        </section>
      </div>
    )
  );
};

export default OfferingModal;
